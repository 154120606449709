import { InitialFeedbackData } from "./InitParameters";
// This is the object which is used to open/close/update data to
var feedbackData = InitialFeedbackData;
// eslint-disable-next-line @typescript-eslint/naming-convention
export var InAppFeedbackInit = (function () {
    /*
    Register and Unregister is part of the boilerplate code to enable dynamic data passing
     */
    var register = function (listener) {
        if (listener) {
            listeners.push(listener);
        }
        return feedbackData;
    };
    var unregister = function (listener) {
        var index = listeners.indexOf(listener);
        if (index > -1) {
            listeners.splice(index, 1);
        }
    };
    var listeners = [];
    /*
    This is the list of function provided to update the data passed to Feedback
     */
    var updateFeedbackObject = function (updatedInAppFeedbackInitOptions) {
        // eslint-disable-next-line no-restricted-properties
        Object.assign(feedbackData, updatedInAppFeedbackInitOptions);
        listeners.forEach(function (listener) { return listener(feedbackData); });
    };
    var configObject = {
        register: register,
        unregister: unregister,
        updateFeedbackObject: updateFeedbackObject
    };
    return configObject;
})();

import {
  ClearPathSelection,
  DeepLinkDataIdRetrieveAction,
  IsWayFindingSupportedAction,
  IsWayFindingSupportedRetrievedAction,
  PathFindingRetrieveActions,
  PathFindingSelectionActions,
  PathRetrievedAction,
  RetrievingPathAction,
  SelectPathAction
} from "./PathFindingActions";
import { IMobileHandOffData } from "@smartbuilding/myhub-api-service";
import { IPathRequest } from "../Types";
import { IRouteData } from "@smartbuilding/azure-maps";

export function retrievingPath(request: IPathRequest): RetrievingPathAction {
  return {
    payload: request,
    type: PathFindingRetrieveActions.RETRIEVING_PATH
  };
}

export function pathRetrieved(
  request: IPathRequest,
  routeData: IRouteData | null,
  qrCodeParams: IMobileHandOffData | undefined,
  error?: boolean,
  errorCode?: string
): PathRetrievedAction {
  return {
    payload: { request, routeData, qrCodeParams },
    error: error,
    errorCode: errorCode,
    type: PathFindingRetrieveActions.PATH_RETRIEVED
  };
}

export function selectPath(request: IPathRequest): SelectPathAction {
  return {
    payload: request,
    type: PathFindingSelectionActions.SELECT_PATH
  };
}

export function clearPath(): ClearPathSelection {
  return {
    type: PathFindingSelectionActions.CLEAR_PATH_SELECTION
  };
}

export function isWayFindingSupported(floorId: string): IsWayFindingSupportedAction {
  return {
    payload: floorId,
    type: PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED
  };
}

export function isWayFindingSupportedRetrieved(
  floorId: string,
  isWayFindingSupportedStatus: boolean
): IsWayFindingSupportedRetrievedAction {
  return {
    payload: { floorId, isWayFindingSupportedStatus },
    type: PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED_RETRIEVED
  };
}

export function deepLinkDataIdRetrieved(qrcodeUrl: string, data: IMobileHandOffData): DeepLinkDataIdRetrieveAction {
  return {
    payload: { qrcodeUrl, data },
    type: PathFindingRetrieveActions.DEEP_LINK_DATA_ID_RETRIEVED
  };
}

import { ISearchResultItem, SearchFilter } from "./MenuSearch.Types";
import { ISearchResultItemProps, ISearchResultSectionProps } from "@smartbuilding/ui-components-search";
import { ILogger } from "@smartbuilding/log-provider";
import { ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";

export function sortBuildingCategories(categories: Record<string, ISpaceCategory>): RoomSubTypes[] {
  if (Object.keys(categories).length === 0) return [];
  const topLevelCategories = categories["root"].childCategories.filter((category) => category !== "DisabledSpaces");
  const sortedTopLevelCategories = topLevelCategories.sort(
    (a, b) => categories[a].webLogicalOrder - categories[b].webLogicalOrder
  );
  const sortedChildCategories: string[] = [];
  for (const parent of sortedTopLevelCategories) {
    const childrenMap = categories[parent].childCategoriesMap;
    Object.keys(childrenMap)
      .sort((a, b) => childrenMap[a] - childrenMap[b])
      .forEach((child) => {
        if (!sortedChildCategories.includes(child)) sortedChildCategories.push(child);
      });
  }
  const buildingRoomSubTypes: RoomSubTypes[] = [];
  for (const category of sortedChildCategories) {
    const categoryEnum = RoomSubTypes[category as keyof typeof RoomSubTypes];
    if (!categoryEnum) continue;
    buildingRoomSubTypes.push(categoryEnum);
  }
  return buildingRoomSubTypes;
}

export function sortSearchResults(list: ISearchResultItem[]): ISearchResultItem[] {
  return list.sort((a, b) => {
    const aNum = +a.sortBy.split(/[-.,_]/)[0];
    const bNum = +b.sortBy.split(/[-.,_]/)[0];

    if (!isNaN(aNum) && !isNaN(bNum)) return aNum - bNum;
    else if (!isNaN(aNum)) return -1;
    else if (!isNaN(bNum)) return 1;
    else return a.sortBy.localeCompare(b.sortBy);
  });
}

export function filterSearchResults(term: string, list: ISearchResultItem[]): ISearchResultItem[] {
  const matchTerm = term.replace(/\s/g, "").toUpperCase();
  return matchTerm
    ? list.filter((item) =>
        item.filterBy.some((filterText) => filterText.replace(/\s/g, "").toUpperCase().startsWith(matchTerm))
      )
    : list;
}

export function getSearchResultItems(list: ISearchResultItem[]): ISearchResultItemProps[] {
  return list.map(({ itemData }) => itemData);
}

export function filterAndSortResults(
  term: string,
  list: ISearchResultItem[],
  searchFilter: SearchFilter,
  logger: ILogger
): ISearchResultItemProps[] {
  const getSearchFilteredListStartTime = performance.now();
  const filteredList = filterSearchResults(term, list);
  const sortedList = sortSearchResults(filteredList);
  const searchResultItems = getSearchResultItems(sortedList);
  const getSearchFilteredListEndTime = performance.now();
  const totalGetSearchFilteredListTime = getSearchFilteredListEndTime - getSearchFilteredListStartTime;
  if (term && sortedList.length > 0) {
    const logData = {
      searchedTerm: term,
      timeInMs: totalGetSearchFilteredListTime.toString(),
      searchResultsCount: sortedList.length.toString(),
      searchFilterName: searchFilter
    };
    logger.logEvent(`[Menu Panel Search] Search Filter And Sort Results Time And Count`, logData);
  }
  return searchResultItems;
}

export function calculateSearchResults(list: ISearchResultSectionProps[]): Record<string, number> {
  const searchResults: Record<string, number> = {};
  list.forEach((category) => {
    searchResults[category.key] = category.items.length;
  });
  return searchResults;
}

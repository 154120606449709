export var AdalStorageKey;
(function (AdalStorageKey) {
    AdalStorageKey["AdalAccessTokenKey"] = "adal.access.token.key";
    AdalStorageKey["AdalExpirationKey"] = "adal.expiration.key";
    AdalStorageKey["AdalTokenRenewStatus"] = "adal.token.renew.status";
    AdalStorageKey["AdalTokenKeys"] = "adal.token.keys";
    AdalStorageKey["AdalLoginError"] = "adal.login.error";
    AdalStorageKey["AdalErrorDescription"] = "adal.error.description";
    AdalStorageKey["AdalError"] = "adal.error";
})(AdalStorageKey || (AdalStorageKey = {}));

import { Provider, serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { IWeatherService } from "@smartbuilding/weather-service";
import { MenuWeatherBase } from "./MenuWeather.Base";
import React from "react";
import { getLocation } from "../../../redux/Selectors";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useSelector } from "react-redux";

export function MenuWeather(): JSX.Element {
  const weatherService = useInjection<Provider<IWeatherService>>(serviceIdentifiers.weatherService);
  const location = useSelector(getLocation);

  return (
    <>
      {location && (
        <MenuWeatherBase
          latitude={location.latitude}
          longitude={location.longitude}
          weatherServiceProvider={weatherService}
        />
      )}
    </>
  );
}

import "reflect-metadata";
import {
  AppMetricsSaga,
  AppSaga,
  DeviceConfigSaga,
  MapSaga,
  PathFindingSaga,
  PeopleSaga,
  RootSaga,
  SpaceSaga
} from "../redux/Sagas";
import { AuthClient, CypressClient, IAuthClient } from "@smartbuilding/auth-client";
import {
  AutoCompleteProvider,
  AzureMapsTokenHttp,
  BadgeScanHttpProvider,
  BadgeScanService,
  IAutoCompleteProvider,
  IAzureMapsTokenHttp,
  IBadgeScanHttpProvider,
  IBadgeScanService,
  IPeopleHttpProvider,
  IRoomImageProvider,
  ISmartBuildingApiHttp,
  ISmartBuildingApiRoomHttp,
  ISpaceCategoriesService,
  PeopleHttpProvider,
  RoomImageProvider,
  SmartBuildingApiHttp,
  SmartBuildingApiRoomHttp,
  SpaceCategoriesService
} from "@smartbuilding/smartbuilding-api-service";
import { BrowserHistory, createBrowserHistory } from "history";
import {
  ClientDevice,
  Environment,
  FeatureFlightingService,
  IFeatureFlightingService,
  IUserRingService,
  SBClientUserRingService
} from "@smartbuilding/feature-flighting-service";
import { Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { ConfigurationService, IConfigurationService } from "@smartbuilding/configuration-provider";
import { ConsoleLogger, ILogger, LogService } from "@smartbuilding/log-provider";
import { Container, decorate, injectable } from "inversify";
import { DIPService, IDIPService } from "../services/DIPService/DIPService";
import {
  DIPToPointrMapper,
  DirectionsHttp,
  IDIPToPointrMapper,
  IDirectionsHttp,
  IDirectionsService,
  IPointRAuthTokenHttp,
  IPointRAuthTokenService,
  IPointRHttp,
  IPointRHttpWrapper,
  IPointrRoute,
  IRouteConverter,
  PointRAuthTokenHttp,
  PointRAuthTokenService,
  PointRHttp,
  PointRHttpWrapper,
  PointRService,
  PointrRouteConverter,
  RouteDataModel,
  WayfindingApiRouteConverter
} from "@smartbuilding/directions-service";
import { DurableConnectionBuilder, IDurableConnectionBuilder, IHubConfig } from "@dw/signalr-client";
import { HealthCheckService, IHealthCheckService } from "../services/HealthCheckService";
import { HttpService, IHttpService } from "@smartbuilding/smartbuilding-http-service";
import { ILocalBlobService, LocalBlobService } from "@smartbuilding/local-blob-service";
import {
  IMostSearchService,
  ISpaceSearchService,
  MostSearchService,
  SpaceSearchService
} from "@smartbuilding/search-service";
import {
  IMyHubUserHttpService,
  IMyHubUserService,
  MyHubUserHttpService,
  MyHubUserService
} from "@smartbuilding/myhub-api-service";
import { IPeopleRepo, IPeopleService, PeopleRepo, PeopleService } from "@smartbuilding/people-service";
import { IPoiDetailsService, PoiDetailsService } from "@smartbuilding/poi-service";
import {
  IRoomCardService,
  IRoomFeatureService,
  RoomCardService,
  RoomFeatureService
} from "@smartbuilding/room-card-service";
import { IRoomImageService, MrcdpRoomImageService } from "@smartbuilding/mrcdp-client";
import {
  ISensorReader,
  ISensorSubscriptionManager,
  ISensorValueService,
  SensorPipe,
  SensorSubscriptionManager
} from "@smartbuilding/space-sensors";
import {
  ISpaceHttpService,
  ISpaceRepo,
  IUserHttpService,
  IUserRepo,
  SpaceHttpService,
  SpaceRepo,
  UserHttpService,
  UserRepo
} from "@smartbuilding/adt-v2-api";
import {
  ISpaceSubscriptionHttpService,
  SpaceSubscriptionHttpService,
  SpaceSubscriptionService
} from "@smartbuilding/space-subscription";
import { ITimeoutService, TimeoutService } from "@smartbuilding/timeout-service";
import { IWeatherService, WeatherService } from "@smartbuilding/weather-service";
import { IWebClientConfiguration, SessionStorageKeys, configurationBaseApiUrl } from "../constants";
import { Provider, serviceIdentifiers } from "./ServiceIdentifiers";
import { AppBootstrapper } from "../AppBootstrapper";
import { HttpServiceInterceptor } from "../auth/HttpServiceInterceptor";
import { KeepPromise } from "./KeepPromise";
import { MapErrorObserver } from "../utilities/Observers/MapErrorObserver";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { SBClientPlugin } from "../telemetryPlugin/SBClientTelemetryPlugin";
import { SignalRSaga } from "@smartbuilding/signalr-redux";
import { electronService } from "@smartbuilding/electron-service";
import { store } from "../redux/Store";

export const container = new Container({ defaultScope: "Singleton" });

container.bind<Container>(serviceIdentifiers.inversifyContainer).toConstantValue(container);
container.bind<AppBootstrapper>(serviceIdentifiers.appBootstrapper).to(AppBootstrapper);
container
  .bind<IConfigurationService<IWebClientConfiguration>>(serviceIdentifiers.configService)
  .toDynamicValue((context) => {
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    return new ConfigurationService<IWebClientConfiguration>(configurationBaseApiUrl, httpService, logger);
  });

// Binding Helper Services
container.bind<BrowserHistory>(serviceIdentifiers.browserHistory).toConstantValue(createBrowserHistory());
container.bind<ReactPlugin>(serviceIdentifiers.telemetryReactPlugin).toConstantValue(new ReactPlugin());
if (window.location.href.includes("localhost")) {
  container.bind<ILogger>(serviceIdentifiers.logger).toConstantValue(new ConsoleLogger());
} else {
  container.bind<ILogger>(serviceIdentifiers.logger).toDynamicValue((context) => {
    const reactPlugin = context.container.get<ReactPlugin>(serviceIdentifiers.telemetryReactPlugin);
    const browserHistory = context.container.get<BrowserHistory>(serviceIdentifiers.browserHistory);
    const logService = new LogService(
      sessionStorage.getItem(SessionStorageKeys.APPINSIGHTS_INSTRUMENTATIONKEY) ?? "",
      [
        reactPlugin,
        new SBClientPlugin({
          ComponentId: sessionStorage.getItem(SessionStorageKeys.ComponentId) ?? "",
          ComponentName: sessionStorage.getItem(SessionStorageKeys.ComponentName) ?? "",
          Service: sessionStorage.getItem(SessionStorageKeys.Service) ?? "",
          ServiceLine: sessionStorage.getItem(SessionStorageKeys.ServiceLine) ?? "",
          ServiceOffering: sessionStorage.getItem(SessionStorageKeys.ServiceOffering) ?? "",
          EnvironmentName: sessionStorage.getItem(SessionStorageKeys.Environment) ?? ""
        })
      ],
      { [reactPlugin.identifier]: { history: browserHistory } }
    );

    sessionStorage.removeItem(SessionStorageKeys.APPINSIGHTS_INSTRUMENTATIONKEY);
    return logService;
  });
}

container.bind<Configuration>(serviceIdentifiers.msalConfig).toDynamicValue((context) => {
  const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
  return {
    auth: {
      clientId: `${sessionStorage.getItem(SessionStorageKeys.AadAppId)}`,
      authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
      redirectUri: `${window.location.origin}/`,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      iframeTimeout: 60000,
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              logger.logError(new Error(message));
              return;
            case LogLevel.Info:
              logger.logTrace(message);
              return;
            case LogLevel.Verbose:
              logger.logTrace(message);
              return;
            case LogLevel.Warning:
              logger.logWarning(message);
              return;
          }
        }
      }
    }
  };
});
container.bind<IAuthClient>(serviceIdentifiers.authClient).toDynamicValue((context) => {
  const msalConfig = context.container.get<Configuration>(serviceIdentifiers.msalConfig);
  const logger = context.container.get<ILogger>(serviceIdentifiers.logger);

  if (sessionStorage.getItem("CYPRESS")) {
    return new CypressClient(msalConfig.auth.clientId);
  } else {
    return new AuthClient(new PublicClientApplication(msalConfig), logger);
  }
});

// Binding Http Services
container.bind<HttpServiceInterceptor>(serviceIdentifiers.httpServiceInterceptor).to(HttpServiceInterceptor);
container.bind<IHttpService>(serviceIdentifiers.httpService).toDynamicValue((context) => {
  const interceptorService = context.container.get<HttpServiceInterceptor>(serviceIdentifiers.httpServiceInterceptor);
  return new HttpService({ baseURL: "" }, interceptorService.getHttpServiceInterceptors());
});
container.bind<ITimeoutService>(serviceIdentifiers.timeoutService).toConstantValue(new TimeoutService());
container.bind<ILocalBlobService>(serviceIdentifiers.localBlobService).toDynamicValue((context) => {
  const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
  return new LocalBlobService(httpService);
});

// Binding Updated DIP service
container.bind<Provider<IDIPService>>(serviceIdentifiers.dipService).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const authClient = context.container.get<IAuthClient>(serviceIdentifiers.authClient);
    return Promise.all([
      configService.getSetting("DTDLAadResourceId"),
      configService.getSetting("DTDLv3Url"),
      configService.getSetting("DIPSubscriptionKey")
    ]).then(([dipAad, dipURL, dipSubKey]) => {
      return new DIPService(authClient, dipAad as string, dipURL as string, dipSubKey as string);
    });
  });
});

// Binding Space Services (DTDL)
container.bind<Provider<ISpaceHttpService>>(serviceIdentifiers.spaceHttp).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("DTDLUrl").then((url) => {
      return new SpaceHttpService(url as string, logger, httpService);
    });
  });
});
container.bind<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo).toProvider((context) => {
  return KeepPromise(async () => {
    const spaceHttp = context.container.get<Provider<ISpaceHttpService>>(serviceIdentifiers.spaceHttp)();
    return new SpaceRepo(await spaceHttp);
  });
});

//Binding BadgeScan service
container.bind<Provider<IBadgeScanHttpProvider>>(serviceIdentifiers.badgeScanApiHttp).toProvider((context) => {
  return KeepPromise(async () => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("SmartBuildingApiUrl").then((url) => {
      return new BadgeScanHttpProvider(httpService, url as string);
    });
  });
});
container.bind<Provider<IBadgeScanService>>(serviceIdentifiers.badgeScanServiceIdentifier).toProvider((context) => {
  return KeepPromise(async () => {
    const badgescanHttpProvider = context.container.get<Provider<IBadgeScanHttpProvider>>(
      serviceIdentifiers.badgeScanApiHttp
    );
    return new BadgeScanService(await badgescanHttpProvider());
  });
});

//Binding auto-complete
container.bind<Provider<IPeopleHttpProvider>>(serviceIdentifiers.peopleHttp).toProvider((context) => {
  return KeepPromise(async () => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("SmartBuildingApiUrl").then((url) => {
      return new PeopleHttpProvider(httpService, url as string);
    });
  });
});
container.bind<Provider<IAutoCompleteProvider>>(serviceIdentifiers.autoComplete).toProvider((context) => {
  return KeepPromise(async () => {
    const peopleHttpProvider = context.container.get<Provider<IPeopleHttpProvider>>(serviceIdentifiers.peopleHttp);
    return new AutoCompleteProvider(await peopleHttpProvider());
  });
});

container.bind<IAzureMapsTokenHttp>(serviceIdentifiers.azureMapsTokenHttp).toDynamicValue((context) => {
  const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
  const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
  return new AzureMapsTokenHttp(httpService, logger, sessionStorage.getItem(SessionStorageKeys.Environment) ?? "");
});

// Binding User repo and user http service
container.bind<Provider<IUserHttpService>>(serviceIdentifiers.userHttpService).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("DTDLUrl").then((url) => {
      return new UserHttpService(url as string, logger, httpService);
    });
  });
});

container.bind<Provider<IUserRepo>>(serviceIdentifiers.userRepo).toProvider((context) => {
  return KeepPromise(() => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const userHttpProvider = context.container.get<Provider<IUserHttpService>>(serviceIdentifiers.userHttpService);
    return userHttpProvider().then((userHttp) => {
      return new UserRepo(logger, userHttp);
    });
  });
});

// Binding Weather Services
container.bind<Provider<IWeatherService>>(serviceIdentifiers.weatherService).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    const azureMapsTokenHttp = context.container.get<IAzureMapsTokenHttp>(serviceIdentifiers.azureMapsTokenHttp);
    return configService.getSetting("AzureMapsClientId").then((clientId) => {
      return new WeatherService(httpService, azureMapsTokenHttp, clientId as string);
    });
  });
});

// Binding Sensor Subscription Modules
container.bind<Provider<IHubConfig>>(serviceIdentifiers.hubConfig).toProvider((context) => {
  return KeepPromise(() => {
    const config = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    return Promise.all([
      config.getSetting("SpaceStateSignalRHubV2"),
      config.getSetting("NotificationApiUrlV2"),
      config.getSetting("NotificationApiAAD")
    ]).then(([signalRHub, notificationApiUrl, notificationApiAAD]) => {
      return {
        hubName: signalRHub as string,
        tokenEndpointUrl: notificationApiAAD as string,
        url: notificationApiUrl as string
      };
    });
  });
});
container
  .bind<Provider<IDurableConnectionBuilder>>(serviceIdentifiers.durableConnectionBuilder)
  .toProvider((context) => {
    return KeepPromise(() => {
      const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
        serviceIdentifiers.configService
      );
      return configService.getSetting("SignalRRefreshTimeout").then((serverTimeout) => {
        return new DurableConnectionBuilder(serverTimeout as number);
      });
    });
  });
// bining calls to Notification api v2 for subscribing and unsubscribing
container
  .bind<Provider<ISpaceSubscriptionHttpService>>(serviceIdentifiers.spaceSubscriptionHttpService)
  .toProvider((context) => {
    return KeepPromise(() => {
      const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
        serviceIdentifiers.configService
      );
      const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
      const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
      return configService.getSetting("NotificationApiUrlV2").then((url) => {
        return new SpaceSubscriptionHttpService(url as string, logger, httpService);
      });
    });
  });
container
  .bind<Provider<ISensorValueService<string>>>(serviceIdentifiers.spaceSubscriptionService)
  .toProvider((context) => {
    return KeepPromise(() => {
      const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
      const dConnectionBld = context.container.get<Provider<IDurableConnectionBuilder>>(
        serviceIdentifiers.durableConnectionBuilder
      );
      const authClient = context.container.get<IAuthClient>(serviceIdentifiers.authClient);
      const hubConfig = context.container.get<Provider<IHubConfig>>(serviceIdentifiers.hubConfig);
      const spaceSubscriptionHttp = context.container.get<Provider<ISpaceSubscriptionHttpService>>(
        serviceIdentifiers.spaceSubscriptionHttpService
      );
      return Promise.all([dConnectionBld(), hubConfig()]).then(([durableConnection, config]) => {
        return spaceSubscriptionHttp().then((spaceSubscriptionHttp) => {
          return new SpaceSubscriptionService(
            logger,
            durableConnection,
            authClient,
            spaceSubscriptionHttp,
            config,
            true
          );
        });
      });
    });
  });
container
  .bind<ISensorSubscriptionManager>(serviceIdentifiers.sensorSubscription)
  .toConstantValue(new SensorSubscriptionManager());

decorate(injectable, SensorPipe);
container.bind<ISensorReader<string>>(serviceIdentifiers.sensorReader).toConstructor(SensorPipe);

container.bind<Provider<IRoomImageService>>(serviceIdentifiers.roomImageService).toProvider((context) => {
  return KeepPromise(async () => {
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return new MrcdpRoomImageService(httpService);
  });
});

container.bind<Provider<IRoomImageProvider>>(serviceIdentifiers.roomImageProvider).toProvider((context) => {
  return KeepPromise(async () => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("SmartBuildingApiUrl").then((url) => {
      return new RoomImageProvider(httpService, url as string);
    });
  });
});

// Binding meeting room service
container.bind<Provider<IRoomCardService>>(serviceIdentifiers.roomCardService).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const roomImageService = context.container.get<Provider<IRoomImageService>>(serviceIdentifiers.roomImageService)();
    const spaceRepo = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo)();
    return new RoomCardService(logger, await spaceRepo, await roomImageService);
  });
});

// Binding poi details service
container.bind<Provider<IPoiDetailsService>>(serviceIdentifiers.poiDetailsService).toProvider((context) => {
  return KeepPromise(() => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const spaceRepoProvider = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo);
    return spaceRepoProvider().then((spaceRepo) => {
      return new PoiDetailsService(logger, spaceRepo);
    });
  });
});

container
  .bind<Provider<ISmartBuildingApiRoomHttp>>(serviceIdentifiers.smartBuildingApiRoomHttpService)
  .toProvider((context) => {
    return KeepPromise(() => {
      const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
        serviceIdentifiers.configService
      );
      const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
      const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
      return configService.getSetting("SmartBuildingApiUrl").then((url) => {
        return new SmartBuildingApiRoomHttp(httpService, url as string, logger);
      });
    });
  });

// Binding meeting room feature
container.bind<Provider<IRoomFeatureService>>(serviceIdentifiers.roomFeatureService).toProvider((context) => {
  return KeepPromise(() => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const spaceRepoProvider = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo);
    return spaceRepoProvider().then((spaceRepo) => {
      return new RoomFeatureService(logger, spaceRepo);
    });
  });
});

// Binding Search related services
container.bind<IMostSearchService>(serviceIdentifiers.mostSearchedService).toConstantValue(new MostSearchService());
container.bind<Provider<ISpaceSearchService>>(serviceIdentifiers.spaceSearchService).toProvider((context) => {
  return KeepPromise(() => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const spaceRepoProvider = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo);
    return spaceRepoProvider().then((spaceRepo: ISpaceRepo) => {
      return new SpaceSearchService(logger, spaceRepo);
    });
  });
});

// Binding directions service
container.bind<Provider<IDIPToPointrMapper>>(serviceIdentifiers.dipToPointRMapper).toProvider((context) => {
  return KeepPromise(async () => {
    const pointRHttp = context.container.get<Provider<PointRHttp>>(serviceIdentifiers.pointrHttp);
    return new DIPToPointrMapper(await pointRHttp());
  });
});
container
  .bind<Provider<IRouteConverter<RouteDataModel | IPointrRoute>>>(serviceIdentifiers.routeConverter)
  .toProvider((context) => {
    return KeepPromise(async () => {
      const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
        serviceIdentifiers.configService
      );
      const isPointREnabled = (await configService.getSetting("EnablePointR")) as string;
      if (isPointREnabled.toLowerCase() === "true") {
        const dipToPointRMapper = context.container.get<Provider<IDIPToPointrMapper>>(
          serviceIdentifiers.dipToPointRMapper
        );
        return new PointrRouteConverter(await dipToPointRMapper());
      } else {
        return new WayfindingApiRouteConverter();
      }
    });
  });
container.bind<Provider<IDirectionsHttp>>(serviceIdentifiers.directionsServiceHttp).toProvider((context) => {
  return KeepPromise(() => {
    const configProvider = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configProvider.getSetting("WayfindingAPIMUrl").then((wayfindingApiUrl) => {
      return new DirectionsHttp(httpService, wayfindingApiUrl as string);
    });
  });
});
container.bind<Provider<IPointRAuthTokenHttp>>(serviceIdentifiers.pointrAuthTokenHttp).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    return Promise.all([
      configService.getSetting("PointRAPIUrl"),
      configService.getSetting("PointRAuthUsername"),
      configService.getSetting("PointRAuthPassword")
    ]).then(
      ([pointRUrl, pointRUsername, pointRPassword]) =>
        new PointRAuthTokenHttp(
          httpService,
          pointRUrl as string,
          pointRUsername as string,
          pointRPassword as string,
          logger
        )
    );
  });
});
container.bind<Provider<IPointRAuthTokenService>>(serviceIdentifiers.pointrAuthTokenService).toProvider((context) => {
  return KeepPromise(async () => {
    const pointrAuthTokenHttp = context.container.get<Provider<IPointRAuthTokenHttp>>(
      serviceIdentifiers.pointrAuthTokenHttp
    );
    return new PointRAuthTokenService(await pointrAuthTokenHttp());
  });
});
container.bind<Provider<IPointRHttp>>(serviceIdentifiers.pointrHttp).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return Promise.all([configService.getSetting("PointRAPIUrl"), configService.getSetting("PointRMSFTClientId")]).then(
      ([pointRUrl, pointRMSFTClientId]) =>
        new PointRHttp(pointRUrl as string, pointRMSFTClientId as string, httpService)
    );
  });
});
container.bind<Provider<IPointRHttpWrapper>>(serviceIdentifiers.pointrHttpWrapper).toProvider((context) => {
  return KeepPromise(async () => {
    const dipToPointRMapper = context.container.get<Provider<DIPToPointrMapper>>(serviceIdentifiers.dipToPointRMapper);
    const routeConverter = context.container.get<Provider<IRouteConverter<IPointrRoute>>>(
      serviceIdentifiers.routeConverter
    );
    const pointRHttp = context.container.get<Provider<IPointRHttp>>(serviceIdentifiers.pointrHttp);
    return new PointRHttpWrapper(await pointRHttp(), await routeConverter(), await dipToPointRMapper());
  });
});
container.bind<Provider<IDirectionsService>>(serviceIdentifiers.directionsService).toProvider((context) => {
  return KeepPromise(async () => {
    const httpWrapper = context.container.get<Provider<IPointRHttpWrapper>>(serviceIdentifiers.pointrHttpWrapper);
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    return new PointRService(await httpWrapper(), logger);
  });
});

// People Service bindings
container.bind<Provider<IPeopleRepo>>(serviceIdentifiers.peopleRepo).toProvider((context) => {
  return KeepPromise(() => {
    const userRepoProvider = context.container.get<Provider<IUserRepo>>(serviceIdentifiers.userRepo);
    const spaceRepoProvider = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo);
    return Promise.all([userRepoProvider(), spaceRepoProvider()]).then(([userRepo, spaceRepo]) => {
      return new PeopleRepo(userRepo, spaceRepo);
    });
  });
});
container.bind<Provider<IPeopleService>>(serviceIdentifiers.peopleService).toProvider((context) => {
  return KeepPromise(() => {
    const localBlobService = context.container.get<ILocalBlobService>(serviceIdentifiers.localBlobService);
    const peopleRepoProvider = context.container.get<Provider<IPeopleRepo>>(serviceIdentifiers.peopleRepo);
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    return Promise.all([
      peopleRepoProvider(),
      configService.getSetting("GraphApiAddress"),
      configService.getSetting("GraphApiGetPhotoOfSizeRelativeUrl"),
      configService.getSetting("GraphApiGetPhotoRelativeUrl")
    ]).then(([peopleRepo, graphApiAddress, graphGetPhotoOfSizeRelativeUrl, graphApiGetPhotoRelativeUrl]) => {
      return new PeopleService(peopleRepo, localBlobService, {
        graphApi: graphApiAddress as string,
        graphApiGetPhotoOfSizeRelativeUrl: graphGetPhotoOfSizeRelativeUrl as string,
        graphApiGetPhotoRelativeUrl: graphApiGetPhotoRelativeUrl as string
      });
    });
  });
});

container.bind<Provider<ISmartBuildingApiHttp>>(serviceIdentifiers.smartBuildingApiHttp).toProvider((context) => {
  return KeepPromise(async () => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return new SmartBuildingApiHttp(httpService, (await configService.getSetting("SmartBuildingApiUrl")) as string);
  });
});
container.bind<Provider<ISpaceCategoriesService>>(serviceIdentifiers.spaceCategoryService).toProvider((context) => {
  return KeepPromise(async () => {
    const smartBuildingApiHttp = context.container.get<Provider<ISmartBuildingApiHttp>>(
      serviceIdentifiers.smartBuildingApiHttp
    );
    return new SpaceCategoriesService(await smartBuildingApiHttp());
  });
});

// Feature Flighting Service
container.bind<Provider<IUserRingService>>(serviceIdentifiers.userRingService).toProvider((context) => {
  return KeepPromise(async () => {
    const authClient = context.container.get<IAuthClient>(serviceIdentifiers.authClient);
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const smartBuildingApiHttp = context.container.get<Provider<ISmartBuildingApiHttp>>(
      serviceIdentifiers.smartBuildingApiHttp
    );

    return new SBClientUserRingService(authClient, await smartBuildingApiHttp(), logger);
  });
});
container.bind<Provider<IFeatureFlightingService>>(serviceIdentifiers.featureFlightingService).toProvider((context) => {
  return KeepPromise(async () => {
    const userRingServiceProvider = context.container.get<Provider<IUserRingService>>(
      serviceIdentifiers.userRingService
    );
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const [userRingService, environment, azureExPEndpoint] = await Promise.all([
      userRingServiceProvider(),
      configService.getSetting("Environment"),
      configService.getSetting("AzureExperimentationEndpoint")
    ]);

    const clientDevice = electronService.isElectron() ? ClientDevice.Kiosk : ClientDevice.Web;
    return new FeatureFlightingService(
      userRingService,
      logger,
      { clientDevice, environment: environment as Environment },
      { endpoint: azureExPEndpoint as string }
    );
  });
});

// Redux sagas
container.bind<Provider<AppMetricsSaga>>(serviceIdentifiers.appMetricsSaga).toProvider((context) => {
  return KeepPromise(() => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    return Promise.resolve(new AppMetricsSaga(logger, configService));
  });
});
container.bind<Provider<SpaceSaga>>(serviceIdentifiers.spaceSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const imgService = context.container.get<Provider<IRoomImageService>>(serviceIdentifiers.roomImageService)();
    const imgProvider = context.container.get<Provider<IRoomImageProvider>>(serviceIdentifiers.roomImageProvider)();
    const poiService = context.container.get<Provider<IPoiDetailsService>>(serviceIdentifiers.poiDetailsService)();
    const spaceRepo = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo)();
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const peopleService = context.container.get<Provider<IPeopleService>>(serviceIdentifiers.peopleService)();
    const spaceCategoryService = context.container.get<Provider<ISpaceCategoriesService>>(
      serviceIdentifiers.spaceCategoryService
    )();
    return new SpaceSaga(
      await spaceRepo,
      await imgService,
      await imgProvider,
      await poiService,
      await peopleService,
      logger,
      configService,
      await spaceCategoryService
    );
  });
});

container.bind<Provider<AppSaga>>(serviceIdentifiers.appSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    return new AppSaga(logger);
  });
});

container.bind<Provider<SignalRSaga>>(serviceIdentifiers.signalRSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const spaceRepo = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo)();
    const spaceSubscribe = context.container.get<Provider<ISensorValueService<string>>>(
      serviceIdentifiers.spaceSubscriptionService
    )();
    return new SignalRSaga(await spaceRepo, logger, await spaceSubscribe, store);
  });
});

container.bind<Provider<DeviceConfigSaga>>(serviceIdentifiers.deviceSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const spaceRepo = context.container.get<Provider<ISpaceRepo>>(serviceIdentifiers.spaceRepo)();
    return new DeviceConfigSaga(logger, await spaceRepo);
  });
});

container.bind<MapSaga>(serviceIdentifiers.mapSaga).toConstantValue(new MapSaga());
container.bind<Provider<PeopleSaga>>(serviceIdentifiers.peopleSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const peopleService = context.container.get<Provider<IPeopleService>>(serviceIdentifiers.peopleService);

    const badgeScanService = context.container.get<Provider<IBadgeScanService>>(
      serviceIdentifiers.badgeScanServiceIdentifier
    )();

    const autoComplete = context.container.get<Provider<IAutoCompleteProvider>>(serviceIdentifiers.autoComplete)();

    return new PeopleSaga(logger, await peopleService(), await badgeScanService, await autoComplete);
  });
});
container.bind<Provider<PathFindingSaga>>(serviceIdentifiers.pathFindingSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const directionsService = context.container.get<Provider<IDirectionsService>>(serviceIdentifiers.directionsService);
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const myhubUserService = context.container.get<Provider<IMyHubUserService>>(serviceIdentifiers.myhubUserService);

    return new PathFindingSaga(logger, await directionsService(), configService, await myhubUserService());
  });
});

container.bind<Provider<RootSaga>>(serviceIdentifiers.rootSaga).toProvider((context) => {
  return KeepPromise(async () => {
    const appMetricsSaga = context.container.get<Provider<AppMetricsSaga>>(serviceIdentifiers.appMetricsSaga)();
    const spaceSaga = context.container.get<Provider<SpaceSaga>>(serviceIdentifiers.spaceSaga)();
    const appSaga = context.container.get<Provider<AppSaga>>(serviceIdentifiers.appSaga)();
    const configSaga = context.container.get<Provider<DeviceConfigSaga>>(serviceIdentifiers.deviceSaga)();
    const pathFindingSaga = context.container.get<Provider<PathFindingSaga>>(serviceIdentifiers.pathFindingSaga)();
    const peopleSaga = context.container.get<Provider<PeopleSaga>>(serviceIdentifiers.peopleSaga)();
    const mapSaga = context.container.get<MapSaga>(serviceIdentifiers.mapSaga);
    const signalRSaga = context.container.get<Provider<SignalRSaga>>(serviceIdentifiers.signalRSaga)();

    return new RootSaga(
      mapSaga,
      await spaceSaga,
      await appSaga,
      await peopleSaga,
      await pathFindingSaga,
      await appMetricsSaga,
      await configSaga,
      await signalRSaga
    );
  });
});

// Binding Health Check Service
container.bind<Provider<IHealthCheckService>>(serviceIdentifiers.healthCheckService).toProvider((context) => {
  return KeepPromise(async () => {
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const kioskDeviceInfo = await electronService.getDeviceInfoAsync();
    return new HealthCheckService(logger, kioskDeviceInfo);
  });
});

// Contains callback functions that will be triggered when an error event occurs in Azure Maps.
container.bind<MapErrorObserver>(serviceIdentifiers.mapObserver).toDynamicValue((context) => {
  const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
  return new MapErrorObserver(logger);
});

//Binding MyHub User API
container.bind<IMyHubUserHttpService>(serviceIdentifiers.myhubUserHttpService).toProvider((context) => {
  return KeepPromise(() => {
    const configService = context.container.get<IConfigurationService<IWebClientConfiguration>>(
      serviceIdentifiers.configService
    );
    const logger = context.container.get<ILogger>(serviceIdentifiers.logger);
    const httpService = context.container.get<IHttpService>(serviceIdentifiers.httpService);
    return configService.getSetting("MyHubUserURL").then((url) => {
      return new MyHubUserHttpService(url as string, logger, httpService);
    });
  });
});

container.bind<Provider<IMyHubUserService>>(serviceIdentifiers.myhubUserService).toProvider((context) => {
  return KeepPromise(async () => {
    const myhubHttpProvider = context.container.get<Provider<IMyHubUserHttpService>>(
      serviceIdentifiers.myhubUserHttpService
    );
    return new MyHubUserService(await myhubHttpProvider());
  });
});

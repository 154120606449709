import { getBootstrapperError, getHttpError, getMapError, getSagaError } from "../redux/Selectors/ErrorSelector";
import { useSelector } from "react-redux";

// Use this file to perform additional logging/handling of errors depending on the error type.
// The error must be thrown so that it can be caught by the error boundary.

export const useErrorCheck = (): void => {
  const mapError = useSelector(getMapError);
  const sagaError = useSelector(getSagaError);
  const httpError = useSelector(getHttpError);
  const bootstrapperError = useSelector(getBootstrapperError);

  if (mapError) {
    throw mapError;
  }

  if (sagaError) {
    throw sagaError;
  }

  if (httpError) {
    throw httpError;
  }

  if (bootstrapperError) {
    throw bootstrapperError;
  }
};

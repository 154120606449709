import "azure-maps-control/dist/atlas.min.css";
import "azure-maps-indoor/dist/atlas-indoor.min.css";
import { AuthenticationType, Map } from "azure-maps-control";
import { BluedotMapMarker } from "../MapMarkers/BluedotMapMarker";
import { LogService } from "@smartbuilding/log-provider";
import { BuildingOverlayMapLayer } from "../MapLayers/BuildingLayer/BuildingOverlayMapLayer";
import { IndoorAzureMap } from "../IndoorAzureMap/IndoorAzureMap";
import { MapStyles } from "../Types/MapStyles";
import { VehicleTrackingLayer } from "../MapLayers/TransportationLayer/VehicleTrackingLayer";
var OutdoorAzureMap = /** @class */ (function () {
    function OutdoorAzureMap(containerElem, clientId, tokenFunction, theme, zoom, getAzureMapsDataCallback, observers, logger, telemetryCallback, iKey) {
        if (iKey === void 0) { iKey = "3e382ce9-6d18-4278-bba5-18479edd88ab"; }
        var _this = this;
        this.observers = observers;
        this.telemetryCallback = telemetryCallback;
        logger ? (this.logger = logger) : (this.logger = new LogService(iKey));
        var loadStart = performance.now();
        this.map = new Map(containerElem, {
            authOptions: {
                authType: AuthenticationType.anonymous,
                clientId: clientId,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getToken: function (resolve, reject, map) {
                    tokenFunction()
                        .then(function (token) { return resolve(token); })
                        .catch(function (error) {
                        reject(error);
                    });
                }
            },
            showFeedbackLink: false,
            showLogo: false,
            zoom: zoom,
            style: theme
        });
        this.map.events.addOnce("load", function () {
            var _a;
            var loadEnd = performance.now();
            _this.logger.logEvent("[AzureMapLoadTime] Outdoor map load time", {
                timeInMs: performance.now() - loadStart
            });
            (_a = _this.telemetryCallback) === null || _a === void 0 ? void 0 : _a.call(_this, {
                messageName: "[AzureMapLoadTime] Outdoor map load time",
                timeInMs: loadEnd - loadStart,
                loadEndtime: loadEnd,
                epochTimeMs: Date.now()
            });
        });
        this.indoorMap = new IndoorAzureMap(this.map, this.logger, getAzureMapsDataCallback, this.telemetryCallback);
        this.registerErrorHandler();
    }
    OutdoorAzureMap.prototype.setOutdoorMapCenter = function (lat, lng) {
        var currentCenter = this.map.getCamera().center;
        if (currentCenter && currentCenter[0] === lng && currentCenter[1] === lat) {
            return;
        }
        this.map.setCamera({ center: [lng, lat] });
    };
    OutdoorAzureMap.prototype.createIndoorMap = function (mapOptions) {
        this.indoorMap.updateIndoorMap(mapOptions);
        return this.indoorMap;
    };
    OutdoorAzureMap.prototype.removeIndoorMap = function () {
        this.indoorMap.dispose();
    };
    OutdoorAzureMap.prototype.zoomIn = function (zoomStep) {
        var _a;
        if (zoomStep === void 0) { zoomStep = 0.5; }
        var currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
        if (currentZoom) {
            this.map.setCamera({ zoom: currentZoom + zoomStep });
        }
    };
    OutdoorAzureMap.prototype.zoomOut = function (zoomStep) {
        var _a;
        if (zoomStep === void 0) { zoomStep = 0.5; }
        var currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
        if (currentZoom) {
            this.map.setCamera({ zoom: currentZoom - zoomStep });
        }
    };
    OutdoorAzureMap.prototype.setZoom = function (zoomLevel) {
        this.map.setCamera({ zoom: zoomLevel });
    };
    OutdoorAzureMap.prototype.createBuildingClusteringMapLayer = function (buildingData, onBuildingClick) {
        if (!this.buildingClusteringLayer) {
            this.buildingClusteringLayer = new BuildingOverlayMapLayer(this.map, buildingData, onBuildingClick, this.telemetryCallback);
        }
        else {
            this.buildingClusteringLayer.toggleBuildingOverlayVisibility(true);
        }
    };
    OutdoorAzureMap.prototype.removeBuildingClusteringMapLayer = function () {
        this.buildingClusteringLayer && this.buildingClusteringLayer.toggleBuildingOverlayVisibility(false);
    };
    OutdoorAzureMap.prototype.createBluedot = function (position, locationMode) {
        this.bluedotMarker = new BluedotMapMarker(this.map, position, locationMode);
    };
    OutdoorAzureMap.prototype.removeBluedot = function () {
        this.bluedotMarker && this.bluedotMarker.removeBluedot();
    };
    OutdoorAzureMap.prototype.updateOutdoorMap = function (mapOptions) {
        if (mapOptions.lat && mapOptions.lng) {
            this.setOutdoorMapCenter(mapOptions.lat, mapOptions.lng);
            this.map.setCamera({ center: [mapOptions.lat, mapOptions.lng] });
        }
        if (mapOptions.theme) {
            this.map.setStyle({ style: mapOptions.theme });
        }
    };
    OutdoorAzureMap.prototype.getMapStyle = function () {
        return this.map.getStyle().style;
    };
    OutdoorAzureMap.prototype.toggleMapTheme = function () {
        var _a;
        if (this.getMapStyle() === MapStyles.Dark) {
            this.updateMapStyle(MapStyles.Light);
        }
        else {
            this.updateMapStyle(MapStyles.Dark);
        }
        (_a = this.buildingClusteringLayer) === null || _a === void 0 ? void 0 : _a.updateBuildingOverlayStyle();
        this.indoorMap.toggleIndoorMapTheme();
    };
    OutdoorAzureMap.prototype.updateMapStyle = function (selectedStyle) {
        if (Object.values(MapStyles).includes(selectedStyle)) {
            this.map.setStyle({ style: selectedStyle });
        }
    };
    OutdoorAzureMap.prototype.setMapRotation = function (rotation) {
        var currentRotation = this.map.getCamera().bearing;
        if (currentRotation && currentRotation !== rotation) {
            this.map.setCamera({ bearing: rotation });
        }
    };
    OutdoorAzureMap.prototype.addOrUpdateVehicleTrackingLayer = function (vehicleTracking) {
        if (this.vehicleTrackingLayer === undefined) {
            this.vehicleTrackingLayer = new VehicleTrackingLayer(this.map);
        }
        this.vehicleTrackingLayer.addOrUpdateVehicleTrackingLayer(vehicleTracking);
    };
    OutdoorAzureMap.prototype.removeVehicleTrackingLayer = function () {
        if (this.vehicleTrackingLayer === undefined)
            return;
        this.vehicleTrackingLayer.dispose();
        this.vehicleTrackingLayer = undefined;
    };
    OutdoorAzureMap.prototype.registerErrorHandler = function () {
        var _this = this;
        this.map.events.add("error", function (mapError) {
            var _a, _b, _c;
            (_c = (_b = (_a = _this.observers) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.notify) === null || _c === void 0 ? void 0 : _c.call(_b, mapError);
        });
    };
    return OutdoorAzureMap;
}());
export { OutdoorAzureMap };

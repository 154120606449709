import { GenericSpaceCard, PageStatus } from "@smartbuilding/ui-components-space-card";
import { ISpaceStatusLabelProps, SpaceStatusColor } from "@smartbuilding/ui-components-space-status";
import { OccupancyStatusDataValue, RoomSubTypes } from "@smartbuilding/adt-v2-types";
import React, { useEffect, useState } from "react";
import { SpaceTypeNames, getStatusIcon } from "@smartbuilding/utilities";
import {
  getBuildingName,
  getFloors,
  getIsPeopleFeatureDisabled,
  getListOfPeopleAtSpace,
  getPersonImageMap,
  getSelectedPathMetadata,
  getSpaceBusynessRuleSet,
  getWebSensorLayerEnabledArray
} from "../../../redux/Selectors";
import { getIsDetailsPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { useDispatch, useSelector } from "react-redux";
import { IAuthClient } from "@smartbuilding/auth-client";
import { IDetailsPanelSpaceCardProps } from "../DetailsPanelProps";
import { ILogger } from "@smartbuilding/log-provider";
import { ImageSize } from "../../../redux/Types";
import { PersonaList } from "@smartbuilding/ui-components-persona";
import { RootState } from "../../../redux/Reducers/RootReducer";
import { electronService } from "@smartbuilding/electron-service";
import { getSensorData } from "@smartbuilding/signalr-redux";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { toggleDetailsPanelAction } from "../../../redux/Actions/LayoutActionCreator";
import { useDirectionController } from "../useDirectionController";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useMyHubPathFindingQRCodeUrl } from "../useMyHubPathFindingQRCodeURL";
import { useRoomStatus } from "../../../hooks/useRoomStatus";
import { useSpaceCardStyles } from "./useSpaceCardStyles";

export function SpaceCard(props: IDetailsPanelSpaceCardProps): JSX.Element {
  const { selectedSpace, onPanelBackClickCallback, onPanelDismissCallback } = props;
  const styles = useSpaceCardStyles();
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const loggerMsgTitle = "Space Card";
  const peopleTitle = "People";
  const noEmployeeText = "There are no employees currently assigned to this office.";
  const authClient = useInjection<IAuthClient>(serviceIdentifiers.authClient);
  const buildingName = useSelector(getBuildingName) ?? "";
  const floors = useSelector(getFloors);
  const roomSubTypesWithBusynessInfo = useSelector(getSpaceBusynessRuleSet);
  const roomSubTypesWithStatusInfo = useSelector(getWebSensorLayerEnabledArray);
  const { status } = useRoomStatus(selectedSpace.cardAttributes.id);
  const personImage = useSelector(getPersonImageMap)[ImageSize.Large];
  const peopleListForASpace = useSelector((state: RootState) =>
    getListOfPeopleAtSpace(state, selectedSpace.cardAttributes.id)
  );
  const [floorName, setFloorName] = useState("");
  const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Default);
  const dispatch = useDispatch();
  const isDetailsPanelOpen = useSelector(getIsDetailsPanelOpen);
  const isZoomed = useSelector(getIsZoomed);
  const pathMetadata = useSelector(getSelectedPathMetadata);
  const sensorSpaceMap = useSelector(getSensorData);
  const peopleFeatureDisabled = useSelector(getIsPeopleFeatureDisabled);

  const toggleDetailsPanel = (): void => {
    dispatch(toggleDetailsPanelAction());
  };

  const pathfindingQRCodeUrl = useMyHubPathFindingQRCodeUrl();
  const {
    wayFindingSupported,
    useElevator,
    onUseElevatorValueChange,
    onDirectionClick,
    resetDirectionButton,
    directionsErrorMessage
  } = useDirectionController(
    selectedSpace.cardAttributes.id,
    selectedSpace.cardAttributes.floorId,
    logger,
    loggerMsgTitle,
    authClient,
    electronService.isElectron(),
    setPageStatus,
    pathMetadata
  );

  useEffect(() => {
    if (pathMetadata?.routeData || pathMetadata?.retrieving) {
      resetDirectionButton();
    }
    setPageStatus(PageStatus.Default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpace]);

  useEffect(() => {
    setFloorName(floors.find((f) => f.id === selectedSpace.cardAttributes.floorId)?.name ?? "");
  }, [floors, selectedSpace]);

  const getPersonList = (): React.ReactNode => {
    const items = peopleListForASpace.map((person, index) => ({
      name: person.displayName,
      description: person.jobTitle,
      img: { src: personImage[person.dtId], alt: person.displayName },
      key: `${index}`
    }));

    return <PersonaList size={"md"} items={items} />;
  };

  const getInfoComponent = (): React.ReactNode => {
    if (
      selectedSpace.type === RoomSubTypes.Office ||
      selectedSpace.type === RoomSubTypes.Desk ||
      selectedSpace.type === RoomSubTypes.TeamOffice
    ) {
      return peopleFeatureDisabled ? (
        <></>
      ) : (
        <>
          <p className={styles.peopleTitle}>{peopleTitle}</p>
          {peopleListForASpace.length === 0 ? <p className={styles.peopleText}>{noEmployeeText}</p> : getPersonList()}
        </>
      );
    } else if (selectedSpace.cardAttributes.icon) {
      return <img className={styles.img} src={selectedSpace.cardAttributes.icon} alt={"Room Visual"} />;
    } else {
      return undefined;
    }
  };

  const getStatusLabelInfo = (): ISpaceStatusLabelProps | undefined => {
    if (roomSubTypesWithBusynessInfo.includes(selectedSpace.type)) {
      const occupancyStatus = sensorSpaceMap[selectedSpace.id]?.OccupancyStatus as
        | string
        | number
        | boolean
        | undefined;
      const peopleCount = sensorSpaceMap[selectedSpace.id]?.PeopleCount as number | undefined;
      const roomCapacity = (selectedSpace.cardAttributes.roomCapacity as number) ?? 0;
      if (occupancyStatus === OccupancyStatusDataValue.Error) {
        return { statusLabel: "Occupancy unknown", statusColorName: SpaceStatusColor.Grey };
      }
      if (!roomCapacity || peopleCount === undefined) return undefined;
      const ratio = peopleCount / roomCapacity;
      const label = ratio < 0.4 ? "Not busy" : ratio > 0.75 ? "Very busy" : "Busy";
      const statusColorName =
        ratio < 0.4 ? SpaceStatusColor.Green : ratio > 0.75 ? SpaceStatusColor.Red : SpaceStatusColor.Orange;
      const statusInfoTooltipLabel = `We are currently detecting about
             ${peopleCount} ${
               peopleCount !== 1 ? "people" : "person"
             } in this space with a capacity of ${roomCapacity} ${roomCapacity !== 1 ? "people" : "person"}`;
      return {
        statusLabel: label,
        statusColorName: statusColorName,
        statusInfoTooltipLabel: statusInfoTooltipLabel,
        showInfo: true,
        size: "Large",
        block: true
      };
    } else if (roomSubTypesWithStatusInfo.includes(selectedSpace.type)) {
      return {
        statusLabel: status.primaryStatus,
        statusIcon: getStatusIcon(status.primaryStatus),
        statusColorName: status.statusColor,
        statusDescription: status.secondaryStatus,
        // statusCaption: "updated now", TODO: Adding caption when update frequence functionality is supported
        // statusCaptionUpdated: true,
        showIcon: status.primaryStatus !== "",
        size: "Large",
        block: true
      };
    } else {
      return undefined;
    }
  };

  return (
    <GenericSpaceCard
      navHeaderBackBtnCallback={onPanelBackClickCallback}
      navHeaderCloseBtnCallback={onPanelDismissCallback}
      navHeaderAriaLabel={props.selectedSpace.name}
      navHeaderBackBtnLabel="Back"
      navHeaderCloseBtnLabel="Close"
      navHeaderBackBtnDescribedBy="generic-back-button"
      navHeaderCloseBtnDescribedBy="generic-close-button"
      spaceCardPrimaryName={selectedSpace.cardAttributes.spaceName}
      spaceCardFriendlyName={selectedSpace.cardAttributes.friendlyName}
      spaceCardSecondaryText={`${SpaceTypeNames[selectedSpace.type]} | ${buildingName} , floor ${floorName}`}
      infoComponent={getInfoComponent()}
      status={getStatusLabelInfo()}
      pageStatus={pageStatus}
      useElevatorChecked={useElevator}
      onUseElevatorValueChange={wayFindingSupported ? onUseElevatorValueChange : undefined}
      directionsErrorMessage={directionsErrorMessage}
      onDirectionClick={wayFindingSupported ? onDirectionClick : undefined}
      sliderAriaLabel={`Press to ${!isDetailsPanelOpen ? "open" : "close"} details panel.`}
      isZoomed={isZoomed}
      isDocked={!isDetailsPanelOpen}
      sliderCallback={toggleDetailsPanel}
      {...pathfindingQRCodeUrl}
    />
  );
}

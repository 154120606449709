var LocalBlobService = /** @class */ (function () {
    function LocalBlobService(httpService) {
        this.httpService = httpService;
    }
    LocalBlobService.prototype.getLocalBlobUrlForImage = function (remoteUrl) {
        return this.httpService
            .get(remoteUrl, {
            headers: {
                accept: "image/webp,image/*,*/*;q=0.8"
            },
            responseType: "arraybuffer"
        })
            .then(function (imageResponse) {
            var blob = new Blob([imageResponse.data], { type: imageResponse.headers["content-type"] });
            return URL.createObjectURL(blob);
        });
    };
    LocalBlobService.prototype.revokeLocalBlobUrl = function (localBlobUrl) {
        URL.revokeObjectURL(localBlobUrl);
    };
    return LocalBlobService;
}());
export { LocalBlobService };

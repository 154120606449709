import {
  DeepLinkSpaceSelectActions,
  SpaceAction,
  SpaceCategoryActions,
  SpaceRetrieveActions,
  SpaceSelectedActions
} from "../Actions";
import { IBuilding, IRoomInfo, ISpaceInfo, ISpaceStore, PoiFloorMapping, RootCategoryLabel } from "../Types";
import { ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";

export const initialState: ISpaceStore = {
  buildingId: "",
  buildings: {},
  rooms: {},
  selectedFloorId: "",
  selectedRoomId: undefined,
  regions: {},
  category: undefined,
  categorySpaces: [],
  menuPanelCategory: RootCategoryLabel,
  spaceBusynessRuleSet: [],
  deepLinkFloorId: undefined,
  deepLinkRoomId: undefined
};

export function spaceReducer(state = initialState, action: SpaceAction): ISpaceStore {
  switch (action.type) {
    case SpaceSelectedActions.BUILDING_SELECTED:
      return handleBuildingSelection(state, action.payload);

    case SpaceRetrieveActions.BUILDINGS_WITH_MAP_DATA_RETRIEVED:
      return handleBuildingsWithMapDataRetrieve(state, action.payload);

    case SpaceRetrieveActions.REGIONS_DATA_RETRIEVED:
      return handleRegionsDataRetrieve(state, action.payload);

    case SpaceRetrieveActions.BUILDING_DATA_RETRIEVED:
      return handleBuildingDataUpdated(state, action.payload);

    case SpaceRetrieveActions.ROOMS_RETRIEVED:
      return handleRoomRetrieve(state, action.payload.buildingId, action.payload.data);

    case SpaceRetrieveActions.ROOM_IMAGE_RETRIEVED:
      return handleRoomImageRetrieve(state, action.payload);

    case SpaceRetrieveActions.POINTS_OF_INTEREST_RETRIEVED:
      return handlePoiRetrieve(state, action.payload.buildingId, action.payload.data);

    case SpaceSelectedActions.FLOOR_SELECTION_COMPLETE:
      return { ...state, selectedFloorId: action.payload };

    case SpaceSelectedActions.ROOM_SELECTION_COMPLETE:
      return { ...state, selectedRoomId: action.payload, category: undefined };

    case SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTION_COMPLETE:
      return { ...state, selectedRoomId: action.payload.roomId, category: undefined };

    case SpaceSelectedActions.ROOM_SELECTION_CLEARED:
      return { ...state, selectedRoomId: undefined };

    case SpaceRetrieveActions.CATEGORIES_RETRIEVED:
      return handleBuildingCategoriesRetrieved(state, action.payload.buildingId, action.payload.categories);

    case SpaceRetrieveActions.SPACE_BUSYNESS_RULE_SET_RETRIEVED:
      return { ...state, spaceBusynessRuleSet: action.payload };

    case SpaceCategoryActions.SPACE_CATEGORY_SELECTED:
      return { ...state, category: action.payload, selectedRoomId: undefined };

    case SpaceCategoryActions.SPACE_CATEGORY_CLEARED:
      return { ...state, category: undefined };

    case SpaceCategoryActions.SPACE_CATEGORY_SELECTED_COMPLETE:
      return { ...state, categorySpaces: action.payload };

    case SpaceCategoryActions.SPACE_MENU_PANEL_CATEGORY_SELECTED:
      return { ...state, menuPanelCategory: action.payload };

    case DeepLinkSpaceSelectActions.DEEP_LINK_FLOOR_SELECT:
      return { ...state, deepLinkFloorId: action.payload };

    case DeepLinkSpaceSelectActions.DEEP_LINK_ROOM_SELECT:
      return { ...state, deepLinkRoomId: action.payload };

    default:
      return state;
  }
}

function handleBuildingSelection(state: ISpaceStore, buildingId: string): ISpaceStore {
  const floors = state.buildings[buildingId]?.floors;
  const floorId = floors ? getTargetFloorId(state.selectedFloorId, floors) : "";

  return { ...state, buildingId, selectedRoomId: undefined, selectedFloorId: floorId };
}

function handleBuildingsWithMapDataRetrieve(state: ISpaceStore, buildings: IBuilding[]): ISpaceStore {
  const buildingsMap: Record<string, IBuilding> = { ...state.buildings };
  for (const building of buildings) {
    buildingsMap[building.id] = {
      ...buildingsMap[building.id],
      id: building.id,
      name: building.name,
      mapData: building.mapData,
      regionId: building.regionId
    };
  }

  return { ...state, buildings: { ...buildingsMap } };
}

function handleRegionsDataRetrieve(state: ISpaceStore, regions: ISpaceInfo[]): ISpaceStore {
  const regionsMap: Record<string, ISpaceInfo> = { ...state.regions };
  for (const region of regions) {
    regionsMap[region.id] = {
      id: region.id,
      name: region.name
    };
  }
  return { ...state, regions: regionsMap };
}

function handleBuildingDataUpdated(state: ISpaceStore, building: IBuilding): ISpaceStore {
  const storedBuilding = state.buildings[building.id];
  const floors = building.floors ? sortFloors(building.floors) : storedBuilding?.floors;
  let floorId = state.selectedFloorId;
  if (state.buildingId === building.id) {
    floorId = floors ? getTargetFloorId(state.selectedFloorId, floors) : "";
  }

  return {
    ...state,
    selectedFloorId: floorId,
    buildings: {
      ...state.buildings,
      [building.id]: {
        ...storedBuilding,
        id: building.id,
        name: building.name,
        floors: floors,
        location: building.location ?? storedBuilding?.location,
        mapData: building.mapData ?? storedBuilding?.mapData,
        regionId: building.regionId ?? storedBuilding?.regionId
      }
    }
  };
}

function handlePoiRetrieve(state: ISpaceStore, buildingId: string, poiMapping: PoiFloorMapping): ISpaceStore {
  const building = state.buildings[buildingId];
  if (building) {
    return {
      ...state,
      buildings: { ...state.buildings, [buildingId]: { ...building, pointsOfInterest: poiMapping } }
    };
  } else {
    return {
      ...state,
      buildings: {
        ...state.buildings,
        [buildingId]: {
          ...state.buildings[buildingId],
          id: buildingId,
          name: "",
          pointsOfInterest: poiMapping
        }
      }
    };
  }
}

function handleRoomRetrieve(state: ISpaceStore, buildingId: string, rooms: IRoomInfo[]): ISpaceStore {
  const building = state.buildings[buildingId];
  const roomMap = { ...state.rooms };
  const roomsToStore = building?.rooms ? [...building?.rooms] : [];

  for (const room of rooms) {
    if (!roomsToStore.includes(room.id)) {
      roomsToStore.push(room.id);
    }

    const roomInStore = roomMap[room.id];
    roomMap[room.id] = {
      ...roomInStore,
      ...room,
      cardAttributes: {
        ...roomInStore?.cardAttributes,
        ...room?.cardAttributes,
        roomCapabilities: room?.cardAttributes?.roomCapabilities ?? roomInStore?.cardAttributes?.roomCapabilities
      }
    };
  }

  return {
    ...state,
    buildings: { ...state.buildings, [buildingId]: { ...building, id: buildingId, rooms: roomsToStore } },
    rooms: roomMap
  };
}

function handleRoomImageRetrieve(state: ISpaceStore, roomImage: { id: string; image: string }): ISpaceStore {
  const roomMap = { ...state.rooms };
  roomMap[roomImage.id] = {
    ...roomMap[roomImage.id],
    cardAttributes: {
      ...roomMap[roomImage.id].cardAttributes,
      icon: roomImage.image
    }
  };

  return { ...state, rooms: roomMap };
}

function sortFloors(floors: ISpaceInfo[]): ISpaceInfo[] {
  return floors.sort((floorA, floorB) => {
    if (typeof floorA.logicalOrder === "number" && typeof floorB.logicalOrder === "number") {
      return floorB.logicalOrder - floorA.logicalOrder;
    }
    return -1;
  });
}

function getTargetFloorId(currentFloorId: string, floors: ISpaceInfo[]): string {
  let floorId = "";
  if (floors?.find((floor) => floor.id === currentFloorId)) {
    floorId = currentFloorId;
  } else {
    floorId = getLowestLogicalFloor(floors);
  }
  return floorId;
}

function getLowestLogicalFloor(floors: ISpaceInfo[]): string {
  let defaultFloor = floors?.[floors.length - 1].id ?? "";
  for (let i = floors.length - 1; i >= 0; i--) {
    const currFloor = floors[i];
    if (typeof currFloor.logicalOrder === "number" && currFloor.logicalOrder >= 0) {
      defaultFloor = currFloor.id;
      break;
    }
  }

  return defaultFloor;
}

function handleBuildingCategoriesRetrieved(
  state: ISpaceStore,
  buildingId: string,
  categories: Record<string, ISpaceCategory>
): ISpaceStore {
  return {
    ...state,
    buildings: { ...state.buildings, [buildingId]: { ...state.buildings[buildingId], categories: categories } }
  };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var MapThemeStyles;
(function (MapThemeStyles) {
    MapThemeStyles["Dark"] = "grayscale_dark";
    MapThemeStyles["Light"] = "road";
    MapThemeStyles["HighContrast"] = "high_contrast_dark";
})(MapThemeStyles || (MapThemeStyles = {}));
export var MapTypeStyles;
(function (MapTypeStyles) {
    MapTypeStyles["Gray"] = "grayscale_light";
    MapTypeStyles["Night"] = "night";
    MapTypeStyles["LightShaded"] = "road_shaded_relief";
    MapTypeStyles["Satellite"] = "satellite";
    MapTypeStyles["SatelliteWithLabels"] = "satellite_road_labels";
})(MapTypeStyles || (MapTypeStyles = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export var MapStyles = __assign(__assign({}, MapThemeStyles), MapTypeStyles);

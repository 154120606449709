import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";
// eslint-disable-next-line @typescript-eslint/naming-convention
export var OccupiedIcon = createSvgIcon({
    // eslint-disable-next-line react/display-name
    svg: function (args) {
        return (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.24902 7H10.7499C11.3022 7 11.7499 7.44772 11.7499 8C11.7499 8.55229 11.3022 9 10.7499 9H5.24902C4.69674 9 4.24902 8.55229 4.24902 8C4.24902 7.44772 4.69674 7 5.24902 7Z", fill: "#D78B45" })));
    },
    displayName: "OccupiedIcon"
});

import { MapAction, MapViewActions } from "../Actions/MapActions";
import { IMapStore } from "../Types";
import { PoiType } from "@smartbuilding/poi-service";

export const initialState: IMapStore = {
  layers: {
    people: false,
    poi: {
      AEDDevice: false,
      BombShelter: false,
      EmergencyExit: false,
      FireExtinguisher: false,
      KioskLocation: true
    },
    route: false,
    peopleDensity: false,
    hotdesk: false,
    spacePins: false
  }
};

export function mapReducer(state = initialState, action: MapAction): IMapStore {
  switch (action.type) {
    case MapViewActions.RENDER_PEOPLE_LAYER:
      return { ...state, layers: { ...state.layers, people: true } };

    case MapViewActions.CLEAR_PEOPLE_LAYER:
      return { ...state, layers: { ...state.layers, people: false } };

    case MapViewActions.RENDER_ROUTE_LAYER:
      return { ...state, layers: { ...state.layers, route: true } };

    case MapViewActions.CLEAR_ROUTE_LAYER:
      return { ...state, layers: { ...state.layers, route: false } };
    case MapViewActions.RENDER_HOTDESK_LAYER:
      return { ...state, layers: { ...state.layers, hotdesk: true } };

    case MapViewActions.CLEAR_HOTDESK_LAYER:
      return { ...state, layers: { ...state.layers, hotdesk: false } };

    case MapViewActions.RENDER_PEOPLEDENSITY_LAYER:
      return { ...state, layers: { ...state.layers, peopleDensity: true } };

    case MapViewActions.CLEAR_PEOPLEDENSITY_LAYER:
      return { ...state, layers: { ...state.layers, peopleDensity: false } };

    case MapViewActions.RENDER_SPACEPINS_LAYER:
      return { ...state, layers: { ...state.layers, spacePins: !isPoiEnabled(state) } };

    case MapViewActions.CLEAR_SPACEPINS_LAYER:
      return { ...state, layers: { ...state.layers, spacePins: false } };

    case MapViewActions.RENDER_POI_LAYER:
      return poiLayerUpdate(state, action.payload);

    case MapViewActions.CLEAR_POI_LAYER:
      return clearPoiLayers(state);

    default:
      return state;
  }
}

function poiLayerUpdate(state: IMapStore, poiType: PoiType): IMapStore {
  const poiLayers: Record<PoiType, boolean> = {
    ...initialState.layers.poi,
    [poiType]: true
  };

  return { ...state, layers: { ...state.layers, poi: poiLayers } };
}

function clearPoiLayers(state: IMapStore): IMapStore {
  const poiLayers: Record<PoiType, boolean> = {
    ...initialState.layers.poi
  };

  return { ...state, layers: { ...state.layers, poi: poiLayers } };
}

function isPoiEnabled(state: IMapStore): boolean {
  for (const key of Object.keys(state.layers.poi)) {
    if (key !== PoiType.KioskLocation && state.layers.poi[key as keyof typeof PoiType]) {
      return true;
    }
  }
  return false;
}

import {
  ClearDeviceConfigAction,
  ConfigSpaceDataRetrieved,
  DeviceConfigRetrieveActions,
  DeviceConfigSelectedActions,
  LoadDeviceConfigAction
} from "./DeviceConfigAction";
import { IDeviceConfigStore } from "../Types";

export function clearDeviceConfig(): ClearDeviceConfigAction {
  return { type: DeviceConfigSelectedActions.CLEAR_CONFIG_DATA };
}

export function loadDeviceConfig(): LoadDeviceConfigAction {
  return { type: DeviceConfigSelectedActions.LOAD_CONFIG_DATA };
}

export function storeDeviceConfigData(configData: IDeviceConfigStore): ConfigSpaceDataRetrieved {
  return {
    type: DeviceConfigRetrieveActions.STORE_CONFIG_DATA,
    payload: { configData }
  };
}

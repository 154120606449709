export function getRouteLegId(routeLeg) {
    return "RL_".concat(routeLeg.fromToPair[0].id, "_").concat(routeLeg.fromToPair[1].id);
}
export function getRouteLegLineLayerId(routeLeg) {
    return "LL_".concat(routeLeg.fromToPair[0].id, "_").concat(routeLeg.fromToPair[1].id);
}
export function getRouteLegDataSourceId(routeLeg) {
    return "DS_".concat(routeLeg.fromToPair[0].id, "_").concat(routeLeg.fromToPair[1].id);
}
export function getRouteLegSymbolLayerId(routeLeg) {
    return "SL_".concat(routeLeg.fromToPair[0].id, "_").concat(routeLeg.fromToPair[1].id);
}
export function getVehicleMarkerId(id) {
    return "MR_".concat(id);
}
export function getStopLabelId(stopId) {
    return "LABEL_".concat(stopId);
}

import { IAzureMapsTokenHttp, ISmartBuildingApiHttp } from "@smartbuilding/smartbuilding-api-service";
import { IMapControlBaseProps, IMapControlStyleProps, IMapControlStyles } from "./MapControl.Types";
import { Provider, serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import React, { useContext } from "react";
import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { ILogger } from "@smartbuilding/log-provider";
import { IWebClientConfiguration } from "../../../constants";
import { MapControlBase } from "./MapControl.Base";
import { ThemeContext } from "../../../theme-provider/ThemeProvider";
import { getMapControlStyles } from "./MapControl.Styles";
import { styled } from "@fluentui/react";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";

// eslint-disable-next-line @typescript-eslint/naming-convention
const StyledMapControl = styled<IMapControlBaseProps, IMapControlStyleProps, IMapControlStyles>(
  MapControlBase,
  getMapControlStyles
);

export function MapControl(): JSX.Element {
  const configurationService = useInjection<IConfigurationService<IWebClientConfiguration>>(
    serviceIdentifiers.configService
  );
  const { theme } = useContext(ThemeContext);
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const azureMapsTokenService = useInjection<IAzureMapsTokenHttp>(serviceIdentifiers.azureMapsTokenHttp);
  const smartBuildingApiHttpProvider = useInjection<Provider<ISmartBuildingApiHttp>>(
    serviceIdentifiers.smartBuildingApiHttp
  );
  return (
    <StyledMapControl
      configurationService={configurationService}
      azureMapsTokenService={azureMapsTokenService}
      logger={logger}
      theme={theme}
      smartBuildingApiHttpProvider={smartBuildingApiHttpProvider}
    />
  );
}

import { rootIdentifier } from "./SpaceCategoriesConstants";
export var groupSpacesByCategory = function (buildingSpaceResponse) {
    var topLevelCategories = Object.keys(buildingSpaceResponse);
    var categoryMap = {};
    var spaces = {};
    categoryMap[rootIdentifier] = createRootCategoryEntry(topLevelCategories);
    for (var _i = 0, topLevelCategories_1 = topLevelCategories; _i < topLevelCategories_1.length; _i++) {
        var category = topLevelCategories_1[_i];
        categoryMap[category] = {
            childCategories: [],
            parentCategory: [rootIdentifier],
            spaces: [],
            spacesByFloor: {},
            webLogicalOrder: 0,
            mobileLogicalOrder: 0,
            childCategoriesMap: {},
            type: category,
            webSensorLayerEnabled: false,
            mobileSensorLayerEnabled: false
        };
        var categorySpaces = buildingSpaceResponse[category];
        for (var _a = 0, _b = Object.keys(categorySpaces); _a < _b.length; _a++) {
            var key = _b[_a];
            var space = categorySpaces[key];
            if (isSpaceQueryResponse(space)) {
                spaces[key] = createSpaceEntry(space);
                categoryMap[space.type] = createSpaceCategoriesEntry(categoryMap[space.type], space);
                if (category !== space.type) {
                    categoryMap[space.type].parentCategory = getParentCategories(categoryMap[space.type].parentCategory, category);
                    categoryMap[category].childCategoriesMap = getChildCategoriesMap(categoryMap[category].childCategoriesMap, space.type, space.webLogicalOrder);
                    categoryMap[category].childCategories = getChildCategories(categoryMap[category].childCategories, space.type);
                }
            }
            else {
                categoryMap[key].displayName = space.displayName;
                categoryMap[key].description = space.description;
                categoryMap[key].webLogicalOrder = space.webLogicalOrder;
                categoryMap[key].mobileLogicalOrder = space.mobileLogicalOrder;
            }
        }
    }
    handleCleanup(categoryMap, spaces);
    return { categoryMap: categoryMap, spaces: spaces };
};
var handleCleanup = function (categoryMap, spaces) {
    sortSpacesByFloor(categoryMap, spaces);
};
export var sortSpacesByFloor = function (categoryMap, spaces) {
    for (var _i = 0, _a = Object.values(categoryMap); _i < _a.length; _i++) {
        var categoryDetails = _a[_i];
        for (var _b = 0, _c = Object.values(categoryDetails.spacesByFloor); _b < _c.length; _b++) {
            var spaceList = _c[_b];
            spaceList.sort(function (a, b) {
                var spaceA = spaces[a];
                var spaceB = spaces[b];
                return spaceA.name < spaceB.name ? -1 : 1;
            });
        }
    }
};
export var createSpaceEntry = function (space) {
    var _a;
    return ({
        alias: space.alias,
        capacity: space.capacity,
        buildingId: space.buildingId,
        categoryName: space.type,
        featureId: space.featureId,
        floorId: space.floorId,
        id: space.id,
        name: space.name,
        regionId: space.regionId,
        friendlyName: space === null || space === void 0 ? void 0 : space.friendlyName,
        roomCapacity: (_a = space === null || space === void 0 ? void 0 : space.roomCapacity) !== null && _a !== void 0 ? _a : 0,
        capabilities: space.capabilities,
        webLogicalOrder: space.webLogicalOrder,
        mobileLogicalOrder: space.mobileLogicalOrder
    });
};
export var createRootCategoryEntry = function (categories) { return ({
    childCategories: categories,
    parentCategory: null,
    spaces: [],
    spacesByFloor: {},
    webLogicalOrder: 0,
    mobileLogicalOrder: 0,
    childCategoriesMap: Object.fromEntries(categories.map(function (key) { return [key, 0]; })),
    type: rootIdentifier,
    webSensorLayerEnabled: false,
    mobileSensorLayerEnabled: false
}); };
export var getParentCategories = function (currentParentCategories, categoryToAdd) {
    if (!currentParentCategories) {
        currentParentCategories = [];
    }
    if (!currentParentCategories.includes(categoryToAdd)) {
        currentParentCategories.push(categoryToAdd);
    }
    return currentParentCategories;
};
export var getChildCategories = function (currentChildCategories, categoryToAdd) {
    if (!currentChildCategories.includes(categoryToAdd)) {
        currentChildCategories.push(categoryToAdd);
    }
    return currentChildCategories;
};
export var getChildCategoriesMap = function (currentChildCategories, categoryToAdd, logicalOrder) {
    if (!Object.keys(currentChildCategories).includes(categoryToAdd)) {
        currentChildCategories[categoryToAdd] = logicalOrder;
    }
    return currentChildCategories;
};
export var createSpaceCategoriesEntry = function (spaceCategoryEntry, space) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var spaceCategory = {
        childCategories: (_a = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.childCategories) !== null && _a !== void 0 ? _a : [],
        parentCategory: (_b = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.parentCategory) !== null && _b !== void 0 ? _b : null,
        spaces: (_c = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.spaces) !== null && _c !== void 0 ? _c : [],
        spacesByFloor: (_d = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.spacesByFloor) !== null && _d !== void 0 ? _d : {},
        description: (_e = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.description) !== null && _e !== void 0 ? _e : space.description,
        displayName: (_f = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.displayName) !== null && _f !== void 0 ? _f : space.displayName,
        webLogicalOrder: (_g = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.webLogicalOrder) !== null && _g !== void 0 ? _g : 0,
        mobileLogicalOrder: (_h = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.mobileLogicalOrder) !== null && _h !== void 0 ? _h : 0,
        childCategoriesMap: (_j = spaceCategoryEntry === null || spaceCategoryEntry === void 0 ? void 0 : spaceCategoryEntry.childCategoriesMap) !== null && _j !== void 0 ? _j : {},
        type: space.type,
        webSensorLayerEnabled: (_k = space === null || space === void 0 ? void 0 : space.webSensorLayerEnabled) !== null && _k !== void 0 ? _k : false,
        mobileSensorLayerEnabled: (_l = space === null || space === void 0 ? void 0 : space.mobileSensorLayerEnabled) !== null && _l !== void 0 ? _l : false
    };
    if (!spaceCategory.spaces.includes(space.id)) {
        spaceCategory.spacesByFloor[space.floorId] = (_m = spaceCategory.spacesByFloor[space.floorId]) !== null && _m !== void 0 ? _m : [];
        spaceCategory.spacesByFloor[space.floorId].push(space.id);
        spaceCategory.spaces.push(space.id);
    }
    return spaceCategory;
};
export var isSpaceQueryResponse = function (space) {
    var spaceQuery = space;
    return Boolean(spaceQuery.type && spaceQuery.name && spaceQuery.id);
};
var roomTagFriendlyNames = {
    whiteboard: "Whiteboard",
    outlet: "Outlets",
    screen: "Large Screen",
    projector_screen: "Projector Screen",
    speaker: "Speakers",
    podium: "Podium",
    webcam: "Webcam",
    sofa: "Sofa",
    windows: "Windows",
    teleprompter: "Teleprompter",
    blinds: "Blinds",
    projector: "Projector",
    curtains: "Curtains"
};
export var handleRoomTagsToShow = function (tags) {
    if (!tags) {
        return [];
    }
    var roomTags = Object.keys(tags);
    var friendlyNameRoomTags = roomTags
        .filter(function (tag) { return tag in roomTagFriendlyNames; })
        .map(function (tag) { return roomTagFriendlyNames[tag]; });
    return friendlyNameRoomTags;
};

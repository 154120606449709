var RoomImageProvider = /** @class */ (function () {
    function RoomImageProvider(httpService, baseApiUrl) {
        this.httpService = httpService;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.logAndReject = function (error, url) {
            var errorMessage = error ? error : "error encountered during http request";
            if (url) {
                errorMessage = "error encountered during http request from following url" + { url: url };
            }
            return Promise.reject(errorMessage);
        };
        this.endpointUrl = "".concat(baseApiUrl, "/api/v1.0/spaces/image/getUrl");
    }
    RoomImageProvider.prototype.getLocalRoomImageUrl = function (roomKey) {
        var _this = this;
        if (!roomKey) {
            return this.logAndReject("Room key number cannot be undefined or empty");
        }
        return this.httpService
            .get("".concat(this.endpointUrl, "?roomKey=").concat(roomKey))
            .then(function (res) {
            return res.data;
        })
            .catch(function (error) { return _this.logAndReject(error, "".concat(_this.endpointUrl, "/").concat(roomKey)); });
    };
    return RoomImageProvider;
}());
export { RoomImageProvider };

import { IWeatherService } from "@smartbuilding/weather-service";
import { Provider } from "../../../serviceContainer/ServiceIdentifiers";

export interface IMenuWeatherProps {
  latitude: number;
  longitude: number;
  weatherServiceProvider: Provider<IWeatherService>;
}

// true if using fahrenheit, false if using celsius
export const weatherRegionTempMap: Record<string, boolean> = {
  ASIA: false,
  MSUS: true,
  EMEA: false,
  LATAM: false,
  "PUGET SOUND": true,
  CANADA: false
};

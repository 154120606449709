import { IAppLoggedMetricsData } from "../Types/AppMetricTypes";
import { RootState } from "../Reducers/RootReducer";

export const getAppLoggedMetrics = (store: RootState): IAppLoggedMetricsData => {
  const perfEntries = performance.getEntriesByType("navigation");
  const perfEntry = perfEntries[0] as unknown as { domComplete: number; domInteractive: number };

  const updateTimes = store.appMetrics.stateTimeStamps;

  const metricsToLog: IAppLoggedMetricsData = {
    activityId: store.appMetrics.activityId,
    domCompleteAfterMs: perfEntry.domComplete.toString(),
    domInteractiveAfterMs: perfEntry.domInteractive.toString(),
    appUseableAfterMs: (updateTimes.Loaded - updateTimes.Loading).toString()
  };

  return metricsToLog;
};

import {
  IMenuSearchBaseProps,
  IMenuSearchStyleProps,
  IMenuSearchStyles,
  ISearchResultItem,
  MenuResultsShown,
  NavigationIds,
  NoResultsText,
  NonRoomSearchFilter,
  SearchFilter
} from "./MenuSearch.Types";
import { ISearchResultItemProps, ISearchResultSectionProps, Search } from "@smartbuilding/ui-components-search";
import React, { useEffect, useState } from "react";
import { Text, classNamesFunction } from "@fluentui/react";
import { calculateSearchResults, filterAndSortResults, sortBuildingCategories } from "./MenuSearchUtilities";
import { getBuilding, getBuildingCategories } from "../../../redux/Selectors/SpaceSelectors";
import { personSelected, renderSpacePinsLayer, setBuilding, setRoom } from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { getIsPeopleFeatureDisabled } from "../../../redux/Selectors";
import { getResetStatus } from "../../../redux/Selectors/ResetSelector";
import { useBuildingSearchResultOptions } from "./useBuildingSearchResultOptions";
import { useMenuSearchLogger } from "./useMenuSearchLogger";
import { usePeopleSearchResultOptions } from "./usePeopleSearchResultOptions";
import { useRoomsSearchResultOptions } from "./useRoomSearchResultOptions";

const getClassNames = classNamesFunction<IMenuSearchStyleProps, IMenuSearchStyles>();
export function MenuSearchBase(props: IMenuSearchBaseProps): JSX.Element {
  const shouldReset = useSelector(getResetStatus);
  const buildingCategories = useSelector(getBuildingCategories);
  const building = useSelector(getBuilding);
  const peopleFeatureDisabled = useSelector(getIsPeopleFeatureDisabled);
  const [buildingRoomSubTypes, setBuildingRoomSubTypes] = useState<RoomSubTypes[]>([]);

  useEffect(() => {
    setBuildingRoomSubTypes(sortBuildingCategories(buildingCategories));
  }, [buildingCategories]);
  const classNames = getClassNames(props.styles, { theme: props.theme });
  const dispatch = useDispatch();

  const navigationBaseNameMap: Partial<Record<SearchFilter, string>> = {};
  const navigationIdMap: Partial<Record<SearchFilter, string>> = {};
  for (const category of buildingRoomSubTypes) {
    navigationIdMap[category] = category[0].toLowerCase() + category.substring(1) + "s";
    navigationBaseNameMap[category] = buildingCategories[category] ? buildingCategories[category].displayName : "";
  }
  navigationIdMap[NonRoomSearchFilter.Default] = NavigationIds.Default;
  navigationIdMap[NonRoomSearchFilter.Buildings] = NavigationIds.Buildings;
  navigationIdMap[NonRoomSearchFilter.People] = NavigationIds.People;
  navigationBaseNameMap[NonRoomSearchFilter.Default] = NonRoomSearchFilter.Default;
  navigationBaseNameMap[NonRoomSearchFilter.Buildings] = NonRoomSearchFilter.Buildings;
  navigationBaseNameMap[NonRoomSearchFilter.People] = NonRoomSearchFilter.People;

  const [searchedTerm, setSearchedTerm] = useState<string>("");
  const [results, setResults] = useState<ISearchResultSectionProps[]>([]);
  const [searchFilter, setSearchFilter] = useState<SearchFilter>(NonRoomSearchFilter.Default);
  const menuSearchLogger = useMenuSearchLogger(searchedTerm, searchFilter, props.logger);
  const handleSearchResultClick = (id: string, type: RoomSubTypes | "Person" | "Building"): void => {
    menuSearchLogger.onSearchCallback(id, type);
    if (type === "Building") {
      dispatch(setBuilding(id));
    } else if (type === "Person") {
      dispatch(personSelected(id));
    } else {
      dispatch(renderSpacePinsLayer());
      dispatch(setRoom(id));
    }
  };

  const buildingsList = useBuildingSearchResultOptions(handleSearchResultClick);
  const peopleList = usePeopleSearchResultOptions(handleSearchResultClick);
  const roomsList = useRoomsSearchResultOptions(handleSearchResultClick, buildingRoomSubTypes);

  const handleChange = (term: string): void => {
    setSearchedTerm(term);
  };

  const getSpaceList = (filter: SearchFilter): ISearchResultItem[] => {
    if (filter === NonRoomSearchFilter.Buildings) {
      return buildingsList;
    } else if (filter === NonRoomSearchFilter.People) {
      return peopleList;
    } else if (filter === NonRoomSearchFilter.Default) {
      return [];
    } else if (filter === RoomSubTypes.Office) {
      return (roomsList.Desk ?? []).concat(roomsList.Office ?? []);
    }
    return roomsList[filter] ?? [];
  };

  const getSearchNavigation = (
    id: string,
    baseName: string,
    items: ISearchResultItemProps[],
    expanded: boolean,
    displayCount: number,
    showEndText: boolean,
    searchFilter: SearchFilter
  ): ISearchResultSectionProps => {
    if (id === NavigationIds.People) {
      return {
        key: id,
        name: `${baseName} in ${building?.name}`,
        nameEnd: items.length !== 1 ? `(${items.length} results)` : `(${items.length} result)`,
        items: items.slice(0, displayCount)
      };
    }
    const name = items.length !== 1 ? `${baseName} (${items.length} results)` : `${baseName} (${items.length} result)`;
    const navigation: ISearchResultSectionProps = {
      key: id,
      name: name,
      items: expanded ? items : items.slice(0, displayCount)
    };

    if (showEndText && items.length > 0) {
      navigation.endText = expanded ? "back" : "See all";
      navigation.onClick = expanded
        ? () => setSearchFilter(NonRoomSearchFilter.Default)
        : () => setSearchFilter(searchFilter);
    }

    return navigation;
  };

  const getSearchList = (
    term: string,
    displayCount: number,
    buildingRoomSubTypes: RoomSubTypes[]
  ): ISearchResultSectionProps[] => {
    const buildingPeopleDisplayCount = term === "" ? 0 : displayCount;
    const roomsDisplayCount = term === "" ? MenuResultsShown.Min : displayCount;
    let numCategoriesSearched = 0;
    const searchedBuildings = filterAndSortResults(
      term,
      getSpaceList(NonRoomSearchFilter.Buildings),
      NonRoomSearchFilter.Buildings,
      props.logger
    );
    const searchedPeople = filterAndSortResults(
      term,
      getSpaceList(NonRoomSearchFilter.People),
      NonRoomSearchFilter.People,
      props.logger
    );
    const searchList: ISearchResultSectionProps[] = [];

    if (searchedBuildings.length > 0) {
      searchList.push(
        getSearchNavigation(
          navigationIdMap[NonRoomSearchFilter.Buildings] ?? NonRoomSearchFilter.Buildings,
          navigationBaseNameMap[NonRoomSearchFilter.Buildings] ?? NonRoomSearchFilter.Buildings,
          searchedBuildings,
          false,
          buildingPeopleDisplayCount,
          true,
          NonRoomSearchFilter.Buildings
        )
      );
      numCategoriesSearched++;
    }

    if (!peopleFeatureDisabled && searchedPeople.length > 0) {
      searchList.push(
        getSearchNavigation(
          navigationIdMap[NonRoomSearchFilter.People] ?? NonRoomSearchFilter.People,
          navigationBaseNameMap[NonRoomSearchFilter.People] ?? NonRoomSearchFilter.People,
          searchedPeople,
          false,
          buildingPeopleDisplayCount,
          false,
          NonRoomSearchFilter.People
        )
      );
      numCategoriesSearched++;
    }

    buildingRoomSubTypes.forEach((category) => {
      const searchedRooms = filterAndSortResults(term, getSpaceList(category), category, props.logger);
      if (searchedRooms.length > 0) {
        searchList.push(
          getSearchNavigation(
            navigationIdMap[category] ?? category,
            navigationBaseNameMap[category] ?? category,
            searchedRooms,
            false,
            roomsDisplayCount,
            true,
            category
          )
        );
        numCategoriesSearched++;
      }
    });

    if (numCategoriesSearched === MenuResultsShown.MidResultsCategoryCount) {
      searchList.forEach((searchResults) => (searchResults.items = searchResults.items.slice(0, MenuResultsShown.Mid)));
    } else if (numCategoriesSearched >= MenuResultsShown.MinResultsCategoryCount) {
      searchList.forEach((searchResults) => (searchResults.items = searchResults.items.slice(0, MenuResultsShown.Min)));
    }

    return searchList.length === 0
      ? [{ key: NoResultsText.NoResultsId, name: NoResultsText.NoResults, items: [] }]
      : searchList;
  };

  useEffect(() => {
    let searchList: ISearchResultSectionProps[];
    const getSearchListStartTime = performance.now();
    if (searchFilter === NonRoomSearchFilter.Default) {
      searchList = getSearchList(searchedTerm, MenuResultsShown.Max, buildingRoomSubTypes);
    } else {
      searchList = [
        getSearchNavigation(
          navigationIdMap[searchFilter] ?? searchFilter,
          navigationBaseNameMap[searchFilter] ?? searchFilter,
          filterAndSortResults(searchedTerm, getSpaceList(searchFilter), searchFilter, props.logger),
          true,
          0,
          true,
          searchFilter
        )
      ];
    }
    setResults(searchList);
    if (searchedTerm || searchFilter !== NonRoomSearchFilter.Default) {
      const getSearchListEndTime = performance.now();
      const totalGetSearchListTime = getSearchListEndTime - getSearchListStartTime;
      const searchResultsCount = calculateSearchResults(searchList);
      const logData: Record<string, string> = {
        searchedTerm: searchedTerm,
        timeInMs: totalGetSearchListTime.toString()
      };
      Object.keys(searchResultsCount).forEach(
        (searchResults) => (logData[searchResults] = searchResultsCount[searchResults].toString())
      );
      props.logger.logEvent("[Menu Panel Search] Overall Search Results Render Time And Count", logData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedTerm, searchFilter, buildingsList, peopleList, roomsList]);

  return (
    <div className={classNames.root} onFocus={menuSearchLogger.onFocus} onBlur={menuSearchLogger.onBlur}>
      <Text id="menuSearchTitle" className={classNames.text}>
        What can we help you find?
      </Text>
      <Search
        placeholder={
          peopleFeatureDisabled ? "Search for a place or building" : "Search for a person, place or building"
        }
        items={results}
        onChange={handleChange}
        reset={shouldReset}
        aria-labelledby="menuSearchTitle"
      />
    </div>
  );
}

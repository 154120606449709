/* eslint-disable @typescript-eslint/no-explicit-any */
// entire file uses azure-maps sdk functions so map is often cast to any
import { MapIconLayerIds, MapLabelLayerIds } from "./MapIconUtility.types";
export function removeAllIcons(map) {
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        var labelLayer = map.layers.getLayerById("labels_indoor");
        if (!labelLayer)
            return;
        var configurableMap = map._getMap();
        for (var _i = 0, _a = labelLayer.layers; _i < _a.length; _i++) {
            var layer = _a[_i];
            if (layer.layout["icon-image"]) {
                configurableMap.setLayoutProperty(layer.id, "visibility", "none");
            }
        }
    });
}
export function removeAllLabels(map) {
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        map._getMap().setLayoutProperty(MapLabelLayerIds.IndoorLabels, "visibility", "none");
    });
}
export function restoreAllIcons(map) {
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        var labelLayer = map.layers.getLayerById("labels_indoor");
        if (!labelLayer)
            return;
        var configurableMap = map._getMap();
        for (var _i = 0, _a = labelLayer.layers; _i < _a.length; _i++) {
            var layer = _a[_i];
            if (layer.layout["icon-image"]) {
                configurableMap.setLayoutProperty(layer.id, "visibility", undefined);
            }
        }
    });
}
export function restoreAllLabels(map) {
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        map._getMap().setLayoutProperty(MapLabelLayerIds.IndoorLabels, "visibility", undefined);
    });
}
export function removeIcon(map, roomType) {
    if (MapIconLayerIds[roomType] === undefined) {
        return;
    }
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        map._getMap().setLayoutProperty(MapIconLayerIds[roomType], "visibility", "none");
    });
}
export function showIcon(map, roomType) {
    if (MapIconLayerIds[roomType] === undefined) {
        return;
    }
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        map._getMap().setLayoutProperty(MapIconLayerIds[roomType], "visibility", undefined);
    });
}
export function replaceIcon(map, roomType, imageId, image) {
    if (MapIconLayerIds[roomType] === undefined) {
        return;
    }
    if (!map)
        return;
    map.events.addOnce("ready", function () {
        if (image)
            map.imageSprite.add(imageId, image);
        map._getMap().setLayoutProperty(MapIconLayerIds[roomType], "icon-image", imageId);
    });
}

import { IMapLayers, PoiFloorMapping } from "../../../redux/Types";
import { IStyle, IStyleFunction } from "@fluentui/react";
import { ILogger } from "@smartbuilding/log-provider";
import { ISBClientTheme } from "../../../theme-provider/Theme.Types";
import { PoiType } from "@smartbuilding/poi-service";

export interface IMenuPoiBaseProps {
  pointsOfInterest: PoiFloorMapping;
  floorId?: string;
  buildingId?: string;
  mapLayers: IMapLayers;
  logger: ILogger;
  theme: ISBClientTheme;
  styles?: IStyleFunction<IMenuPoiStyleProps, IMenuPoiStyles>;
}

export interface IMenuPoiStyleProps {
  theme: ISBClientTheme;
}

export interface IMenuPoiStyles {
  root: IStyle;
}

export type MenuPoiButtonLabels = Record<PoiType, string>;
export const defaultMenuPoiButtonLabels: MenuPoiButtonLabels = {
  KioskLocation: "KioskLocation",
  EmergencyExit: "Exit",
  FireExtinguisher: "Fire",
  AEDDevice: "AED",
  BombShelter: "Shelter"
};

import * as DIP from "@dip/redux-sagas";
import { appMetricsReducer } from "./MetricsReducer";
import { appReducer } from "./AppReducer";
import { combineReducers } from "redux";
import { deviceConfigReducer } from "./DeviceConfigReducer";
import { errorReducer } from "./ErrorReducer";
import { layoutReducer } from "./LayoutReducer";
import { mapReducer } from "./MapReducer";
import { pathFindingReducer } from "./PathFindingReducer";
import { peopleReducer } from "./PeopleReducer";
import { resetReducer } from "./ResetReducer";
import { roomBookedReducer } from "./RoomBookedReducer";
import { signalrReducer } from "@smartbuilding/signalr-redux";
import { spaceReducer } from "./SpaceReducer";

export const rootReducer = combineReducers({
  appMetrics: appMetricsReducer,
  app: appReducer,
  space: spaceReducer,
  map: mapReducer,
  people: peopleReducer,
  pathFinding: pathFindingReducer,
  signalR: signalrReducer,
  deviceConfig: deviceConfigReducer,
  reset: resetReducer,
  layout: layoutReducer,
  dip: DIP.reducer,
  errors: errorReducer,
  roomBooked: roomBookedReducer
});

// This is only used for testing in order to bypass the DIP state.
// Otherwise, any render with a store will hang
export const mockRootReducer = combineReducers({
  appMetrics: appMetricsReducer,
  app: appReducer,
  space: spaceReducer,
  map: mapReducer,
  people: peopleReducer,
  pathFinding: pathFindingReducer,
  signalR: signalrReducer,
  deviceConfig: deviceConfigReducer,
  reset: resetReducer,
  layout: layoutReducer,
  errors: errorReducer,
  roomBooked: roomBookedReducer
});
export type RootState = ReturnType<typeof rootReducer>;

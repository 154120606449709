var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var MostSearchService = /** @class */ (function () {
    function MostSearchService() {
        this.searchRecord = {};
        this.mostSearchLimit = 5;
        this.mostSearchItems = [];
    }
    MostSearchService.prototype.getRecentSearches = function () {
        var _this = this;
        var slicedEnteries = this.mostSearchItems.slice(0, this.mostSearchLimit);
        var result = {};
        slicedEnteries.forEach(function (key) {
            if (_this.searchRecord[key].itemIdOrType) {
                result[key] = _this.searchRecord[key].itemIdOrType;
            }
            else {
                result[key] = "";
            }
        });
        return result;
    };
    MostSearchService.prototype.addOrUpdateSearchRecord = function (item, itemType) {
        var foundItem = this.searchRecord[item];
        if (foundItem) {
            this.searchRecord[item].itemNumber++;
        }
        else {
            this.searchRecord[item] = { itemNumber: 1, itemIdOrType: "" };
            if (itemType) {
                this.searchRecord[item].itemIdOrType = itemType;
            }
        }
        this.sortRecord();
    };
    MostSearchService.prototype.sortRecord = function () {
        var _this = this;
        var sortedList = Object.keys(this.searchRecord).sort(function (a, b) {
            return _this.searchRecord[b].itemNumber - _this.searchRecord[a].itemNumber;
        });
        this.mostSearchItems = [];
        if (Object.keys(this.searchRecord).length >= this.mostSearchLimit) {
            this.mostSearchItems = __spreadArray([], sortedList.slice(0, this.mostSearchLimit), true);
        }
        else {
            this.mostSearchItems = __spreadArray([], sortedList, true);
        }
    };
    return MostSearchService;
}());
export { MostSearchService };

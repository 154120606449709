export var ConferenceStatus;
(function (ConferenceStatus) {
    ConferenceStatus["Busy"] = "Busy";
    ConferenceStatus["Error"] = "Error";
    ConferenceStatus["Free"] = "Free";
})(ConferenceStatus || (ConferenceStatus = {}));
export var OccupancyStatus;
(function (OccupancyStatus) {
    OccupancyStatus["Available"] = "Available";
    OccupancyStatus["Error"] = "Error";
    OccupancyStatus["Occupied"] = "Occupied";
    OccupancyStatus["ReservedUnoccupied"] = "ReservedUnoccupied";
    OccupancyStatus["ReservedSpaceUnoccupied"] = "Reserved Unoccupied";
    OccupancyStatus["Unoccupied"] = "Unoccupied";
    OccupancyStatus["Undefined"] = "Undefined";
})(OccupancyStatus || (OccupancyStatus = {}));
export var SoundLevel;
(function (SoundLevel) {
    SoundLevel["Emergency"] = "Emergency";
    SoundLevel["Error"] = "Error";
    SoundLevel["Loud"] = "Loud";
    SoundLevel["Moderate"] = "Moderate";
    SoundLevel["Quiet"] = "Quiet";
})(SoundLevel || (SoundLevel = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AnimationType, startLinkedParallelAnimations } from "../../MapUtilities/Animation";
import { SpacePinIconLabels } from "../../Assets";
import { getTextOptionColors } from "../../Utilities/MapStyles";
// TODO: Enum this
var featureTextProperty = "featureText";
var idProperty = "id";
var layerSizeStateValues = {
    Initial: 0.6,
    Shown: 1,
    Selected: 1.8
};
var layerOpacityStateValues = {
    Initial: 0,
    Shown: 1
};
var layerPinTopTailGapStateValues = {
    Reset: 0,
    Default: 7
};
var textFieldExpression = [
    "format",
    ["get", "featureText"],
    "\n",
    {},
    ["get", "busynessStatus"],
    {
        "text-color": ["get", "busynessStatusColor"]
    }
];
var SpaceSymbolLayer = /** @class */ (function () {
    function SpaceSymbolLayer(map, pinTopLayer, pinTailLayer, menuPanelSelection, pinZoomVisibility, dismissCallback, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribers) {
        var _this = this;
        this.map = map;
        this.pinTopLayer = pinTopLayer;
        this.pinTailLayer = pinTailLayer;
        this.pinZoomVisibility = pinZoomVisibility;
        this.dismissCallback = dismissCallback;
        this.subscribers = subscribers;
        this.easeInOutAnimationConfig = { exponent: 3, offset: 0, duration: 200, type: AnimationType.EaseInOut };
        this.springAnimationConfig = { friction: 5.5, mass: 3, tension: 300, amplitude: -7, type: AnimationType.Spring, duration: 800 };
        this.sizeAnimationValues = { layer: layerSizeStateValues.Initial, selected: layerSizeStateValues.Initial };
        this.opacityAnimationValue = layerOpacityStateValues.Initial;
        this.verticalOffsetValues = { layer: 0, selected: 0 };
        this.selectedId = "";
        this.inTransition = false;
        this.mouseoverCursor = function () {
            _this.map.getCanvasContainer().style.cursor = "pointer";
        };
        this.mouseoutCursor = function () {
            _this.map.getCanvasContainer().style.cursor = "grab";
        };
        this.markerDismissClick = function (event) {
            var _a;
            (_a = _this.dismissCallback) === null || _a === void 0 ? void 0 : _a.call(_this);
        };
        this.markerSelectClick = function (event) {
            var _a, _b;
            var shapes = event.shapes;
            var selectedFeatureId = shapes[0].getProperties()[idProperty];
            var selectedFeatureText = shapes[0].getProperties()[featureTextProperty];
            if (!_this.inTransition) {
                if (selectedFeatureId === _this.selectedId) {
                    (_a = _this.dismissCallback) === null || _a === void 0 ? void 0 : _a.call(_this);
                }
                else {
                    (_b = _this.subscribers) === null || _b === void 0 ? void 0 : _b.forEach(function (subscriber) {
                        subscriber({ id: selectedFeatureId, name: selectedFeatureText });
                    });
                }
            }
        };
        this.handlePinSizeDrawCallback = this.handlePinSizeDrawCallback.bind(this);
        this.handlePinTopPositionDrawCallback = this.handlePinTopPositionDrawCallback.bind(this);
        this.setPinTailOptions();
        this.setPinTopOptions();
        this.map.events.add("ready", function () {
            menuPanelSelection
                ? _this.map.events.add("click", _this.pinTopLayer, _this.markerSelectClick)
                : _this.map.events.add("click", _this.pinTopLayer, _this.markerDismissClick);
            _this.map.events.add("mouseover", _this.pinTopLayer, _this.mouseoverCursor);
            _this.map.events.add("mouseout", _this.pinTopLayer, _this.mouseoutCursor);
        });
    }
    SpaceSymbolLayer.prototype.noAnimationShow = function () {
        this.opacityAnimationValue = layerOpacityStateValues.Shown;
        this.sizeAnimationValues.layer = layerSizeStateValues.Shown;
        this.sizeAnimationValues.selected = layerSizeStateValues.Selected;
        this.verticalOffsetValues.layer = layerPinTopTailGapStateValues.Default;
        this.verticalOffsetValues.selected = layerPinTopTailGapStateValues.Default;
        this.setPinTopOptions();
        this.setPinTailOptions();
    };
    SpaceSymbolLayer.prototype.show = function () {
        return startLinkedParallelAnimations([
            {
                duration: this.easeInOutAnimationConfig.duration,
                animationConfigurations: [
                    this.getPinSizeAnimationConfig(this.sizeAnimationValues.layer, layerSizeStateValues.Shown),
                    this.getPinOpacityAnimationConfig(this.opacityAnimationValue, layerSizeStateValues.Shown)
                ]
            },
            {
                duration: this.springAnimationConfig.duration,
                animationConfigurations: [this.getPinTopPositionAnimationConfig()]
            }
        ]);
    };
    SpaceSymbolLayer.prototype.noAnimationSelect = function (id) {
        this.selectedId = id;
        this.setPinTailOptions();
        this.setPinTopOptions();
    };
    SpaceSymbolLayer.prototype.select = function (id) {
        this.selectedId = id;
        return startLinkedParallelAnimations([
            {
                duration: this.easeInOutAnimationConfig.duration,
                animationConfigurations: [
                    this.getPinSizeAnimationConfig(this.sizeAnimationValues.selected, layerSizeStateValues.Selected),
                    this.getPinTopPositionResetAnimationConfig()
                ]
            },
            {
                duration: this.springAnimationConfig.duration,
                animationConfigurations: [this.getPinTopPositionAnimationConfig()]
            }
        ]);
    };
    SpaceSymbolLayer.prototype.selectAll = function () {
        return startLinkedParallelAnimations([
            {
                duration: this.easeInOutAnimationConfig.duration,
                animationConfigurations: [
                    this.getPinSizeAnimationConfig(this.sizeAnimationValues.layer, layerSizeStateValues.Selected),
                    this.getPinTopPositionResetAnimationConfig()
                ]
            },
            {
                duration: this.springAnimationConfig.duration,
                animationConfigurations: [this.getPinTopPositionAnimationConfig()]
            }
        ]);
    };
    SpaceSymbolLayer.prototype.noAnimationUnselect = function () {
        this.selectedId = "";
        this.setPinTailOptions();
        this.setPinTopOptions();
    };
    SpaceSymbolLayer.prototype.unselect = function () {
        var _this = this;
        if (!this.selectedId) {
            // return early to prevent a small animation from happening if no selected space.
            return Promise.resolve();
        }
        this.inTransition = true;
        return startLinkedParallelAnimations([
            {
                duration: this.easeInOutAnimationConfig.duration,
                animationConfigurations: [
                    this.getPinSizeAnimationConfig(this.sizeAnimationValues.layer, layerSizeStateValues.Shown),
                    this.getPinTopPositionResetAnimationConfig()
                ]
            },
            {
                duration: this.springAnimationConfig.duration,
                animationConfigurations: [this.getPinTopPositionAnimationConfig()]
            }
        ]).then(function () {
            _this.selectedId = "";
            _this.inTransition = false;
        });
    };
    SpaceSymbolLayer.prototype.remove = function () {
        // Scale from current size down to 0.6 and set position to 0
        return startLinkedParallelAnimations([
            {
                duration: this.easeInOutAnimationConfig.duration,
                animationConfigurations: [
                    this.getPinSizeAnimationConfig(this.sizeAnimationValues.layer, layerSizeStateValues.Initial),
                    this.getPinTopPositionResetAnimationConfig()
                ]
            },
            {
                duration: this.springAnimationConfig.duration,
                animationConfigurations: [this.getPinTopPositionAnimationConfig()]
            }
        ]);
    };
    SpaceSymbolLayer.prototype.dispose = function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.map.events.remove("click", this.pinTopLayer, this.markerDismissClick);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.map.events.remove("click", this.pinTopLayer, this.markerSelectClick);
        this.map.events.remove("mouseover", this.pinTopLayer, this.mouseoverCursor);
        this.map.events.remove("mouseout", this.pinTopLayer, this.mouseoutCursor);
    };
    SpaceSymbolLayer.prototype.hide = function () {
        var filterExpression = ["any", ["in", ["get", "category"], ["literal", []]]];
        this.pinTopLayer.setOptions({
            filter: filterExpression
        });
        this.pinTailLayer.setOptions({
            filter: filterExpression
        });
    };
    SpaceSymbolLayer.prototype.turnVisibilityOff = function () {
        this.pinTopLayer.setOptions(__assign(__assign({}, this.pinTopLayer.getOptions()), { visible: false }));
        this.pinTailLayer.setOptions(__assign(__assign({}, this.pinTailLayer.getOptions()), { visible: false }));
    };
    SpaceSymbolLayer.prototype.turnVisibilityOn = function () {
        this.pinTopLayer.setOptions(__assign(__assign({}, this.pinTopLayer.getOptions()), { visible: true }));
        this.pinTailLayer.setOptions(__assign(__assign({}, this.pinTailLayer.getOptions()), { visible: true }));
    };
    SpaceSymbolLayer.prototype.filter = function (filterExpression) {
        this.pinTopLayer.setOptions({ filter: filterExpression });
        this.pinTailLayer.setOptions({ filter: filterExpression });
    };
    SpaceSymbolLayer.prototype.getPinSizeAnimationConfig = function (initialVal, finalVal) {
        var _this = this;
        var isGrowing = initialVal < finalVal;
        return __assign({ draw: function (val) { return _this.handlePinSizeDrawCallback(initialVal, val, isGrowing); }, scale: Math.abs(finalVal - initialVal) }, this.easeInOutAnimationConfig);
    };
    SpaceSymbolLayer.prototype.getPinOpacityAnimationConfig = function (initialVal, finalVal) {
        var _this = this;
        var isGrowing = initialVal < finalVal;
        return __assign({ draw: function (val) { return _this.handleOpacityDrawCallback(initialVal, val, isGrowing); }, scale: Math.abs(finalVal - initialVal) }, this.easeInOutAnimationConfig);
    };
    SpaceSymbolLayer.prototype.getPinTopPositionResetAnimationConfig = function () {
        var _this = this;
        return __assign({ draw: function (val) { return _this.handlePinTopPositionDrawCallback(-val); }, scale: Math.abs(this.verticalOffsetValues.layer - layerPinTopTailGapStateValues.Reset) }, this.easeInOutAnimationConfig);
    };
    SpaceSymbolLayer.prototype.getPinTopPositionAnimationConfig = function () {
        var _this = this;
        return __assign({ draw: function (val) { return _this.handlePinTopPositionDrawCallback(val); } }, this.springAnimationConfig);
    };
    SpaceSymbolLayer.prototype.handlePinSizeDrawCallback = function (initialValue, newValue, isGrowing) {
        var valToStore = isGrowing ? initialValue + newValue : initialValue - newValue;
        this.sizeAnimationValues.selected = valToStore;
        if (this.isAllSymbolAnimation()) {
            this.sizeAnimationValues.layer = valToStore;
        }
        this.setPinTailOptions();
        this.setPinTopOptions();
    };
    SpaceSymbolLayer.prototype.handleOpacityDrawCallback = function (initialValue, newValue, isGrowing) {
        var valToStore = isGrowing ? initialValue + newValue : initialValue - newValue;
        this.opacityAnimationValue = valToStore;
        this.setPinTailOptions();
        this.setPinTopOptions();
    };
    SpaceSymbolLayer.prototype.handlePinTopPositionDrawCallback = function (value) {
        var offsetVal = layerPinTopTailGapStateValues.Default + value;
        this.verticalOffsetValues.selected = offsetVal;
        if (this.isAllSymbolAnimation()) {
            this.verticalOffsetValues.layer = offsetVal;
        }
        this.setPinTopOptions();
    };
    SpaceSymbolLayer.prototype.setPinTopOptions = function () {
        this.pinTopLayer.setOptions({
            iconOptions: this.getIconOptions(SpacePinIconLabels.PinTop),
            maxZoom: this.pinZoomVisibility.max,
            minZoom: this.pinZoomVisibility.min
        });
    };
    SpaceSymbolLayer.prototype.setPinTailOptions = function () {
        this.pinTailLayer.setOptions({
            iconOptions: this.getIconOptions(SpacePinIconLabels.PinTail),
            textOptions: this.getTextOptions(),
            maxZoom: this.pinZoomVisibility.max,
            minZoom: this.pinZoomVisibility.min
        });
    };
    SpaceSymbolLayer.prototype.getTextOptions = function () {
        var colors = getTextOptionColors(this.map);
        return {
            textField: ["case", ["has", "busynessStatus"], textFieldExpression, ["get", featureTextProperty]],
            font: ["SegoeUi-SemiBold"],
            color: colors.font,
            haloColor: colors.halo,
            haloBlur: 0.5,
            haloWidth: 1,
            offset: ["case", ["has", "busynessStatus"], ["literal", [0, 1.1]], ["literal", [0, 0.5]]],
            size: 10,
            opacity: this.opacityAnimationValue
        };
    };
    SpaceSymbolLayer.prototype.getIconOptions = function (pinType) {
        var sizeExpression = [
            "match",
            // Getting the feature id from the properties
            ["get", "id"],
            // Selected feature id
            "".concat(this.selectedId),
            ["/", ["literal", this.sizeAnimationValues.selected], 2],
            // Default value is the symbol layer value
            ["/", ["literal", this.sizeAnimationValues.layer], 2]
        ];
        var offsetExpression = [
            "match",
            // Getting the feature id from the properties
            ["get", "id"],
            // Selected feature id
            "".concat(this.selectedId),
            ["literal", [0, 0 - this.verticalOffsetValues.selected * 2]],
            // Default value is the symbol layer value
            ["literal", [0, 0 - this.verticalOffsetValues.layer * 2]]
        ];
        var icon = SpacePinIconLabels.PinTail;
        var offset = [0, 0];
        if (pinType === SpacePinIconLabels.PinTop) {
            var iconExpression = ["concat", "".concat(SpacePinIconLabels.PinTop, "/"), ["get", "category"]];
            icon = iconExpression;
            offset = offsetExpression;
        }
        return {
            image: icon,
            size: sizeExpression,
            opacity: this.opacityAnimationValue,
            allowOverlap: true,
            ignorePlacement: true,
            offset: offset,
            visible: true
        };
    };
    SpaceSymbolLayer.prototype.isAllSymbolAnimation = function () {
        return !this.selectedId;
    };
    return SpaceSymbolLayer;
}());
export { SpaceSymbolLayer };

import {
  AppResetAction,
  AppResetActions,
  AppResetInProgressAction,
  DetailsPanelPageStackActions,
  DetailsPanelPageStackAddPageAction,
  DetailsPanelPageStackClearStackAction,
  DetailsPanelPageStackRemovePageAction,
  InstantBookingActions,
  InstantBookingEnabledAction,
  ListViewActions,
  ListViewSpaceSelectedAction,
  OCVFeedbackActions,
  OCVFeedbackEnabledAction,
  RoomTagEnabledAction,
  RoomTagsActions
} from "./AppActions";
import { IPageInfo, InstantBookingDurationCategory } from "../Types";

export function modifyDetailsPanelStackPage(
  pageInfo?: IPageInfo
): DetailsPanelPageStackAddPageAction | DetailsPanelPageStackRemovePageAction {
  if (pageInfo) {
    return {
      type: DetailsPanelPageStackActions.ADD_PAGE,
      payload: pageInfo
    };
  } else {
    return {
      type: DetailsPanelPageStackActions.REMOVE_PAGE
    };
  }
}

export function clearDetailsPanelStack(): DetailsPanelPageStackClearStackAction {
  return {
    type: DetailsPanelPageStackActions.CLEAR_STACK
  };
}

export function enableInstantBooking(): InstantBookingEnabledAction {
  return {
    type: InstantBookingActions.INSTANT_BOOKING_ENABLED
  };
}

export function selectListViewCard(
  selectedCategory: string | InstantBookingDurationCategory,
  selectedSpaceIds: string[],
  targetStartTime?: Date,
  targetEndTime?: Date
): ListViewSpaceSelectedAction {
  return {
    type: ListViewActions.LIST_VIEW_SPACE_SELECTION,
    payload: {
      selectedCategory,
      selectedSpaceIds,
      targetStartTime,
      targetEndTime
    }
  };
}

export function enableOCVFeedback(): OCVFeedbackEnabledAction {
  return {
    type: OCVFeedbackActions.OCV_FEEDBACK_ENABLED
  };
}

export function resetApp(): AppResetAction {
  return {
    type: AppResetActions.APP_RESET
  };
}

export function setResetInProgress(inProgress: boolean): AppResetInProgressAction {
  return {
    type: AppResetActions.APP_RESET_IN_PROGRESS,
    payload: inProgress
  };
}

export function enableRoomTags(): RoomTagEnabledAction {
  return {
    type: RoomTagsActions.ROOM_TAG_ENABLED
  };
}

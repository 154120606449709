var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AzureMapService } from "../MapUtilities/AzureMapService";
import { DynamicBlueDotMarker } from "../MapMarkers/DynamicBlueDotMarker";
import { addImageTemplate as atlasAddImageTemplate } from "azure-maps-control";
import { IconSvgCreator } from "../MapUtilities/IconSvgCreator";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { SpacePopupLayer } from "../MapLayers/SpacePopupLayer";
import { MapEvent } from "../Types/MapEventCallback";
import { SpacePinIconLabels, spacePinIcons } from "../Assets/";
import { AzureMapsDataService } from "../MapUtilities/AzureMapService/AzureMapsDataService";
import { BusynessMapLayer } from "../MapLayers/BusynessLayer/BusynessMapLayer";
import { CustomMapLayer } from "../MapLayers/CustomMapLayer";
import { EnabledRoomSubTypes } from "../MapLayers/SpaceSymbolLayer/SpaceSymbolLayer.Types";
import { HotDeskOverlayMapLayer } from "../MapLayers/HotDeskLayer/HotDeskOverlayMapLayer";
import { MapFeaturesDataSource } from "../MapLayers/DataSources/MapFeaturesDataSource";
import { PeopleDensityOverlayMapLayer } from "../MapLayers/PeopleDensityLayer/PeopleDensityOverlayMapLayer";
import { PeopleOverlayMapLayer } from "../MapLayers/PeopleLayer/PeopleOverlayMapLayer";
import { PersonLayer } from "../MapLayers/PersonLayer/PersonLayer";
import { RouteOverlayMapLayer } from "../MapLayers/RouteLayer/RouteOverlayMapLayer";
import { SensorOverlayMapLayer } from "../MapLayers/SensorLayer/SensorOverlayMapLayer";
import { SpacePinsLayer } from "../MapLayers/SpacePinsLayer/SpacePinsLayer";
import { SpaceSymbolManager } from "../MapLayers/SpaceSymbolLayer/SpaceSymbolManager";
import { indoor } from "azure-maps-indoor";
var IndoorAzureMap = /** @class */ (function () {
    function IndoorAzureMap(map, logger, getAzureMapsDataCallback, telemetryCallback, bearing) {
        var _this = this;
        this.map = map;
        this.telemetryCallback = telemetryCallback;
        this.bearing = bearing;
        this.facility = null;
        this.facilityLevels = [];
        this.features = [];
        this.indoorMapOptions = undefined;
        this.eventSubscribers = {};
        this.defaultIndoorZoomLevel = 19;
        this.defaultPitchDegree = 0;
        this.mapRequestQueue = [];
        this.setFloor = function (floorName) {
            var _a;
            if (!_this.facility) {
                _this.addToMapRequestQueue(_this.functionWrapper(_this.setFloor, _this, [floorName]));
                return;
            }
            var facilityLevels = _this.facilityLevels;
            var level = (_a = facilityLevels.find(function (l) { return _this.isLevel(floorName, l.name); })) !== null && _a !== void 0 ? _a : facilityLevels.find(function (l) { return l.ordinal === 0; });
            if (!facilityLevels || facilityLevels.length === 0) {
                _this.addToMapRequestQueue(_this.functionWrapper(_this.setFloor, _this, [floorName]));
                return;
            }
            if (_this.isFacilityMappingEmpty()) {
                _this.setIndoorMapToBound();
            }
            if (level) {
                _this.indoorMap.setFacility(_this.facility.id, level.ordinal);
                _this.setCustomLayerFloor(level.levelId);
            }
        };
        this.setCurrentFacilityLevel = function () {
            var currentFloorNum = _this.indoorMap.getCurrentFacility()[1];
            var selectedFacilityLevel = _this.facilityLevels.find(function (val) { return val.ordinal === currentFloorNum; });
            _this.currentFacilityLevel = selectedFacilityLevel;
        };
        this.getCurrentFloor = function () {
            var _a, _b;
            return (_b = (_a = _this.currentFacilityLevel) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
        };
        this.getCurrentLevelId = function () {
            var _a, _b;
            return (_b = (_a = _this.currentFacilityLevel) === null || _a === void 0 ? void 0 : _a.levelId) !== null && _b !== void 0 ? _b : "";
        };
        this.getLevelOrdinal = function (floorName) {
            var _a;
            return (_a = _this.facilityLevels.find(function (level) { return level.name === floorName; })) === null || _a === void 0 ? void 0 : _a.ordinal;
        };
        this.onMapClickEventCallback = function (event) {
            var nonDefaultClickFeatures = _this.map.layers.getRenderedShapes(event.position, [
                "menuPinTopLayer",
                "clickPinTopLayer",
                "pinTopLayer",
                "pinTailLayer"
            ]);
            if (nonDefaultClickFeatures.length > 0) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var features = _this.map.layers.getRenderedShapes(event.position);
            for (var _i = 0, features_1 = features; _i < features_1.length; _i++) {
                var feature = features_1[_i];
                if (feature && feature.layer && feature.layer["source-layer"] === "Indoor unit" && feature.properties) {
                    _this.notifySubscribers({ name: feature.properties.name, id: feature.properties.originalId }, _this.eventSubscribers[MapEvent.MapClick]);
                }
            }
        };
        this.onLevelChangeCallback = function (event) {
            _this.setCurrentFacilityLevel();
            if (_this.currentFacilityLevel) {
                var idleTime_1 = performance.now();
                _this.map.events.addOnce("idle", function () {
                    var _a, _b, _c;
                    (_a = _this.telemetryCallback) === null || _a === void 0 ? void 0 : _a.call(_this, {
                        messageName: "[AzureIndoorMaps-FloorSwitcher] IndoorMaps Facility Loaded in idle state",
                        timeInMs: idleTime_1 - performance.now(),
                        loadEndtime: performance.now(),
                        epochTimeMs: Date.now(),
                        buildingName: (_b = _this.facility) === null || _b === void 0 ? void 0 : _b.name,
                        buildingId: (_c = _this.facility) === null || _c === void 0 ? void 0 : _c.originalId
                    });
                });
                _this.notifySubscribers(_this.currentFacilityLevel.name, _this.eventSubscribers[MapEvent.IndoorLevelChange]);
            }
        };
        this.onFacilityChangeCallback = function (event) {
            _this.setCurrentFacilityLevel();
            var count = 0;
            while (_this.mapRequestQueue && _this.mapRequestQueue.length > 0) {
                count++;
                if (count > 10) {
                    //limit consecutive queue calls to 10
                    return;
                }
                var request = _this.mapRequestQueue.shift();
                if (request) {
                    request();
                }
            }
        };
        this.imageMissingCallback = function (event) {
            if (event === SpacePinIconLabels.PinTail) {
                atlasAddImageTemplate(SpacePinIconLabels.PinTail, spacePinIcons.PinTail, true);
                _this.map.imageSprite.createFromTemplate(SpacePinIconLabels.PinTail, SpacePinIconLabels.PinTail, undefined, undefined, 2);
                EnabledRoomSubTypes["PinTail"] = true;
                return;
            }
            var roomSubType = RoomSubTypes[event.split("/")[1]];
            if (!EnabledRoomSubTypes[roomSubType]) {
                EnabledRoomSubTypes[roomSubType] = true;
                _this.iconSvgCreator.createSpacePinSvg(roomSubType, false).then(function (spacePin) {
                    atlasAddImageTemplate("".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), spacePin.svg, true);
                    _this.map.imageSprite.createFromTemplate("".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), "".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), undefined, undefined, 2);
                });
            }
        };
        this.logger = logger;
        this.azureMapsService = new AzureMapService(this.map);
        this.azureMapsDataService = new AzureMapsDataService(this.logger, this.azureMapsService, getAzureMapsDataCallback);
        this.mapFeaturesDataSource = new MapFeaturesDataSource(this.map, this.logger, "MapFeatures");
        this.indoorMap = new indoor.IndoorManager(this.map, { theme: "auto" });
        this.spacePopupLayer = new SpacePopupLayer(this.map, this.indoorMap, this.azureMapsDataService, function () { var _a, _b; return (_b = (_a = _this.indoorMapOptions) === null || _a === void 0 ? void 0 : _a.datasetId) !== null && _b !== void 0 ? _b : ""; }, function () { var _a; return (_a = _this.facilityLevels) !== null && _a !== void 0 ? _a : []; });
        this.hotDeskLayer = new HotDeskOverlayMapLayer(this.spacePopupLayer);
        this.peopleDensityLayer = new PeopleDensityOverlayMapLayer(this.spacePopupLayer);
        this.spaceSymbolLayer = new SpaceSymbolManager(this.map, this.logger, this.mapFeaturesDataSource.getDataSource());
        this.sensorOverlayLayer = new SensorOverlayMapLayer(this.map, this.logger, this.mapFeaturesDataSource.getDataSource());
        this.map.events.addOnce("ready", function () {
            _this.spacePinsLayer = new SpacePinsLayer(_this.map, _this.mapFeaturesDataSource.getDataSource());
        });
        this.busynessLayer = new BusynessMapLayer(this.map, this.mapFeaturesDataSource.getDataSource(), this.logger);
        this.iconSvgCreator = new IconSvgCreator(this.logger, undefined);
        this.preloadSpacePinAssets();
    }
    IndoorAzureMap.prototype.setTilesetId = function (tilesetId, datasetId, bearing) {
        var options = this.indoorMap.getOptions();
        this.logger.logEvent("[IndoorMap] tilesetId value set", {
            currentTilesetId: options.tilesetId,
            tilesetId: tilesetId
        });
        this.indoorMap.setOptions(__assign(__assign({}, options), { tilesetId: tilesetId }));
        this.initializeIndoorMap(tilesetId, datasetId, bearing);
    };
    IndoorAzureMap.prototype.updateIndoorMap = function (indoorMapOptions) {
        var _this = this;
        var mapInitializedCallback = function () {
            var _a, _b, _c, _d;
            var indoorLoadEnd = performance.now();
            _this.logger.logEvent("[AzureMapLoadTime] Indoor map initialization time", {
                timeInMs: indoorLoadEnd - indoorLoadStart,
                buildingName: (_a = _this.facility) === null || _a === void 0 ? void 0 : _a.name
            });
            (_b = _this.telemetryCallback) === null || _b === void 0 ? void 0 : _b.call(_this, {
                messageName: "[AzureMapLoadTime] Indoor map initialization time",
                timeInMs: indoorLoadEnd - indoorLoadStart,
                loadEndTime: indoorLoadEnd,
                epochTimeMs: Date.now(),
                buildingName: (_c = _this.facility) === null || _c === void 0 ? void 0 : _c.name,
                buildingId: (_d = _this.facility) === null || _d === void 0 ? void 0 : _d.originalId
            });
            _this.map.events.addOnce("idle", function () {
                var _a, _b, _c, _d, _e, _f, _g;
                var idleTime = performance.now();
                _this.logger.logEvent("[AzureMapLoadTime] Indoor map initialization until map idle time", {
                    timeInMs: idleTime - indoorLoadEnd,
                    buildingName: (_a = _this.facility) === null || _a === void 0 ? void 0 : _a.name
                });
                (_b = _this.telemetryCallback) === null || _b === void 0 ? void 0 : _b.call(_this, {
                    messageName: "[AzureMapLoadTime] Indoor map initialization until map idle time",
                    timeInMs: idleTime - indoorLoadEnd,
                    loadEndtime: idleTime,
                    epochTimeMs: Date.now(),
                    buildingName: (_c = _this.facility) === null || _c === void 0 ? void 0 : _c.name,
                    buildingId: (_d = _this.facility) === null || _d === void 0 ? void 0 : _d.originalId,
                    buildingFeatureCount: (_e = _this.features) === null || _e === void 0 ? void 0 : _e.length
                });
                if (_this.isFacilityMappingEmpty()) {
                    _this.logger.logEvent("[IndoorMap] The indoor map was empty, refreshing map", {
                        tilesetId: (_f = _this.indoorMapOptions) === null || _f === void 0 ? void 0 : _f.tilesetId,
                        buildingFeatureCount: (_g = _this.features) === null || _g === void 0 ? void 0 : _g.length
                    });
                    _this.refreshMap();
                }
            });
            _this.toggleSensorLayerVisibility(true);
            _this.setSpaceFeatureProperties(_this.features);
            _this.unsubscribeFromEvent(MapEvent.IndoorMapInitialized, mapInitializedCallback);
        };
        var indoorLoadStart = performance.now();
        this.subscribeToEvent(MapEvent.IndoorMapInitialized, mapInitializedCallback);
        this.indoorMapOptions = indoorMapOptions;
        if (indoorMapOptions.tilesetId) {
            this.logger.logEvent("[IndoorMap] tilesetId set", { tilesetId: indoorMapOptions.tilesetId });
            this.setTilesetId(indoorMapOptions.tilesetId, indoorMapOptions.datasetId, indoorMapOptions.bearing);
        }
    };
    IndoorAzureMap.prototype.initializeIndoorMap = function (tilesetId, datasetId, bearing) {
        return __awaiter(this, void 0, void 0, function () {
            var azureMapsWFSData, _a, _b, _c, _d, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        this.facility = null;
                        this.facilityLevels = [];
                        this.features = [];
                        this.mapRequestQueue = [];
                        this.subscribeToMapEvents();
                        return [4 /*yield*/, this.azureMapsDataService.getAzureMapsData(datasetId)];
                    case 1:
                        azureMapsWFSData = _g.sent();
                        if (!azureMapsWFSData) return [3 /*break*/, 5];
                        _a = this;
                        return [4 /*yield*/, this.azureMapsDataService.getFacility(azureMapsWFSData)];
                    case 2:
                        _a.facility = _g.sent();
                        _b = this;
                        return [4 /*yield*/, this.azureMapsDataService.getLevels(azureMapsWFSData)];
                    case 3:
                        _b.facilityLevels = _g.sent();
                        _c = this;
                        return [4 /*yield*/, this.azureMapsDataService.getFeatures(azureMapsWFSData)];
                    case 4:
                        _c.features = _g.sent();
                        return [3 /*break*/, 9];
                    case 5:
                        _d = this;
                        return [4 /*yield*/, this.azureMapsDataService.getFacility(undefined, datasetId)];
                    case 6:
                        _d.facility = _g.sent();
                        _e = this;
                        return [4 /*yield*/, this.azureMapsDataService.getLevels(undefined, datasetId)];
                    case 7:
                        _e.facilityLevels = _g.sent();
                        _f = this;
                        return [4 /*yield*/, this.azureMapsDataService.getFeatures(undefined, datasetId)];
                    case 8:
                        _f.features = _g.sent();
                        _g.label = 9;
                    case 9:
                        this.indoorMap.setFacility(this.facility.id, 0);
                        this.setCurrentFacilityLevel();
                        this.setIndoorMapToBound();
                        this.spacePopupLayer.setBuildingFeatures(this.features);
                        this.notifySubscribers(tilesetId, this.eventSubscribers.IndoorMapInitialized);
                        if (bearing) {
                            this.bearing = bearing;
                            this.setMapRotation(this.bearing);
                        }
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    IndoorAzureMap.prototype.subscribeToMapEvents = function () {
        this.map.events.add("click", this.onMapClickEventCallback);
        this.map.events.add("levelchanged", this.indoorMap, this.onLevelChangeCallback);
        this.map.events.add("facilitychanged", this.indoorMap, this.onFacilityChangeCallback);
        this.map.events.add("styleimagemissing", this.imageMissingCallback);
    };
    IndoorAzureMap.prototype.setIndoorMapToBound = function () {
        return __awaiter(this, void 0, void 0, function () {
            var padding, offset, floorBoundingBox, _a, zoomValue, zoomToSet;
            var _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this.indoorMapOptions)
                            return [2 /*return*/];
                        padding = undefined;
                        offset = undefined;
                        if (this.indoorMapOptions.isSmartBuildingClient) {
                            padding = { top: 10, bottom: 10, left: 10, right: 10 };
                            offset = [50, -15];
                        }
                        if (!((_c = (_b = this.currentFacilityLevel) === null || _b === void 0 ? void 0 : _b.bounds) !== null && _c !== void 0)) return [3 /*break*/, 1];
                        _a = _c;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.azureMapsDataService.getBoundingBox(this.indoorMapOptions.tilesetId, (_d = this.map.authentication.getToken()) !== null && _d !== void 0 ? _d : "", this.map.authentication.getClientId())];
                    case 2:
                        // fallback
                        _a = (_e.sent());
                        _e.label = 3;
                    case 3:
                        floorBoundingBox = _a;
                        this.map.setCamera({ bounds: floorBoundingBox, padding: padding, offset: offset });
                        zoomValue = this.map.getCamera().zoom;
                        zoomToSet = zoomValue && zoomValue > this.defaultIndoorZoomLevel
                            ? (zoomValue + this.defaultIndoorZoomLevel) / 2
                            : this.defaultIndoorZoomLevel;
                        this.map.setCamera({ zoom: zoomToSet });
                        if (this.bearing) {
                            this.setMapRotation(this.bearing);
                        }
                        this.resetPitch();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.resetPitch = function () {
        this.map.setCamera({ pitch: this.defaultPitchDegree });
    };
    IndoorAzureMap.prototype.preloadSpacePinAssets = function () {
        return __awaiter(this, void 0, void 0, function () {
            var startTime, _i, _a, roomSubType, roomSubTypeEnum;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        startTime = performance.now();
                        _i = 0, _a = Object.keys(EnabledRoomSubTypes);
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        roomSubType = _a[_i];
                        roomSubTypeEnum = RoomSubTypes[roomSubType];
                        if (!roomSubTypeEnum)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.iconSvgCreator.createSpacePinSvg(roomSubTypeEnum, false)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.map.events.addOnce("ready", function () {
                            var _a;
                            atlasAddImageTemplate(SpacePinIconLabels.PinTail, spacePinIcons.PinTail, true);
                            _this.map.imageSprite.createFromTemplate(SpacePinIconLabels.PinTail, SpacePinIconLabels.PinTail, undefined, undefined, 2);
                            Object.keys(EnabledRoomSubTypes).forEach(function (roomSubType) {
                                var roomSubTypeEnum = RoomSubTypes[roomSubType];
                                if (roomSubTypeEnum) {
                                    _this.iconSvgCreator.createSpacePinSvg(roomSubTypeEnum, false).then(function (spacePin) {
                                        atlasAddImageTemplate("".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), spacePin.svg, true);
                                        _this.map.imageSprite.createFromTemplate("".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), "".concat(SpacePinIconLabels.PinTop, "/").concat(roomSubType), undefined, undefined, 2);
                                    });
                                }
                            });
                            var endTime = performance.now();
                            var timeInMs = endTime - startTime;
                            (_a = _this.telemetryCallback) === null || _a === void 0 ? void 0 : _a.call(_this, {
                                messageName: "[SpacePins] Preload Complete",
                                timeInMs: timeInMs,
                                loadEndTime: endTime,
                                epochTimeMs: Date.now()
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.setMapRotation = function (rotation) {
        this.map.setCamera({ bearing: rotation });
    };
    IndoorAzureMap.prototype.setSpaceSensorProperties = function (idSensorPropsMap) {
        this.mapFeaturesDataSource.setSensorProperties(idSensorPropsMap);
    };
    IndoorAzureMap.prototype.setSpaceCategoryProperties = function (categoryRoomMap) {
        this.mapFeaturesDataSource.setSpaceCategoryProperties(categoryRoomMap);
    };
    IndoorAzureMap.prototype.setSpaceFeatureProperties = function (features) {
        this.mapFeaturesDataSource.setFeatureProperties(features);
    };
    IndoorAzureMap.prototype.createSpaceSymbolLayer = function (categoryRoomMap, pinZoomVisibility, dismissCallback, backClickCallback) {
        this.setSpaceCategoryProperties(categoryRoomMap);
        this.spaceSymbolLayer.initialize(categoryRoomMap, pinZoomVisibility, dismissCallback, backClickCallback, this.eventSubscribers[MapEvent.MapClick], this.telemetryCallback);
    };
    IndoorAzureMap.prototype.enableSpacePinAnimations = function () {
        this.spaceSymbolLayer.enablePinAnimations();
    };
    IndoorAzureMap.prototype.toggleSensorLayerVisibility = function (isVisible) {
        var _a;
        (_a = this.sensorOverlayLayer) === null || _a === void 0 ? void 0 : _a.toggleSensorLayerVisibility(isVisible);
    };
    IndoorAzureMap.prototype.toggleIndoorMapTheme = function () {
        var _a;
        (_a = this.sensorOverlayLayer) === null || _a === void 0 ? void 0 : _a.toggleSensorLayerTheme();
    };
    IndoorAzureMap.prototype.renderSpaceSymbolLayer = function (categories, isLayerCurrentlyVisible) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busynessLayer.hideBaseMapBusynessLayer();
                        return [4 /*yield*/, this.spaceSymbolLayer.renderSpaceSymbolLayer("menu", categories, this.getCurrentLevelId(), isLayerCurrentlyVisible)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.renderSpaceSymbolLayerFromClick = function (selectedSpaceId_1, isLayerCurrentlyVisible_1) {
        return __awaiter(this, arguments, void 0, function (selectedSpaceId, isLayerCurrentlyVisible, centerOffset, selectedSpaceFloorName) {
            var _a;
            if (centerOffset === void 0) { centerOffset = [0, 0]; }
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (isLayerCurrentlyVisible && selectedSpaceFloorName && selectedSpaceFloorName !== ((_a = this.currentFacilityLevel) === null || _a === void 0 ? void 0 : _a.name))
                            this.setFloor(selectedSpaceFloorName);
                        return [4 /*yield*/, this.spaceSymbolLayer.renderSpaceSymbolLayer("click", selectedSpaceId, this.getCurrentLevelId(), isLayerCurrentlyVisible, centerOffset)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.removeSpaceSymbolLayer = function () {
        this.spaceSymbolLayer.removeSpaceSymbolLayer();
        this.busynessLayer.showBaseMapBusynessLayer();
    };
    IndoorAzureMap.prototype.hideSpaceSymbolLayer = function () {
        this.spaceSymbolLayer.hide();
    };
    IndoorAzureMap.prototype.showSpaceSymbolLayer = function () {
        this.spaceSymbolLayer.show();
    };
    IndoorAzureMap.prototype.disposeSpaceSymbolLayer = function () {
        this.spaceSymbolLayer.dispose();
    };
    IndoorAzureMap.prototype.highlightSpace = function (featureId_1, category_1, isLayerCurrentlyVisible_1) {
        return __awaiter(this, arguments, void 0, function (featureId, category, isLayerCurrentlyVisible, centerOffset, selectedSpaceFloorName) {
            if (centerOffset === void 0) { centerOffset = [0, 0]; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isLayerCurrentlyVisible && selectedSpaceFloorName)
                            this.setFloor(selectedSpaceFloorName);
                        return [4 /*yield*/, this.spaceSymbolLayer.highlightSpace(featureId, category, this.getCurrentLevelId(), isLayerCurrentlyVisible, centerOffset)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.removeHighlight = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spaceSymbolLayer.removeHighlight()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.renderSpacePins = function (spaceIds) {
        var _a;
        (_a = this.spacePinsLayer) === null || _a === void 0 ? void 0 : _a.renderPins(spaceIds);
    };
    IndoorAzureMap.prototype.renderSingleSpacePin = function (spaceId) {
        var _a;
        (_a = this.spacePinsLayer) === null || _a === void 0 ? void 0 : _a.renderSinglePin(spaceId);
    };
    IndoorAzureMap.prototype.createOverlayMapLayers = function (overlayLayersMap, overlayMapOptions) {
        var _a, _b, _c;
        // Worst case pass in empty array, equivalent to no gray out
        var floorBoundingCoordinates = (_b = (_a = this.currentFacilityLevel) === null || _a === void 0 ? void 0 : _a.boundingCoordinates) !== null && _b !== void 0 ? _b : [];
        (_c = this.overlayLayerMap) === null || _c === void 0 ? void 0 : _c.clearKioskMarker();
        this.overlayLayerMap = new CustomMapLayer(this.map, overlayMapOptions, floorBoundingCoordinates, this.iconSvgCreator);
        return this.overlayLayerMap.createOverlayMapLayers(overlayLayersMap);
    };
    IndoorAzureMap.prototype.createRouteLayer = function (routeData, isStartMarkerDraggable, onStartMarkerDroppedCallback) {
        if (this.routeLayer)
            this.routeLayer.dispose();
        if (!this.facility) {
            this.addToMapRequestQueue(this.functionWrapper(this.createRouteLayer, this, [
                routeData,
                isStartMarkerDraggable,
                onStartMarkerDroppedCallback
            ]));
            return;
        }
        this.routeLayer = new RouteOverlayMapLayer(this.map, this.indoorMap, this.iconSvgCreator, routeData, this.logger, isStartMarkerDraggable, this.setFloor, this.getCurrentFloor, this.getLevelOrdinal, onStartMarkerDroppedCallback, this.telemetryCallback, this.bearing);
    };
    IndoorAzureMap.prototype.createPeopleDensityLayer = function (peopleDensityData) {
        return this.peopleDensityLayer.createOrUpdatePeopleCountPopups(peopleDensityData);
    };
    IndoorAzureMap.prototype.removePeopleDensityLayer = function () {
        this.peopleDensityLayer.removePopups();
    };
    IndoorAzureMap.prototype.removeRouteLayer = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.routeLayer) return [3 /*break*/, 2];
                        this.routeLayer.dispose();
                        this.routeLayer = undefined;
                        return [4 /*yield*/, this.setIndoorMapToBound()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    IndoorAzureMap.prototype.createHotDeskLayer = function (hotdeskData) {
        return this.hotDeskLayer.createOrUpdateHotdeskPopups(hotdeskData);
    };
    IndoorAzureMap.prototype.removeHotDeskLayer = function () {
        this.hotDeskLayer.removePopups();
    };
    IndoorAzureMap.prototype.createDynamicBluedot = function (position, isVisible) {
        if (this.dynamicBluedot && this.dynamicBluedotHtmlMarker) {
            this.dynamicBluedot.updateDynamicBlueDot(position, this.dynamicBluedotHtmlMarker, isVisible);
        }
        else {
            this.dynamicBluedot = new DynamicBlueDotMarker(this.map);
            this.dynamicBluedotHtmlMarker = this.dynamicBluedot.createDynamicBluedot(position);
        }
    };
    IndoorAzureMap.prototype.removeDynamicBluedot = function () {
        if (this.dynamicBluedot) {
            this.dynamicBluedot.dispose();
        }
        this.dynamicBluedot = undefined;
    };
    IndoorAzureMap.prototype.resetToDynamicBluedot = function () {
        if (this.dynamicBluedot) {
            this.dynamicBluedot.resetToDynamicBluedot();
        }
    };
    IndoorAzureMap.prototype.zoomToRoute = function () {
        if (this.routeLayer) {
            this.routeLayer.resetToRoute();
        }
    };
    IndoorAzureMap.prototype.createPeopleLayer = function (zoomThreshold, callbackFn, peopleFeatureImageMap) {
        if (this.peopleLayer) {
            this.peopleLayer.dispose();
        }
        this.peopleLayer = new PeopleOverlayMapLayer(this.map, zoomThreshold, callbackFn, peopleFeatureImageMap);
    };
    IndoorAzureMap.prototype.removePeopleLayer = function () {
        if (this.peopleLayer) {
            this.peopleLayer.dispose();
        }
        this.peopleLayer = undefined;
    };
    IndoorAzureMap.prototype.hidePeopleMarkersInFeatureId = function (featureId) {
        if (!this.peopleLayer)
            return;
        this.peopleLayer.hideMarkersInFeatureId(featureId);
    };
    IndoorAzureMap.prototype.resetHidePeopleMarkersInFeatureId = function () {
        if (!this.peopleLayer)
            return;
        this.peopleLayer.resetHideMarkersInFeatureId();
    };
    IndoorAzureMap.prototype.subscribeToEvent = function (eventType, callback) {
        var _a;
        if ((_a = this.eventSubscribers[eventType]) === null || _a === void 0 ? void 0 : _a.includes(callback)) {
            return;
        }
        var subscribers = this.eventSubscribers[eventType];
        if (subscribers) {
            this.eventSubscribers[eventType] = __spreadArray(__spreadArray([], subscribers, true), [callback], false);
        }
        else {
            this.eventSubscribers[eventType] = [callback];
        }
    };
    IndoorAzureMap.prototype.unsubscribeFromEvent = function (eventType, callback) {
        var _a, _b;
        if ((_a = this.eventSubscribers[eventType]) === null || _a === void 0 ? void 0 : _a.includes(callback)) {
            this.eventSubscribers[eventType] = (_b = this.eventSubscribers[eventType]) === null || _b === void 0 ? void 0 : _b.filter(function (subscriber) { return subscriber !== callback; });
        }
    };
    IndoorAzureMap.prototype.isLevel = function (floorName, levelName) {
        var floorNum = +floorName;
        var isSameStr = isNaN(floorNum) && floorName === levelName;
        var isSameNum = !isNaN(floorNum) && floorNum === +levelName;
        return isSameStr || isSameNum;
    };
    IndoorAzureMap.prototype.setCustomLayerFloor = function (levelId) {
        var _a, _b;
        (_a = this.sensorOverlayLayer) === null || _a === void 0 ? void 0 : _a.setSensorLayerFloor(levelId);
        this.busynessLayer.setBusynessLayerFloor(levelId);
        this.spaceSymbolLayer.setSpaceSymbolLayerFloor(levelId);
        (_b = this.spacePinsLayer) === null || _b === void 0 ? void 0 : _b.setFloorId(levelId);
    };
    IndoorAzureMap.prototype.dispose = function () {
        var _a;
        this.logger.logEvent("[IndoorMap] Indoor map disposed, tileset set to undefined", {
            tilesetId: (_a = this.indoorMapOptions) === null || _a === void 0 ? void 0 : _a.tilesetId
        });
        // unsubscribe from all events
        this.unsubscribeFromAllEvents();
        this.disposeMapLayers();
        this.indoorMap.setOptions(__assign(__assign({}, this.indoorMap.getOptions()), { tilesetId: undefined }));
    };
    IndoorAzureMap.prototype.refreshMap = function () {
        var _a;
        this.indoorMap.setOptions(__assign(__assign({}, this.indoorMap.getOptions()), { tilesetId: undefined }));
        this.indoorMap.setOptions(__assign(__assign({}, this.indoorMap.getOptions()), { tilesetId: (_a = this.indoorMapOptions) === null || _a === void 0 ? void 0 : _a.tilesetId }));
    };
    IndoorAzureMap.prototype.unsubscribeFromAllEvents = function () {
        this.eventSubscribers = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.map.events.remove("click", this.onMapClickEventCallback);
        this.map.events.remove("levelchanged", this.indoorMap, this.onLevelChangeCallback);
        this.map.events.remove("facilitychanged", this.indoorMap, this.onFacilityChangeCallback);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.map.events.remove("styleimagemissing", this.imageMissingCallback);
    };
    IndoorAzureMap.prototype.disposeMapLayers = function () {
        this.removeRouteLayer();
        this.removePeopleLayer();
        this.hotDeskLayer.removePopups();
        this.peopleDensityLayer.removePopups();
        this.overlayLayerMap && this.overlayLayerMap.flushMapLayers();
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    IndoorAzureMap.prototype.functionWrapper = function (fn, context, params) {
        return function () {
            fn.apply(context, params);
        };
    };
    IndoorAzureMap.prototype.addToMapRequestQueue = function (request) {
        // Limit the map request queue length
        if (this.mapRequestQueue && this.mapRequestQueue.length <= 10) {
            this.mapRequestQueue.push(request);
        }
        else {
            return;
        }
    };
    // Callback functions can be defined with any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    IndoorAzureMap.prototype.notifySubscribers = function (data, subscribers) {
        subscribers === null || subscribers === void 0 ? void 0 : subscribers.forEach(function (subscriber) {
            subscriber(data);
        });
    };
    IndoorAzureMap.prototype.getFacilityMapping = function () {
        return this.indoorMap._getFacilityMapping();
    };
    IndoorAzureMap.prototype.isFacilityMappingEmpty = function () {
        var facilityMapping = this.getFacilityMapping();
        return Object.keys(facilityMapping).length === 0;
    };
    IndoorAzureMap.prototype.createPersonLayer = function (displayName, featureId, usersFloor, centerOffset, base64Image, datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var allFeatures, feature;
            var _a, _b;
            return __generator(this, function (_c) {
                this.removePersonLayer();
                if (!displayName || !usersFloor || !datasetId) {
                    (_a = this.telemetryCallback) === null || _a === void 0 ? void 0 : _a.call(this, {
                        messageName: "[PersonLayer] Missing displayName, usersFloor, or datasetId",
                        displayName: displayName,
                        featureId: featureId,
                        usersFloor: usersFloor
                    });
                    return [2 /*return*/];
                }
                allFeatures = this.features;
                feature = allFeatures.find(function (feature) { return feature.id === featureId; });
                if (!feature || !feature.boundingBox) {
                    (_b = this.telemetryCallback) === null || _b === void 0 ? void 0 : _b.call(this, {
                        messageName: "[PersonLayer] No feature or bounding box found",
                        displayName: displayName,
                        featureId: featureId,
                        datasetId: datasetId
                    });
                    return [2 /*return*/];
                }
                this.personLayer = new PersonLayer(this.map, this.indoorMap, displayName, base64Image !== null && base64Image !== void 0 ? base64Image : "", usersFloor, this.getCurrentFloor, centerOffset, this.setFloor, feature.boundingBox, this.telemetryCallback);
                return [2 /*return*/];
            });
        });
    };
    IndoorAzureMap.prototype.removePersonLayer = function () {
        if (!this.personLayer) {
            return;
        }
        this.personLayer.dispose();
        this.personLayer = undefined;
    };
    return IndoorAzureMap;
}());
export { IndoorAzureMap };

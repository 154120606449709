export enum MapOverlayMarkerIcons {
  PoiIcons = "marker-flat"
}

export enum MapOverlayColors {
  "AedDevicePoi" = "blue",
  "EmergencyExitPoi" = "green",
  "FireExtinguisherPoi" = "red",
  "BombShelterPoi" = "gray",
  "PeopleLayer" = "teal",
  "GetDirection" = "GetDirection",
  "KioskBlueDot" = "magenta"
}

var textFieldExpression = [
    "format",
    ["get", "featureText"],
    "\n",
    {},
    ["get", "busynessStatus"],
    {
        "text-color": ["get", "busynessStatusColor"]
    }
];
var featureTextProperty = "featureText";
export var textOptions = {
    textField: ["case", ["has", "busynessStatus"], textFieldExpression, ["get", featureTextProperty]],
    font: ["SegoeUi-SemiBold"],
    color: "white",
    haloColor: "#010202",
    haloBlur: 0.5,
    haloWidth: 1,
    offset: ["case", ["has", "busynessStatus"], ["literal", [0, 1.1]], ["literal", [0, 0.5]]],
    size: 10
};
export var zoomThreshold = { min: 17.75, max: 20.15 };
export var SizeConstants;
(function (SizeConstants) {
    SizeConstants[SizeConstants["DEFAULT"] = 0.5] = "DEFAULT";
    SizeConstants[SizeConstants["SELECTED"] = 0.9] = "SELECTED";
})(SizeConstants || (SizeConstants = {}));

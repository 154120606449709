import { ILogger } from "@smartbuilding/log-provider";
import { MapStateControllerBase } from "./MapStateController.Base";
import React from "react";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";

export function MapStateController(): JSX.Element {
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  return <MapStateControllerBase logger={logger} />;
}

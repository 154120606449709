var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TraversalSpaceSubtype } from "../DirectionsTypes";
var PointRHttp = /** @class */ (function () {
    function PointRHttp(url, pointRMSFTClientId, httpService) {
        var _a;
        this.url = url;
        this.pointRMSFTClientId = pointRMSFTClientId;
        this.httpService = httpService;
        this.modeByTraversalType = (_a = {},
            _a[TraversalSpaceSubtype.StairwellArea] = "normal",
            _a[TraversalSpaceSubtype.ElevatorSpot] = "accessible",
            _a);
    }
    PointRHttp.prototype.getRoute = function (sourceId, destinationId, traversalSpaceType, traversalSubtype, sourceCoordinate, destinationCoordinate, sourceLevel, destinationLevel, sourceBuildingInternalId, destinationBuildingInternalId) {
        var requestRoute = "".concat(this.url, "/path");
        var isFirstQueryParameter = true;
        if (sourceCoordinate && sourceLevel !== undefined && sourceBuildingInternalId !== undefined) {
            requestRoute += this.addQueryParameters("startPointLon=".concat(sourceCoordinate.Lng) +
                "&startPointLat=".concat(sourceCoordinate.Lat, "&startPointLevel=").concat(sourceLevel) +
                "&startBuilding=".concat(sourceBuildingInternalId), isFirstQueryParameter);
            isFirstQueryParameter = false;
        }
        if (destinationCoordinate && destinationLevel !== undefined && destinationBuildingInternalId !== undefined) {
            requestRoute += this.addQueryParameters("destPointLon=".concat(destinationCoordinate.Lng) +
                "&destPointLat=".concat(destinationCoordinate.Lat, "&destPointLevel=").concat(destinationLevel) +
                "&destBuilding=".concat(destinationBuildingInternalId), isFirstQueryParameter);
            isFirstQueryParameter = false;
        }
        if (sourceId && sourceCoordinate === undefined) {
            requestRoute += this.addQueryParameters("startPoiExternalIdentifier=".concat(sourceId), isFirstQueryParameter);
            isFirstQueryParameter = false;
        }
        if (destinationId && destinationCoordinate === undefined) {
            requestRoute += this.addQueryParameters("destPoiExternalIdentifier=".concat(destinationId), isFirstQueryParameter);
            isFirstQueryParameter = false;
        }
        requestRoute += this.addQueryParameters("mode=".concat(this.modeByTraversalType[traversalSubtype !== null && traversalSubtype !== void 0 ? traversalSubtype : TraversalSpaceSubtype.StairwellArea]), isFirstQueryParameter);
        return this.httpService.get(requestRoute).then(function (res) { return res.data.result; });
    };
    PointRHttp.prototype.getSites = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpService.get("".concat(this.url, "/clients/").concat(this.pointRMSFTClientId, "/sites"))];
                    case 1:
                        response = _a.sent();
                        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                            return [2 /*return*/, response.data.result];
                        }
                        else {
                            throw new Error("An invalid response has been received from the Pointr sites endpoint");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PointRHttp.prototype.addQueryParameters = function (parameters, isFirst) {
        return (isFirst ? "?" : "&") + parameters;
    };
    return PointRHttp;
}());
export { PointRHttp };

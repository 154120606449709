var DeferredPromise = /** @class */ (function () {
    function DeferredPromise() {
        var resolvePlaceHolder = function (value) {
            return;
        };
        var rejectPlaceHolder = function () {
            return;
        };
        this.promise = new Promise(function (resolve, reject) {
            resolvePlaceHolder = resolve;
            rejectPlaceHolder = reject;
        });
        this.resolve = resolvePlaceHolder;
        this.reject = rejectPlaceHolder;
    }
    return DeferredPromise;
}());
export { DeferredPromise };

import {
  AppAction,
  AppResetActions,
  DetailsPanelPageStackActions,
  InstantBookingActions,
  OCVFeedbackActions,
  RoomTagsActions
} from "../Actions";
import { IAppStore, IPageInfo } from "../Types";

export const initialState: IAppStore = {
  detailsPanelPageStack: [],
  ocvFeedbackEnabled: false,
  resetInProgress: false,
  roomTagEnabled: true,
  instantBookingEnabled: false
};

export function appReducer(state = initialState, action: AppAction): IAppStore {
  switch (action.type) {
    case DetailsPanelPageStackActions.ADD_PAGE:
      return handleAddPage(state, action.payload);

    case DetailsPanelPageStackActions.REMOVE_PAGE:
      return handleRemovePage(state);

    case DetailsPanelPageStackActions.CLEAR_STACK:
      return { ...state, detailsPanelPageStack: [] };

    case InstantBookingActions.INSTANT_BOOKING_ENABLED: {
      return {
        ...state,
        instantBookingEnabled: true
      };
    }

    case OCVFeedbackActions.OCV_FEEDBACK_ENABLED: {
      return {
        ...state,
        ocvFeedbackEnabled: true
      };
    }
    case AppResetActions.APP_RESET_IN_PROGRESS: {
      return {
        ...state,
        resetInProgress: action.payload
      };
    }
    case RoomTagsActions.ROOM_TAG_ENABLED: {
      return {
        ...state,
        roomTagEnabled: true
      };
    }
    default:
      return state;
  }
}

function handleAddPage(state: IAppStore, pageInfo: IPageInfo): IAppStore {
  return { ...state, detailsPanelPageStack: [...state.detailsPanelPageStack, pageInfo] };
}

function handleRemovePage(state: IAppStore): IAppStore {
  return {
    ...state,
    detailsPanelPageStack: state.detailsPanelPageStack.filter(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (_, index) => index !== state.detailsPanelPageStack.length - 1
    )
  };
}

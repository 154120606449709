import { MapStyles } from "../Types/MapStyles";
var darkColorPalette = {
    font: "#FFFFFF",
    halo: "#010202"
};
var lightColorPalette = {
    font: "#0078D7",
    halo: "#FFFFFF"
};
export var getTextOptionColors = function (map) {
    return map.getStyle().style === MapStyles.Dark ? darkColorPalette : lightColorPalette;
};

import { easeInExponentialAnimation, easeInOutExponentialAnimation, easeOutExponentialAnimation, springAnimationDrawer } from "./AnimationDrawingUtilities";
import { AnimationType } from "./IAnimationConfig";
import { animationTimingHelper } from "./AnimationLoopUtilities";
/**
 *
 * @param duration Duration in milliseconds of the animation
 * @param type @type {AnimationType} The type of animation to run
 * @param animationConfig @type {IAnimationConfig} The configuration for the given animation
 * @param useRequestAnimationFrame @default false Flag denoting whether to use requestAnimationFrame to run through the animation loop.
 * @returns Promise to when the animation ends
 */
export function startAnimation(duration, type, animationConfig, useRequestAnimationFrame) {
    if (useRequestAnimationFrame === void 0) { useRequestAnimationFrame = true; }
    var config = getTimingConfiguration(duration, type, animationConfig);
    if (!config) {
        return Promise.resolve();
    }
    var timingConfigs = [config];
    return animationTimingHelper(timingConfigs, useRequestAnimationFrame);
}
/**
 *
 * @param duration Duration in milliseconds of the animation
 * @param animationConfigurations @type {Array<(IAnimationConfig) & { AnimationType}>} An array of animation configurations to invoke
 * @param useRequestAnimationFrame @default false Flag denoting whether to use requestAnimationFrame to run through the animation loop.
 * @returns Promise to when the animation ends
 */
export function startParallelAnimations(duration, animationConfigurations, useRequestAnimationFrame) {
    if (useRequestAnimationFrame === void 0) { useRequestAnimationFrame = false; }
    var timeProgressCallback = function (timeProgress) {
        animationConfigurations.map(function (config) {
            if (config.type === AnimationType.Spring && isSpringConfiguration(config)) {
                springAnimationDrawer(timeProgress, config);
            }
            else if (isExponentialConfiguration(config)) {
                getExponentialAnimationProcessor(config.type)(timeProgress, config);
            }
        });
    };
    return animationTimingHelper([{ duration: duration, timeProgressCallback: timeProgressCallback }], useRequestAnimationFrame);
}
/**
 * Runs animations in sequential order, with a slight overlap between the end of one animation and the beginning of the next animation
 * @param configurations @type {Array<duration, AnimationType, animationConfiguration: (IAnimationConfig)>} An array of animation configurations to run sequentially
 * @param useRequestAnimationFrame @default false Flag denoting whether to use requestAnimationFrame to run through the animation loop.
 * @returns Promise to when the animation ends
 */
export function startLinkedAnimations(configurations, useRequestAnimationFrame) {
    if (useRequestAnimationFrame === void 0) { useRequestAnimationFrame = false; }
    var timeProgressConfigurations = [];
    for (var _i = 0, configurations_1 = configurations; _i < configurations_1.length; _i++) {
        var configuration = configurations_1[_i];
        var timeProgressConfig = getTimingConfiguration(configuration.duration, configuration.type, configuration.animationConfiguration);
        if (!timeProgressConfig) {
            continue;
        }
        timeProgressConfigurations.push(timeProgressConfig);
    }
    return animationTimingHelper(timeProgressConfigurations, useRequestAnimationFrame);
}
/**
 * Invokes multiple drawers in parallel, and links parallel animations with a slight overlap between animations
 * @param configurations An array of animation configurations to run through in parallel
 * @param useRequestAnimationFrame @default false Flag denoting whether to use requestAnimationFrame to run through the animation loop.
 * @returns Promise to when the animation ends
 */
export function startLinkedParallelAnimations(configurations, useRequestAnimationFrame) {
    if (useRequestAnimationFrame === void 0) { useRequestAnimationFrame = true; }
    var timeProgressConfigurations = [];
    var _loop_1 = function (configuration) {
        timeProgressConfigurations.push({
            duration: configuration.duration,
            timeProgressCallback: function (timeProgress) {
                configuration.animationConfigurations.map(function (config) {
                    if (config.type === AnimationType.Spring && isSpringConfiguration(config)) {
                        springAnimationDrawer(timeProgress, config);
                    }
                    else if (isExponentialConfiguration(config)) {
                        getExponentialAnimationProcessor(config.type)(timeProgress, config);
                    }
                });
            }
        });
    };
    for (var _i = 0, configurations_2 = configurations; _i < configurations_2.length; _i++) {
        var configuration = configurations_2[_i];
        _loop_1(configuration);
    }
    return animationTimingHelper(timeProgressConfigurations, useRequestAnimationFrame);
}
/**
 * Helping function to generate a timing configuration
 */
function getTimingConfiguration(duration, type, animationConfiguration) {
    var timingConfiguration = undefined;
    if (type === AnimationType.Spring && isSpringConfiguration(animationConfiguration)) {
        timingConfiguration = {
            duration: duration,
            timeProgressCallback: function (timeProgress) { return springAnimationDrawer(timeProgress, animationConfiguration); }
        };
    }
    else if (isExponentialConfiguration(animationConfiguration)) {
        var drawer_1 = getExponentialAnimationProcessor(type);
        timingConfiguration = {
            duration: duration,
            timeProgressCallback: function (timeProgress) { return drawer_1(timeProgress, animationConfiguration); }
        };
    }
    return timingConfiguration;
}
/**
 * Helper function to retrieve the animation processor based on the animation type.
 * Defaults to ease in animation processor
 * @param animationType The type of animation that is being triggered
 * @returns {AnimationProcessor} Animation processor
 */
function getExponentialAnimationProcessor(animationType) {
    switch (animationType) {
        case AnimationType.EaseIn:
            return easeInExponentialAnimation;
        case AnimationType.EaseInOut:
            return easeInOutExponentialAnimation;
        case AnimationType.EaseOut:
            return easeOutExponentialAnimation;
        default:
            return easeInExponentialAnimation;
    }
}
/**
 * User-defined type guard to help clarify the type for Typescript
 */
function isExponentialConfiguration(config) {
    return !!config.exponent;
}
/**
 * User-defined type guard to help clarify the type for Typescript
 */
function isSpringConfiguration(config) {
    return !!config.amplitude;
}

export var BlobInclude;
(function (BlobInclude) {
    BlobInclude["All"] = "All";
    BlobInclude["ContentInfo"] = "ContentInfo";
    BlobInclude["Description"] = "Description";
    BlobInclude["FullPath"] = "FullPath";
    BlobInclude["None"] = "None";
    BlobInclude["Types"] = "Types";
})(BlobInclude || (BlobInclude = {}));
export var DeviceBlobSubtype;
(function (DeviceBlobSubtype) {
    DeviceBlobSubtype["KioskSettings"] = "KioskSettings";
})(DeviceBlobSubtype || (DeviceBlobSubtype = {}));
export var SpaceBlobSubtype;
(function (SpaceBlobSubtype) {
    SpaceBlobSubtype["EvacuationRouteMap"] = "EvacuationRouteMap";
    SpaceBlobSubtype["GenericMap"] = "GenericMap";
    SpaceBlobSubtype["None"] = "None";
    SpaceBlobSubtype["WayfindingMap"] = "WayfindingMap";
    SpaceBlobSubtype["WebClientCascadingSpaceConfig"] = "WebclientCascadingSpaceConfig";
})(SpaceBlobSubtype || (SpaceBlobSubtype = {}));
export var DeviceBlobType;
(function (DeviceBlobType) {
    DeviceBlobType["Settings"] = "Settings";
})(DeviceBlobType || (DeviceBlobType = {}));
export var SpaceBlobType;
(function (SpaceBlobType) {
    SpaceBlobType["AsaQuery"] = "AsaQuery";
    SpaceBlobType["CascadingSpaceConfig"] = "CascadingSpaceConfig";
    SpaceBlobType["Map"] = "Map";
    SpaceBlobType["None"] = "None";
    SpaceBlobType["UserDefinedFunction"] = "UserDefinedFunction";
})(SpaceBlobType || (SpaceBlobType = {}));

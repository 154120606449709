import { data } from "azure-maps-control";
export function findSpaceLocation(spaceId, dataSource) {
    var featureShape = dataSource.getShapeById(spaceId);
    if (featureShape) {
        return {
            coordinate: data.BoundingBox.getCenter(featureShape.getBounds()),
            levelId: featureShape.getProperties()["levelId"]
        };
    }
    return undefined;
}

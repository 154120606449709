import { AvailableIcon, BusyIcon, OccupiedIcon } from "../Icons";
import { BusynessStatusColors, BusynessStatusValue, PrimaryStatusValue, SecondaryStatusValue, SensorLayerColors, SpaceStatusIcon } from "../Enums/RoomStatus";
import { ConferenceStatusDataValue, OccupancyStatusDataValue } from "@smartbuilding/adt-v2-types";
import { SpaceStatusColor } from "@smartbuilding/ui-components-space-status";
import { SpaceTypeNames } from "../Enums/SpaceTypeNames";
var availableIconString = AvailableIcon.toString();
var busyIconString = BusyIcon.toString();
var occupiedIconString = OccupiedIcon.toString();
export function getStatusIcon(statusText) {
    switch (statusText) {
        case "Available":
            return SpaceStatusIcon.Available;
        case "Reserved":
            return SpaceStatusIcon.Reserved;
        default:
            return SpaceStatusIcon.Occupied;
    }
}
export function evaluateSpaceStatus(conferenceStatus, occupancyStatus
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    if (conferenceStatus === ConferenceStatusDataValue.Free && occupancyStatus === OccupancyStatusDataValue.Unoccupied) {
        return {
            primaryStatus: PrimaryStatusValue.Available,
            secondaryStatus: SecondaryStatusValue.Unoccupied,
            iconName: availableIconString,
            statusColor: SpaceStatusColor.Green,
            mapColor: SensorLayerColors.GREEN,
            rank: 0
        };
    }
    else if (conferenceStatus === ConferenceStatusDataValue.Busy &&
        occupancyStatus === OccupancyStatusDataValue.ReservedUnoccupied) {
        return {
            primaryStatus: PrimaryStatusValue.Reserved,
            secondaryStatus: SecondaryStatusValue.Unoccupied,
            iconName: busyIconString,
            statusColor: SpaceStatusColor.Orange,
            mapColor: SensorLayerColors.ORANGE,
            rank: 4
        };
    }
    else if ((conferenceStatus === ConferenceStatusDataValue.Error || conferenceStatus === undefined) &&
        (occupancyStatus === OccupancyStatusDataValue.Unoccupied ||
            occupancyStatus === OccupancyStatusDataValue.ReservedUnoccupied)) {
        /**
         * Effective Date: 2024-08-21
         * Real-time calendar data in no longer available.
         * Change secondaryStatus to Unoccupied.
         */
        // return {
        //     primaryStatus: PrimaryStatusValue.Available,
        //     secondaryStatus: SecondaryStatusValue.NonReservable,
        //     iconName: availableIconString,
        //     statusColor: SpaceStatusColor.Green,
        //     mapColor: SensorLayerColors.GREEN,
        //     rank: 7
        // };
        return {
            primaryStatus: PrimaryStatusValue.Available,
            secondaryStatus: SecondaryStatusValue.Unoccupied,
            iconName: availableIconString,
            statusColor: SpaceStatusColor.Green,
            mapColor: SensorLayerColors.GREEN,
            rank: 7
        };
    }
    else if (conferenceStatus === ConferenceStatusDataValue.Free &&
        occupancyStatus === OccupancyStatusDataValue.Occupied) {
        return {
            primaryStatus: PrimaryStatusValue.Occupied,
            secondaryStatus: SecondaryStatusValue.NotReserved,
            iconName: occupiedIconString,
            statusColor: SpaceStatusColor.Orange,
            mapColor: SensorLayerColors.ORANGE,
            rank: 3
        };
    }
    else if (conferenceStatus === ConferenceStatusDataValue.Busy &&
        occupancyStatus === OccupancyStatusDataValue.Occupied) {
        return {
            primaryStatus: PrimaryStatusValue.Reserved,
            secondaryStatus: SecondaryStatusValue.Occupied,
            iconName: busyIconString,
            statusColor: SpaceStatusColor.Red,
            mapColor: SensorLayerColors.RED,
            rank: 5
        };
    }
    else if ((conferenceStatus === ConferenceStatusDataValue.Error || conferenceStatus === undefined) &&
        occupancyStatus === OccupancyStatusDataValue.Occupied) {
        /**
         * Effective Date: 2024-08-21
         * Real-time calendar data in no longer available.
         * Change secondaryStatus to Occupied.
         */
        // return {
        //     primaryStatus: PrimaryStatusValue.Occupied,
        //     secondaryStatus: SecondaryStatusValue.NonReservable,
        //     iconName: busyIconString,
        //     statusColor: SpaceStatusColor.Red,
        //     mapColor: SensorLayerColors.RED,
        //     rank: 8
        // };
        return {
            primaryStatus: PrimaryStatusValue.Occupied,
            secondaryStatus: SecondaryStatusValue.Occupied,
            iconName: busyIconString,
            statusColor: SpaceStatusColor.Orange,
            mapColor: SensorLayerColors.ORANGE,
            rank: 8
        };
    }
    else if (conferenceStatus === ConferenceStatusDataValue.Free &&
        occupancyStatus === OccupancyStatusDataValue.Error) {
        return {
            primaryStatus: PrimaryStatusValue.Available,
            secondaryStatus: SecondaryStatusValue.OccupancyUnknown,
            iconName: availableIconString,
            statusColor: SpaceStatusColor.Green,
            mapColor: SensorLayerColors.GREEN,
            rank: 1
        };
    }
    else if (conferenceStatus === ConferenceStatusDataValue.Busy &&
        occupancyStatus === OccupancyStatusDataValue.Error) {
        return {
            primaryStatus: PrimaryStatusValue.Reserved,
            secondaryStatus: SecondaryStatusValue.OccupancyUnknown,
            iconName: busyIconString,
            statusColor: SpaceStatusColor.Red,
            mapColor: SensorLayerColors.RED,
            rank: 6
        };
    }
    else if ((conferenceStatus === ConferenceStatusDataValue.Error || conferenceStatus === undefined) &&
        occupancyStatus === OccupancyStatusDataValue.Error) {
        /**
         * Effective Date: 2024-08-21
         * Real-time calendar data in no longer available.
         * Change secondaryStatus to Unknown.
         */
        // return {
        //     primaryStatus: "",
        //     secondaryStatus: `${PrimaryStatusValue.NonReservable}, ${SecondaryStatusValue.OccupancyUnknown}`,
        //     iconName: "",
        //     statusColor: SpaceStatusColor.Red,
        //     mapColor: SensorLayerColors.TRANSPARENT,
        //     rank: 2
        // };
        return {
            primaryStatus: "",
            secondaryStatus: SecondaryStatusValue.OccupancyUnknown,
            iconName: "",
            statusColor: SpaceStatusColor.Grey,
            mapColor: SensorLayerColors.TRANSPARENT,
            rank: 2
        };
    }
    else {
        return {
            primaryStatus: "",
            secondaryStatus: "".concat(SecondaryStatusValue.NonReservable, ", ").concat(SecondaryStatusValue.NoOccupancySensor),
            iconName: "",
            statusColor: SpaceStatusColor.Grey,
            mapColor: SensorLayerColors.TRANSPARENT,
            rank: 9
        };
    }
}
export var getStatus = function (spaceId, sensorSpaceMap) {
    var _a, _b;
    if (sensorSpaceMap[spaceId]) {
        var occupancyStatus = (_a = sensorSpaceMap[spaceId]) === null || _a === void 0 ? void 0 : _a.OccupancyStatus;
        var conferenceStatus = (_b = sensorSpaceMap[spaceId]) === null || _b === void 0 ? void 0 : _b.ConferenceStatus;
        var status_1 = evaluateSpaceStatus(conferenceStatus, occupancyStatus);
        var statusIcon = getStatusIcon(status_1.primaryStatus);
        return {
            statusLabel: status_1.primaryStatus,
            statusIcon: statusIcon,
            statusColorName: status_1.statusColor,
            statusDescription: status_1.secondaryStatus,
            rank: status_1.rank
        };
    }
    else {
        return undefined;
    }
};
export function getLogicalOrderBySpaceType(spaceCategory, spaceType) {
    var _a, _b;
    var roomType = Object.keys(SpaceTypeNames).find(function (space) { return SpaceTypeNames[space] === spaceType; });
    var parentCategory = roomType && ((_b = (_a = spaceCategory[roomType]) === null || _a === void 0 ? void 0 : _a.parentCategory) === null || _b === void 0 ? void 0 : _b[0]);
    var isParentRoot = parentCategory === "root";
    if (roomType) {
        if (isParentRoot) {
            return spaceCategory[roomType].webLogicalOrder;
        }
        if (parentCategory) {
            return spaceCategory[parentCategory].childCategoriesMap[roomType];
        }
    }
    return 0;
}
export var getLogicalOrder = function (buildingCategories, rank, spaceType) {
    if (rank) {
        return rank;
    }
    if (spaceType) {
        return getLogicalOrderBySpaceType(buildingCategories, spaceType);
    }
    return 0;
};
export function evaluateBusynessStatus(peopleCount, availableCapacity, occupancyStatus) {
    if (occupancyStatus === OccupancyStatusDataValue.Error) {
        return {
            busynessStatus: BusynessStatusValue.OccupancyUnknown,
            busynessStatusColor: BusynessStatusColors.WHITE
        };
    }
    if (peopleCount === undefined || availableCapacity === undefined) {
        return {
            busynessStatus: undefined,
            busynessStatusColor: undefined
        };
    }
    if (peopleCount / availableCapacity <= 0.4) {
        return { busynessStatus: BusynessStatusValue.NotBusy, busynessStatusColor: BusynessStatusColors.GREEN };
    }
    else if (peopleCount / availableCapacity <= 0.75) {
        return { busynessStatus: BusynessStatusValue.Busy, busynessStatusColor: BusynessStatusColors.ORANGE };
    }
    else {
        return { busynessStatus: BusynessStatusValue.VeryBusy, busynessStatusColor: BusynessStatusColors.RED };
    }
}

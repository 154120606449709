import { SpaceSensorDataItemMap } from "../SpaceSensorDataItemMap";
var SensorPipe = /** @class */ (function () {
    function SensorPipe(sources, onSensorValue) {
        this.sources = sources;
        this.userOnSensorValue = onSensorValue;
        this.sensorValueDestinations = new SpaceSensorDataItemMap();
    }
    SensorPipe.prototype.subscribeToSensorValue = function (spaceId, sensorDataType, sensorReader) {
        // If this pipe is not subscribed to this data type on this space, subscribe to it
        if (!this.sensorValueDestinations.hasSensorDataTypeItems(spaceId, sensorDataType)) {
            for (var _i = 0, _a = this.sources; _i < _a.length; _i++) {
                var source = _a[_i];
                source.subscribeToSensorValue(spaceId, sensorDataType, this);
            }
        }
        // Always add the sensor reader to our list of sensor readers regardless of if we needed to subscribe on the source or not.
        this.sensorValueDestinations.add(spaceId, sensorDataType, sensorReader);
    };
    SensorPipe.prototype.unsubscribeFromSensorValue = function (spaceId, sensorDataType, sensorReader) {
        // If we removed the last sensorReader who listened to this space and sensorDataType unsubscribe from that
        // space and data type from our sources.
        if (this.sensorValueDestinations.remove(spaceId, sensorDataType, sensorReader)) {
            for (var _i = 0, _a = this.sources; _i < _a.length; _i++) {
                var source = _a[_i];
                source.unsubscribeFromSensorValue(spaceId, sensorDataType, this);
            }
        }
    };
    SensorPipe.prototype.onSensorValue = function (spaceId, sensorDataType, sensorValue, timeStamp) {
        var finalSensorValue = this.userOnSensorValue(spaceId, sensorDataType, sensorValue, timeStamp);
        var listeners = this.sensorValueDestinations.get(spaceId, sensorDataType);
        if (listeners) {
            for (var _i = 0, listeners_1 = listeners; _i < listeners_1.length; _i++) {
                var listener = listeners_1[_i];
                listener.onSensorValue(spaceId, sensorDataType, finalSensorValue, timeStamp);
            }
        }
    };
    SensorPipe.prototype.hasSubscribers = function (spaceId, sensorDataType) {
        return this.sensorValueDestinations.hasSensorDataTypeItems(spaceId, sensorDataType);
    };
    return SensorPipe;
}());
export { SensorPipe };

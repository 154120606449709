var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import "../../Styles/PeopleLayer.css";
import { HtmlMarker, data } from "azure-maps-control";
import { MapThemeStyles } from "../../Types/MapStyles";
var PeopleOverlayMapLayer = /** @class */ (function () {
    function PeopleOverlayMapLayer(map, zoomThreshold, getPeopleImageCallback, peopleFeatureImageMap) {
        this.map = map;
        this.zoomThreshold = zoomThreshold;
        this.getPeopleImageCallback = getPeopleImageCallback;
        this.peopleFeatureImageMap = peopleFeatureImageMap;
        this.processingFeature = new Set();
        this.visibleFeature = new Set();
        this.featurePersonMap = {};
        this.featureMarkerMap = {};
        this.isDisposed = false;
        this.featureIdToHide = "";
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.map.events.add("render", this.onChangeEvent);
    }
    PeopleOverlayMapLayer.prototype.dispose = function () {
        this.isDisposed = true;
        this.visibleFeature.clear();
        this.map.events.remove("render", this.onChangeEvent);
        this.removeMarkers();
    };
    PeopleOverlayMapLayer.prototype.hideAllMarkers = function () {
        var _this = this;
        Object.keys(this.featureMarkerMap).forEach(function (featureId) {
            _this.toggleMarker(featureId, false);
        });
    };
    PeopleOverlayMapLayer.prototype.showAllMarkers = function () {
        var _this = this;
        Object.keys(this.featureMarkerMap).forEach(function (featureId) {
            _this.toggleMarker(featureId, true);
        });
    };
    PeopleOverlayMapLayer.prototype.hideMarkersInFeatureId = function (featureId) {
        if (!featureId)
            return;
        this.featureIdToHide = featureId;
        this.toggleMarker(this.featureIdToHide, false);
    };
    PeopleOverlayMapLayer.prototype.resetHideMarkersInFeatureId = function () {
        var zoomLevel = this.map.getCamera().zoom;
        if (zoomLevel && zoomLevel >= this.zoomThreshold.min && zoomLevel <= this.zoomThreshold.max) {
            // Show all employees in room only if the people layer is currently rendered.
            this.toggleMarker(this.featureIdToHide, true);
        }
        this.featureIdToHide = "";
    };
    PeopleOverlayMapLayer.prototype.onChangeEvent = function () {
        this.renderLayer();
    };
    PeopleOverlayMapLayer.prototype.renderLayer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var zoomLevel, cameraBoundingBox, featureGeometries;
            var _this = this;
            return __generator(this, function (_a) {
                zoomLevel = this.map.getCamera().zoom;
                cameraBoundingBox = this.map.getCamera().bounds;
                this.visibleFeature.clear();
                if (zoomLevel && zoomLevel >= this.zoomThreshold.min && zoomLevel <= this.zoomThreshold.max && cameraBoundingBox) {
                    featureGeometries = this.getVisibleFeatures(cameraBoundingBox);
                    // List of features that we have already created markers for; we want to toggle their visibility if they are in the current view or not
                    Object.keys(this.featureMarkerMap).forEach(function (featureId) {
                        _this.toggleMarker(featureId, _this.visibleFeature.has(featureId));
                    });
                    // Go through new list of features and determine if we need to create new markers for
                    featureGeometries.forEach(function (featureGeometry) {
                        if (!_this.featureMarkerMap[featureGeometry.featureId]) {
                            _this.renderPeopleInFeature(featureGeometry);
                        }
                    });
                    // If this.selectedPersonFeatureId is defined, then we need to turn off the markers for everyone in that feature id.
                    if (this.featureIdToHide) {
                        this.toggleMarker(this.featureIdToHide, false);
                    }
                }
                else {
                    // Hiding markers if we are not within the expected view of the map
                    this.hideAllMarkers();
                }
                return [2 /*return*/];
            });
        });
    };
    PeopleOverlayMapLayer.prototype.renderPeopleInFeature = function (featureGeometry) {
        return __awaiter(this, void 0, void 0, function () {
            var isProcessingFeature, markerAdded, pplInFeature;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isProcessingFeature = this.processingFeature.has(featureGeometry.featureId);
                        markerAdded = this.featureMarkerMap[featureGeometry.featureId];
                        if (isProcessingFeature || markerAdded) {
                            return [2 /*return*/];
                        }
                        this.processingFeature.add(featureGeometry.featureId);
                        pplInFeature = this.featurePersonMap[featureGeometry.featureId];
                        if (!!pplInFeature) return [3 /*break*/, 3];
                        if (!(this.peopleFeatureImageMap && Object.keys(this.peopleFeatureImageMap).length > 0)) return [3 /*break*/, 1];
                        pplInFeature = this.getPeopleImagesInFeature(featureGeometry.featureId);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getPeopleImageCallback(featureGeometry.featureId)];
                    case 2:
                        pplInFeature = _a.sent();
                        _a.label = 3;
                    case 3:
                        this.addPeopleImagesToMap(featureGeometry, pplInFeature);
                        this.processingFeature.delete(featureGeometry.featureId);
                        return [2 /*return*/];
                }
            });
        });
    };
    // Returns imageUrls for a featureId if all images are already passed to the layer
    PeopleOverlayMapLayer.prototype.getPeopleImagesInFeature = function (featureId) {
        var peopleImageUrls = [];
        if (this.peopleFeatureImageMap) {
            for (var _i = 0, _a = Object.values(this.peopleFeatureImageMap); _i < _a.length; _i++) {
                var person = _a[_i];
                if (person.featureId === featureId) {
                    peopleImageUrls.push({ imageUrl: person.imageUrl });
                }
            }
        }
        return peopleImageUrls;
    };
    PeopleOverlayMapLayer.prototype.addPeopleImagesToMap = function (featureGeometry, pplImages) {
        if (this.isDisposed)
            return;
        var imageUrls = pplImages.map(function (p) { return p.imageUrl; });
        if (imageUrls.length <= 0) {
            return;
        }
        var currentTheme = this.map.getStyle().style;
        var maxPplImages = 3;
        var baseOffset = 13;
        var markerDiv = document.createElement("div");
        markerDiv.setAttribute("class", "person-marker");
        imageUrls.splice(0, maxPplImages).forEach(function (image, index) {
            var offset = baseOffset * index;
            var imageElement = document.createElement("img");
            imageElement.src = image;
            imageElement.setAttribute("class", "person-marker-image");
            imageElement.setAttribute("alt", "Person image");
            imageElement.style.transform = "translateX(-".concat(offset, "px)");
            imageElement.style.border = currentTheme === MapThemeStyles.Dark ? "3px solid #000" : "3px solid #FFF";
            markerDiv.appendChild(imageElement);
        });
        if (imageUrls.length > 0) {
            var extraPploffset = baseOffset * maxPplImages;
            var extraPplDiv = document.createElement("div");
            var extraPplText = document.createTextNode("+".concat(imageUrls.length));
            extraPplDiv.appendChild(extraPplText);
            extraPplDiv.setAttribute("class", "person-marker-extra-ppl-div");
            extraPplDiv.style.transform = "translate(-".concat(extraPploffset, "px, 3px)");
            extraPplDiv.style.backgroundColor = currentTheme === MapThemeStyles.Dark ? "#FFFFFF" : "#333333";
            extraPplDiv.style.color = currentTheme === MapThemeStyles.Dark ? "#616161" : "#ADADAD";
            extraPplDiv.style.outline = currentTheme === MapThemeStyles.Dark ? "3px solid #000" : "3px solid #FFF";
            markerDiv.appendChild(extraPplDiv);
        }
        var boundingBox = data.BoundingBox.fromData(featureGeometry.geometry);
        var htmlMarker = new HtmlMarker({
            htmlContent: markerDiv,
            position: data.BoundingBox.getCenter(boundingBox),
            visible: this.visibleFeature.has(featureGeometry.featureId)
        });
        this.map.markers.add(htmlMarker);
        this.featureMarkerMap[featureGeometry.featureId] = htmlMarker;
    };
    PeopleOverlayMapLayer.prototype.toggleMarker = function (featureId, visible) {
        if (!this.featureMarkerMap[featureId]) {
            return;
        }
        var options = this.featureMarkerMap[featureId].getOptions();
        this.featureMarkerMap[featureId].setOptions(__assign(__assign({}, options), { visible: visible }));
    };
    PeopleOverlayMapLayer.prototype.getVisibleFeatures = function (boundingBox) {
        var _a, _b;
        var featureIds = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var features = this.map.layers.getRenderedShapes(boundingBox);
        for (var _i = 0, features_1 = features; _i < features_1.length; _i++) {
            var feature = features_1[_i];
            var featureId = (_a = feature.properties) === null || _a === void 0 ? void 0 : _a.featureId;
            var featureCategory = (_b = feature.properties) === null || _b === void 0 ? void 0 : _b.categoryName;
            if (feature.geometry && featureId && featureCategory && featureCategory.startsWith("room")) {
                this.visibleFeature.add(feature.properties.featureId);
                featureIds.push({
                    geometry: feature.geometry,
                    featureId: feature.properties.featureId
                });
            }
        }
        return featureIds;
    };
    PeopleOverlayMapLayer.prototype.removeMarkers = function () {
        var _this = this;
        Object.values(this.featureMarkerMap).forEach(function (marker) {
            _this.map.markers.remove(marker);
        });
    };
    return PeopleOverlayMapLayer;
}());
export { PeopleOverlayMapLayer };

import { ICheckableListItem, IListItem } from "@smartbuilding/ui-components-list";
import { ISmartBuildingApiRoomHttp, ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";
import { InstantBookingDurationCategory, RootCategoryLabel } from "../../redux/Types";
import { Provider, serviceIdentifiers } from "../../serviceContainer/ServiceIdentifiers";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { MenuPanel as SBSCustomMenuPanel, SpaceFilterPage } from "@smartbuilding/ui-components-menu-panel";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  clearSpacePinsLayer,
  renderSpacePinsLayer,
  selectListViewCard,
  setCategory,
  setMenuPanelCategory
} from "../../redux/Actions";
import {
  getBuildingCategories,
  getBuildingName,
  getInstantBookingEnabled,
  getMenuPanelCategory,
  getRooms,
  getSelectedListViewCard
} from "../../redux/Selectors";
import { getIsDetailsPanelOpen, getIsMenuPanelOpen, getIsZoomed } from "../../redux/Selectors/LayoutSelectors";
import { setDetailsPanelStateAction, toggleMenuPanelAction } from "../../redux/Actions/LayoutActionCreator";
import { ILogger } from "@smartbuilding/log-provider";
import { MenuPoi } from "./MenuPoi/MenuPoi";
import { MenuSearch } from "./MenuSearch/MenuSearch";
import { MenuWeather } from "./MenuWeather/MenuWeather";
import { categoryIconMap } from "./MenuPanelProps";
import { electronService } from "@smartbuilding/electron-service";
import { getAvailableRooms } from "../DetailsPanel/ListViewCard/useListViewCardUtilities";
import { isRoomBookable } from "../DetailsPanel/BookableSpaceCard/useBookableSpaceCardUtilities";
import { useInjection } from "../../serviceContainer/ServiceContainerProvider";

export function MenuPanel(): JSX.Element {
  const spaceHttpProvider = useInjection<Provider<ISmartBuildingApiRoomHttp>>(
    serviceIdentifiers.smartBuildingApiRoomHttpService
  );
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const loggerMsgTitle = "Menu Panel";
  const buildingName = useSelector(getBuildingName);
  const buildingCategories = useSelector(getBuildingCategories);
  const buildingSpaces = useSelector(getRooms);
  const menuPanelCategory = useSelector(getMenuPanelCategory);
  const [pageContent, setPageContent] = useState<ReactNode>(undefined);
  const [prevCategory, setPrevCategory] = useState(RootCategoryLabel);
  const [transitionRight, setTransitionRight] = useState(false);
  const [playAnimation, setPlayAnimation] = useState(false);
  const isZoomed = useSelector(getIsZoomed);
  const isMenuPanelOpen = useSelector(getIsMenuPanelOpen);
  const isDetailsPanelOpen = useSelector(getIsDetailsPanelOpen);
  const instantBookingEnabled = useSelector(getInstantBookingEnabled);
  const selectedListViewCard = useSelector(getSelectedListViewCard);
  const [instantBookingLoadingMins, setInstantBookingLoadingMins] = useState<number | undefined>(undefined);
  const [instantBookingSelectedMins, setInstantBookingSelectedMins] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();

  const toggleMenuPanel = (): void => {
    dispatch(toggleMenuPanelAction());
  };

  useEffect(() => {
    if (selectedListViewCard) {
      if (selectedListViewCard.selectedCategory === InstantBookingDurationCategory.InstantBooking30MinsCategory) {
        setInstantBookingSelectedMins(30);
      } else {
        setInstantBookingSelectedMins(60);
      }
    } else {
      setInstantBookingSelectedMins(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListViewCard]);

  useEffect(() => {
    if (typeof buildingCategories[menuPanelCategory] !== "undefined") {
      if (menuPanelCategory === RootCategoryLabel) {
        if (prevCategory !== RootCategoryLabel) {
          logger.logEvent("[Menu Panel] space filter page is playing changing-to-root animation");
          setTransitionRight(true);
          setPlayAnimation(true);
        }
        logger.logEvent("[Menu Panel] space filter page category is changed to root");
        setPageContent(
          <SpaceFilterPage
            // TODO: Implement popular section when data is available
            // popularItems={}
            browseItems={getBrowseItems(buildingCategories)}
          />
        );
        dispatch(clearSpacePinsLayer());
      } else {
        if (prevCategory === RootCategoryLabel) {
          logger.logEvent("[Menu Panel] space filter page is playing changing-to-subcategory animation");
          setTransitionRight(false);
          setPlayAnimation(true);
        }
        logger.logEvent("[Menu Panel] space filter page category is changed to a parent category", {
          parentCategory: menuPanelCategory
        });
        setPageContent(
          <SpaceFilterPage
            backClickAction={getBackClickAction}
            uncheckAllSubpage={!isDetailsPanelOpen}
            subpageTitle={buildingCategories[menuPanelCategory].displayName ?? menuPanelCategory}
            subpageItems={getSubpageItems(buildingCategories, menuPanelCategory)}
            onCheckedItemsChange={(checkedItems: Record<string, boolean>) => {
              handleCheckedValueChange(checkedItems, menuPanelCategory);
            }}
          />
        );
      }
      setPrevCategory(menuPanelCategory);
    }
    if (!buildingCategories[menuPanelCategory]) {
      logger.logEvent("[Menu Panel] Unable to render space filter page with undefined ISpaceCategory");
      setPageContent(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingCategories, menuPanelCategory, isDetailsPanelOpen]);

  const getBrowseItems = (categoryMap: Record<string, ISpaceCategory>): Array<IListItem> => {
    return categoryMap[RootCategoryLabel].childCategories
      .filter((childCategory) => childCategory !== "DisabledSpaces")
      .map((childCategory) => ({
        key: `${childCategory}-${categoryMap[childCategory].webLogicalOrder}`,
        text: categoryMap[childCategory].displayName ?? childCategory,
        secondaryText: categoryMap[childCategory].description,
        icon: categoryIconMap[childCategory],
        onClick: () => {
          batch(() => {
            dispatch(setCategory(childCategory));
            dispatch(renderSpacePinsLayer());
          });
          if (categoryMap[childCategory].childCategories.length > 0) {
            dispatch(setMenuPanelCategory(childCategory));
          }
        }
      }))
      .sort((a, b) => {
        const logicOrderOfA = parseInt(a.key.split("-")[1]);
        const logicOrderOfB = parseInt(b.key.split("-")[1]);
        return logicOrderOfA - logicOrderOfB;
      });
  };

  const getBackClickAction = (): void => {
    logger.logEvent("[Menu Panel] space filter page back button gets clicked");
    dispatch(setMenuPanelCategory(RootCategoryLabel));
  };

  const getSubpageItems = (
    categoryMap: Record<string, ISpaceCategory>,
    currentCategory: string
  ): Array<ICheckableListItem> => {
    return Object.keys(categoryMap[currentCategory].childCategoriesMap)
      .map((childCategory) => ({
        key: `${childCategory}-${categoryMap[currentCategory].childCategoriesMap[childCategory]}`,
        text: categoryMap[childCategory].displayName ?? childCategory,
        secondaryText: categoryMap[childCategory].description,
        icon: categoryIconMap[childCategory]
      }))
      .sort((a, b) => {
        const logicOrderOfA = parseInt(a.key.split("-")[1]);
        const logicOrderOfB = parseInt(b.key.split("-")[1]);
        return logicOrderOfA - logicOrderOfB;
      });
  };

  const handleCheckedValueChange = (checkedItems: Record<string, boolean>, currentCategory: string): void => {
    const childCategoryKey = Object.keys(checkedItems).find((item) => checkedItems[item] === true);
    const subCategory = Object.keys(checkedItems).find((item) => checkedItems[item] === false);
    if (childCategoryKey) {
      const childCategory = childCategoryKey.split("-")[0];
      dispatch(setCategory(childCategory));
      dispatch(setDetailsPanelStateAction(true));
      logger.logEvent("[Menu Panel] space filter page child category is checked", {
        parentCategory: currentCategory,
        childCategory: childCategory
      });
    } else {
      if (isDetailsPanelOpen) {
        dispatch(setCategory(currentCategory));
      } else {
        dispatch(setCategory(subCategory));
      }
    }
  };

  const handleInstantBookingCallback = useCallback(
    (duration: number): void => {
      setInstantBookingLoadingMins(duration);
      const spaceAliasIdMap: Record<string, string> = {};
      buildingSpaces.forEach((space) => {
        if (isRoomBookable(space) && space.cardAttributes.conferenceRoomAlias) {
          spaceAliasIdMap[space.cardAttributes.conferenceRoomAlias] = space.id;
        }
      });
      const targetStartTime = new Date();
      const targetEndTime = new Date();
      targetStartTime.setHours(targetStartTime.getHours(), targetStartTime.getMinutes(), 0, 0);
      targetEndTime.setHours(targetEndTime.getHours(), targetEndTime.getMinutes() + duration, 0, 0);
      getAvailableRooms(
        spaceHttpProvider,
        spaceAliasIdMap,
        targetStartTime,
        targetEndTime,
        duration,
        handleDispatchInstantBookingListViewCard,
        setInstantBookingLoadingMins,
        logger,
        loggerMsgTitle
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildingSpaces]
  );

  const handleDispatchInstantBookingListViewCard = (
    duration: number,
    listOfAvailableIds: string[],
    targetStartTime: Date,
    targetEndTime: Date
  ): void => {
    const categoryName =
      duration === 30
        ? InstantBookingDurationCategory.InstantBooking30MinsCategory
        : InstantBookingDurationCategory.InstantBooking60MinsCategory;
    dispatch(selectListViewCard(categoryName, listOfAvailableIds, targetStartTime, targetEndTime));
  };

  return (
    <SBSCustomMenuPanel
      buildingName={buildingName ?? ""}
      menuWeather={<MenuWeather />}
      menuSearch={<MenuSearch />}
      menuPoi={<MenuPoi />}
      pageContent={pageContent}
      animDirection={transitionRight}
      animDuration={500}
      playAnimation={playAnimation}
      animationOnRest={() => {
        setPlayAnimation(false);
      }}
      isZoomed={isZoomed}
      isDocked={!isMenuPanelOpen}
      sliderCallback={toggleMenuPanel}
      sliderAriaLabel={`Press to ${!isMenuPanelOpen ? "open" : "close"} menu panel.`}
      instantBookingCallback={
        instantBookingEnabled && !electronService.isElectron() ? handleInstantBookingCallback : undefined
      }
      instantBookingLoadingMins={instantBookingLoadingMins}
      instantBookingSelectedMins={instantBookingSelectedMins}
      instantBookingAriaLabelPrefix="Filter rooms by instant booking duration: "
      instantBookingDescribedByPrefix="instant-booking-button-"
    />
  );
}

import { IMenuSearchStyleProps, IMenuSearchStyles } from "./MenuSearch.Types";
import { FontSizes } from "@fluentui/react";

export function getMenuSearchStyles(props: IMenuSearchStyleProps): IMenuSearchStyles {
  return {
    root: { display: "flex", flexDirection: "column", width: "338px" },
    text: { fontSize: FontSizes.superLarge, color: props.theme.semanticColors.menuItemText, marginBottom: "24px" },
    searchBox: { backgroundColor: "transparent" },
    bodyDropdown: { backgroundColor: "transparent" }
  };
}

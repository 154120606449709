var Guid = /** @class */ (function () {
    function Guid(guid) {
        this.guid = "";
        this.guid = guid;
    }
    Guid.newGuid = function () {
        var bits = [];
        for (var i = 0; i < 256; i++) {
            bits[i] = (i < 16 ? "0" : "") + i.toString(16);
        }
        var d0 = (Math.random() * 0xffffffff) | 0;
        var d1 = (Math.random() * 0xffffffff) | 0;
        var d2 = (Math.random() * 0xffffffff) | 0;
        var d3 = (Math.random() * 0xffffffff) | 0;
        var strGuid = bits[d0 & 0xff] +
            bits[(d0 >> 8) & 0xff] +
            bits[(d0 >> 16) & 0xff] +
            bits[(d0 >> 24) & 0xff] +
            "-" +
            bits[d1 & 0xff] +
            bits[(d1 >> 8) & 0xff] +
            "-" +
            bits[((d1 >> 16) & 0x0f) | 0x40] +
            bits[(d1 >> 24) & 0xff] +
            "-" +
            bits[(d2 & 0x3f) | 0x80] +
            bits[(d2 >> 8) & 0xff] +
            "-" +
            bits[(d2 >> 16) & 0xff] +
            bits[(d2 >> 24) & 0xff] +
            bits[d3 & 0xff] +
            bits[(d3 >> 8) & 0xff] +
            bits[(d3 >> 16) & 0xff] +
            bits[(d3 >> 24) & 0xff];
        return new Guid(strGuid);
    };
    Guid.parse = function (guid) {
        return new Guid(guid);
    };
    Guid.prototype.toString = function () {
        return this.guid;
    };
    Guid.empty = new Guid("00000000-0000-0000-0000-000000000000");
    return Guid;
}());
export { Guid };

import { IAction } from "./IAction";
import { IError } from "../Reducers/ErrorReducer";

export enum ErrorTypes {
  MAP_ERROR = "MAP_ERROR",
  SAGA_ERROR = "SAGA_ERROR",
  HTTP_ERROR = "HTTP_ERROR",
  BOOTSTRAPPER_ERROR = "BOOTSTRAPPER_ERROR"
}

export type MapErrorAction = IAction<IError, ErrorTypes.MAP_ERROR>;
export type SagaErrorAction = IAction<IError, ErrorTypes.SAGA_ERROR>;
export type HttpErrorAction = IAction<IError, ErrorTypes.HTTP_ERROR>;
export type BootstrapperErrorAction = IAction<IError, ErrorTypes.BOOTSTRAPPER_ERROR>;

export type ErrorAction = MapErrorAction | SagaErrorAction | HttpErrorAction | BootstrapperErrorAction;

import { IAction, IBaseAction } from "./IAction";
import { IPersonCardData, IPersonSuggestionData } from "@smartbuilding/smartbuilding-api-service";
import { ImageSize, PersonBlobImageMap, SpacePersonMap, SuggestionBlobImageMap } from "../Types/IPeopleStore";
import { IPerson } from "@smartbuilding/people-service";

export enum PeopleSuggestedActions {
  RETRIEVE_PEOPLE_SUGGESTIONS = "RETRIEVE_PEOPLE_SUGGESTIONS", //Handed by People Saga
  RETRIEVE_SUGGESTIONS_WITH_IMAGE = "RETRIEVE_SUGGESTIONS_WITH_IMAGE", //Handed by People Sage
  PEOPLE_SUGGESTIONS_RETRIEVED = "PEOPLE_SUGGESTIONS_RETRIEVED", // Dispatched by People Saga and handled in People Reducer
  SUGGESTION_IMAGE_RETRIEVED = "SUGGESTION_IMAGE_RETRIEVED" // Dispatched by People Saga and handled in People Reducer
}

export enum PeopleRetrieveActions {
  RETRIEVE_CURRENT_USER = "RETRIEVE_CURRENT_USER", // Handled by People Saga
  CURRENT_USER_RETRIEVED = "CURRENT_USER_RETRIEVED", // Handled by People Saga
  RETRIEVE_PEOPLE_IN_BUILDING = "RETRIEVE_PEOPLE_IN_BUILDING", // Handled by People Saga
  PEOPLE_IN_BUILDING_RETRIEVED = "PEOPLE_IN_BUILDING_RETRIEVED", // Dispatched by People Saga and handled in People Reducer

  RETRIEVE_PEOPLE_AT_SPACE = "RETRIEVE_PEOPLE_AT_SPACE", // Handled by People Saga
  PEOPLE_AT_SPACE_RETRIEVED = "PEOPLE_AT_SPACE_RETRIEVED", // Dispatched by People Saga and handled in People Reducer

  RETRIEVE_PERSON_WITH_IMG = "RETRIEVE_PERSON_WITH_IMG", // Handled by People Saga
  PERSON_RETRIEVED = "PERSON_RETRIEVED", // Dispatched by People Saga and handled in People Reducer

  RETRIEVE_PEOPLE_IMAGES = "RETRIEVE_PEOPLE_IMAGES", // Handled by People Saga
  PEOPLE_IMAGES_RETRIEVED = "PEOPLE_IMAGES_RETRIEVED", // Dispatched by People Saga and handled in People Reducer

  PEOPLE_ALIAS_RETRIEVED = "PEOPLE_ALIAS_RETRIEVED", // Handled by People Saga
  IS_VALID_PERSON_CARD_ID = "IS_VALID_PERSON_CARD_ID", // Handled by People Saga

  PEOPLE_FEATURES_DISABLED = "PEOPLE_FEATURES_DISABLED",

  BADGE_SCAN_STATUS = "BADGE_SCAN_STATUS",
  RETRIEVE_PERSON_CARD_DATA = "RETRIEVE_PERSON_CARD_DATA"
}

export enum PeopleSelectedActions {
  PERSON_SELECTED = "PERSON_SELECTED",
  PERSON_SELECTED_CLEARED = "PERSON_SELECTED_CLEARED"
}

export enum PeopleScannedActions {
  PERSON_BADGE_SCANNED = "PERSON_BADGE_SCANNED",
  PERSON_SCAN_CLEARED = "PERSON_SCANNED_CLEARED"
}
export type RetrievePeopleSuggestionsAction = IAction<string, PeopleSuggestedActions.RETRIEVE_PEOPLE_SUGGESTIONS>;
export type PeopleSuggestionsRetrievedAction = IAction<
  { aliasInputValue: string; suggestions: IPersonSuggestionData[] },
  PeopleSuggestedActions.PEOPLE_SUGGESTIONS_RETRIEVED
>;
export type SuggestionImageRetrievedAction = IAction<
  { data: SuggestionBlobImageMap; size: ImageSize },
  PeopleSuggestedActions.SUGGESTION_IMAGE_RETRIEVED
>;
export type RetrieveSuggestionsWithImageAction = IAction<
  { upn: string; size: ImageSize },
  PeopleSuggestedActions.RETRIEVE_SUGGESTIONS_WITH_IMAGE
>;
export type CurrentUserRetrievedAction = IAction<
  { userEmail: string; userName: string | undefined; image?: string | undefined },
  PeopleRetrieveActions.CURRENT_USER_RETRIEVED
>;
export type RetrievePeopleInBuildingAction = IAction<string, PeopleRetrieveActions.RETRIEVE_PEOPLE_IN_BUILDING>;
export type PeopleInBuildingRetrievedAction = IAction<
  SpacePersonMap,
  PeopleRetrieveActions.PEOPLE_IN_BUILDING_RETRIEVED
>;
export type RetrieveCurrentUserAction = IAction<
  { userEmail: string; userName: string | undefined },
  PeopleRetrieveActions.RETRIEVE_CURRENT_USER
>;
export type RetrievePeopleAtSpaceAction = IAction<string, PeopleRetrieveActions.RETRIEVE_PEOPLE_AT_SPACE>;
export type PeopleAtSpaceRetrievedAction = IAction<SpacePersonMap, PeopleRetrieveActions.PEOPLE_AT_SPACE_RETRIEVED>;
export type RetrievePersonWithImgAction = IAction<
  { upn: string; size: ImageSize },
  PeopleRetrieveActions.RETRIEVE_PERSON_WITH_IMG
>;
export type PersonRetrievedAction = IAction<IPerson, PeopleRetrieveActions.PERSON_RETRIEVED>;
export type RetrievePeopleImagesAction = IAction<
  { peopleIds: string[]; size: ImageSize },
  PeopleRetrieveActions.RETRIEVE_PEOPLE_IMAGES
>;
export type PeopleImagesRetrievedAction = IAction<
  { data: PersonBlobImageMap; size: ImageSize },
  PeopleRetrieveActions.PEOPLE_IMAGES_RETRIEVED
>;

export type PeopleAliasDataRetrievedAction = IAction<
  { cardId: string; aliasData: IPersonCardData },
  PeopleRetrieveActions.PEOPLE_ALIAS_RETRIEVED
>;
export type RetrievePersonCardDataAction = IAction<string, PeopleRetrieveActions.RETRIEVE_PERSON_CARD_DATA>;
export type BadgeScanStatusAction = IAction<string, PeopleRetrieveActions.BADGE_SCAN_STATUS>;
export type PersonSelectedAction = IAction<string, PeopleSelectedActions.PERSON_SELECTED>;
export type PersonSelectionClearedAction = IBaseAction<PeopleSelectedActions.PERSON_SELECTED_CLEARED>;

export type PersonScannedAction = IAction<string, PeopleScannedActions.PERSON_BADGE_SCANNED>;
export type PersonScanClearedAction = IBaseAction<PeopleScannedActions.PERSON_SCAN_CLEARED>;

export type PeopleFeaturesDisabledAction = IBaseAction<PeopleRetrieveActions.PEOPLE_FEATURES_DISABLED>;

type PeopleSuggestedAction =
  | RetrievePeopleSuggestionsAction
  | RetrieveSuggestionsWithImageAction
  | PeopleSuggestionsRetrievedAction
  | SuggestionImageRetrievedAction;
type PeopleRetrieveAction =
  | RetrieveCurrentUserAction
  | CurrentUserRetrievedAction
  | RetrievePeopleInBuildingAction
  | PeopleInBuildingRetrievedAction
  | RetrievePeopleAtSpaceAction
  | PeopleAtSpaceRetrievedAction
  | RetrievePersonWithImgAction
  | PersonRetrievedAction
  | RetrievePeopleImagesAction
  | PeopleImagesRetrievedAction
  | PeopleFeaturesDisabledAction;
type PeopleSelectedAction = PersonSelectedAction | PersonSelectionClearedAction;
type PeopleScannedAction =
  | PersonScannedAction
  | PersonScanClearedAction
  | PeopleAliasDataRetrievedAction
  | BadgeScanStatusAction
  | RetrievePersonCardDataAction;

export type PeopleAction = PeopleSuggestedAction | PeopleRetrieveAction | PeopleSelectedAction | PeopleScannedAction;

var TimeoutService = /** @class */ (function () {
    function TimeoutService() {
    }
    // callback can take any type of arguments
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TimeoutService.prototype.timeout = function (callback, delay) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var promiseProps = {
            __timeoutId: 0,
            __timeoutRejector: function () {
                return;
            },
            __canceledTimeout: false
        };
        var timeoutPromise = new Promise(function (resolve, reject) {
            var timeoutId = window.setTimeout(function () {
                if (!callback) {
                    resolve(undefined);
                }
                else {
                    // eslint-disable-next-line prefer-spread
                    resolve(callback.apply(null, args));
                }
            }, delay);
            promiseProps.__timeoutId = timeoutId;
            promiseProps.__timeoutRejector = reject;
            promiseProps.__canceledTimeout = false;
        });
        timeoutPromise.__timeoutId = promiseProps.__timeoutId;
        timeoutPromise.__timeoutRejector = promiseProps.__timeoutRejector;
        timeoutPromise.__canceledTimeout = promiseProps.__canceledTimeout;
        return timeoutPromise;
    };
    TimeoutService.prototype.cancel = function (promise) {
        var timeoutPromise = promise;
        if (typeof timeoutPromise.__canceledTimeout !== "undefined" &&
            timeoutPromise.__timeoutId &&
            timeoutPromise.__timeoutRejector) {
            if (timeoutPromise.__canceledTimeout) {
                return false;
            }
            timeoutPromise.__canceledTimeout = true;
            clearTimeout(timeoutPromise.__timeoutId);
            timeoutPromise.__timeoutRejector();
            return true;
        }
        throw new Error("retryPromise was not created using TimeoutService");
    };
    return TimeoutService;
}());
export { TimeoutService };

/**
 * Calculates, for a given value of t, the value of an ease-in animation function via the formula y(t) = scale * t^exponent + offset
 * Invokes provided draw function with the latest value corresponding to the animation.
 * @param timeProgress The current value of t, expected to be between 0 and 1
 * @param config The animation configuration.
 */
export function easeInExponentialAnimation(timeProgress, config) {
    var exponent = config.exponent, scale = config.scale, offset = config.offset, draw = config.draw;
    var baseVal = Math.pow(timeProgress, exponent);
    var scaledVal = scale * baseVal + offset;
    draw(scaledVal);
}
/**
 * Calculates, for a given value of t, the value an ease-out animation function via the formula y(t) = scale * (1 - (1-t)^exponent) + offset
 * Invokes provided draw function with the latest value corresponding to the animation.
 * @param timeProgress The current value of t, expected to be between 0 and 1
 * @param config The animation configuration.
 */
export function easeOutExponentialAnimation(timeProgress, config) {
    var exponent = config.exponent, scale = config.scale, offset = config.offset, draw = config.draw;
    var baseVal = 1 - Math.pow(1 - timeProgress, exponent);
    var scaledVal = scale * baseVal + offset;
    draw(scaledVal);
}
/**
 * Calculates, for a given value of t, the value of an ease-in animation for the first half (t < 0.5)
 * and an ease out animation for the second half (t >= 0.5) via the formulas:
 *  Ease-in animation: y(t) = scale * ((2t)^exponent / 2) + offset
 *  Ease-out animation: y(t) = scale * ((2 - (2 - (2t)^exponent)) / 2) + offset.
 * Invokes provided draw function with the latest value corresponding to the animation.
 * @param timeProgress The current value of t, expected to be between 0 and 1
 * @param config The animation configuration.
 */
export function easeInOutExponentialAnimation(timeProgress, config) {
    var draw = config.draw, exponent = config.exponent, scale = config.scale, offset = config.offset;
    var baseVal = 0;
    if (timeProgress < 0.5) {
        baseVal = 0.5 * Math.pow(2 * timeProgress, exponent);
    }
    else {
        baseVal = 0.5 * (2 - Math.pow(2 - 2 * timeProgress, exponent));
    }
    draw(scale * baseVal + offset);
}
/**
 * Calculates, for a given value of t, the value of a spring animation function with formula for harmonic oscillations of: y(t) = A * (e^(-d * t)) * cos(w*t);
 * Where A is the amplitude, e is the euler value, d the damping force and w the angular frequency.
 * For springs the angular frequency is calculated to: sqrt(k / m), where k is the tension and m the mass of the object.
 * @param timeProgress The current value of t, expected to be between 0 and 1
 * @param config The animation configuration
 */
export function springAnimationDrawer(timeProgress, config) {
    var draw = config.draw, tension = config.tension, friction = config.friction, amplitude = config.amplitude, mass = config.mass;
    var angularFrequency = Math.sqrt(tension / mass);
    var damping = Math.pow(2.718, -friction * timeProgress);
    var val = amplitude * damping * Math.cos(angularFrequency * timeProgress);
    draw(val);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export var EnabledRoomSubTypes = {
    ConferenceRoom: false,
    FocusRoom: false,
    ProjectRoom: false,
    SpecialtyProjectRoom: false,
    OpenMeetingArea: false,
    ConversationRoom: false,
    CorporateSupport: false,
    Touchdown: false,
    PhoneRoom: false,
    Library: false,
    Lounge: false,
    Cafe: false,
    Cafeteria: false,
    Kitchen: false,
    KitchenetteVending: false,
    WaterCoolerPoint: false,
    DiningSeating: false,
    BathRoom: false,
    Reception: false,
    LobbyReception: false,
    Elevator: false,
    Stairwell: false,
    CopyRoom: false,
    MailRoom: false,
    LactationRoom: false,
    MothersRoom: false,
    MeditationRoom: false,
    WellnessRelaxationRoom: false,
    MeditationMultiFaithRoom: false,
    RecreationGaming: false,
    Sauna: false,
    ShowerLockerRoom: false,
    GymFitnessCenter: false,
    PatioTerrace: false,
    BombShelter: false,
    Techlink: false,
    ITSupport: false,
    MicrosoftStore: false,
    RetailShop: false,
    ParkingBay: false,
    EVChargingStation: false,
    Atrium: false,
    Auditorium: false,
    EnvisioningCenter: false,
    InnovationCenter: false,
    InteractiveCenter: false,
    Neighborhood: false,
    Office: false,
    SharedOffice: false,
    TeamOffice: false,
    TechnicalBriefingCenter: false,
    TrainingRoom: false,
    PinTail: false,
    MultiPurposeRoom: false,
    DiningServery: false
};

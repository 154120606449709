import { FeatureExperimentsNamespaces, FeatureFlagNames } from "@smartbuilding/feature-flighting-service";
import { useEffect, useState } from "react";
import { getSelectedUrl } from "../../redux/Selectors";
import { useFeatureFlightingContext } from "@smartbuilding/feature-flighting-service-react";
import { useSelector } from "react-redux";

interface IUseMyHubPathFindingQRCodeUrl {
  qrCodeURL?: string;
  qrCodeSize?: number;
  qrCodeDescription?: string;
}

export function useMyHubPathFindingQRCodeUrl(): IUseMyHubPathFindingQRCodeUrl {
  const featureFlightingContext = useFeatureFlightingContext();
  const qrCodeURL = useSelector(getSelectedUrl);
  const qrCodeDescription = "Scan to view directions in MyHub";
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    featureFlightingContext.initialize().then(() => {
      const value = featureFlightingContext.getFeatureValue(
        FeatureExperimentsNamespaces.SmartBuildingClient,
        FeatureFlagNames.EnablePathfindingQRCode
      );

      const enableQrCode = typeof value === "boolean" && value;
      if (enableQrCode) {
        setEnable(enableQrCode);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enable ? { qrCodeURL, qrCodeDescription, qrCodeSize: undefined } : {};
}

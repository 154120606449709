import {
  DeferredPromise,
  evaluateBusynessStatus,
  evaluateSpaceStatus,
  getSpaceCategoryMap
} from "@smartbuilding/utilities";
import { FeatureExperimentsNamespaces, FeatureFlagNames } from "@smartbuilding/feature-flighting-service";
import {
  IHotDeskData,
  IIndoorAzureMap,
  IOverlayMapOptions,
  IPeopleDensityData,
  IPersonImage,
  PoiLayers
} from "@smartbuilding/azure-maps";
import { IRoomInfo, ImageSize, PersonBlobImageMap } from "../../../redux/Types";
import {
  clearDetailsPanelStack,
  clearSpacePinsLayer,
  modifyDetailsPanelStackPage,
  renderSpacePinsLayer,
  retrievePeopleImages
} from "../../../redux/Actions";
import {
  getBuildingCategories,
  getBuildingId,
  getBuildingName,
  getCategory,
  getCurrentFloorHotDeskingRoomsWithSensorsMap,
  getDeviceConfigFloorId,
  getDiningRoomsForCurrentFloor,
  getFloors,
  getHotDeskingRoomsForCurrentFloor,
  getIsPeopleFeatureDisabled,
  getMapLayers,
  getMenuPanelSpaceCategory,
  getPeopleInBuilding,
  getPersonImageMap,
  getPointsOfInterest,
  getSelectedPathMetadata,
  getSelectedSpace,
  getSpaceBusynessRooms,
  getSpaceBusynessRoomsWithSensorsMap
} from "../../../redux/Selectors";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import AedDeviceIcon from "../../../../Assets/Icons/Pois/AedDevice.svg";
import BombShelterIcon from "../../../../Assets/Icons/Pois/BombShelter.svg";
import EmergencyExitIcon from "../../../../Assets/Icons/Pois/EmergencyExit.svg";
import FireExtinguisherIcon from "../../../../Assets/Icons/Pois/FireExtinguisher.svg";
import { ILogger } from "@smartbuilding/log-provider";
import { ISensorProperties } from "@smartbuilding/adt-v2-types";
import { MapOverlayColors } from "../MapOverlayLayer/MapOverlayLayer.Types";
import { PoiType } from "@smartbuilding/poi-service";
import { debounce } from "lodash";
import { getSensorData } from "@smartbuilding/signalr-redux";
import moment from "moment";
import { useFeatureFlightingContext } from "@smartbuilding/feature-flighting-service-react";

interface IFeaturePersonImageRetrieve {
  deferredPromise: DeferredPromise<IPersonImage[]>;
  requestedPplIds: string[];
}

const layerRenderEventName = "[React Webclient Metric] POI Layer Render Time";
const kioskBlueDotEvent = "[KioskBlueDot] Kiosk blue dot added";
const spaceLayerPeopleLayerThreshold = 20.15;

export function useMapLayers(indoorMap: IIndoorAzureMap | null, floorId: string, logger: ILogger): void {
  const peopleLayerZoomThreshold = { min: spaceLayerPeopleLayerThreshold, max: 24 };
  const mapLayers = useSelector(getMapLayers);
  const pathMetadata = useSelector(getSelectedPathMetadata);
  const deviceConfigFloorId = useSelector(getDeviceConfigFloorId);
  const poiData = useSelector(getPointsOfInterest)[floorId];
  const pathwayCreatedTime = useRef<Date | null>(null);
  const featurePersonImageRetrieve = useRef<Record<string, IFeaturePersonImageRetrieve>>({});
  const people = useSelector(getPeopleInBuilding);
  const personImageMap = useSelector(getPersonImageMap);
  const buildingId = useSelector(getBuildingId);
  const buildingName = useSelector(getBuildingName);
  const buildingCategories = useSelector(getBuildingCategories);
  const sensorRoomMap = useSelector(getSensorData);
  const category = useSelector(getCategory);
  const selectedSpace = useSelector(getSelectedSpace);
  const menuPanelSpaceCategory = useSelector(getMenuPanelSpaceCategory);
  const dispatch = useDispatch();
  const hotdeskingRoomsOnCurrentFloor = useSelector(getHotDeskingRoomsForCurrentFloor);
  const hotdeskingSensorMap = useSelector(getCurrentFloorHotDeskingRoomsWithSensorsMap);
  const diningRoomsOnCurrentFloor = useSelector(getDiningRoomsForCurrentFloor);
  const floors = useSelector(getFloors);
  const spaceBusynessRooms = useSelector(getSpaceBusynessRoomsWithSensorsMap);
  const buildingBusynessRooms = useSelector(getSpaceBusynessRooms);
  const peopleFeatureDisabled = useSelector(getIsPeopleFeatureDisabled);
  const spaceSymbolLayerZoomThreshold = {
    min: 17.75,
    max: peopleFeatureDisabled ? 24 : spaceLayerPeopleLayerThreshold
  };
  const featureFlightingContext = useFeatureFlightingContext();

  const getFormattedPeopleDensityData = (rooms: IRoomInfo[]): IPeopleDensityData[] => {
    const data: IPeopleDensityData[] = [];
    rooms.forEach((room) => {
      if (sensorRoomMap[room.id]?.PeopleCount !== undefined) {
        data.push({
          spaceId: room.id,
          peopleCount: sensorRoomMap[room.id]?.PeopleCount as number,
          totalCapacity: room.cardAttributes.totalCapacity ?? 0,
          roomType: room.type
        });
      }
    });

    return data;
  };

  const getFormattedHotDeskData = (rooms: IRoomInfo[]): IHotDeskData[] => {
    const data: IHotDeskData[] = [];
    rooms.forEach((room) => {
      if (room.cardAttributes.totalCapacity) {
        data.push({
          spaceId: room.id,
          availableCapacity: hotdeskingSensorMap[room.id]?.AvailableCapacity as number,
          totalCapacity: room.cardAttributes.totalCapacity,
          occupancyStatus: hotdeskingSensorMap[room.id]?.OccupancyStatus as string | number | boolean | undefined
        });
      }
    });

    return data;
  };

  const getFormattedPoiData = (poiType: PoiType): number[][] => {
    const data = poiData && poiData[poiType] ? poiData[poiType] : null;
    if (data) {
      return data.map((values) => {
        return [values.longitude, values.latitude];
      });
    }

    return [];
  };

  const getBusynessSensorData = useMemo(
    () => (): Record<string, ISensorProperties> => {
      const idBusynessDataMap: Record<string, ISensorProperties> = {};
      for (const id of Object.keys(spaceBusynessRooms)) {
        const busynessStatus = evaluateBusynessStatus(
          spaceBusynessRooms[id]?.PeopleCount as number,
          buildingBusynessRooms.find((room) => room.id === id)?.cardAttributes.roomCapacity ?? 0,
          spaceBusynessRooms[id]?.OccupancyStatus as string | number | boolean | undefined
        );
        idBusynessDataMap[id] = {
          busynessStatus: busynessStatus.busynessStatus,
          busynessStatusColor: busynessStatus.busynessStatusColor
        };
      }
      return idBusynessDataMap;
    },
    [buildingBusynessRooms, spaceBusynessRooms]
  );

  const enabledBuildingCategories = useMemo(() => getSpaceCategoryMap(buildingCategories), [buildingCategories]);

  const enabledOverlays: Record<PoiLayers, boolean> = {
    AedDevicePoi: mapLayers.poi.AEDDevice,
    BombShelterPoi: mapLayers.poi.BombShelter,
    EmergencyExitPoi: mapLayers.poi.EmergencyExit,
    FireExtinguisherPoi: mapLayers.poi.FireExtinguisher,
    KioskBlueDot: deviceConfigFloorId && deviceConfigFloorId === floorId ? true : false
  };

  useEffect(() => {
    const overlayMapOptions: IOverlayMapOptions = {
      overlayDataSources: {
        getAEDPointCloud: getFormattedPoiData(PoiType.AEDDevice),
        getBombShelterSpaces: getFormattedPoiData(PoiType.BombShelter),
        getEmergencyExitPointCloud: getFormattedPoiData(PoiType.EmergencyExit),
        getFireExtinguisherPointCloud: getFormattedPoiData(PoiType.FireExtinguisher),
        getKioskBlueDot:
          deviceConfigFloorId && deviceConfigFloorId === floorId ? getFormattedPoiData(PoiType.KioskLocation) : []
      },
      overlayStyles: {
        SBSMapLayerColors: {
          AedDevicePoi: AedDeviceIcon,
          BombShelterPoi: BombShelterIcon,
          EmergencyExitPoi: EmergencyExitIcon,
          FireExtinguisherPoi: FireExtinguisherIcon,
          KioskBlueDot: MapOverlayColors.KioskBlueDot
        }
      }
    };
    const addMapLayers = async (): Promise<void> => {
      const layerRenderStartTime = performance.now();
      await indoorMap?.createOverlayMapLayers(enabledOverlays, overlayMapOptions);
      const layerRenderEndTime = performance.now();
      const totalRenderTime = layerRenderEndTime - layerRenderStartTime;
      const logData = {
        timeInMs: totalRenderTime.toString(),
        ShowingAed: enabledOverlays.AedDevicePoi.toString(),
        ShowingBombShelter: enabledOverlays.BombShelterPoi.toString(),
        ShowingEmergencyExit: enabledOverlays.EmergencyExitPoi.toString(),
        ShowingFireExtinguisher: enabledOverlays.FireExtinguisherPoi.toString(),
        ShowKioskBlueDot: enabledOverlays.KioskBlueDot.toString()
      };
      logger.logEvent(layerRenderEventName, logData);
      if (enabledOverlays.KioskBlueDot) {
        logger.logEvent(kioskBlueDotEvent, {
          buildingName: buildingName,
          floorId: floorId,
          location: overlayMapOptions.overlayDataSources.getKioskBlueDot
        });
      }
    };
    addMapLayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poiData, indoorMap, mapLayers.poi, floorId]);
  useEffect(() => {
    const peopleDensityFeatureIds: IPeopleDensityData[] = getFormattedPeopleDensityData(diningRoomsOnCurrentFloor);
    indoorMap?.removePeopleDensityLayer();
    featureFlightingContext.initialize().then(() => {
      const peopleDensityFlight = featureFlightingContext.getFeatureValue(
        FeatureExperimentsNamespaces.SmartBuildingClient,
        FeatureFlagNames.PeopleDensityEnabled
      );
      const peopleDensityValue = typeof peopleDensityFlight === "boolean" && peopleDensityFlight;

      if (peopleDensityFeatureIds.length && mapLayers.peopleDensity && peopleDensityValue) {
        indoorMap?.createPeopleDensityLayer(peopleDensityFeatureIds);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLayers.peopleDensity, diningRoomsOnCurrentFloor]);

  useEffect(() => {
    const hotDeskFeatureIds: IHotDeskData[] = getFormattedHotDeskData(hotdeskingRoomsOnCurrentFloor);
    indoorMap?.removeHotDeskLayer();
    if (hotDeskFeatureIds.length && mapLayers.hotdesk) {
      indoorMap?.createHotDeskLayer(hotDeskFeatureIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotdeskingRoomsOnCurrentFloor, mapLayers.hotdesk, hotdeskingSensorMap]);

  useEffect(() => {
    if (mapLayers.route && pathMetadata?.routeData) {
      indoorMap?.removeRouteLayer();
      indoorMap?.createRouteLayer(pathMetadata.routeData, false);
      pathwayCreatedTime.current = moment().toDate();
      logger.logEvent("[mapControlBase] Direction Path Created Successfully");
      dispatch(clearSpacePinsLayer());
      if (pathMetadata.pathfindingLoggerID && pathMetadata.routeRenderStartTime) {
        const routeRenderTotalTime = performance.now() - pathMetadata.routeRenderStartTime;
        logger.logEvent("[mapControlBase] Path Rendered", {
          pathfindingLoggerID: pathMetadata.pathfindingLoggerID,
          routeRenderTotalTime: routeRenderTotalTime.toString()
        });
      }
    } else {
      if (pathwayCreatedTime.current) {
        const pathwayDuration = moment().diff(pathwayCreatedTime.current, "seconds");
        logger.logEvent("[mapControlBase] Direction Path Ended Successfully");
        logger.trackMetric("[mapControlBase] Total Direction Path Duration In Seconds", pathwayDuration);
      }
      indoorMap?.removeRouteLayer();
      pathwayCreatedTime.current = null;
      dispatch(renderSpacePinsLayer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLayers.route, pathMetadata, indoorMap]);

  useEffect(() => {
    indoorMap?.removePeopleLayer();
    if (!peopleFeatureDisabled && mapLayers.people) {
      indoorMap?.createPeopleLayer(peopleLayerZoomThreshold, (featureId) => {
        const peopleIds: string[] = [];
        people.forEach((person) => {
          if (person.featureId === featureId) peopleIds.push(person.dtId);
        });

        if (peopleIds.length <= 0) return Promise.resolve([]);
        const peopleImages = getPeopleImagesInFeature(peopleIds, personImageMap[ImageSize.Small]);
        if (peopleImages && peopleImages.length > 0) {
          return Promise.resolve(peopleImages);
        }

        const existingRetrieve = featurePersonImageRetrieve.current[featureId];
        if (existingRetrieve) {
          return existingRetrieve.deferredPromise.promise;
        }
        const deferredPromise = new DeferredPromise<IPersonImage[]>();
        featurePersonImageRetrieve.current[featureId] = {
          deferredPromise: deferredPromise,
          requestedPplIds: peopleIds
        };

        dispatch(retrievePeopleImages(peopleIds, ImageSize.Small));
        return deferredPromise.promise;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleFeatureDisabled, mapLayers.people, buildingId]);

  useEffect(() => {
    if (!peopleFeatureDisabled && featurePersonImageRetrieve.current) {
      Object.keys(featurePersonImageRetrieve.current).forEach((featureId) => {
        const retrieve = featurePersonImageRetrieve.current[featureId];
        const peopleImages = getPeopleImagesInFeature(retrieve.requestedPplIds, personImageMap[ImageSize.Small]);
        if (peopleImages && peopleImages.length > 0) {
          retrieve.deferredPromise.resolve(peopleImages);
          delete featurePersonImageRetrieve.current[featureId];
        }
      });
    }
    // We only want to run this when person image map updates, not when the list of people updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleFeatureDisabled, personImageMap]);

  const onPanelBackClickCallback = (): void => {
    dispatch(modifyDetailsPanelStackPage());
  };

  const onPanelDismissCallback = (): void => {
    dispatch(clearDetailsPanelStack());
  };

  useEffect(() => {
    if (sensorRoomMap && Object.keys(buildingCategories).length !== 0) {
      const idColorDataMap: Record<string, ISensorProperties> = {};
      let buildingSensorRooms: string[] = [];
      for (const category of Object.keys(buildingCategories)) {
        if (buildingCategories[category].webSensorLayerEnabled) {
          buildingSensorRooms = buildingSensorRooms.concat(buildingCategories[category].spaces);
        }
      }
      for (const id of Object.keys(sensorRoomMap)) {
        if (buildingSensorRooms.includes(id)) {
          idColorDataMap[id] = {
            /**
             * Effective Date: 2024-08-21
             * Real-time calendar data in no longer available.
             * Commenting out until we have a solution.
             */
            // sensorStatusColor: evaluateSpaceStatus(
            //     sensorRoomMap[id].ConferenceStatus,
            //     sensorRoomMap[id].OccupancyStatus
            // ).mapColor
            /**
             * Effective Date: 2024-08-21
             * Real-time calendar data in no longer available.
             * Set ConferenceStatus as undefined until we have a solution.
             */
            sensorStatusColor: evaluateSpaceStatus(undefined, sensorRoomMap[id].OccupancyStatus).mapColor
          };
        }
      }
      indoorMap?.setSpaceSensorProperties(idColorDataMap);
      logger.logEvent("[SensorOverlayLayer] Added sensor layer to map", {
        buildingId: buildingId,
        buildingName: buildingName as string,
        roomCount: Object.keys(idColorDataMap).length.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingCategories, sensorRoomMap]);

  useEffect(() => {
    const idBusynessDataMap = getBusynessSensorData();
    if (Object.keys(idBusynessDataMap).length > 0) {
      indoorMap?.setSpaceSensorProperties(idBusynessDataMap);
      logger.logEvent("[BusynessLayer] Added busyness data to map", {
        buildingId: buildingId,
        buildingName: buildingName as string,
        roomCount: Object.keys(idBusynessDataMap).length.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceBusynessRooms]);

  useEffect(() => {
    indoorMap?.disposeSpaceSymbolLayer();
    if (Object.keys(buildingCategories).length !== 0) {
      if (buildingCategories) {
        const spaceCategoryMap = enabledBuildingCategories;
        const logData = {
          buildingName: buildingName as string,
          buildingCategories: Object.keys(spaceCategoryMap).toString()
        };
        logger.logEvent("[SpaceSymbolLayer] Space pins added to building", logData);
        indoorMap?.createSpaceSymbolLayer(
          spaceCategoryMap,
          spaceSymbolLayerZoomThreshold,
          onPanelDismissCallback,
          onPanelBackClickCallback
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingCategories]);

  useEffect(() => {
    if (mapLayers.spacePins) {
      indoorMap?.showSpaceSymbolLayer();
    } else {
      indoorMap?.hideSpaceSymbolLayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLayers.spacePins]);

  useEffect(() => {
    const handleCategoryUpdate = async (): Promise<void> => {
      if (menuPanelSpaceCategory && menuPanelSpaceCategory.parentCategory !== null) {
        if (category && !menuPanelSpaceCategory.childCategories.includes(category)) {
          // menu panel at root category state
          const layerRenderStartTime = performance.now();
          await indoorMap?.renderSpaceSymbolLayer(menuPanelSpaceCategory.childCategories, mapLayers.spacePins);
          const layerRenderEndTime = performance.now();
          const totalRenderTime = layerRenderEndTime - layerRenderStartTime;
          const logData = {
            timeInMs: totalRenderTime.toString(),
            buildingName: buildingName as string,
            parentCategory: category,
            childCategories: menuPanelSpaceCategory.childCategories.toString()
          };
          logger.logEvent("[SpaceSymbolLayer] layer rendered for parent category", logData);
        } else if (category && menuPanelSpaceCategory.childCategories.includes(category)) {
          // menu panel at category state && a category is checked
          const layerRenderStartTime = performance.now();
          await indoorMap?.renderSpaceSymbolLayer([category], mapLayers.spacePins);
          const layerRenderEndTime = performance.now();
          const totalRenderTime = layerRenderEndTime - layerRenderStartTime;
          const logData = {
            timeInMs: totalRenderTime.toString(),
            buildingName: buildingName as string,
            category: category
          };
          logger.logEvent("[SpaceSymbolLayer] layer rendered for child category", logData);
        }
      } else {
        indoorMap?.removeSpaceSymbolLayer();
        logger.logEvent("[SpaceSymbolLayer] Space pins removed from map");
      }
    };

    handleCategoryUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuPanelSpaceCategory, category]);

  useEffect(() => {
    const handleSpaceUpdate = async (): Promise<void> => {
      if (menuPanelSpaceCategory && menuPanelSpaceCategory.parentCategory === null && !selectedSpace) {
        // menu at root and no space is selected
        indoorMap?.removeSpaceSymbolLayer();
        logger.logEvent("[SpaceSymbolLayer] Single space pin removed from map");
      } else if (menuPanelSpaceCategory && menuPanelSpaceCategory.parentCategory !== null && !selectedSpace) {
        // menu at category root and no space is selected
        await indoorMap?.removeHighlight();
        logger.logEvent("[SpaceSymbolLayer] Space pin highlight removed");
      }
      const selectedSpaceFloorName = floors.find((f) => f.id === selectedSpace?.cardAttributes.floorId)?.name;
      if (selectedSpace && menuPanelSpaceCategory && menuPanelSpaceCategory.parentCategory === null) {
        // menu panel at root state and a space is selected on the map
        await renderSymbolLayerFromClick([selectedSpace.id], mapLayers.spacePins, selectedSpaceFloorName);
      } else if (selectedSpace && menuPanelSpaceCategory && menuPanelSpaceCategory.parentCategory !== null) {
        //space selected in details panel and markers are on map
        await highlightSpace(selectedSpace.id, selectedSpace.type, mapLayers.spacePins, selectedSpaceFloorName);
      }
    };

    handleSpaceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpace]);
  const getPeopleImagesInFeature = (pplIds: string[], personImageMap: PersonBlobImageMap): IPersonImage[] => {
    const pplImages: IPersonImage[] = [];
    pplIds.forEach((personId) => {
      const imageUrl = personImageMap[personId];
      if (imageUrl) {
        pplImages.push({
          imageUrl: imageUrl
        });
      }
    });

    return pplImages;
  };

  const renderSymbolLayerFromClickRef = useRef<Function>();
  renderSymbolLayerFromClickRef.current = async (
    selectedSpaceId: string[],
    isLayerVisible: boolean,
    selectedSpaceFloorName?: string
  ) => {
    await indoorMap?.renderSpaceSymbolLayerFromClick(
      selectedSpaceId,
      isLayerVisible,
      undefined,
      selectedSpaceFloorName
    );
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  const renderSymbolLayerFromClick = useCallback(
    debounce(async (selectedSpaceId: string[], isLayerVisible: boolean, selectedSpaceFloorName?: string) => {
      if (renderSymbolLayerFromClickRef.current) {
        await renderSymbolLayerFromClickRef.current(selectedSpaceId, isLayerVisible, selectedSpaceFloorName);
      }
    }, 500),
    [renderSymbolLayerFromClickRef]
  );

  const highlightSpaceRef = useRef<Function>();
  highlightSpaceRef.current = async (
    selectedSpaceId: string,
    category: string,
    isLayerVisible: boolean,
    selectedSpaceFloorName?: string
  ) => {
    await indoorMap?.highlightSpace(selectedSpaceId, category, isLayerVisible, undefined, selectedSpaceFloorName);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  const highlightSpace = useCallback(
    debounce(
      async (selectedSpaceId: string, category: string, isLayerVisible: boolean, selectedSpaceFloorName?: string) => {
        if (highlightSpaceRef.current) {
          await highlightSpaceRef.current(selectedSpaceId, category, isLayerVisible, selectedSpaceFloorName);
        }
      },
      500
    ),
    [highlightSpaceRef]
  );
}

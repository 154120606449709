import { BuildingFilledIcon, BuildingOutlineIcon } from "@smartbuilding/ui-components-icons";
import { ISearchResultItem, MenuSearchClickCallback } from "./MenuSearch.Types";
import { getBuildings, getRegionMap } from "../../../redux/Selectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useBuildingSearchResultOptions(onClickCallback: MenuSearchClickCallback): ISearchResultItem[] {
  const buildings = useSelector(getBuildings);
  const regionsMap = useSelector(getRegionMap);
  const [buildingsList, setBuildingsList] = useState<ISearchResultItem[]>([]);

  useEffect(() => {
    const list: ISearchResultItem[] = buildings.map((building) => {
      return {
        sortBy: building.name,
        filterBy: [building.name],
        itemData: {
          key: building.id,
          primaryText: building.name,
          secondaryText:
            building.regionId && regionsMap[building.regionId]?.name !== "PUGET SOUND"
              ? regionsMap[building.regionId]?.name
              : "MSUS > PUGET SOUND",
          icon: {
            base: BuildingOutlineIcon,
            hoverIcon: BuildingFilledIcon,
            activeIcon: BuildingFilledIcon
          },
          onClick: () => {
            onClickCallback(building.id, "Building");
          }
        }
      };
    });

    setBuildingsList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildings]);

  return buildingsList;
}

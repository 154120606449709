var AzureMapServiceCache = /** @class */ (function () {
    function AzureMapServiceCache() {
        this.levelsCache = {};
        this.featuresCache = {};
        this.facilityCache = {};
        if (AzureMapServiceCache.instance) {
            return AzureMapServiceCache.instance;
        }
        AzureMapServiceCache.instance = this;
        return this;
    }
    AzureMapServiceCache.prototype.setFacility = function (datasetId, facility) {
        if (!this.facilityCache[datasetId] && facility) {
            this.facilityCache[datasetId] = facility;
        }
    };
    AzureMapServiceCache.prototype.setLevels = function (datasetId, facilityLevels) {
        if (!this.levelsCache[datasetId] && facilityLevels.length !== 0) {
            this.levelsCache[datasetId] = facilityLevels;
        }
    };
    AzureMapServiceCache.prototype.setFeatures = function (datasetId, facilityFeatures) {
        if (!this.featuresCache[datasetId] && facilityFeatures.length !== 0) {
            this.featuresCache[datasetId] = facilityFeatures;
        }
    };
    AzureMapServiceCache.prototype.getFacility = function (datasetId) {
        return this.facilityCache[datasetId];
    };
    AzureMapServiceCache.prototype.getLevels = function (datasetId) {
        return this.levelsCache[datasetId];
    };
    AzureMapServiceCache.prototype.getFeatures = function (datasetId) {
        return this.featuresCache[datasetId];
    };
    return AzureMapServiceCache;
}());
export { AzureMapServiceCache };

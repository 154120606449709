import { IMenuPoiStyleProps, IMenuPoiStyles } from "./MenuPoi.Types";

export function getMenuPoiStyles(props: IMenuPoiStyleProps): IMenuPoiStyles {
  return {
    root: {
      width: "100%",
      flexFlow: "row nowrap",
      justifyContent: "space-around"
    }
  };
}

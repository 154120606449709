import { FeedbackAgeGroup, FeedbackAuthenticationType, FeedbackHostPlatformType, FeedbackPolicyValue, FeedbackUiType } from "./FeedbackTypes";
import { M365ThemeKind } from "@ms/centro-hvc-loader";
// eslint-disable-next-line @typescript-eslint/no-empty-function
function onDismissPrint(isFeedbackSent) { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
function onErrorPrint(errorMessage) { }
// eslint-disable-next-line @typescript-eslint/no-empty-function
function onSuccessPrint(clientFeedbackId) { }
// eslint-disable-next-line @typescript-eslint/naming-convention
export var InitialFeedbackData = {
    appId: 50182,
    clientName: "SBS Kiosks",
    authenticationType: FeedbackAuthenticationType.AAD,
    ageGroup: FeedbackAgeGroup.Adult,
    isProduction: false,
    callbackFunctions: {
        onDismiss: onDismissPrint,
        onSuccess: onSuccessPrint,
        onError: onErrorPrint
    },
    feedbackConfig: {
        featureAreas: ["General", "Room Booking Service", "Direction Service", "Search Service", "Map Service"],
        feedbackUiType: FeedbackUiType.Modal,
        hostPlatform: FeedbackHostPlatformType.Web,
        isDisplayed: false,
        isEmailCollectionEnabled: false,
        isFileUploadEnabled: false,
        isScreenshotEnabled: false,
        isScreenRecordingEnabled: false,
        complianceChecks: {
            connectedExperiences: FeedbackPolicyValue.Enabled,
            policyAllowFeedback: FeedbackPolicyValue.Enabled,
            policyAllowSurvey: FeedbackPolicyValue.Enabled,
            policyAllowScreenshot: FeedbackPolicyValue.Enabled,
            policyAllowContact: FeedbackPolicyValue.Enabled,
            policyAllowContent: FeedbackPolicyValue.Enabled
        }
    },
    themeOptions: { baseTheme: M365ThemeKind.M365Dark }
};

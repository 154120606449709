var SmartBuildingApiHttp = /** @class */ (function () {
    function SmartBuildingApiHttp(httpService, baseApiUrl) {
        this.httpService = httpService;
        this.endpointUrl = "".concat(baseApiUrl, "/api/v1.0/spaces");
    }
    SmartBuildingApiHttp.prototype.getAzureMapsData = function (datasetId) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/mapData/").concat(datasetId))
            .then(function (val) { return val.data; })
            .catch(function () {
            return undefined;
        });
    };
    SmartBuildingApiHttp.prototype.getSpacePinIcon = function (spacetype, filled) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/pinIcon/").concat(spacetype, "/").concat(filled))
            .then(function (val) { return val.data; })
            .catch(function () {
            return undefined;
        });
    };
    SmartBuildingApiHttp.prototype.getSpacesInBuilding = function (buildingId) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/").concat(buildingId))
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpacesInBuildingByCategory = function (buildingId, category) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/").concat(buildingId, "/").concat(category))
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpacesInFloor = function (buildingId, floorId) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/").concat(buildingId, "/").concat(floorId))
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getCategoriesInBuilding = function (buildingId) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/categories/").concat(buildingId))
            .then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getUserRing = function (upn) {
        /**
         * @todo Replace with actual call to SmartBuildingAPI to get kiosk ring
         */
        return this.httpService.get("".concat(this.endpointUrl, "/user/").concat(upn)).then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getKioskRing = function (kioskId) {
        /**
         * @todo Replace with actual call to SmartBuildingAPI to get kiosk ring
         */
        return this.httpService.get("".concat(this.endpointUrl, "/kiosk/").concat(kioskId)).then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getSpaceBusynessRuleSet = function () {
        return this.httpService.get("".concat(this.endpointUrl, "/spaceBusynessRuleSet")).then(function (val) { return val.data; });
    };
    SmartBuildingApiHttp.prototype.getRoomTags = function (buildingId) {
        return this.httpService
            .get("".concat(this.endpointUrl, "/tags/").concat(buildingId))
            .then(function (val) { return val.data; })
            .catch(function (error) {
            throw error;
        });
    };
    return SmartBuildingApiHttp;
}());
export { SmartBuildingApiHttp };

export var PoiLayers;
(function (PoiLayers) {
    PoiLayers["AedDevicePoi"] = "AedDevicePoi";
    PoiLayers["EmergencyExitPoi"] = "EmergencyExitPoi";
    PoiLayers["FireExtinguisherPoi"] = "FireExtinguisherPoi";
    PoiLayers["BombShelterPoi"] = "BombShelterPoi";
    PoiLayers["KioskBlueDot"] = "KioskBlueDot";
})(PoiLayers || (PoiLayers = {}));
export var SBSBuildingClusteringLayer;
(function (SBSBuildingClusteringLayer) {
    SBSBuildingClusteringLayer["BuildingLayer"] = "BuildingLayer";
    SBSBuildingClusteringLayer["BuildingMarkerLayer"] = "BuildingMarkerLayer";
    SBSBuildingClusteringLayer["BuildingLabelLayer"] = "BuildingLabelLayer";
    SBSBuildingClusteringLayer["ClusterMarkerLayer"] = "ClusterMarkerLayer";
    SBSBuildingClusteringLayer["ClusterLabelLayer"] = "ClusterLabelLayer";
})(SBSBuildingClusteringLayer || (SBSBuildingClusteringLayer = {}));
// SBSCustomOutdoorMapLayer is extending BuildingClusteringLayer
// export type SBSCustomOutdoorMapLayer = SBSBuildingClusteringLayer;
export var SBSCustomOutdoorMapIcons;
(function (SBSCustomOutdoorMapIcons) {
    SBSCustomOutdoorMapIcons["BuildingMarkerIcon"] = "BuildingMarkerIcon";
    SBSCustomOutdoorMapIcons["ClusterMarkerIcon"] = "ClusterMarkerIcon";
    SBSCustomOutdoorMapIcons["BuildingCafeIcon"] = "BuildingCafeIcon";
})(SBSCustomOutdoorMapIcons || (SBSCustomOutdoorMapIcons = {}));

import { IInstantBookingConfirmationInfo } from "../../components/DetailsPanel/DetailsPanelProps";

export interface IAppStore {
  /**
   * The current page stack for details panel
   */
  detailsPanelPageStack: Array<IPageInfo>;

  /**
   * The flag to determine if we enable instant booking feature
   */
  instantBookingEnabled: boolean;

  /**
   * The flag to determine if we enable ocv feedback feature
   */
  ocvFeedbackEnabled: boolean;

  /**
   * The flag to determine if we are in the process of resetting the app
   */
  resetInProgress: boolean;

  /**
   * The flag to determine if we enable ML generated room tags
   */
  roomTagEnabled: boolean;
}

export interface IPageInfo {
  /**
   * The id of selected space
   */
  selectedSpaceId?: string;

  /**
   * The id of selected person
   */
  selectedPersonId?: string;

  /**
   * The selected category
   */
  selectedCategory?: string;

  /**
   * The selected instant booking duration
   */
  selectedInstantBookingDuration?: number;

  /**
   * The model contains information for list view card
   */
  selectedListViewCard?: IListViewPageInfo;

  /**
   * Info passed to the current selected bookable space for cancel reservation
   */
  instantBookingConfirmationInfo?: IInstantBookingConfirmationInfo;
}

export interface IListViewPageInfo {
  /**
   * The category of this list view page
   */
  selectedCategory: string | InstantBookingDurationCategory;

  /**
   * The list of ids of selected space
   */
  selectedSpaceIds: Array<string>;

  /**
   * The target start time for instant booking
   */
  targetStartTime?: Date;

  /**
   * The target end time for instant booking
   */
  targetEndTime?: Date;
}

export enum InstantBookingDurationCategory {
  /**
   * the instant booking category for 30 mins
   */
  InstantBooking30MinsCategory = "Instant Booking 30 mins",

  /**
   * the instant booking category for 60 mins
   */
  InstantBooking60MinsCategory = "Instant Booking 60 mins"
}

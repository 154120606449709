import { PoiType } from "@smartbuilding/poi-service";

export type FeaturePersonImageMap = Record<string, string>; // <map feature id, person image url>
export interface IMapStore {
  layers: IMapLayers;
}

export interface IMapLayers {
  people: boolean;
  route: boolean;
  poi: Record<PoiType, boolean>;
  peopleDensity: boolean;
  hotdesk: boolean;
  spacePins: boolean;
}

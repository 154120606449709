import { BaseTelemetryPlugin, IProcessTelemetryContext, ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { IDeviceConfigStore, ISpaceStore } from "../redux/Types";
import type { ISBClientTelemetryCustomProperties } from "./ISBClientTelemetryCustomProperties";
import { store as SBSReduxStore } from "../redux/Store";
import { electronService } from "@smartbuilding/electron-service";
import { injectable } from "inversify";

@injectable()
export class SBClientPlugin extends BaseTelemetryPlugin {
  public constructor(private appCustomDimensions: ISBClientTelemetryCustomProperties) {
    super();
  }

  public processTelemetry(env: ITelemetryItem, itemCtx?: IProcessTelemetryContext): void {
    const store = SBSReduxStore.getState();
    const customDimensions: Record<string, string> = {
      ...env.data,
      ...this.appCustomDimensions,
      SelectedSpace: JSON.stringify(this.getSpaceCustomDimensions(store.space))
    };

    if (electronService.isElectron()) {
      customDimensions.KioskInfo = JSON.stringify(this.getKioskCustomDimensions(store.deviceConfig));
    }

    env.data = customDimensions;
    this.processNext(env, itemCtx || ({} as IProcessTelemetryContext));
  }

  private getSpaceCustomDimensions(spaceStore: ISpaceStore): Record<string, string> {
    const building = spaceStore.buildings[spaceStore.buildingId];
    const floor = building?.floors?.find((f) => f.id === spaceStore.selectedFloorId);
    const output: Record<string, string> = {
      BuildingId: spaceStore.buildingId,
      FloorId: spaceStore.selectedFloorId
    };

    if (building) output["BuildingName"] = building.name;
    if (floor) output["FloorName"] = floor.name;
    if (spaceStore.selectedRoomId) output["RoomId"] = spaceStore.selectedRoomId;

    return output;
  }

  private getKioskCustomDimensions(deviceConfigStore: IDeviceConfigStore): Record<string, string> {
    return {
      SpaceId: deviceConfigStore.spaceId || "",
      BuildingId: deviceConfigStore.buildingId || "",
      FloorId: deviceConfigStore.floorId || "",
      location: deviceConfigStore.location ? JSON.stringify(deviceConfigStore.location) : ""
    };
  }
}

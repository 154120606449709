export var EventType;
(function (EventType) {
    EventType["SensorType"] = "SensorType";
    EventType["SensorChange"] = "SensorChange";
    EventType["SpaceChange"] = "SpaceChange";
    EventType["Topology"] = "Topology";
    EventType["Telemetry"] = "Telemetry";
})(EventType || (EventType = {}));
export var EventSubtype;
(function (EventSubtype) {
    EventSubtype["SpaceUpdateEventSubtype"] = "SpaceUpdate";
    EventSubtype["SensorUpdateEventSubtype"] = "SensorUpdate";
})(EventSubtype || (EventSubtype = {}));
export var NotificationEventType;
(function (NotificationEventType) {
    NotificationEventType["ValueChangeEvent"] = "ValueChange";
    NotificationEventType["ValueDeleteEvent"] = "ValueDelete";
    NotificationEventType["HealthChangeEvent"] = "HealthChange";
    NotificationEventType["RequestTwinChangeEvent"] = "RequestStatusChange";
})(NotificationEventType || (NotificationEventType = {}));

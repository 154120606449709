import { PersonHasIncludes, UserSubTypes } from "@smartbuilding/adt-v2-types";
var PeopleSearchService = /** @class */ (function () {
    function PeopleSearchService(logger, userRepo) {
        this.logger = logger;
        this.userRepo = userRepo;
    }
    PeopleSearchService.prototype.getPeopleInBuilding = function (buildingId) {
        var _this = this;
        var filter = "buildingId eq '".concat(buildingId, "'");
        return this.userRepo
            .getUsers(filter, [PersonHasIncludes.None], UserSubTypes.Employee)
            .then(function (response) {
            var peopleNameAndId = {};
            response.forEach(function (user) {
                peopleNameAndId[user.properties.displayName] = user.dtId;
            });
            return peopleNameAndId;
        })
            .catch(function (err) {
            _this.logger.logError(new Error("[SearchService] Unable to get users for Search:" + err.message));
            new Error("Unable to get users");
            return Promise.reject(err);
        });
    };
    return PeopleSearchService;
}());
export { PeopleSearchService };

import moment from "moment";
var SmartBuildingApiRoomHttp = /** @class */ (function () {
    function SmartBuildingApiRoomHttp(httpService, smartBuildingAPI, logger) {
        this.httpService = httpService;
        this.smartBuildingAPI = smartBuildingAPI;
        this.logger = logger;
        this.serviceTag = "SmartBuildingApiRoomHttp";
        this.transformDataForAvailableSlot = function (meetingBookingSlots, noSameDayCheck) {
            var slotsBookableToday = [];
            meetingBookingSlots.forEach(function (currentSlot) {
                var slotStartMoment = moment(currentSlot.slotStartTime);
                var slotEndMoment = moment(currentSlot.slotEndTime);
                var isSameDay = noSameDayCheck ? true : moment().isSame(slotStartMoment, "d");
                if (currentSlot.canBookSlot && isSameDay) {
                    slotsBookableToday.push({
                        slotStartTime: slotStartMoment.format("h:mm a"),
                        slotEndTime: slotEndMoment.format("h:mm a"),
                        isBookable: currentSlot.canBookSlot,
                        slotDuration: slotEndMoment.diff(slotStartMoment, "minutes").toString(),
                        startTimeUtc: slotStartMoment.valueOf(),
                        endTimeUtc: slotEndMoment.valueOf()
                    });
                }
            });
            return slotsBookableToday;
        };
    }
    SmartBuildingApiRoomHttp.prototype.cancelReservation = function (eventId, confRoomAlias) {
        var _this = this;
        if (eventId && confRoomAlias) {
            var model = {
                message: "Reservation Cancel By Smart Building Service",
                eventId: eventId
            };
            return this.httpService
                .post("".concat(this.smartBuildingAPI, "/api/v1.0/rooms/CancelReservation/").concat(confRoomAlias), model)
                .then(function (_a) {
                var data = _a.data;
                return data;
            })
                .catch(function (error) {
                var errorMessage = typeof error.message === "string"
                    ? error.message
                    : "[".concat(_this.serviceTag, "-CancelReservation] can't cancel the reservation for the following room: ").concat(confRoomAlias);
                var errorProperties = {
                    AppErrorMessage: errorMessage
                };
                _this.logger.logError(new Error("".concat(errorMessage)), errorProperties);
                return Promise.reject(error);
            });
        }
        else
            return Promise.reject(new Error("[".concat(this.serviceTag, "-CancelReservation] Undefined arguments - eventId: ").concat(eventId, ", confRoomAlias: ").concat(confRoomAlias)));
    };
    SmartBuildingApiRoomHttp.prototype.reserveRoom = function (buildingName, spaceName, confRoomAlias, startTime, endTime, userAlias, subject, body, attendees) {
        var _this = this;
        attendees.push("".concat(userAlias, "@microsoft.com"));
        var bookingData = {
            buildingName: buildingName,
            spaceName: spaceName,
            attendees: attendees,
            body: body,
            endTime: endTime,
            startTime: startTime,
            subject: subject
        };
        if (confRoomAlias) {
            return this.httpService
                .post("".concat(this.smartBuildingAPI, "/api/v1.0/rooms/").concat(confRoomAlias, "/ScheduleMeeting"), bookingData)
                .then(function (val) { return val.data; })
                .catch(function (error) {
                var errorMessage = typeof error.message === "string"
                    ? error.message
                    : "[".concat(_this.serviceTag, "-ReserveRoom] can't make reservation for the following room: ").concat(confRoomAlias);
                var errorProperties = {
                    AppErrorMessage: errorMessage
                };
                _this.logger.logError(new Error("".concat(errorMessage)), errorProperties);
                return Promise.reject(error);
            });
        }
        else
            return Promise.reject(new Error("[".concat(this.serviceTag, "-ReserveRoom] Undefined Arguments - confRoomAlias: ").concat(confRoomAlias)));
    };
    SmartBuildingApiRoomHttp.prototype.getAvailableTimeSlots = function (confRoomAlias, numberOfSlots, noSameDayCheck) {
        var _this = this;
        if (confRoomAlias) {
            return this.httpService
                .get("".concat(this.smartBuildingAPI, "/api/v1.0/rooms/").concat(confRoomAlias, "/timeslots"), {
                params: {
                    roomAlias: confRoomAlias,
                    numberOfSlots: numberOfSlots
                }
            })
                .then(function (_a) {
                var data = _a.data;
                return data;
            })
                .then(function (data) { return _this.transformDataForAvailableSlot(data, noSameDayCheck); })
                .catch(function (error) {
                var errorMessage = typeof error.message === "string"
                    ? error.message
                    : "[".concat(_this.serviceTag, "-GetAvailableTimeSlots] can't retrieve available slots for the following room: ").concat(confRoomAlias);
                var errorProperties = {
                    AppErrorMessage: errorMessage
                };
                _this.logger.logError(new Error("".concat(_this.serviceTag, ": ").concat(errorMessage)), errorProperties);
                return Promise.reject(error);
            });
        }
        else {
            return Promise.reject(new Error("[".concat(this.serviceTag, "-GetAvailableTimeSlots] Undefined Arguments - confRoomAlias: ").concat(confRoomAlias)));
        }
    };
    SmartBuildingApiRoomHttp.prototype.getAvailableRooms = function (confAliasList, startTime, endTime) {
        var _this = this;
        if (confAliasList !== undefined &&
            confAliasList.length > 0 &&
            startTime > 0 &&
            endTime > 0 &&
            endTime > startTime) {
            var timeFilterRoomModel = {
                startTime: startTime,
                endTime: endTime,
                confAliasList: confAliasList
            };
            return this.httpService
                .post("".concat(this.smartBuildingAPI, "/api/v1.0/rooms/GetAvailableRooms"), timeFilterRoomModel)
                .then(function (val) { return val.data; })
                .catch(function (error) {
                var errorMessage = typeof error.message === "string"
                    ? error.message
                    : "[".concat(_this.serviceTag, "-GetAvailableRooms] can't get available rooms for the rooms: ").concat(JSON.stringify(confAliasList), " at the startTime: ").concat(startTime, ", endTime: ").concat(endTime, ".");
                var errorProperties = {
                    AppErrorMessage: errorMessage
                };
                _this.logger.logError(new Error("".concat(errorMessage)), errorProperties);
                return Promise.reject(error);
            });
        }
        else {
            return Promise.reject(new Error("[".concat(this.serviceTag, "-GetAvailableRooms] Invalid Arguments: confAliasList - ").concat(confAliasList, ", startTime - ").concat(startTime, ", endTime - ").concat(endTime)));
        }
    };
    return SmartBuildingApiRoomHttp;
}());
export { SmartBuildingApiRoomHttp };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var DirectionsHttp = /** @class */ (function () {
    /**
     * Directions Http Client that makes API requests to the Wayfinding API for directions and supports endpoints.
     * @param httpClient Http client that can make authenticated API calls to Wayfinding API
     * @param baseUrl Base server url for wayfinding api. Do not include api endpoint. Ex. https://directions.azurewebsite.net/
     */
    function DirectionsHttp(httpClient, baseUrl) {
        this.httpClient = httpClient;
        this.serviceTag = "[Directions Http]";
        this.directionsUrl = "".concat(baseUrl, "/api/v2.0/wayfinding/directions");
        this.supportsUrl = "".concat(baseUrl, "/api/v2.0/wayfinding/supports");
        this.spacePropertiesUrl = "".concat(baseUrl, "/api/v2.0/wayfinding/spaceproperties");
    }
    DirectionsHttp.prototype.getRoute = function (sourceSpaceId, destinationSpaceId, traversalSpaceType, traversalSubtype, sourceCoordinate, destinationCoordinate) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = "".concat(this.directionsUrl);
                        if (!sourceSpaceId || !destinationSpaceId) {
                            throw new Error("".concat(this.serviceTag, " Destination and source id cannot be null or undefined"));
                        }
                        request += "?sourceSpaceId=".concat(sourceSpaceId, "&destinationSpaceId=").concat(destinationSpaceId);
                        if (sourceCoordinate) {
                            request += "&sourceCoordinate=" + JSON.stringify(sourceCoordinate);
                        }
                        if (destinationCoordinate) {
                            request += "&destinationCoordinate=" + JSON.stringify(destinationCoordinate);
                        }
                        request += "&preferredTraversalSpaceType=".concat(traversalSpaceType, "&preferredTraversalSpaceSubtype=").concat(traversalSubtype);
                        return [4 /*yield*/, this.httpClient
                                .get(request)
                                .then(function (_a) {
                                var data = _a.data;
                                return data;
                            })
                                .catch(function (error) {
                                return Promise.reject(new Error("".concat(_this.serviceTag, " Have errors to request for RouteDataModel")));
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DirectionsHttp.prototype.isWayfindingSupported = function (floorId) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!floorId) {
                            throw new Error("".concat(this.serviceTag, " Floor id cannot be null"));
                        }
                        request = "".concat(this.supportsUrl, "/").concat(floorId);
                        return [4 /*yield*/, this.httpClient.get(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    DirectionsHttp.prototype.getSpaceProperties = function (buildingId) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!buildingId) {
                            throw new Error("".concat(this.serviceTag, " Space id cannot be null"));
                        }
                        return [4 /*yield*/, this.httpClient.get("".concat(this.spacePropertiesUrl, "/").concat(buildingId))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    return DirectionsHttp;
}());
export { DirectionsHttp };

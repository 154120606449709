import {
  IMapFeaturesOverlayBaseProps,
  IMapFeaturesOverlayStyleProps,
  IMapFeaturesOverlayStyles
} from "./MapFeaturesOverlay.Types";
import React, { useContext } from "react";
import { getIsMenuPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { MapControl } from "../MapControl/MapControl";
import { MenuPanel } from "../../MenuPanel/MenuPanel";
import { ThemeContext } from "../../../theme-provider/ThemeProvider";
import { classNamesFunction } from "@uifabric/utilities";
import { useSelector } from "react-redux";

const getClassNames = classNamesFunction<IMapFeaturesOverlayStyleProps, IMapFeaturesOverlayStyles>();

export function MapFeaturesOverlayBase(props: IMapFeaturesOverlayBaseProps): JSX.Element {
  const { theme } = useContext(ThemeContext);
  const isZoomed = useSelector(getIsZoomed);
  const isMenuPanelOpen = useSelector(getIsMenuPanelOpen);

  const classNames = getClassNames(props.styles, {
    theme,
    isZoomed,
    isMenuPanelOpen
  });

  return (
    <div data-testid="mapFeaturesOverlay" className={`${classNames.root} ${isZoomed ? classNames.zoomStyles : ""}`}>
      <MenuPanel />
      <MapControl />
    </div>
  );
}

import { data } from "azure-maps-control";
function getFeatureFromStop(stop) {
    return new data.Feature(new data.Point([stop.waypoint.longitude, stop.waypoint.latitude]));
}
export function getFeaturesFromRouteLeg(routeLeg) {
    return routeLeg.fromToPair.map(function (stop) { return getFeatureFromStop(stop); });
}
export function getUniqueStopsFromRouteLegs(routeLegs) {
    var pinStops = routeLegs.map(function (routeLeg) { return routeLeg.fromToPair.flat(); }).flat();
    var pickupOrDropoffStops = pinStops.filter(function (s) { return s.isPickup || s.isDropoff; });
    var uniqueStops = new Map();
    for (var _i = 0, pickupOrDropoffStops_1 = pickupOrDropoffStops; _i < pickupOrDropoffStops_1.length; _i++) {
        var stop_1 = pickupOrDropoffStops_1[_i];
        if (!uniqueStops.has(stop_1.id)) {
            uniqueStops.set(stop_1.id, stop_1);
        }
    }
    return Array.from(uniqueStops.values());
}

import { imageMap, imgDir } from "./MrcdpModels";
var LocalRoomImageService = /** @class */ (function () {
    function LocalRoomImageService() {
    }
    LocalRoomImageService.prototype.getRoomImageUrl = function (dtSpaceId) {
        dtSpaceId = dtSpaceId.toLocaleLowerCase();
        var imageUrl = imageMap[dtSpaceId];
        var finalImageUrl = "";
        if (imageUrl) {
            finalImageUrl = imgDir + imageUrl;
        }
        return Promise.resolve(finalImageUrl);
    };
    return LocalRoomImageService;
}());
export { LocalRoomImageService };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
var LogService = /** @class */ (function () {
    function LogService(instrumentationKey, plugins, 
    // Adjusting to the appropriate typing for appinsights
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pluginConfig) {
        this.appInsightsClient = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: plugins,
                extensionConfig: pluginConfig
            }
        });
        this.appInsightsClient.loadAppInsights();
        this.appInsightsClient.trackPageView();
    }
    LogService.prototype.logError = function (error, properties) {
        try {
            return this.appInsightsClient.trackException({
                error: error,
                severityLevel: SeverityLevel.Error,
                properties: properties
            });
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.logTrace = function (message, properties) {
        try {
            return this.appInsightsClient.trackTrace({
                message: message,
                severityLevel: SeverityLevel.Information,
                properties: properties
            });
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.logEvent = function (name, properties) {
        try {
            return this.appInsightsClient.trackEvent({
                name: name,
                properties: properties
            });
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.logWarning = function (message, properties) {
        try {
            return this.appInsightsClient.trackTrace({
                message: message,
                severityLevel: SeverityLevel.Warning,
                properties: properties
            });
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.startTrackingPage = function (name) {
        try {
            return this.appInsightsClient.trackPageView({ name: name });
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.stopTrackingPage = function (name, url, properties) {
        try {
            return this.appInsightsClient.stopTrackPage(name, url, properties);
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.trackMetric = function (name, average, measurementCount, smallestMeasurement, largestMeasurement, properties) {
        try {
            var metric = {
                name: name,
                average: average,
                sampleCount: measurementCount,
                max: largestMeasurement,
                min: smallestMeasurement,
                properties: properties
            };
            return this.appInsightsClient.trackMetric(metric);
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.trackDependencyData = function (name, serverRequestId, responseCode, absoluteUrl, success, requestDuration, requestMethod, properties) {
        try {
            var dependencyData = {
                id: serverRequestId,
                responseCode: responseCode,
                success: success,
                duration: requestDuration,
                type: requestMethod,
                name: name,
                target: absoluteUrl,
                properties: properties
            };
            return this.appInsightsClient.trackDependencyData(dependencyData);
        }
        catch (e) {
            return undefined;
        }
    };
    LogService.prototype.addErrorToProperties = function (properties, error) {
        return __assign(__assign({}, properties), { Error: JSON.stringify(error), ErrorMsg: error.message });
    };
    return LogService;
}());
export { LogService };

export var ExtendedPropertyKey;
(function (ExtendedPropertyKey) {
    ExtendedPropertyKey["AppDefaults"] = "AppDefaults";
    ExtendedPropertyKey["ConfRoomAlias"] = "ConfRoomAlias";
    ExtendedPropertyKey["ConfRoomSize"] = "ConfRoomSize";
    ExtendedPropertyKey["ElevatorType"] = "ElevatorType";
    ExtendedPropertyKey["EnabledFeature"] = "EnabledFeature";
    ExtendedPropertyKey["FacilityManagerSpaces"] = "FacilityManagerSpaces";
    ExtendedPropertyKey["HeatmapEnabled"] = "HeatMapEnabled";
    ExtendedPropertyKey["KioskLocation"] = "KioskLocation";
    ExtendedPropertyKey["MeridianMapNorthOffset"] = "MeridianMapNorthOffset";
    ExtendedPropertyKey["MeridianMapid"] = "MeridianMapId";
    ExtendedPropertyKey["MeridianOffset"] = "MeridianConversionValues";
    ExtendedPropertyKey["NeighborhoodNamedLabels"] = "NeighborhoodNamedLabels";
    ExtendedPropertyKey["NeighborhoodTeamName"] = "NeighborhoodTeamName";
    ExtendedPropertyKey["PrivacyLevel"] = "PrivacyLevel";
    ExtendedPropertyKey["RoomCapabilities"] = "RoomCapabilities";
    ExtendedPropertyKey["RoomKey"] = "RoomKey";
    ExtendedPropertyKey["RoomState"] = "RoomState";
    ExtendedPropertyKey["SensorPosition"] = "SensorPosition";
    ExtendedPropertyKey["StairwellType"] = "StairwellType";
    ExtendedPropertyKey["SuggestedServiceTimes"] = "SuggestedServiceTimes";
    ExtendedPropertyKey["WayfindingEnabled"] = "WayfindingEnabled";
    ExtendedPropertyKey["WorkspaceEnabled"] = "WorkSpaceEnabled";
})(ExtendedPropertyKey || (ExtendedPropertyKey = {}));

import { HubEvent, RetryPolicy } from "@dw/signalr-client";
import { NotificationEventType } from "./NotificationEvent";
import { SensorValueCache, SpaceSensorDataItemMap } from "@smartbuilding/space-sensors";
import { HubConnectionState } from "@microsoft/signalr";
import { SensorDataType } from "@smartbuilding/management-api-types";
import Timeout from "await-timeout";
var SpaceSubscriptionService = /** @class */ (function () {
    function SpaceSubscriptionService(logger, durableConnectionBuilder, authClient, spaceSubscriptionHttpService, hubConfig, signalRConnectionLoggingEnabled) {
        if (signalRConnectionLoggingEnabled === void 0) { signalRConnectionLoggingEnabled = true; }
        var _this = this;
        this.logger = logger;
        this.durableConnectionBuilder = durableConnectionBuilder;
        this.authClient = authClient;
        this.spaceSubscriptionHttpService = spaceSubscriptionHttpService;
        this.signalRConnectionLoggingEnabled = signalRConnectionLoggingEnabled;
        this.serviceTag = "[Space.Subscription.Service]";
        this.sensorValueCache = new SensorValueCache();
        this.requestQueue = [];
        this.onSensorValue = function (spaceUpdate) {
            var valueChangeObject = {
                currentValue: null,
                eventTimestamp: "",
                id: "",
                previousValue: null,
                unitType: "",
                properties: {},
                valueType: SensorDataType.ConferenceStatus
            };
            if (spaceUpdate.eventType === NotificationEventType.ValueChangeEvent) {
                valueChangeObject = spaceUpdate.messageBody;
            }
            var sensorDataUpdate = _this.sensorValueCache.setSensorValue(valueChangeObject.id, valueChangeObject.valueType, valueChangeObject.currentValue, new Date(valueChangeObject.eventTimestamp));
            if (sensorDataUpdate) {
                var signalREndTimeStamp = new Date(Date.now());
                _this.logger.logEvent("".concat(_this.serviceTag, " received space sensor value updated"), {
                    spaceUpdateId: valueChangeObject.id,
                    spaceUpdateValueType: valueChangeObject.valueType,
                    spaceUpdateValue: valueChangeObject.currentValue,
                    spaceUpdateEventTimestamp: valueChangeObject.eventTimestamp,
                    signalREndTimeStamp: signalREndTimeStamp.toString()
                });
                var sensorValueSubscribers = _this.subscriberMap.get(valueChangeObject.id, valueChangeObject.valueType);
                for (var _i = 0, sensorValueSubscribers_1 = sensorValueSubscribers; _i < sensorValueSubscribers_1.length; _i++) {
                    var sensorValueSubscriber = sensorValueSubscribers_1[_i];
                    sensorValueSubscriber.onSensorValue(valueChangeObject.id, valueChangeObject.valueType, valueChangeObject.currentValue, new Date(valueChangeObject.eventTimestamp));
                }
            }
        };
        this.onStateChange = function (state) {
            if (_this.signalRConnectionLoggingEnabled) {
                _this.logger.logEvent("".concat(_this.serviceTag, " connection state changed"), {
                    SignalRConnected: state === HubConnectionState.Connected ? "true" : "false"
                });
            }
            if (state === HubConnectionState.Connected) {
                _this.onConnected();
            }
        };
        this.subscriberMap = new SpaceSensorDataItemMap();
        var hubUrl = "".concat(hubConfig.url).concat(hubConfig.hubName);
        var options = {
            hubUrl: hubUrl,
            retryPolicy: RetryPolicy.infinite
        };
        options.tokenFactory = function () {
            if (signalRConnectionLoggingEnabled) {
                _this.logger.logEvent("".concat(_this.serviceTag, " signalr connection requested token"));
            }
            return _this.authClient.getToken("".concat(hubConfig.tokenEndpointUrl));
        };
        this.connection = this.durableConnectionBuilder.buildConnection(options);
        this.connection.bindOnStateChangeHandler(this.onStateChange);
        this.connection.on(HubEvent.onNotificationEvent, this.onSensorValue);
        this.connection.start();
    }
    SpaceSubscriptionService.prototype.groupSubscribeToSensorValues = function (spaceIds, sensorDataTypes, sensorReader) {
        var spacesToSubscribeTo = [];
        if (spaceIds.length > 0) {
            for (var _i = 0, spaceIds_1 = spaceIds; _i < spaceIds_1.length; _i++) {
                var spaceId = spaceIds_1[_i];
                for (var _a = 0, sensorDataTypes_1 = sensorDataTypes; _a < sensorDataTypes_1.length; _a++) {
                    var sensorType = sensorDataTypes_1[_a];
                    if (spaceId) {
                        this.subscriberMap.add(spaceId, sensorType, sensorReader);
                        spacesToSubscribeTo.push("".concat(NotificationEventType.ValueChangeEvent, "_").concat(spaceId, "_").concat(sensorType));
                    }
                }
            }
        }
        return this.spaceSubscriptionHttpService.groupSubscribeToSpaces(spacesToSubscribeTo);
    };
    SpaceSubscriptionService.prototype.groupUnsubscribeToSensorValues = function (spaceIds, sensorDataTypes, sensorReader) {
        if (!spaceIds) {
            this.flushRequestQueue();
        }
        var spacesToUnSubscribeTo = [];
        if (spaceIds.length > 0 && sensorDataTypes.length > 0) {
            for (var _i = 0, spaceIds_2 = spaceIds; _i < spaceIds_2.length; _i++) {
                var spaceId = spaceIds_2[_i];
                for (var _a = 0, sensorDataTypes_2 = sensorDataTypes; _a < sensorDataTypes_2.length; _a++) {
                    var sensorType = sensorDataTypes_2[_a];
                    if (spaceId) {
                        this.subscriberMap.remove(spaceId, sensorType, sensorReader);
                        spacesToUnSubscribeTo.push("".concat(NotificationEventType.ValueChangeEvent, "_").concat(spaceId, "_").concat(sensorType));
                    }
                }
            }
        }
        return this.spaceSubscriptionHttpService.groupUnsubscribeToSpaces(spacesToUnSubscribeTo);
    };
    SpaceSubscriptionService.prototype.subscribeToSensorValue = function (spaceId, sensorDataType, sensorReader) {
        var _this = this;
        if (!spaceId) {
            throw new Error("".concat(this.serviceTag, ": subscribeToSensorValue -> spaceId must be provided"));
        }
        if (!sensorDataType) {
            throw new Error("".concat(this.serviceTag, ": subscribeToSensorValue -> sensorDataType must be provided"));
        }
        if (!sensorReader) {
            throw new Error("".concat(this.serviceTag, ": subscribeToSensorValue -> sensorReader must be provided"));
        }
        if (!this.subscriberMap.hasSensorDataTypeItems(spaceId, sensorDataType)) {
            this.requestQueue.push({
                sensorDataType: sensorDataType,
                spaceId: spaceId,
                type: HubEvent.subscribe
            });
        }
        this.subscriberMap.add(spaceId, sensorDataType, sensorReader);
        return new Timeout().set(0).then(function () { return _this.flushRequestQueue(); });
    };
    SpaceSubscriptionService.prototype.unsubscribeFromSensorValue = function (spaceId, sensorDataType, sensorReader) {
        var _this = this;
        if (this.subscriberMap.remove(spaceId, sensorDataType, sensorReader)) {
            this.requestQueue.push({
                sensorDataType: sensorDataType,
                spaceId: spaceId,
                type: HubEvent.unsubscribe
            });
        }
        return new Timeout().set(0).then(function () { return _this.flushRequestQueue(); });
    };
    SpaceSubscriptionService.prototype.getSensorValue = function (spaceId, sensorDataType) {
        return this.sensorValueCache.getSensorValue(spaceId, sensorDataType);
    };
    SpaceSubscriptionService.prototype.getSensorTimeStamp = function (spaceId, sensorDataType) {
        return this.sensorValueCache.getSensorTimeStamp(spaceId, sensorDataType);
    };
    SpaceSubscriptionService.prototype.hasSensorValue = function (spaceId, sensorDataType) {
        return this.sensorValueCache.hasSensorValue(spaceId, sensorDataType);
    };
    SpaceSubscriptionService.prototype.sendUnsubscribe = function (spaceGroups) {
        var _this = this;
        if (!spaceGroups || !Array.isArray(spaceGroups)) {
            throw new Error("".concat(this.serviceTag, ": invalid argument exception"));
        }
        if (spaceGroups.length < 1) {
            return Promise.resolve();
        }
        return Promise.resolve(this.connection.sendAsync(HubEvent.unsubscribe, spaceGroups)).catch(function (error) {
            var props = {};
            if (error && !error.stack && !error.message) {
                props = _this.logger.addErrorToProperties(props, error);
                error = new Error("".concat(_this.serviceTag, ": error unsubscribing from space"));
            }
            _this.logger.logError(error, props);
            return Promise.reject(error);
        });
    };
    SpaceSubscriptionService.prototype.flushRequestQueue = function () {
        var _a;
        var _this = this;
        if (this.requestQueue.length === 0) {
            return Promise.resolve();
        }
        var spaceGroupRequests = (_a = {},
            _a[HubEvent.subscribe] = {
                spaceSensorsMap: {},
                spaceSensors: []
            },
            _a[HubEvent.unsubscribe] = {
                spaceSensorsMap: {},
                spaceSensors: []
            },
            _a);
        for (var _i = 0, _b = this.requestQueue; _i < _b.length; _i++) {
            var request = _b[_i];
            var requestGroup = spaceGroupRequests[request.type];
            var spaceGroup = requestGroup.spaceSensorsMap[request.sensorDataType];
            if (!spaceGroup) {
                spaceGroup = "".concat(NotificationEventType.ValueChangeEvent, "_").concat(request.spaceId, "_").concat(request.sensorDataType);
                requestGroup.spaceSensorsMap[request.sensorDataType] = spaceGroup;
                requestGroup.spaceSensors.push(spaceGroup);
            }
        }
        // Empty the request queue
        var backupRequestQueue = this.requestQueue;
        this.requestQueue = [];
        // Subscribe to all space groups
        var sub = spaceGroupRequests[HubEvent.subscribe];
        var unsub = spaceGroupRequests[HubEvent.unsubscribe];
        var subscribeRequest = sub.spaceSensors.length > 0
            ? Promise.resolve(this.connection.sendAsync(HubEvent.subscribe, sub.spaceSensors))
            : Promise.resolve();
        var unsubRequest = unsub.spaceSensors.length > 0 ? this.sendUnsubscribe(unsub.spaceSensors) : Promise.resolve();
        return Promise.all([subscribeRequest, unsubRequest]).catch(function (error) {
            var _a;
            var props = {};
            if (error && !error.stack && !error.message) {
                props = _this.logger.addErrorToProperties(props, error);
                error = new Error("".concat(_this.serviceTag, ": error flushing request queue"));
            }
            _this.logger.logError(error, props);
            // We failed to properly subscribe to all spaces. Put ALL of them back in the request queue.
            (_a = _this.requestQueue).push.apply(_a, backupRequestQueue);
        });
    };
    SpaceSubscriptionService.prototype.onConnected = function () {
        this.rebuildRequestQueue();
        this.flushRequestQueue();
    };
    SpaceSubscriptionService.prototype.rebuildRequestQueue = function () {
        var subscribedSpacesAndDataTypes = this.subscriberMap.getAllSpacesAndDataTypesWithItems();
        for (var _i = 0, subscribedSpacesAndDataTypes_1 = subscribedSpacesAndDataTypes; _i < subscribedSpacesAndDataTypes_1.length; _i++) {
            var space = subscribedSpacesAndDataTypes_1[_i];
            for (var _a = 0, _b = space.sensorDataTypes; _a < _b.length; _a++) {
                var dataType = _b[_a];
                this.requestQueue.push({
                    sensorDataType: dataType,
                    spaceId: space.spaceId,
                    type: HubEvent.subscribe
                });
            }
        }
    };
    return SpaceSubscriptionService;
}());
export { SpaceSubscriptionService };

import { IError, IErrorState } from "../Reducers/ErrorReducer";
import { RootState } from "../Reducers/RootReducer";
import { createSelector } from "reselect";

const getErrors = (store: RootState): IErrorState => store.errors;

export const getMapError = createSelector([getErrors], (errors: IErrorState): IError | null => errors.mapError);
export const getSagaError = createSelector([getErrors], (errors: IErrorState): IError | null => errors.sagaError);

export const getHttpError = createSelector([getErrors], (errors: IErrorState): IError | null => errors.httpError);

export const getBootstrapperError = createSelector(
  [getErrors],
  (errors: IErrorState): IError | null => errors.bootstrapperError
);

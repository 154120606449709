import { data } from "azure-maps-control";
export function getInitialRouteFloor(routeData) {
    return routeData.route[0].floor;
}
export function getDestinationFloor(routeData) {
    var route = getDestinationRoute(routeData);
    return route.floor;
}
export function getDestinationRoute(routeData) {
    var route = routeData.route.find(function (r) {
        return !r.segments[r.segments.length - 1].continuationFloor ||
            r.segments[r.segments.length - 1].continuationFloor === r.floor;
    });
    if (!route)
        throw new Error("Unable to retrieve destination route from route data");
    return route;
}
export function getRouteContinuationFloor(routeData, floor, currentRoute) {
    var _a;
    var route = routeData.route.find(function (r) { return r.floor === floor; });
    var currentRouteData = currentRoute && currentRoute.floor === floor ? currentRoute : route;
    var continuationFloor = (_a = currentRouteData === null || currentRouteData === void 0 ? void 0 : currentRouteData.segments[currentRouteData.segments.length - 1].continuationFloor) !== null && _a !== void 0 ? _a : getDestinationFloor(routeData);
    if (!continuationFloor)
        throw new Error("Unable to retrieve continuation floor from route data");
    return continuationFloor;
}
export function getRoutePositions(route, showDest) {
    var positions = [];
    var segments = [];
    if (showDest) {
        for (var i = route.segments.length - 1; i >= 0; i--) {
            var segment = route.segments[i];
            var nextSegment = route.segments[i - 1];
            positions.unshift(new data.Position(segment.lng, segment.lat));
            segments.unshift(segment);
            if (nextSegment && nextSegment.continuationFloor)
                break;
        }
    }
    else {
        for (var _i = 0, _a = route.segments; _i < _a.length; _i++) {
            var segment = _a[_i];
            positions.push(new data.Position(segment.lng, segment.lat));
            segments.push(segment);
            if (segment.continuationFloor)
                break;
        }
    }
    return {
        positions: positions,
        segments: segments
    };
}
export function getFinalSegmentPosition(route) {
    var finalSegment = route.segments[route.segments.length - 1];
    return new data.Position(finalSegment.lng, finalSegment.lat);
}
export function getInitialSegmentPosition(route) {
    var initialSegment = route.segments[0];
    return new data.Position(initialSegment.lng, initialSegment.lat);
}
export function isMultiFloorRoute(routeData) {
    return routeData.route.length > 1;
}
export function getContinuationFloor(route) {
    return route.segments[route.segments.length - 1].continuationFloor;
}
export function getPopupOptions(baseMarker, continuationFloor, position, zoomLevel) {
    // Linear scale factor depending on the maximum zoom level, which is 24.
    // https://docs.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraoptions?view=azure-maps-typescript-latest#maxZoom
    var scaleFactor = zoomLevel ? (1 / 24) * zoomLevel : 1;
    var height = 28 * scaleFactor;
    return {
        closeButton: false,
        content: "<div id='marker-popup' style=\"height:".concat(height, "px\" onclick='popupCallback()'><span>To Floor ").concat(continuationFloor, "</span></div>"),
        showPointer: false,
        draggable: false,
        position: position,
        pixelOffset: [0, (baseMarker.heightOffset + height + 13) * scaleFactor * -1]
    };
}

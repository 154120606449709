import {
  DeepLinkDataIdRetrieveAction,
  PathFindingAction,
  PathFindingRetrieveActions,
  PathFindingSelectionActions,
  PathRetrievedAction,
  RetrievingPathAction
} from "../Actions";
import { IPathFindingStore, generateMyHubUrlMapKey, generatePathMetadataKey } from "../Types";

export const initialState: IPathFindingStore = {
  pathMetadata: {},
  wayFindingSupportedStatus: {},
  selectedPath: undefined,
  mobileHandOffMap: {}
};

export function pathFindingReducer(state = initialState, action: PathFindingAction): IPathFindingStore {
  switch (action.type) {
    case PathFindingRetrieveActions.PATH_RETRIEVED:
      return handlePathRetrieved(state, action);
    case PathFindingRetrieveActions.RETRIEVING_PATH:
      return insertRetrieving(state, action);
    case PathFindingSelectionActions.SELECT_PATH:
      return { ...state, selectedPath: action.payload };
    case PathFindingSelectionActions.CLEAR_PATH_SELECTION:
      return { ...state, selectedPath: undefined };
    case PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED_RETRIEVED:
      return {
        ...state,
        wayFindingSupportedStatus: {
          ...state.wayFindingSupportedStatus,
          [action.payload.floorId]: action.payload.isWayFindingSupportedStatus
        }
      };
    case PathFindingRetrieveActions.DEEP_LINK_DATA_ID_RETRIEVED:
      return addUrlToMap(state, action);
    default:
      return state;
  }
}

function insertRetrieving(state: IPathFindingStore, action: RetrievingPathAction): IPathFindingStore {
  const key = generatePathMetadataKey(action.payload);
  const pathfindingLoggerID = action.payload.pathfindingLoggerID;
  const routeRenderStartTime = action.payload.routeRenderStartTime;
  return {
    ...state,
    pathMetadata: {
      ...state.pathMetadata,
      [key]: {
        ...state.pathMetadata[key],
        retrieving: true,
        pathfindingLoggerID,
        routeRenderStartTime
      }
    }
  };
}

function handlePathRetrieved(state: IPathFindingStore, action: PathRetrievedAction): IPathFindingStore {
  const key = generatePathMetadataKey(action.payload.request);
  const error = action.error;
  const errorCode = action.errorCode;
  const routeData = action.payload.routeData;
  const qrCodeParams = action.payload.qrCodeParams;
  const pathfindingLoggerID = action.payload.request.pathfindingLoggerID;
  const routeRenderStartTime = action.payload.request.routeRenderStartTime;
  return {
    ...state,
    pathMetadata: {
      ...state.pathMetadata,
      [key]: {
        ...state.pathMetadata[key],
        routeData,
        error,
        errorCode,
        retrieving: false,
        pathfindingLoggerID,
        routeRenderStartTime,
        qrCodeParams
      }
    }
  };
}

function addUrlToMap(state: IPathFindingStore, action: DeepLinkDataIdRetrieveAction): IPathFindingStore {
  const key = generateMyHubUrlMapKey(action.payload.data);

  return {
    ...state,
    mobileHandOffMap: {
      ...state.mobileHandOffMap,
      [key]: action.payload.qrcodeUrl
    }
  };
}

import { makeStyles } from "@fluentui/react-make-styles";

export const usePersonCardStyles = makeStyles({
  personaContainer: () => ({
    marginTop: "15px"
  })
});

export const usePersonaStyles = makeStyles({
  primaryText: {
    height: "24px",
    marginBottom: "6px"
  }
});

import {
  AdtConverters,
  IAzureDigitalTwinV3SpaceRetrieve,
  IAzureDigitalTwinV3UserRetrieve
} from "@smartbuilding/utilities";
import {
  BuildingSelectedAction,
  InstantBookingRoomSelectedAction,
  RoomSelectedAction,
  SpaceSelectedActions
} from "../Actions";
import { CallEffect, all, call, put } from "redux-saga/effects";
import { Employee, Person, SmartUser, Space } from "@smartbuilding/adt-v2-types";
import { IAction, IBaseAction } from "../Actions/IAction";
import {
  IAutoCompleteProvider,
  IBadgeScanService,
  IPersonSuggestionData
} from "@smartbuilding/smartbuilding-api-service";
import { IDipResponse, dipSagas, getTwins } from "@dip/redux-sagas";
import { IElectronDeviceInfo, electronService } from "@smartbuilding/electron-service";
import { IPeopleService, IPerson } from "@smartbuilding/people-service";
import {
  IPersonBlobImageMap,
  ImageSize,
  PeopleCardMap,
  PeopleSuggestionMap,
  PersonBlobImageMap,
  SpacePersonIdMap,
  SpacePersonMap,
  SuggestionBlobImageMap
} from "../Types/IPeopleStore";
import {
  PeopleRetrieveActions,
  PeopleScannedActions,
  PeopleSuggestedActions,
  PersonScannedAction,
  RetrieveCurrentUserAction,
  RetrievePeopleAtSpaceAction,
  RetrievePeopleImagesAction,
  RetrievePeopleInBuildingAction,
  RetrievePeopleSuggestionsAction,
  RetrievePersonWithImgAction,
  RetrieveSuggestionsWithImageAction
} from "../Actions/PeopleActions";
import {
  getPeople,
  getPeopleCardMap,
  getPeopleSuggestionMap,
  getPersonImageMap,
  getSpacePersonIdMap
} from "../Selectors";
import {
  peopleAtSpaceRetrieved,
  peopleImagesRetrieved,
  peopleInBuildingRetrieved,
  peopleSuggestionsRetrieved,
  personCardDataRetrieved,
  personRetrieved,
  retrievePeopleAtSpace,
  retrievePeopleImages,
  retrievePeopleInBuilding,
  setBadgeScanStatus,
  storeCurrentUser,
  suggestionImageRetrieved
} from "../Actions/PeopleActionCreator";
import { select, takeEvery, takeLatest } from "@redux-saga/core/effects";

import { BookingErrorMessages } from "../../constants/error-constant";
import { ILogger } from "@smartbuilding/log-provider";
import { QueryBuilder } from "@dip/querybuilder";
import moment from "moment";

export class PeopleSaga {
  private personImageRetrieving: Record<ImageSize, Set<string>> = {
    [ImageSize.Small]: new Set<string>(),
    [ImageSize.Large]: new Set<string>()
  };
  private peopleInBuildingRetrieve: Set<string> = new Set();
  private personRetrieve: Set<string> = new Set();
  private suggestionsRetrieve: Set<string> = new Set();
  private peopleAtSpaceRetrieve: Set<string> = new Set();

  public constructor(
    private logger: ILogger,
    private peopleService: IPeopleService,
    private badgeScanService: IBadgeScanService,
    private autoCompleteService: IAutoCompleteProvider
  ) {
    this.watcher = this.watcher.bind(this);
    this.retrieveCurrentUserInfo = this.retrieveCurrentUserInfo.bind(this);
    this.retrievePeopleAtSpace = this.retrievePeopleAtSpace.bind(this);
    this.retrievePeopleImages = this.retrievePeopleImages.bind(this);
    this.retrievePeopleInBuilding = this.retrievePeopleInBuilding.bind(this);
    this.retrievePerson = this.retrievePerson.bind(this);
    this.getPersonImageBlobUrl = this.getPersonImageBlobUrl.bind(this);
    this.getPersonDipData = this.getPersonDipData.bind(this);
    this.handleBadgeScannedAction = this.handleBadgeScannedAction.bind(this);
    this.retrievePersonCardData = this.retrievePersonCardData.bind(this);
    this.handleAutoCompleteAction = this.handleAutoCompleteAction.bind(this);
    this.retrievePeopleSuggestions = this.retrievePeopleSuggestions.bind(this);
    this.retrieveSuggestionsWithImage = this.retrieveSuggestionsWithImage.bind(this);
  }

  public *watcher(): Generator {
    yield all([
      // TODO: Assess if this action is needed or we can do without.
      // takeEvery(PeopleRetrieveActions.RETRIEVE_PEOPLE_AT_SPACE, this.retrievePeopleAtSpace),
      takeEvery(PeopleRetrieveActions.RETRIEVE_CURRENT_USER, this.retrieveCurrentUserInfo),
      takeEvery(PeopleRetrieveActions.RETRIEVE_PEOPLE_IMAGES, this.retrievePeopleImages),
      takeEvery(PeopleRetrieveActions.RETRIEVE_PEOPLE_IN_BUILDING, this.retrievePeopleInBuilding),
      takeEvery(PeopleRetrieveActions.RETRIEVE_PERSON_WITH_IMG, this.retrievePerson),
      takeEvery(PeopleSuggestedActions.RETRIEVE_SUGGESTIONS_WITH_IMAGE, this.retrieveSuggestionsWithImage),

      // Listens to actions on space selection to fetch people information that will be needed for the rest of the app
      // 1. Retrieving the people in the building, when the building selection changes
      // 2. Retrieving the people at a given space, when room selection changes
      takeLatest(SpaceSelectedActions.BUILDING_SELECTED, this.handleBuildingSelection),
      takeLatest(SpaceSelectedActions.ROOM_SELECTED, this.handleRoomSelection),
      takeLatest(SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTED, this.handleRoomSelection),
      takeLatest(PeopleScannedActions.PERSON_BADGE_SCANNED, this.handleBadgeScannedAction),
      takeLatest(PeopleSuggestedActions.RETRIEVE_PEOPLE_SUGGESTIONS, this.handleAutoCompleteAction)
    ]);
  }

  private *retrieveCurrentUserInfo(action: RetrieveCurrentUserAction): Generator {
    const userEmail = action.payload.userEmail;
    const userName = action.payload.userName;
    const person = (yield call(this.getPersonDipData, userEmail)) as IPerson;
    const response = (yield call(this.getPersonImageBlobUrl, person, ImageSize.Large)) as {
      personId: string;
      image: string;
    };
    yield put(storeCurrentUser(userEmail, userName, response.image));
  }

  private *retrievePerson(action: RetrievePersonWithImgAction): Generator {
    //TODO: determine if used
    const upn = action.payload.upn;
    const size = action.payload.size;
    const errMsg = "Failed to retrieve person";
    try {
      if (this.personRetrieve.has(upn)) {
        return;
      }

      this.personRetrieve.add(upn);
      const person = (yield call(this.getPersonDipData, upn)) as IPerson;
      if (person) {
        yield put(personRetrieved(person));
        const response = (yield call(this.getPersonImageBlobUrl, person, size)) as {
          personId: string;
          image: string;
        };
        const blobImageMap: PersonBlobImageMap = { [response.personId]: response.image };
        yield put(peopleImagesRetrieved(blobImageMap, size));
        this.personRetrieve.delete(upn);
        return;
      }

      this.logActionError(action, errMsg);
    } catch (error) {
      this.logActionError(action, errMsg, error as Error);
      this.personRetrieve.delete(upn);
    }
  }

  private *retrieveSuggestionsWithImage(action: RetrieveSuggestionsWithImageAction): Generator {
    const upn = action.payload.upn;
    const size = action.payload.size;
    const errMsg = "Failed to retrieve suggestion image.";
    try {
      if (this.suggestionsRetrieve.has(upn)) {
        return;
      }

      this.suggestionsRetrieve.add(upn);
      const query = QueryBuilder.from(Employee).where((q) =>
        q.compare(Employee, (e) => e.userPrincipalName.equals(upn))
      );

      const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Employee[]>;
      const dipPersonData = AdtConverters.formatIntoSmartUser(response.data[0] as IAzureDigitalTwinV3UserRetrieve);
      const person = (yield call(
        [this.peopleService, this.peopleService.getPersonByUpn],
        upn,
        dipPersonData
      )) as IPerson;
      if (person) {
        yield put(personRetrieved(person));
        const response = (yield call(this.getPersonImageBlobUrl, person, size)) as {
          personId: string;
          image: string;
        };
        const suggestionImageMap: SuggestionBlobImageMap = { [upn]: response.image };

        yield put(suggestionImageRetrieved(suggestionImageMap, size));
        this.suggestionsRetrieve.delete(upn);
        return;
      }

      this.logActionError(action, errMsg);
    } catch (error) {
      this.logActionError(action, errMsg, error as Error);
      this.personRetrieve.delete(upn);
    }
  }

  private *retrievePeopleAtSpace(action: RetrievePeopleAtSpaceAction): Generator {
    // TODO: determine if used/has any value
    const spaceId = action.payload;
    const errMsg = "Failed to retrieve people at space";
    try {
      if (this.peopleAtSpaceRetrieve.has(spaceId)) {
        return;
      }
      this.peopleAtSpaceRetrieve.add(spaceId);
      const query = QueryBuilder.from(Space)
        .where((q) => q.compare(Space, (s) => s.$dtId.equals(spaceId)))
        .join(Space, Person, (s) => s.hasPeople)
        .addSelect(Person);
      const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Space[]>;
      if (response.data.length > 0) {
        const dipSpaceData = AdtConverters.formatIntoSmartSpace(response.data[0] as IAzureDigitalTwinV3SpaceRetrieve);
        const people = (yield call(
          [this.peopleService, this.peopleService.getPeopleInSpace],
          spaceId,
          dipSpaceData
        )) as IPerson[];
        if (people) {
          yield put(peopleAtSpaceRetrieved(spaceId, people));
          yield put(
            retrievePeopleImages(
              people.map((p) => p.dtId),
              ImageSize.Small
            )
          );
          this.peopleAtSpaceRetrieve.delete(spaceId);
        }
      }
    } catch (error) {
      this.logActionError(action, errMsg, error as Error);
      this.peopleAtSpaceRetrieve.delete(spaceId);
    }
  }

  private *retrievePeopleInBuilding(action: RetrievePeopleInBuildingAction): Generator {
    const buildingId = action.payload;
    const errMsg = "Failed to retrieve people in the building";
    try {
      if (this.peopleInBuildingRetrieve.has(buildingId)) {
        return;
      }

      this.peopleInBuildingRetrieve.add(buildingId);
      const query = QueryBuilder.from(Employee)
        .where((q) => q.compare(Employee, (e) => e.buildingId.equals(buildingId)))
        .join(Employee, Space, (e) => e.isInSpace)
        .addSelect(Space);
      const response = (yield dipSagas.get(getTwins(query, undefined, true))) as IDipResponse<Employee[]>;
      const employees: SmartUser[] = response.data.map((e) =>
        AdtConverters.formatIntoSmartUser(e as IAzureDigitalTwinV3UserRetrieve)
      );
      const people = (yield call(
        [this.peopleService, this.peopleService.getPeopleInBuildingGroupedBySpace],
        buildingId,
        employees
      )) as SpacePersonMap;

      if (people) {
        yield put(peopleInBuildingRetrieved(people));
        this.peopleInBuildingRetrieve.delete(buildingId);
        return;
      }

      this.logActionError(action, errMsg);
    } catch (error) {
      this.logActionError(action, errMsg, error as Error);
      this.peopleInBuildingRetrieve.delete(buildingId);
    }
  }

  private *retrievePeopleImages(action: RetrievePeopleImagesAction): Generator {
    try {
      const peopleIds = action.payload.peopleIds;
      const imageSize = action.payload.size;
      const blobImageMap: PersonBlobImageMap = {};
      const people = (yield select(getPeople)) as IPerson[];
      const peopleImages = ((yield select(getPersonImageMap)) as PersonBlobImageMap)[imageSize];
      const peopleImageIds = Object.keys(peopleImages);
      const peopleToRetrieveImagesFor = people.filter(
        (person) =>
          // Include the person if they are in the set of people images requested for
          peopleIds.includes(person.dtId) &&
          // Include the person if we have not already made a request for the their image
          !this.personImageRetrieving[imageSize].has(person.dtId) &&
          // Include the person if we don't have their image in the store already
          !peopleImageIds.includes(person.dtId)
      );

      const calls: CallEffect[] = [];
      for (const person of peopleToRetrieveImagesFor) {
        calls.push(call(this.getPersonImageBlobUrl, person, imageSize));
      }

      const responses = (yield all(calls)) as { personId: string; image: string }[];
      for (const response of responses) {
        blobImageMap[response.personId] = response.image;
      }

      yield put(peopleImagesRetrieved(blobImageMap, imageSize));
    } catch (error) {
      const errorMsg = "An error occurred while retrieve people images";
      this.logActionError(action, errorMsg, error as Error);
    }
  }

  private *handleBuildingSelection(action: BuildingSelectedAction): Generator {
    const buildingId = action.payload;
    const people = (yield select(getPeople)) as IPerson[];
    if (!people || !people.find((p) => p.buildingId === buildingId)) {
      yield put(retrievePeopleInBuilding(buildingId));
    }
  }

  private *handleRoomSelection(action: RoomSelectedAction | InstantBookingRoomSelectedAction): Generator {
    const isInstantBooking = typeof action.payload !== "string";
    const spaceId = !isInstantBooking
      ? (action as RoomSelectedAction).payload
      : (action as InstantBookingRoomSelectedAction).payload.roomId;
    const peopleList = ((yield select(getSpacePersonIdMap)) as SpacePersonIdMap)[spaceId];
    //dispatch action when we don't have peopleList for a given space
    if (peopleList === undefined) {
      yield put(retrievePeopleAtSpace(spaceId));
      return;
    }
    const personImageList: string[] = [];
    const peopleImages = ((yield select(getPersonImageMap)) as IPersonBlobImageMap)[ImageSize.Large];

    peopleList.forEach((index) => {
      if (peopleImages[index]) {
        personImageList.push(peopleImages[index]);
      }
    });

    //dispatch action when we have peopleList but we don't have their image
    if (!personImageList.length) {
      yield put(retrievePeopleImages(peopleList, ImageSize.Large));
      return;
    }
    //when we have peopleList and their images skip duplicate calls
    if (personImageList.length && peopleList.length) {
      return;
    }
  }

  private *handleBadgeScannedAction(action: PersonScannedAction): Generator {
    const cardKeyId = action.payload;
    const personCardData = ((yield select(getPeopleCardMap)) as PeopleCardMap)[cardKeyId];
    const electronInfo = (
      electronService.isElectron() ? yield call([electronService, electronService.getDeviceInfoAsync]) : null
    ) as IElectronDeviceInfo;

    if (personCardData) {
      this.logger.logEvent("PersonCardData already processed for the given cardId", {
        cardId: cardKeyId,
        kioskId: electronInfo?.hardwareId
      });
      return;
    }
    if (personCardData === undefined) {
      this.logger.logEvent("Start retrieving PersonCard data from BadgeScan API", {
        cardId: cardKeyId,
        kioskId: electronInfo?.hardwareId
      });
      yield call(this.retrievePersonCardData, cardKeyId, action);
    }
  }

  private *retrievePersonCardData(cardkeyNbr: string, action: PersonScannedAction): Generator {
    const cardKeyId = cardkeyNbr;
    const errMsg = "Failed to retrieve PersonCard data for a given CardKeyId from BadgeScanService";

    if (!cardKeyId) {
      this.logError("cardkey cannot be undefined");
    }
    const startTime = moment();
    try {
      const response = (yield call(
        [this.badgeScanService, this.badgeScanService.getPersonCardDataForGivenCardID],
        cardKeyId
      )) as PeopleCardMap;

      const personCardData = response[cardKeyId];

      //dispatch action when we don't have personCardData for a given cardKey
      if (personCardData) {
        yield put(setBadgeScanStatus(""));
        yield put(personCardDataRetrieved(cardKeyId, personCardData));
        return;
      } else {
        yield put(setBadgeScanStatus(BookingErrorMessages.ScanInputError));
        this.logError(action.type, new Error("Failed to retrieve employee data."));
      }
    } catch (error) {
      yield put(setBadgeScanStatus(BookingErrorMessages.ScanInputError));
      this.logError(`${errMsg}: ${cardKeyId}`, error as Error);
    } finally {
      this.logMetric("SmartBuildingAPI Badge Scan Latency", action, moment().diff(startTime, "milliseconds"));
    }
  }

  private *handleAutoCompleteAction(action: RetrievePeopleSuggestionsAction): Generator {
    const searchKey = action.payload;
    const suggestions = ((yield select(getPeopleSuggestionMap)) as PeopleSuggestionMap)[searchKey];
    if (suggestions) {
      this.logger.logEvent("Suggestions already processed for the given prefix", {
        prefix: searchKey
      });
      return;
    } else {
      yield call(this.retrievePeopleSuggestions, searchKey, action);
    }
  }

  private *retrievePeopleSuggestions(aliasInputValue: string, action: RetrievePeopleSuggestionsAction): Generator {
    const emptyResult: IPersonSuggestionData[] = [];
    const searchKey = aliasInputValue;
    const maxLength = 5;

    if (!searchKey) {
      this.logError("searchKey cannot be undefined");
    }

    const startTime = moment();
    try {
      const response = (yield call(
        [this.autoCompleteService, this.autoCompleteService.getSuggestionsListFromInputAliasPrefix],
        searchKey,
        maxLength,
        true
      )) as PeopleSuggestionMap;
      yield put(peopleSuggestionsRetrieved(aliasInputValue, response[aliasInputValue]));
      this.logger.logEvent("Suggestions successfully retrieved for the given prefix", {
        prefix: searchKey
      });
    } catch (error) {
      yield put(peopleSuggestionsRetrieved(aliasInputValue, emptyResult));
      this.logError(`Failed to retrieve auto complete suggestions for prefix: [${aliasInputValue}]`, error as Error);
    } finally {
      this.logMetric("SmartBuildingAPI People Suggestions Latency", action, moment().diff(startTime, "milliseconds"));
    }
  }

  private *getPersonImageBlobUrl(person: IPerson, imageSize: ImageSize): Generator {
    try {
      this.personImageRetrieving[imageSize].add(person.dtId);
      const imageUrl = yield call(
        [this.peopleService, this.peopleService.getPersonPhotoBlobUrl],
        person.userPrincipalName,
        imageSize
      );

      if (imageUrl) {
        this.personImageRetrieving[imageSize].delete(person.dtId);
        return { personId: person.dtId, image: imageUrl };
      }
    } catch (error) {
      this.logger.logTrace(`Failed to get image for user: ${person.dtId}`, error as Error);
      this.personImageRetrieving[imageSize].delete(person.dtId);
    }
    return { personId: "", image: undefined };
  }

  private *getPersonDipData(upn: string): Generator {
    const query = QueryBuilder.from(Employee).where((q) => q.compare(Employee, (e) => e.userPrincipalName.equals(upn)));

    const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Employee[]>;
    const dipPersonData = AdtConverters.formatIntoSmartUser(response.data[0] as IAzureDigitalTwinV3UserRetrieve);
    const person = (yield call([this.peopleService, this.peopleService.getPersonByUpn], upn, dipPersonData)) as IPerson;
    return person;
  }

  private logActionError<T, K>(action: IAction<T, K> | IBaseAction<T>, errorMsg: string, error?: Error): void {
    const err = error === undefined ? new Error(errorMsg) : error;
    this.logger.logError(err, {
      Action: JSON.stringify(action),
      Message: errorMsg
    });
  }

  private logError(errorMsg: string, error?: Error): void {
    const err = error === undefined ? new Error(errorMsg) : error;
    this.logger.logError(err, {
      Message: errorMsg
    });
  }

  private logMetric<T, K>(message: string, action: IAction<T, K>, latency: number): void {
    this.logger.trackMetric(`[${PeopleSaga.name}] ${message}`, latency, undefined, undefined, undefined, {
      Action: JSON.stringify(action)
    });
  }
}

import { UrlBuilder } from "@smartbuilding/web-request-api";
import { CustomProperties } from "../../commonUtils/CustomProperties";
import { extractTypeFromSpace } from "../../commonUtils/CommonADTFunctions";
import { isString } from "lodash";
var UserHttpService = /** @class */ (function () {
    function UserHttpService(dtdlApiUrl, logger, httpService) {
        var _this = this;
        this.dtdlApiUrl = dtdlApiUrl;
        this.logger = logger;
        this.httpService = httpService;
        this.serviceTag = "[User.Http.Service]";
        this.resourceBaseUrl = "v2.0/persons";
        this.urlBuilder = new UrlBuilder();
        // Any error is expected
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.logAndReject = function (error, url) {
            var _a;
            var errorMessage = isString(error) ? error : "error encountered during http request";
            var errorProperties = (_a = {},
                _a[CustomProperties.AppErrorMessage] = errorMessage,
                _a);
            if (url) {
                errorProperties[CustomProperties.RequestUrl] = url;
            }
            _this.logger.logError(new Error("".concat(_this.serviceTag, ": ").concat(errorMessage)), errorProperties);
            return Promise.reject(error);
        };
        this.requestBaseUrl = "".concat(this.dtdlApiUrl).concat(this.resourceBaseUrl);
        this.httpService = httpService;
    }
    UserHttpService.prototype.getUserById = function (userId, userQuery) {
        var _this = this;
        if (!userQuery) {
            return this.logAndReject("'userQuery' cannot be null or undefined");
        }
        var url = "".concat(this.requestBaseUrl, "/").concat(userId);
        var requestUrl = this.urlBuilder.BuildUrl(url, userQuery);
        return this.httpService
            .get(requestUrl)
            .then(function (res) {
            return _this.transformToSmartUser(res.data);
        })
            .catch(function (error) { return _this.logAndReject(error, requestUrl); });
    };
    UserHttpService.prototype.transformToSmartUser = function (user) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var resultSmartUser = {
            dtId: (_a = user.$dtId) !== null && _a !== void 0 ? _a : "",
            givenName: (_b = user.givenName) !== null && _b !== void 0 ? _b : "",
            type: extractTypeFromSpace(user.$metadata.$model),
            surName: (_c = user.surName) !== null && _c !== void 0 ? _c : "",
            buildingId: user.buildingId,
            floorId: user.floorId,
            regionId: user.regionId,
            properties: {
                displayName: (_d = user.displayName) !== null && _d !== void 0 ? _d : "",
                jobTitle: (_e = user.jobTitle) !== null && _e !== void 0 ? _e : "",
                dtId: user.$dtId ? user.$dtId : "",
                officeLocation: (_f = user.officeLocation) !== null && _f !== void 0 ? _f : "",
                userPrincipalName: (_g = user.userPrincipalName) !== null && _g !== void 0 ? _g : ""
            },
            space: {
                dtId: (_k = (_j = (_h = user.isInSpace) === null || _h === void 0 ? void 0 : _h.targets[0]) === null || _j === void 0 ? void 0 : _j.$dtId) !== null && _k !== void 0 ? _k : "",
                name: (_o = (_m = (_l = user.isInSpace) === null || _l === void 0 ? void 0 : _l.targets[0]) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : "",
                type: extractTypeFromSpace((_q = (_p = user.isInSpace) === null || _p === void 0 ? void 0 : _p.targets[0]) === null || _q === void 0 ? void 0 : _q.$metadata.$model),
                featureId: (_r = user.isInSpace) === null || _r === void 0 ? void 0 : _r.targets[0].featureId
            }
        };
        return resultSmartUser;
    };
    UserHttpService.prototype.getUsers = function (userQuery) {
        var _this = this;
        if (!userQuery) {
            return this.logAndReject("'userQuery' cannot be null or undefined");
        }
        var url = "".concat(this.requestBaseUrl);
        var requestUrl = this.urlBuilder.BuildUrl(url, userQuery);
        return this.httpService
            .get(requestUrl)
            .then(function (res) {
            var resultSmartUsers = {
                items: res.data.items.map(function (item) { return _this.transformToSmartUser(item); }),
                nextPageLink: res.data.nextPageLink,
                count: res.data.count
            };
            return resultSmartUsers;
        })
            .catch(function (error) { return _this.logAndReject(error, requestUrl); });
    };
    return UserHttpService;
}());
export { UserHttpService };

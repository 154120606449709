var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Popup, data } from "azure-maps-control";
import { getFeatureWithSpaceId } from "../../MapUtilities/FeatureProperties/FeatureUtilities";
/**
 * This class serves as a map layer that helps manage popups that will be added to a space (or unit) on the map.
 */
var SpacePopupLayer = /** @class */ (function () {
    function SpacePopupLayer(map, indoorManager, azureMapService, getDatasetId, getFacilityLevels) {
        var _a;
        this.map = map;
        this.indoorManager = indoorManager;
        this.azureMapService = azureMapService;
        this.getDatasetId = getDatasetId;
        this.getFacilityLevels = getFacilityLevels;
        this.managedPopups = {};
        this.spaceFeatures = {};
        this.spaceFeatureRetrieve = {};
        this.minZoomLevel = 18;
        this.minZoomStepForUpdate = 0.1;
        this.spaceContentMinZoomLevel = {
            Large: 20,
            Medium: 19.25,
            Small: 18.5
        };
        this.buildingFeatures = [];
        // This line is needed to avoid losing the 'this' context when Azure Map events trigger the callback
        this.handleLevelChangeCallback = this.handleLevelChangeCallback.bind(this);
        this.handleMapZoomCallback = this.handleMapZoomCallback.bind(this);
        this.prevZoom = (_a = this.map.getCamera().zoom) !== null && _a !== void 0 ? _a : 1;
        this.currentLevelId = this.getCurrentLevelId();
        this.map.events.add("facilitychanged", this.indoorManager, this.handleLevelChangeCallback);
        this.map.events.add("levelchanged", this.indoorManager, this.handleLevelChangeCallback);
        this.map.events.add("zoom", this.handleMapZoomCallback);
    }
    SpacePopupLayer.prototype.createOrUpdatePopup = function (spaceId, content) {
        return __awaiter(this, void 0, void 0, function () {
            var promise, spaceFeatureDetails;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        promise = this.spaceFeatureRetrieve[spaceId];
                        if (!promise) {
                            promise = this.getSpaceDetails(spaceId);
                        }
                        this.spaceFeatureRetrieve[spaceId] = promise;
                        return [4 /*yield*/, promise];
                    case 1:
                        spaceFeatureDetails = _c.sent();
                        delete this.spaceFeatureRetrieve[spaceId];
                        if (spaceFeatureDetails) {
                            this.spaceFeatures[spaceId] = spaceFeatureDetails;
                            this.managedPopups[spaceId] = { content: content, popup: (_b = (_a = this.managedPopups[spaceId]) === null || _a === void 0 ? void 0 : _a.popup) !== null && _b !== void 0 ? _b : new Popup() };
                            this.setPopupOptions(spaceId);
                            this.setPopupVisibility(spaceId);
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    SpacePopupLayer.prototype.removePopup = function (spaceId) {
        var managedPopup = this.managedPopups[spaceId];
        if (managedPopup && managedPopup.popup) {
            this.map.popups.remove(managedPopup.popup);
            delete this.managedPopups[spaceId];
        }
    };
    SpacePopupLayer.prototype.isPopupVisible = function (spaceId) {
        var _a;
        var popup = (_a = this.managedPopups[spaceId]) === null || _a === void 0 ? void 0 : _a.popup;
        if (popup) {
            return popup.isOpen();
        }
        return false;
    };
    SpacePopupLayer.prototype.isPopupCreatedForSpace = function (spaceId) {
        return !!this.managedPopups[spaceId];
    };
    SpacePopupLayer.prototype.setBuildingFeatures = function (buildingFeatures) {
        this.buildingFeatures = buildingFeatures;
    };
    SpacePopupLayer.prototype.handleMapZoomCallback = function () {
        var _a;
        var zoom = (_a = this.map.getCamera()) === null || _a === void 0 ? void 0 : _a.zoom;
        if (this.isZoomLevelLowerThanThreshold()) {
            Object.values(this.managedPopups).forEach(function (managedPopup) { return managedPopup.popup.close(); });
        }
        else if (zoom && Math.abs(this.prevZoom - zoom) > this.minZoomStepForUpdate) {
            for (var _i = 0, _b = Object.keys(this.managedPopups); _i < _b.length; _i++) {
                var spaceId = _b[_i];
                this.setPopupOptions(spaceId);
                this.setPopupVisibility(spaceId);
            }
        }
    };
    SpacePopupLayer.prototype.handleLevelChangeCallback = function () {
        this.currentLevelId = this.getCurrentLevelId();
        for (var _i = 0, _a = Object.keys(this.managedPopups); _i < _a.length; _i++) {
            var spaceId = _a[_i];
            this.setPopupVisibility(spaceId);
        }
    };
    SpacePopupLayer.prototype.setPopupVisibility = function (spaceId) {
        var _a;
        if (this.isZoomLevelLowerThanThreshold()) {
            this.managedPopups[spaceId].popup.close();
        }
        else {
            if (this.currentLevelId === ((_a = this.spaceFeatures[spaceId]) === null || _a === void 0 ? void 0 : _a.levelId)) {
                this.managedPopups[spaceId].popup.open(this.map);
            }
            else {
                this.managedPopups[spaceId].popup.close();
            }
        }
    };
    SpacePopupLayer.prototype.setPopupOptions = function (spaceId) {
        if (!this.spaceFeatures[spaceId] || !this.managedPopups[spaceId])
            return;
        this.managedPopups[spaceId].popup.setOptions(__assign({ draggable: false, closeButton: false, position: data.BoundingBox.getCenter(this.spaceFeatures[spaceId].bbox) }, this.getPopupContent(spaceId)));
    };
    SpacePopupLayer.prototype.getPopupContent = function (spaceId) {
        var _a, _b;
        var zoom = (_b = (_a = this.map.getCamera()) === null || _a === void 0 ? void 0 : _a.zoom) !== null && _b !== void 0 ? _b : this.prevZoom;
        var content = this.managedPopups[spaceId].content;
        var output = content.Small;
        if (content.Large && zoom >= this.spaceContentMinZoomLevel.Large) {
            output = content.Large;
        }
        else if (content.Medium && zoom >= this.spaceContentMinZoomLevel.Medium) {
            output = content.Medium;
        }
        return output;
    };
    SpacePopupLayer.prototype.getSpaceDetails = function (spaceId) {
        return __awaiter(this, void 0, void 0, function () {
            var spaceDetails;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.spaceFeatures[spaceId]) {
                            return [2 /*return*/, this.spaceFeatures[spaceId]];
                        }
                        spaceDetails = this.getSpaceDetailsIfRendered(spaceId);
                        if (!!spaceDetails) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getSpaceDetailsFromDataset(spaceId, this.getDatasetId())];
                    case 1:
                        spaceDetails = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, spaceDetails];
                }
            });
        });
    };
    SpacePopupLayer.prototype.getSpaceDetailsFromDataset = function (spaceId, datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var output, buildingData, _a, unit;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        output = null;
                        if (!(Object.keys(this.buildingFeatures).length === 0 ||
                            !this.buildingFeatures.find(function (feature) { return feature.originalId === spaceId; }))) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.azureMapService.getAzureMapsData(datasetId)];
                    case 1:
                        buildingData = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.azureMapService.getFeatures(buildingData, datasetId)];
                    case 2:
                        _a.buildingFeatures = _b.sent();
                        _b.label = 3;
                    case 3:
                        unit = this.buildingFeatures.find(function (feature) { return feature.originalId === spaceId; });
                        if (unit) {
                            output = { bbox: unit.boundingBox, levelId: unit.levelId };
                        }
                        return [2 /*return*/, output];
                }
            });
        });
    };
    SpacePopupLayer.prototype.getSpaceDetailsIfRendered = function (spaceId) {
        var _a;
        var output = null;
        var feature = getFeatureWithSpaceId(this.map, spaceId);
        if (feature && feature.bbox && feature.properties) {
            output = { bbox: feature.bbox, levelId: (_a = feature.properties) === null || _a === void 0 ? void 0 : _a.levelFeatureId };
        }
        return output;
    };
    SpacePopupLayer.prototype.getCurrentLevelId = function () {
        var _a;
        var ordinalNumber = this.indoorManager.getCurrentFacility()[1];
        var levelId = (_a = this.getFacilityLevels().find(function (level) { return level.ordinal === ordinalNumber; })) === null || _a === void 0 ? void 0 : _a.levelId;
        return levelId !== null && levelId !== void 0 ? levelId : "";
    };
    SpacePopupLayer.prototype.isZoomLevelLowerThanThreshold = function () {
        var _a;
        var zoom = (_a = this.map.getCamera()) === null || _a === void 0 ? void 0 : _a.zoom;
        if (!zoom || zoom <= this.minZoomLevel) {
            return true;
        }
        return false;
    };
    return SpacePopupLayer;
}());
export { SpacePopupLayer };

import { FeedbackUiType, InAppFeedbackInit, InitialFeedbackData } from "@smartbuilding/smartbuilding-ocv-feedback";
import { FooterButton, FooterButtonType } from "@smartbuilding/ui-components-buttons";
import React, { useEffect } from "react";
import { IAuthClient } from "@smartbuilding/auth-client";
import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { ILogger } from "@smartbuilding/log-provider";
import { IWebClientConfiguration } from "../../../constants";
import { electronService } from "@smartbuilding/electron-service";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useOCVFeedbackStyles } from "./useOCVFeedbackStyles";

export function OCVFeedback(): JSX.Element {
  const styles = useOCVFeedbackStyles();
  const isKiosk = electronService.isElectron();
  const feedbackData = InitialFeedbackData;
  const logTitle = "[SBS-OCV-Feedback]";
  const sbsSupportEmail = "mailto:sbs@microsoft.com";
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const authClient = useInjection<IAuthClient>(serviceIdentifiers.authClient);
  const configurationService = useInjection<IConfigurationService<IWebClientConfiguration>>(
    serviceIdentifiers.configService
  );

  useEffect(() => {
    if (!isKiosk) {
      authClient.getUser().then((userEmail) => {
        if (feedbackData.feedbackConfig) {
          feedbackData.feedbackConfig.email = userEmail;
          feedbackData.feedbackConfig.isEmailCollectionEnabled = true;
          feedbackData.feedbackConfig.isFileUploadEnabled = true;
          feedbackData.feedbackConfig.isScreenshotEnabled = true;
          feedbackData.feedbackConfig.isScreenRecordingEnabled = true;
          feedbackData.feedbackConfig.supportUrl = sbsSupportEmail;
        }
      });
    }

    configurationService
      .getSetting("OCVAppId")
      .then((ocvAppId) => {
        feedbackData.appId = Number(ocvAppId);
        if (Number.isNaN(feedbackData.appId)) {
          throw new Error(`${logTitle} Invalid OCVAppId: ${ocvAppId} to be parsed as a number`);
        }
      })
      .catch((error) => {
        logger.logError(error);
      });
    configurationService.getSetting("Environment").then((env) => {
      feedbackData.isProduction = env === "Production";
    });
    configurationService.getSetting("AADTenantId").then((aadTenantId) => {
      feedbackData.tenantId = aadTenantId as string;
    });
    // We only want to run this once at initialization, so we can ignore the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFeedbackUiTypeToModal = (): void => {
    if (feedbackData.feedbackConfig) {
      feedbackData.feedbackConfig.feedbackUiType = FeedbackUiType.Modal;
      feedbackData.feedbackConfig.isDisplayed = true;
      InAppFeedbackInit.updateFeedbackObject(feedbackData);
    }
  };

  return (
    <div id={"ocv-feedback"} className={styles.rootContainer}>
      <FooterButton
        type={FooterButtonType.Feedback}
        text={"Feedback"}
        onClick={() => {
          updateFeedbackUiTypeToModal();
        }}
      />
      <div id="centroRef"></div>
    </div>
  );
}

import { SensorSubTypes } from "@smartbuilding/adt-v2-types";
import { RoomCapabilityNames, capabilityMapping } from "./IRoomCard.Type";
export function findExtendedProperty(propertiesArray, property) {
    if (property && propertiesArray !== undefined && propertiesArray.length > 0) {
        var propertyObj = propertiesArray.find(function (e) { return e.name === property; });
        return propertyObj === null || propertyObj === void 0 ? void 0 : propertyObj.value;
    }
    return "";
}
export function getRoomCapabilitiesForSpace(properties) {
    if (!properties)
        return {};
    if (!properties.capabilities)
        return {};
    var capabilityList = {};
    Object.keys(properties.capabilities).forEach(function (capability) {
        if (properties.capabilities && properties.capabilities[capability] && capabilityMapping[capability]) {
            capabilityList[capability] = capabilityMapping[capability];
        }
    });
    return capabilityList;
}
export function isHotDeskingEnabled(room) {
    var hotDeskingCapabilities = {};
    if (room && room.capabilities) {
        hotDeskingCapabilities = room.capabilities;
    }
    return hotDeskingCapabilities[RoomCapabilityNames.HotDesking] !== undefined
        ? hotDeskingCapabilities[RoomCapabilityNames.HotDesking]
        : false;
}
export function getRoomOccupancyStatus(values) {
    var occupancyStatus = "";
    if (values) {
        values.forEach(function (e) {
            var _a;
            if (e.occupancyStatus && occupancyStatus === "") {
                occupancyStatus = (_a = e.occupancyStatus.value) !== null && _a !== void 0 ? _a : "";
            }
        });
    }
    return occupancyStatus;
}
export function getOccupancyStatusValue(space) {
    var _a, _b;
    var occupancyStatusSensor = (_a = space.values) === null || _a === void 0 ? void 0 : _a.find(function (prop) { var _a; return (_a = prop.$metadata.$model) === null || _a === void 0 ? void 0 : _a.includes(SensorSubTypes.OccupancyStatus); });
    return (_b = occupancyStatusSensor === null || occupancyStatusSensor === void 0 ? void 0 : occupancyStatusSensor.occupancyStatus) === null || _b === void 0 ? void 0 : _b.value;
}
export function getAvailableCapacityValue(space) {
    var _a, _b;
    var availableCapacitySensor = (_a = space.values) === null || _a === void 0 ? void 0 : _a.find(function (prop) { var _a; return (_a = prop.$metadata.$model) === null || _a === void 0 ? void 0 : _a.includes(SensorSubTypes.AvailableCapacity); });
    return (_b = availableCapacitySensor === null || availableCapacitySensor === void 0 ? void 0 : availableCapacitySensor.count) === null || _b === void 0 ? void 0 : _b.value;
}
export function getPeopleCountValue(space) {
    var _a, _b;
    var peopleCountSensor = (_a = space.values) === null || _a === void 0 ? void 0 : _a.find(function (prop) { var _a; return (_a = prop.$metadata.$model) === null || _a === void 0 ? void 0 : _a.includes(SensorSubTypes.PeopleCount); });
    return (_b = peopleCountSensor === null || peopleCountSensor === void 0 ? void 0 : peopleCountSensor.count) === null || _b === void 0 ? void 0 : _b.value;
}
export function isPeopleDensityEnabled(room) {
    var peopleCountCapabilities = {};
    if (room && room.capabilities) {
        peopleCountCapabilities = room.capabilities;
    }
    return peopleCountCapabilities[RoomCapabilityNames.PeopleDensity] !== undefined
        ? peopleCountCapabilities[RoomCapabilityNames.PeopleDensity]
        : false;
}
export function getConferenceStatusValue(space) {
    var _a, _b;
    var conferenceStatusSensor = (_a = space.values) === null || _a === void 0 ? void 0 : _a.find(function (prop) { var _a; return (_a = prop.$metadata.$model) === null || _a === void 0 ? void 0 : _a.includes(SensorSubTypes.ConferenceStatus); });
    return (_b = conferenceStatusSensor === null || conferenceStatusSensor === void 0 ? void 0 : conferenceStatusSensor.conferenceStatus) === null || _b === void 0 ? void 0 : _b.value;
}

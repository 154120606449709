import * as Logger from "@smartbuilding/log-provider";
import { Fallback } from "./Fallback/Fallback";
import { Guid } from "@smartbuilding/utilities";
import React from "react";
import { container } from "../../serviceContainer/ServiceContainer";
import { injectable } from "inversify";
import { serviceIdentifiers } from "../../serviceContainer/ServiceIdentifiers";

interface IState {
  error: Error | null;
  guid: string | null;
}

@injectable()
export class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, IState> {
  private logger: Logger.ILogger;
  public constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.logger = container.get<Logger.ILogger>(serviceIdentifiers.logger);
    this.state = {
      error: null,
      guid: null
    };
  }

  public static getDerivedStateFromError(error: Error): Partial<IState> {
    return {
      error,
      guid: Guid.newGuid().toString()
    };
  }

  public componentDidCatch(error: Error): void {
    this.logError(error);
    this.refreshWindow();
  }

  private logError(error: Error): void {
    this.logger.logError(error, {
      message: "[SBClient] Error boundary has been triggered.",
      guid: this.state.guid
    });
  }

  private refreshWindow(): void {
    const minuteInMs = 60 * 1000;
    const fiveMinutesInMs = 5 * minuteInMs;
    setTimeout(() => {
      window.location.reload();
    }, fiveMinutesInMs);
  }

  public render(): JSX.Element | React.ReactNode {
    return this.state.error ? <Fallback guid={this.state.guid} /> : this.props.children;
  }
}

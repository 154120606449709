import { RoomBookingActions, UpdateBookingStatusAction } from "../Actions/RoomBookingActions";
import { Action } from "redux";

interface IRoomBookedState {
  roomBooked: boolean;
}

const initialState: IRoomBookedState = {
  roomBooked: false
};
export const roomBookedReducer = (state: IRoomBookedState = initialState, action: Action): IRoomBookedState => {
  switch (action.type) {
    case RoomBookingActions.UPDATE_BOOKING_STATUS: {
      const updateAction = action as UpdateBookingStatusAction;

      return {
        ...state,
        roomBooked: updateAction.payload
      };
    }
    default:
      return state;
  }
};

export default roomBookedReducer;

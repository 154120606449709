import {
  AppResetAction,
  AppResetActions,
  InstantBookingRoomSelectionCompleteAction,
  ListViewActions,
  ListViewSpaceSelectedAction,
  PeopleSelectedActions,
  PersonSelectedAction,
  RoomSelectionCompleteAction,
  SpaceCategoryActions,
  SpaceCategorySelectedAction,
  SpaceSelectedActions,
  modifyDetailsPanelStackPage,
  setBuilding,
  setFloor
} from "../Actions";
import { IDipResponse, dipSagas, getTwins } from "@dip/redux-sagas";
import { all, put, select, takeEvery } from "redux-saga/effects";

import { Area } from "@smartbuilding/adt-v2-types";
import { IDeviceConfigStore } from "../Types";
import { ILogger } from "@smartbuilding/log-provider";
import { QueryBuilder } from "@dip/querybuilder";
import { getDeviceConfigData } from "../Selectors";

export class AppSaga {
  public constructor(private logger: ILogger) {
    this.watcher = this.watcher.bind(this);
    this.handleSpaceSelected = this.handleSpaceSelected.bind(this);
    this.handleInstantBookingSpaceSelected = this.handleInstantBookingSpaceSelected.bind(this);
    this.handlePersonSelected = this.handlePersonSelected.bind(this);
    this.handleCategorySelected = this.handleCategorySelected.bind(this);
    this.handleListViewSpaceSelected = this.handleListViewSpaceSelected.bind(this);
    this.handleAppReset = this.handleAppReset.bind(this);
  }

  public *watcher(): Generator {
    yield all([
      takeEvery(SpaceSelectedActions.ROOM_SELECTION_COMPLETE, this.handleSpaceSelected),
      takeEvery(SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTION_COMPLETE, this.handleInstantBookingSpaceSelected),
      takeEvery(PeopleSelectedActions.PERSON_SELECTED, this.handlePersonSelected),
      takeEvery(SpaceCategoryActions.SPACE_CATEGORY_SELECTED, this.handleCategorySelected),
      takeEvery(ListViewActions.LIST_VIEW_SPACE_SELECTION, this.handleListViewSpaceSelected),
      takeEvery(AppResetActions.APP_RESET, this.handleAppReset)
    ]);
  }

  /**
   * Saga that runs when a room selection is completed in the application
   * @param action @type {RoomSelectionCompleteAction}
   */
  private *handleSpaceSelected(action: RoomSelectionCompleteAction): Generator {
    yield put(modifyDetailsPanelStackPage({ selectedSpaceId: action.payload }));
  }

  /**
   * Saga that runs when a room selection is completed in the application
   * @param action @type {RoomSelectionCompleteAction}
   */
  private *handleInstantBookingSpaceSelected(action: InstantBookingRoomSelectionCompleteAction): Generator {
    yield put(
      modifyDetailsPanelStackPage({
        selectedSpaceId: action.payload.roomId,
        instantBookingConfirmationInfo: action.payload.instantBookingConfirmationInfo
      })
    );
  }

  /**
   * Saga that runs when a person selection is dispatched in the application
   * @param action @type {PersonSelectedAction}
   */
  private *handlePersonSelected(action: PersonSelectedAction): Generator {
    yield put(modifyDetailsPanelStackPage({ selectedPersonId: action.payload }));
  }

  /**
   * Saga that runs when a category selection is dispatched in the application
   * @param action @type {SpaceCategorySelectedAction}
   */
  private *handleCategorySelected(action: SpaceCategorySelectedAction): Generator {
    yield put(modifyDetailsPanelStackPage({ selectedCategory: action.payload }));
  }

  /**
   * Saga that runs when list view page info are selected in the application
   * @param action @type {ListViewSpaceSelectedAction}
   */
  private *handleListViewSpaceSelected(action: ListViewSpaceSelectedAction): Generator {
    yield put(modifyDetailsPanelStackPage({ selectedListViewCard: action.payload }));
  }

  private *handleAppReset(action: AppResetAction): Generator {
    const deviceConfig = (yield select(getDeviceConfigData)) as IDeviceConfigStore;

    try {
      if (deviceConfig.buildingId && deviceConfig.floorId) {
        yield put(setBuilding(deviceConfig.buildingId));
        yield put(setFloor(deviceConfig.floorId));
        this.logger.logEvent("[App Reset] In idle State with building id and floor id", {
          buildingId: deviceConfig.buildingId,
          floorId: deviceConfig.floorId
        });
      } else if (deviceConfig.spaceId) {
        const spaceId = deviceConfig.spaceId;
        const query = QueryBuilder.from(Area).where((q) => q.compare(Area, (s) => s.$dtId.equals(spaceId)));
        const response = (yield dipSagas.get(getTwins(query))) as IDipResponse<Area[]>;
        if (response.data.length > 0) {
          const space = response.data[0];
          if (space.buildingId && space.floorId) {
            yield put(setBuilding(space.buildingId));
            yield put(setFloor(space.floorId));
          }
          this.logger.logEvent("[App Reset] In idle State with spaceId ", { spaceId: deviceConfig.spaceId });
        }
        throw new Error("Dip response is empty or has encountered an error: " + response.error);
      }
      throw new Error("Device config is empty");
    } catch (error) {
      this.logger.logError(error as Error);
    }
  }
}

var UrlBuilder = /** @class */ (function () {
    function UrlBuilder() {
    }
    UrlBuilder.prototype.BuildUrl = function (base, params) {
        if (!base && base.length <= 0) {
            throw new Error("Base url cannot be empty");
        }
        return base + this.BuildUrlParamsString(params);
    };
    UrlBuilder.prototype.BuildUrlParamsString = function (urlParamsObj) {
        var paramsString = "";
        var first = true;
        for (var arg in urlParamsObj) {
            if (!urlParamsObj[arg] || urlParamsObj[arg] === undefined) {
                continue;
            }
            var argValue = urlParamsObj[arg];
            var argString = "";
            if (Array.isArray(argValue)) {
                argString = argValue
                    .filter(this.isPrimitiveUrlParameter)
                    .map(function (val) { return val === null || val === void 0 ? void 0 : val.toString().trim(); })
                    .join();
            }
            else if (this.isPrimitiveUrlParameter(argValue)) {
                argString = (argValue === null || argValue === void 0 ? void 0 : argValue.toString().trim()) || "";
            }
            if (argString.length) {
                // Primitive OData URL parameters need to be prepended with $
                var param = (this.isPrimitiveODataUrlParameter(arg) ? "$" + arg : arg) + "=" + argString;
                if (first) {
                    paramsString += "?" + param;
                    first = false;
                }
                else {
                    paramsString += "&" + param;
                }
            }
        }
        return paramsString;
    };
    UrlBuilder.prototype.isPrimitiveUrlParameter = function (value) {
        return (typeof value == "number" && isFinite(value)) || typeof value === "string" || typeof value === "boolean";
    };
    UrlBuilder.prototype.isPrimitiveODataUrlParameter = function (arg) {
        return arg === "filter";
    };
    return UrlBuilder;
}());
export { UrlBuilder };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { Shape, data, source } from "azure-maps-control";
var categoryToMapIconMap = {
    Lounge: "lounge",
    KitchenetteVending: "vending"
};
var SpaceProperties;
(function (SpaceProperties) {
    SpaceProperties["FeatureId"] = "featureId";
    SpaceProperties["FeatureText"] = "featureText";
    SpaceProperties["Id"] = "id";
    SpaceProperties["LevelId"] = "levelId";
    SpaceProperties["Category"] = "category";
    SpaceProperties["MapIcon"] = "mapIcon";
    SpaceProperties["BusynessStatus"] = "busynessStatus";
    SpaceProperties["BusynessStatusColor"] = "busynessStatusColor";
    SpaceProperties["SensorStatusColor"] = "sensorStatusColor";
})(SpaceProperties || (SpaceProperties = {}));
var MapFeaturesDataSource = /** @class */ (function () {
    function MapFeaturesDataSource(map, logger, id) {
        var _this = this;
        this.map = map;
        this.logger = logger;
        this.dataSource = id ? new source.DataSource(id) : new source.DataSource();
        this.map.events.add("ready", function () {
            if (!_this.map.sources.getById(_this.dataSource.getId())) {
                _this.map.sources.add(_this.dataSource);
                _this.logger.logEvent("[MapFeaturesDataSource] added to map", {
                    dataSourceId: _this.dataSource.getId()
                });
            }
            else {
                _this.logger.logWarning("[MapFeaturesDataSource] Duplicate id, unable to add datasource to map", {
                    dataSourceId: _this.dataSource.getId()
                });
            }
        });
    }
    MapFeaturesDataSource.prototype.getDataSource = function () {
        return this.dataSource;
    };
    /**
     * Sets category properties to features
     * @param categoryRoomMap This record should only include categories that are listed in the menu panel's child categories
     */
    MapFeaturesDataSource.prototype.setSpaceCategoryProperties = function (categoryRoomMap) {
        var _a;
        for (var _i = 0, _b = Object.keys(categoryRoomMap); _i < _b.length; _i++) {
            var category = _b[_i];
            if (RoomSubTypes[category] === undefined) {
                continue;
            }
            for (var _c = 0, _d = categoryRoomMap[category]; _c < _d.length; _c++) {
                var id = _d[_c];
                var properties = (_a = {},
                    _a[SpaceProperties.Category] = category,
                    _a[SpaceProperties.MapIcon] = categoryToMapIconMap[category],
                    _a);
                this.createOrUpdateShape(id, properties);
            }
        }
        this.logger.logEvent("[MapFeaturesDataSource] Added space category properties to datasource", {
            categories: Object.keys(categoryRoomMap)
        });
    };
    /**
     * Sets sensor properties to features
     * @param idSensorPropsMap Record that maps a feature's original Id to data <sensorColor, busynessColor, busynessText> obtained from sensor data
     */
    MapFeaturesDataSource.prototype.setSensorProperties = function (idSensorPropsMap) {
        for (var _i = 0, _a = Object.keys(idSensorPropsMap); _i < _a.length; _i++) {
            var id = _a[_i];
            var sensorProperties = idSensorPropsMap[id];
            var sensorShapeToUpdate = this.dataSource.getShapeById(id);
            if (sensorShapeToUpdate) {
                var properties = sensorShapeToUpdate.getProperties();
                for (var _b = 0, _c = Object.keys(sensorProperties); _b < _c.length; _b++) {
                    var key = _c[_b];
                    if (properties[key] !== sensorProperties[key]) {
                        this.logger.logEvent("[MapFeaturesDataSource] sensor properties updated", {
                            spaceId: id,
                            sensorPropertyUpdated: key,
                            prevValue: properties[key],
                            currValue: sensorProperties[key]
                        });
                        // addProperty can update existing keys
                        sensorShapeToUpdate.addProperty(key, sensorProperties[key]);
                    }
                }
            }
            else {
                this.createOrUpdateShape(id, sensorProperties);
            }
        }
    };
    /**
     * Sets feature properties to a feature
     * @param features response from Azure Maps WFS API. Should contain featureId, originalId, levelId, name, and position coordinates
     */
    MapFeaturesDataSource.prototype.setFeatureProperties = function (features) {
        var _a;
        for (var _i = 0, features_1 = features; _i < features_1.length; _i++) {
            var unitFeature = features_1[_i];
            var properties = (_a = {},
                _a[SpaceProperties.FeatureId] = unitFeature.id,
                _a[SpaceProperties.Id] = unitFeature.originalId,
                _a[SpaceProperties.LevelId] = unitFeature.levelId,
                _a[SpaceProperties.FeatureText] = unitFeature.name,
                _a);
            this.createOrUpdateShape(unitFeature.originalId, properties, unitFeature.boundingCoordinates);
        }
        this.cleanUpDataSource(features.map(function (f) { return f.originalId; }));
        this.logger.logEvent("[MapFeaturesDataSource] Added feature properties to datasource", {
            numberOfFeatures: this.dataSource.getShapes().length
        });
    };
    MapFeaturesDataSource.prototype.cleanUpDataSource = function (buildingRoomIds) {
        for (var _i = 0, _a = this.dataSource.getShapes(); _i < _a.length; _i++) {
            var shape = _a[_i];
            var id = shape.getId();
            if (!buildingRoomIds.includes(id)) {
                this.dataSource.removeById(id);
            }
        }
        this.logger.logEvent("[MapFeaturesDataSource] Unneeded features cleaned up", {
            numberOfFeatures: this.dataSource.getShapes().length
        });
    };
    MapFeaturesDataSource.prototype.createOrUpdateShape = function (id, properties, coordinates) {
        var shapeToUpdate = this.dataSource.getShapeById(id);
        if (shapeToUpdate) {
            shapeToUpdate.setProperties(__assign(__assign({}, shapeToUpdate.getProperties()), properties));
            // if coordinates aren't length 1, will need to set coordinates
            if (coordinates && shapeToUpdate.getCoordinates().length === 1)
                shapeToUpdate.setCoordinates(coordinates);
        }
        else {
            this.dataSource.add(new Shape(new data.Polygon(coordinates !== null && coordinates !== void 0 ? coordinates : []), id, __assign({}, properties)));
        }
    };
    return MapFeaturesDataSource;
}());
export { MapFeaturesDataSource };

export var RoomSubTypes;
(function (RoomSubTypes) {
    RoomSubTypes["Atrium"] = "Atrium";
    RoomSubTypes["AtriumVoid"] = "AtriumVoid";
    RoomSubTypes["AudioVideoStudio"] = "AudioVideoStudio";
    RoomSubTypes["Auditorium"] = "Auditorium";
    RoomSubTypes["BathRoom"] = "BathRoom";
    RoomSubTypes["BreakoutWithRegistration"] = "BreakoutWithRegistration";
    RoomSubTypes["BombShelter"] = "BombShelter";
    RoomSubTypes["BuildingPBX"] = "BuildingPBX";
    RoomSubTypes["CableRoom"] = "CableRoom";
    RoomSubTypes["Cafe"] = "Cafe";
    RoomSubTypes["Cafeteria"] = "Cafeteria";
    RoomSubTypes["CafeteriaKitchen"] = "CafeteriaKitchen";
    RoomSubTypes["CafeteriaSupport"] = "CafeteriaSupport";
    RoomSubTypes["ConferenceRoom"] = "ConferenceRoom";
    RoomSubTypes["CopyRoom"] = "CopyRoom";
    RoomSubTypes["CorporateSupport"] = "CorporateSupport";
    RoomSubTypes["ConversationRoom"] = "ConversationRoom";
    RoomSubTypes["Desk"] = "Desk";
    RoomSubTypes["DiningKitchen"] = "DiningKitchen";
    RoomSubTypes["DiningStorage"] = "DiningStorage";
    RoomSubTypes["DiningServery"] = "DiningServery";
    RoomSubTypes["DiningSeating"] = "DiningSeating";
    RoomSubTypes["DiningSupport"] = "DiningSupport";
    RoomSubTypes["Elevator"] = "Elevator";
    RoomSubTypes["ElevatorFreight"] = "ElevatorFreight";
    RoomSubTypes["EnvisioningCenter"] = "EnvisioningCenter";
    RoomSubTypes["EVChargingStation"] = "EVChargingStation";
    RoomSubTypes["FacilitiesStorage"] = "FacilitiesStorage";
    RoomSubTypes["FocusRoom"] = "FocusRoom";
    RoomSubTypes["GymFitnessCenter"] = "GymFitnessCenter";
    RoomSubTypes["Hallway"] = "Hallway";
    RoomSubTypes["HardwareLab"] = "HardwareLab";
    RoomSubTypes["HotDeskingRoom"] = "HotDeskingRoom";
    RoomSubTypes["Hub"] = "Hub";
    RoomSubTypes["InnovationCenter"] = "InnovationCenter";
    RoomSubTypes["InteractiveCenter"] = "InteractiveCenter";
    RoomSubTypes["ITPAC"] = "ITPAC";
    RoomSubTypes["ITSupport"] = "ITSupport";
    RoomSubTypes["JanitorCloset"] = "JanitorCloset";
    RoomSubTypes["Kitchen"] = "Kitchen";
    RoomSubTypes["KitchenetteVending"] = "KitchenetteVending";
    RoomSubTypes["Lab"] = "Lab";
    RoomSubTypes["LactationRoom"] = "LactationRoom";
    RoomSubTypes["Library"] = "Library";
    RoomSubTypes["LoadingReceiving"] = "LoadingReceiving";
    RoomSubTypes["LobbyReception"] = "LobbyReception";
    RoomSubTypes["Lounge"] = "Lounge";
    RoomSubTypes["MailRoom"] = "MailRoom";
    RoomSubTypes["MechanicalElectricalRoom"] = "MechanicalElectricalRoom";
    RoomSubTypes["MicrosoftStore"] = "MicrosoftStore";
    RoomSubTypes["MeditationRoom"] = "MeditationRoom";
    RoomSubTypes["MothersRoom"] = "MothersRoom";
    RoomSubTypes["MPRSupportAV"] = "MPRSupportAV";
    RoomSubTypes["MPRSupportServery"] = "MPRSupportServery";
    RoomSubTypes["MPRSupportCoatCloset"] = "MPRSupportCoatCloset";
    RoomSubTypes["MPRSupportFurnitureStorage"] = "MPRSupportFurnitureStorage";
    RoomSubTypes["MPRSupportServeryStorage"] = "MPRSupportServeryStorage";
    RoomSubTypes["MeditationMultiFaithRoom"] = "MeditationMultiFaithRoom";
    RoomSubTypes["MultiPurposeRoom"] = "MultiPurposeRoom";
    RoomSubTypes["Neighborhood"] = "Neighborhood";
    RoomSubTypes["OccupantStorage"] = "OccupantStorage";
    RoomSubTypes["Office"] = "Office";
    RoomSubTypes["OpenMeetingArea"] = "OpenMeetingArea";
    RoomSubTypes["OtherSpace"] = "OtherSpace";
    RoomSubTypes["OutdoorSpace"] = "OutdoorSpace";
    RoomSubTypes["ParkingBay"] = "ParkingBay";
    RoomSubTypes["PatioTerrace"] = "PatioTerrace";
    RoomSubTypes["PersonalStorage"] = "PersonalStorage";
    RoomSubTypes["PhoneRoom"] = "PhoneRoom";
    RoomSubTypes["ProjectRoom"] = "ProjectRoom";
    RoomSubTypes["Reception"] = "Reception";
    RoomSubTypes["RecreationGaming"] = "RecreationGaming";
    RoomSubTypes["RetailShop"] = "RetailShop";
    RoomSubTypes["Sauna"] = "Sauna";
    RoomSubTypes["SecurityRoom"] = "SecurityRoom";
    RoomSubTypes["ServerDisplay"] = "ServerDisplay";
    RoomSubTypes["ServerRoom"] = "ServerRoom";
    RoomSubTypes["SharedOffice"] = "SharedOffice";
    RoomSubTypes["ShowerLockerRoom"] = "ShowerLockerRoom";
    RoomSubTypes["Specialty"] = "Specialty";
    RoomSubTypes["SpecialtyProjectRoom"] = "SpecialtyProjectRoom";
    RoomSubTypes["SprinklerRoom"] = "SprinklerRoom";
    RoomSubTypes["Scrum"] = "Scrum";
    RoomSubTypes["Stairwell"] = "Stairwell";
    RoomSubTypes["Storage"] = "Storage";
    RoomSubTypes["StorageClosetCoatLuggage"] = "StorageClosetCoatLuggage";
    RoomSubTypes["TrainingRoom"] = "TrainingRoom";
    RoomSubTypes["Techlink"] = "Techlink";
    RoomSubTypes["TechnicalBriefingCenter"] = "TechnicalBriefingCenter";
    RoomSubTypes["TeamOffice"] = "TeamOffice";
    RoomSubTypes["Touchdown"] = "Touchdown";
    RoomSubTypes["UnderConstruction"] = "UnderConstruction";
    RoomSubTypes["UsabilityLab"] = "UsabilityLab";
    RoomSubTypes["VerticalPenetration"] = "VerticalPenetration";
    RoomSubTypes["VisitorArea"] = "VisitorArea";
    RoomSubTypes["WaterCoolerPoint"] = "WaterCoolerPoint";
    RoomSubTypes["WellnessRelaxationRoom"] = "WellnessRelaxationRoom";
    RoomSubTypes["Zone"] = "Zone";
})(RoomSubTypes || (RoomSubTypes = {}));

export function PromiseTimeout(timeMs) {
    var timeoutId = 0;
    var promiseRejector = function () {
        return;
    };
    var timeoutPromise = new Promise(function (resolve, reject) {
        timeoutId = setTimeout(resolve, timeMs);
        promiseRejector = reject;
    });
    var cancellable = true;
    var cancel = function () {
        if (cancellable) {
            clearTimeout(timeoutId);
            promiseRejector();
        }
        cancellable = false;
    };
    return {
        cancel: cancel,
        promise: timeoutPromise
    };
}

export interface IDeviceConfigStore {
  spaceId?: string;
  location?: IConfigLocation;
  buildingId?: string;
  floorId?: string;
  hardwareId?: string;
  bearing?: number;
}

export interface IConfigLocation {
  latitude: number;
  longitude: number;
}

export interface ICustomKioskLocation {
  hardwareId: string;
  kioskLocation: string;
  spaceId: string;
  longitude: number;
  latitude: number;
}

export interface IDeviceConfigKeyVaultResponse {
  kioskLocations: ICustomKioskLocation[];
}

import { GenericSpaceCard, PageStatus } from "@smartbuilding/ui-components-space-card";
import { Persona, PersonaSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { getBuildingName, getFloors, getPersonImageMap, getSelectedPathMetadata } from "../../../redux/Selectors";
import { getIsDetailsPanelOpen, getIsZoomed } from "../../../redux/Selectors/LayoutSelectors";
import { useDispatch, useSelector } from "react-redux";
import { usePersonCardStyles, usePersonaStyles } from "./usePersonCardStyles";
import { IAuthClient } from "@smartbuilding/auth-client";
import { IDetailsPanelPersonCardProps } from "../DetailsPanelProps";
import { ILogger } from "@smartbuilding/log-provider";
import { ImageSize } from "../../../redux/Types";
import { electronService } from "@smartbuilding/electron-service";
import { retrievePeopleImages } from "../../../redux/Actions";
import { serviceIdentifiers } from "../../../serviceContainer/ServiceIdentifiers";
import { toggleDetailsPanelAction } from "../../../redux/Actions/LayoutActionCreator";
import { useDirectionController } from "../useDirectionController";
import { useInjection } from "../../../serviceContainer/ServiceContainerProvider";
import { useMyHubPathFindingQRCodeUrl } from "../useMyHubPathFindingQRCodeURL";

export function PersonCard(props: IDetailsPanelPersonCardProps): JSX.Element {
  const { selectedPerson, onPanelBackClickCallback, onPanelDismissCallback } = props;
  const styles = usePersonCardStyles();
  const personaStyles = usePersonaStyles();
  const loggerMsgTitle = "Person Card";
  const logger = useInjection<ILogger>(serviceIdentifiers.logger);
  const authClient = useInjection<IAuthClient>(serviceIdentifiers.authClient);
  const buildingName = useSelector(getBuildingName) ?? "";
  const floors = useSelector(getFloors);
  const personImage = useSelector(getPersonImageMap)[ImageSize.Large][selectedPerson.dtId];
  const pathMetadata = useSelector(getSelectedPathMetadata);
  const isDetailsPanelOpen = useSelector(getIsDetailsPanelOpen);
  const isZoomed = useSelector(getIsZoomed);
  const [floor, setFloor] = useState("");
  const [pageStatus, setPageStatus] = useState(PageStatus.Default);
  const dispatch = useDispatch();
  const toggleDetailsPanel = (): void => {
    dispatch(toggleDetailsPanelAction());
  };

  const pathfindingQRCodeUrl = useMyHubPathFindingQRCodeUrl();
  const {
    wayFindingSupported,
    useElevator,
    onUseElevatorValueChange,
    onDirectionClick,
    resetDirectionButton,
    directionsErrorMessage
  } = useDirectionController(
    selectedPerson.spaceId,
    selectedPerson.floorId ? selectedPerson.floorId : "",
    logger,
    loggerMsgTitle,
    authClient,
    electronService.isElectron(),
    setPageStatus,
    pathMetadata
  );

  useEffect(() => {
    if (pathMetadata?.routeData || pathMetadata?.retrieving) {
      resetDirectionButton();
    }
    setPageStatus(PageStatus.Default);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPerson]);

  useEffect(() => {
    setFloor(floors.find((f) => f.id === selectedPerson.floorId)?.name ?? "");
  }, [floors, selectedPerson]);

  useEffect(() => {
    if (!personImage) {
      dispatch(retrievePeopleImages([selectedPerson.dtId], ImageSize.Large));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personImage, dispatch]);

  return (
    <GenericSpaceCard
      navHeaderBackBtnCallback={onPanelBackClickCallback}
      navHeaderCloseBtnCallback={onPanelDismissCallback}
      navHeaderAriaLabel={selectedPerson.displayName}
      navHeaderBackBtnLabel="Back"
      navHeaderCloseBtnLabel="Close"
      navHeaderBackBtnDescribedBy="generic-back-button"
      navHeaderCloseBtnDescribedBy="generic-close-button"
      spaceCardPrimaryName={selectedPerson.displayName}
      spaceCardSecondaryText={`${buildingName}, ${floor ? `floor ${floor}, ` : ""}${
        selectedPerson.officeLocation.split("/")[1]
      }`}
      infoComponent={
        <div className={styles.personaContainer}>
          <Persona
            text={selectedPerson.displayName}
            secondaryText={selectedPerson.jobTitle}
            imageUrl={personImage}
            imageAlt={selectedPerson.displayName}
            size={PersonaSize.size120}
            styles={personaStyles}
          />
        </div>
      }
      pageStatus={pageStatus}
      useElevatorChecked={useElevator}
      onUseElevatorValueChange={wayFindingSupported ? onUseElevatorValueChange : undefined}
      directionsErrorMessage={directionsErrorMessage}
      onDirectionClick={wayFindingSupported ? onDirectionClick : undefined}
      sliderAriaLabel={`Press to ${!isDetailsPanelOpen ? "open" : "close"} details panel.`}
      isZoomed={isZoomed}
      isDocked={!isDetailsPanelOpen}
      sliderCallback={toggleDetailsPanel}
      {...pathfindingQRCodeUrl}
    />
  );
}

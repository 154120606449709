var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ElectronService = (function () {
    function ElectronService() {
        this.deviceResponse = null;
    }
    ElectronService.prototype.isElectron = function () {
        return !!("mssbElectron" in window);
    };
    ElectronService.prototype.getDeviceInfoAsync = function (forceRefresh) {
        return __awaiter(this, void 0, void 0, function () {
            var electronInfo, baseInfo, spaceAndLocation, location_1;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!(this.isElectron() && typeof window.mssbElectron.getDeviceInfo === "function")) return [3, 2];
                        return [4, window.mssbElectron.getDeviceInfo(true, forceRefresh ? true : false)];
                    case 1:
                        electronInfo = _f.sent();
                        baseInfo = {
                            id: electronInfo.id,
                            ashwid: electronInfo.ashwid,
                            hardwareId: electronInfo.hardwareId,
                            buildingId: (_a = electronInfo.building) === null || _a === void 0 ? void 0 : _a.id,
                            floorId: (_b = electronInfo.floor) === null || _b === void 0 ? void 0 : _b.id,
                            spaceId: (_c = electronInfo.space) === null || _c === void 0 ? void 0 : _c.id,
                            location: electronInfo.location,
                            advancedHeartbeat: electronInfo.advancedHeartbeat,
                            bearing: electronInfo.bearing
                        };
                        try {
                            spaceAndLocation = JSON.parse(electronInfo.spaceId);
                            location_1 = electronInfo.location;
                            if (!electronInfo.location && spaceAndLocation.lat && spaceAndLocation.lng) {
                                location_1 = { lat: spaceAndLocation.lat, lng: spaceAndLocation.lng };
                            }
                            return [2, __assign(__assign({}, baseInfo), { location: location_1, spaceId: (_d = baseInfo.spaceId) !== null && _d !== void 0 ? _d : spaceAndLocation.id })];
                        }
                        catch (err) {
                            return [2, __assign(__assign({}, baseInfo), { buildingId: (_e = baseInfo.buildingId) !== null && _e !== void 0 ? _e : electronInfo.spaceId })];
                        }
                        _f.label = 2;
                    case 2: throw new Error("Can't get device info since it is not an Electron App");
                }
            });
        });
    };
    return ElectronService;
}());
export var electronService = new ElectronService();

import { isMediumViewport, isWideViewport } from "../utilities/viewportUtilities";
import {
  setDetailsPanelStateAction,
  setMenuPanelStateAction,
  setZoomStateAction
} from "../redux/Actions/LayoutActionCreator";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 * Hook to manage the visibility of menu and details panels based on viewport width.
 * If the viewport width is greater than or equal to 815px, both panels can be open at the same time.
 * If the viewport width is less than 815px, only one panel can be open at a time.
 */

export const useHandleZoomPanelDisplay = (): void => {
  const dispatch = useDispatch();

  const updateStates = (isZoomed: boolean, isMenuPanelOpen: boolean, isDetailsPanelOpen: boolean): void => {
    dispatch(setZoomStateAction(isZoomed));
    dispatch(setMenuPanelStateAction(isMenuPanelOpen));
    dispatch(setDetailsPanelStateAction(isDetailsPanelOpen));
  };

  const handleZoom = useCallback((): void => {
    const body = document.body;

    if (isWideViewport()) {
      updateStates(false, true, true);
      body.style.transform = "scale(1)";
    } else if (isMediumViewport()) {
      updateStates(true, false, false);
      body.style.transform = "scale(0.9)";
    } else {
      updateStates(true, false, false);
      body.style.transform = "scale(0.75)";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleZoom();
    window.addEventListener("resize", handleZoom);
    return () => window.removeEventListener("resize", handleZoom);
  }, [handleZoom]);
};

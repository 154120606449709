import {
  BuildingDataRetrievedAction,
  BuildingSelectedAction,
  BuildingsWithMapDataRetrievedAction,
  CategoriesRetrievedAction,
  DeepLinkFloorSelectAction,
  DeepLinkRoomSelectAction,
  DeepLinkSpaceSelectActions,
  DefaultBuildingSelectedAction,
  FloorSelectedAction,
  FloorSelectionCompleteAction,
  InstantBookingRoomSelectedAction,
  InstantBookingRoomSelectionCompleteAction,
  PointsOfInterestRetrievedAction,
  RegionsDataRetrievedAction,
  RoomImageRetrievedAction,
  RoomSelectedAction,
  RoomSelectionClearedAction,
  RoomSelectionCompleteAction,
  RoomsRetrievedAction,
  SpaceBusynessRuleSetRetrieveAction,
  SpaceCategoryActions,
  SpaceCategorySelectedAction,
  SpaceCategorySelectionCompleteAction,
  SpaceMenuPanelCategorySelectedAction,
  SpaceRetrieveActions,
  SpaceSelectedActions
} from "./SpaceActions";
import { IAzureDigitalTwinSpaceRetrieveIncludesRelationship, RoomSubTypes } from "@smartbuilding/adt-v2-types";
import { IBuilding, IRoomInfo, ISpaceInfo, PoiFloorMapping } from "../Types";
import { IInstantBookingConfirmationInfo } from "../../components/DetailsPanel/DetailsPanelProps";
import { ISpaceCategory } from "@smartbuilding/smartbuilding-api-service";
import { SpaceCategoryClearedAction } from ".";
import { getRestrictedSpaces } from "../../constants/RestrictedSpacesConstants";

export function setBuilding(buildingId: string): BuildingSelectedAction {
  return {
    type: SpaceSelectedActions.BUILDING_SELECTED,
    payload: buildingId
  };
}

export function setBuildingToDefaultId(
  upn: string | undefined,
  buildingName?: string,
  floorName?: string | null,
  roomName?: string | null
): DefaultBuildingSelectedAction {
  return {
    payload: { upn, buildingName, floorName, roomName },
    type: SpaceSelectedActions.DEFAULT_SELECTED_BUILDING
  };
}

export function updateBuilding(
  building: IAzureDigitalTwinSpaceRetrieveIncludesRelationship,
  env: string
): BuildingDataRetrievedAction {
  const restrictedSpaces = getRestrictedSpaces(env);
  const buildingId = building.dtId;
  if (buildingId in restrictedSpaces) {
    const floorIdsToRemove = restrictedSpaces[buildingId];
    if (building.children) {
      building.children = building.children.filter((floor) => !floorIdsToRemove.includes(floor.dtId));
    }
  }

  const payload: IBuilding = {
    id: building.dtId,
    regionId: building.regionId,
    name: building.name,
    floors: building.children?.map((floor) => ({
      id: floor.dtId,
      name: floor.name,
      logicalOrder: floor.logicalOrder
    })),
    categories: {},
    location: building.location?.[0],
    mapData: building.mapData?.[0]
      ? {
          datasetId: building.mapData[0].datasetId ? building.mapData[0].datasetId : "",
          tilesetId: building.mapData[0].tilesetId ? building.mapData[0].tilesetId : ""
        }
      : undefined
  };

  return {
    type: SpaceRetrieveActions.BUILDING_DATA_RETRIEVED,
    payload: payload
  };
}

export function buildingsWithMapDataRetrieved(
  buildings: IAzureDigitalTwinSpaceRetrieveIncludesRelationship[]
): BuildingsWithMapDataRetrievedAction {
  const payload: IBuilding[] = [];
  for (const building of buildings) {
    if (building.mapData) {
      payload.push({
        id: building.dtId,
        name: building.name,
        mapData: {
          datasetId: building.mapData[0].datasetId ? building.mapData[0].datasetId : "",
          tilesetId: building.mapData[0].tilesetId ? building.mapData[0].tilesetId : ""
        },
        categories: {},
        regionId: building.regionId
      });
    }
  }

  return {
    type: SpaceRetrieveActions.BUILDINGS_WITH_MAP_DATA_RETRIEVED,
    payload: payload
  };
}

export function retrievedRegionsData(
  regions: IAzureDigitalTwinSpaceRetrieveIncludesRelationship[]
): RegionsDataRetrievedAction {
  const payload: ISpaceInfo[] = [];
  for (const region of regions) {
    payload.push({
      id: region.dtId,
      name: region.name
    });
  }

  return {
    type: SpaceRetrieveActions.REGIONS_DATA_RETRIEVED,
    payload: payload
  };
}

export function setFloor(floorId: string): FloorSelectedAction {
  return {
    type: SpaceSelectedActions.FLOOR_SELECTED,
    payload: floorId
  };
}

export function floorSelected(floorId: string): FloorSelectionCompleteAction {
  return {
    type: SpaceSelectedActions.FLOOR_SELECTION_COMPLETE,
    payload: floorId
  };
}

export function setRoom(
  roomId?: string,
  instantBookingConfirmationInfo?: IInstantBookingConfirmationInfo
): InstantBookingRoomSelectedAction | RoomSelectedAction | RoomSelectionClearedAction {
  if (roomId && instantBookingConfirmationInfo) {
    return {
      type: SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTED,
      payload: { roomId, instantBookingConfirmationInfo }
    };
  } else if (roomId) {
    return {
      type: SpaceSelectedActions.ROOM_SELECTED,
      payload: roomId
    };
  } else {
    return {
      type: SpaceSelectedActions.ROOM_SELECTION_CLEARED
    };
  }
}

export function roomSelected(
  roomId: string,
  instantBookingConfirmationInfo?: IInstantBookingConfirmationInfo
): InstantBookingRoomSelectionCompleteAction | RoomSelectionCompleteAction {
  if (instantBookingConfirmationInfo) {
    return {
      type: SpaceSelectedActions.INSTANT_BOOKING_ROOM_SELECTION_COMPLETE,
      payload: { roomId, instantBookingConfirmationInfo }
    };
  } else {
    return {
      type: SpaceSelectedActions.ROOM_SELECTION_COMPLETE,
      payload: roomId
    };
  }
}

export function roomsRetrieved(buildingId: string, rooms: IRoomInfo[]): RoomsRetrievedAction {
  return {
    type: SpaceRetrieveActions.ROOMS_RETRIEVED,
    payload: { buildingId, data: rooms }
  };
}

export function roomImageRetrieved(roomImages: { id: string; image: string }): RoomImageRetrievedAction {
  return {
    type: SpaceRetrieveActions.ROOM_IMAGE_RETRIEVED,
    payload: roomImages
  };
}

export function pointsOfInterestRetrieved(
  buildingId: string,
  poiFloorMap: PoiFloorMapping
): PointsOfInterestRetrievedAction {
  return {
    type: SpaceRetrieveActions.POINTS_OF_INTEREST_RETRIEVED,
    payload: { buildingId, data: poiFloorMap }
  };
}

export function categoriesRetrieved(
  buildingId: string,
  categories: Record<string, ISpaceCategory>
): CategoriesRetrievedAction {
  return {
    type: SpaceRetrieveActions.CATEGORIES_RETRIEVED,
    payload: { buildingId, categories }
  };
}

export function spaceBusynessRuleSetRetrieved(
  spaceBusynessRuleSet: RoomSubTypes[]
): SpaceBusynessRuleSetRetrieveAction {
  return {
    type: SpaceRetrieveActions.SPACE_BUSYNESS_RULE_SET_RETRIEVED,
    payload: spaceBusynessRuleSet
  };
}

export function setCategory(category?: string): SpaceCategorySelectedAction | SpaceCategoryClearedAction {
  if (category) {
    return {
      type: SpaceCategoryActions.SPACE_CATEGORY_SELECTED,
      payload: category
    };
  } else {
    return {
      type: SpaceCategoryActions.SPACE_CATEGORY_CLEARED
    };
  }
}

export function categorySelectionComplete(spaces: Array<string>): SpaceCategorySelectionCompleteAction {
  return {
    type: SpaceCategoryActions.SPACE_CATEGORY_SELECTED_COMPLETE,
    payload: spaces
  };
}

export function setMenuPanelCategory(category: string): SpaceMenuPanelCategorySelectedAction {
  return {
    type: SpaceCategoryActions.SPACE_MENU_PANEL_CATEGORY_SELECTED,
    payload: category
  };
}

export function setDeepLinkFloor(floorId: string | undefined): DeepLinkFloorSelectAction {
  return {
    type: DeepLinkSpaceSelectActions.DEEP_LINK_FLOOR_SELECT,
    payload: floorId
  };
}

export function setDeepLinkRoom(roomId: string | undefined): DeepLinkRoomSelectAction {
  return {
    type: DeepLinkSpaceSelectActions.DEEP_LINK_ROOM_SELECT,
    payload: roomId
  };
}

import { AdtConverters, IAzureDigitalTwinV3SpaceRetrieve } from "@smartbuilding/utilities";
import {
  Area,
  Building,
  Elevator,
  Floor,
  IAzureDigitalTwinSpaceRetrieveIncludesRelationship,
  LobbyReception,
  Reception,
  Stairwell
} from "@smartbuilding/adt-v2-types";
import { IAction, IBaseAction } from "../Actions/IAction";
import { IDeviceConfigKeyVaultResponse, IDeviceConfigStore, IPathMetadata, ISpaceInfo } from "../Types";
import { IDipResponse, dipSagas, getTwins } from "@dip/redux-sagas";
import {
  ISpace as IDirectionServiceSpace,
  IDirectionsService,
  IRouteData as IDirectionsServiceRouteData,
  TraversalSpaceSubtype
} from "@smartbuilding/directions-service";
import { IElectronDeviceInfo, electronService } from "@smartbuilding/electron-service";
import { IRouteData as IMapRouteData, getDestinationFloor } from "@smartbuilding/azure-maps";
import { IMobileHandOffData, IMyHubUserService } from "@smartbuilding/myhub-api-service";
import {
  IsWayFindingSupportedAction,
  PathFindingRetrieveActions,
  PathFindingSelectionActions,
  SelectPathAction,
  deepLinkDataIdRetrieved,
  pathRetrieved,
  retrievingPath
} from "../Actions";
import { all, call, put, select, takeEvery } from "@redux-saga/core/effects";
import {
  getDeviceConfigData,
  getFloors,
  getListOfRoomsInBuilding,
  getMyHubUrlMap,
  getSelectedPathMapKey,
  getSelectedPathMetadata,
  getWayfindingSupportedStatusMap
} from "../Selectors";

import { IConfigurationService } from "@smartbuilding/configuration-provider";
import { ILogger } from "@smartbuilding/log-provider";
import { IWebClientConfiguration } from "../../constants/ConfigurationConstants";
import { QueryBuilder } from "@dip/querybuilder";
import { WayfindingProperty } from "@dip/adt-types";
import { getRestrictedSpaces } from "../../constants/RestrictedSpacesConstants";
import { isWayFindingSupportedRetrieved } from "../Actions/PathFindingActionCreator";
import moment from "moment";

export class PathFindingSaga {
  private static logTag = "[PathFinding Saga]";
  public constructor(
    private logger: ILogger,
    private directionsService: IDirectionsService,
    private configService: IConfigurationService<IWebClientConfiguration>,
    private myhubUserService: IMyHubUserService
  ) {
    this.watcher = this.watcher.bind(this);
    this.retrieveIsWayFindingSupported = this.retrieveIsWayFindingSupported.bind(this);
    this.handlePathSelection = this.handlePathSelection.bind(this);
    this.retrieveFromKioskLocation = this.retrieveFromKioskLocation.bind(this);
    this.handleSaveDeepLinkDataAction = this.handleSaveDeepLinkDataAction.bind(this);
    this.retrieveDeepLinkData = this.retrieveDeepLinkData.bind(this);
    this.getBuildingDefaultSpace = this.getBuildingDefaultSpace.bind(this);
    this.getLowestFloorStairOrElevator = this.getLowestFloorStairOrElevator.bind(this);
    this.getSpace = this.getSpace.bind(this);
    this.getLobbyOrReception = this.getLobbyOrReception.bind(this);
    this.getStairs = this.getStairs.bind(this);
    this.getElevator = this.getElevator.bind(this);
    this.getWayfindingDefaultSourceSpace = this.getWayfindingDefaultSourceSpace.bind(this);
  }

  public *watcher(): Generator {
    yield all([
      takeEvery(PathFindingSelectionActions.SELECT_PATH, this.handlePathSelection),
      takeEvery(PathFindingRetrieveActions.IS_WAY_FINDING_SUPPORTED, this.retrieveIsWayFindingSupported)
    ]);
  }

  /**
   * Saga that runs after a path has been selected.
   * Will retrieve the path if needed and update the store.
   */
  private *handlePathSelection(action: SelectPathAction): Generator {
    const request = action.payload;
    const pathMetadata = (yield select(getSelectedPathMetadata)) as IPathMetadata;
    if (pathMetadata?.routeData || pathMetadata?.retrieving) {
      if (pathMetadata.qrCodeParams) {
        yield call(this.handleSaveDeepLinkDataAction, pathMetadata.qrCodeParams);
      }
      return;
    }

    yield put(retrievingPath(request));
    const startTime = moment();
    try {
      let response: { routeData: IDirectionsServiceRouteData; qrCodeParams: IMobileHandOffData } | null = null;
      const { srcSpaceId, destSpaceId, traversalType } = request;
      if (srcSpaceId) {
        const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
        let sourceSpace = (yield call(this.getSpace, srcSpaceId)) as IDirectionServiceSpace;

        if (destinationSpace.buildingId !== sourceSpace.buildingId) {
          sourceSpace = (yield call(
            this.getBuildingDefaultSpace,
            destinationSpace.buildingId,
            traversalType
          )) as IDirectionServiceSpace;
        }
        response = (yield call(
          [this.directionsService, this.directionsService.getRoute],
          srcSpaceId,
          destSpaceId,
          sourceSpace,
          destinationSpace,
          traversalType
        )) as { routeData: IDirectionsServiceRouteData; qrCodeParams: IMobileHandOffData };
      } else if (electronService.isElectron()) {
        response = (yield call(this.retrieveFromKioskLocation, destSpaceId, traversalType)) as {
          routeData: IDirectionsServiceRouteData;
          qrCodeParams: IMobileHandOffData;
        };
      } else {
        const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
        let sourceSpace: IDirectionServiceSpace | null = null;
        if (destinationSpace?.buildingId) {
          sourceSpace = (yield call(
            this.getBuildingDefaultSpace,
            destinationSpace.buildingId,
            traversalType
          )) as IDirectionServiceSpace;
        } else {
          throw new Error("No building found for destination space");
        }
        if (!sourceSpace) throw new Error("No source space found");
        response = (yield call(
          [this.directionsService, this.directionsService.getRoute],
          sourceSpace.dtId,
          destSpaceId,
          sourceSpace,
          destinationSpace,
          traversalType
        )) as { routeData: IDirectionsServiceRouteData; qrCodeParams: IMobileHandOffData };
      }
      this.logMetric("Directions Service Response Time Delay", action, moment().diff(startTime, "milliseconds"));

      if (response) {
        const routeData = (yield this.convertRouteData(response.routeData)) as IMapRouteData;
        if (getDestinationFloor(routeData).length) {
          yield put(pathRetrieved(request, routeData, response.qrCodeParams));
        }
        let mobileHandOffData: IMobileHandOffData = response.qrCodeParams;
        if (electronService.isElectron()) {
          const electronInfo = (yield call([
            electronService,
            electronService.getDeviceInfoAsync
          ])) as IElectronDeviceInfo;
          if (electronInfo.location) {
            mobileHandOffData = {
              ...mobileHandOffData,
              sourceCoordinate: { Lat: electronInfo.location.lat, Lng: electronInfo.location.lng }
            };
          }
        }
        yield call(this.handleSaveDeepLinkDataAction, mobileHandOffData);
      } else {
        yield put(pathRetrieved(request, null, undefined, true));
        this.logError(action, new Error("Failed to retrieve path."));
      }
    } catch (error) {
      let errorCode = "404";

      // Cast 'error' to 'unknown' and narrow the type with type checking
      if (error instanceof Error && "response" in error) {
        const err = error as { response: { data: { error: { code: string } } } };
        if (err.response?.data?.error?.code) {
          errorCode = err.response.data.error.code;
        }
      }
      yield put(pathRetrieved(request, null, undefined, true, errorCode));
      this.logError(action, error as Error);
    } finally {
      this.logMetric("Overall PathFinding Response Time Delay", action, moment().diff(startTime, "seconds"));
    }
  }

  /**
   * Saga that runs when a request to verify if pathfinding is supported on a floor is dispatched
   */
  private *retrieveIsWayFindingSupported(action: IsWayFindingSupportedAction): Generator {
    const floorId = action.payload;
    const env = (yield call([this.configService, this.configService.getSetting], "Environment")) as string;
    const restrictedSpace = getRestrictedSpaces(env);
    if (restrictedSpace[floorId]) {
      yield put(isWayFindingSupportedRetrieved(floorId, false));
    }

    const wayFindingSupportedStatus = (yield select(getWayfindingSupportedStatusMap)) as Record<string, boolean>;
    if (wayFindingSupportedStatus[floorId] === undefined) {
      try {
        this.logEvent("Way Finding Request time triggered to check the direction route status", action, {
          floorId
        });
        const isWayfindingSupported = (yield call(
          [this.directionsService, this.directionsService.isWayFindingSupported],
          floorId
        )) as boolean;
        yield put(isWayFindingSupportedRetrieved(floorId, isWayfindingSupported));
        this.logEvent("Response time taken after triggering Status", action, {
          floorId,
          isWayFindingSupportedStatus: isWayfindingSupported.toString()
        });
      } catch (error) {
        this.logError(action, error as Error, {
          floorId,
          errorMessage: `Unable to retrieve the IsWayFindingSupported status for the Floor ID - ${floorId}`
        });
      }
    }
  }

  private *retrieveFromKioskLocation(destSpaceId: string, traversalType: TraversalSpaceSubtype): Generator {
    const electronInfo = (yield call([electronService, electronService.getDeviceInfoAsync])) as IElectronDeviceInfo;
    const buildingRooms = (yield select(getListOfRoomsInBuilding)) as string[];
    let response: IDirectionsServiceRouteData | null = null;
    const deviceConfig = (yield select(getDeviceConfigData)) as IDeviceConfigStore | undefined;
    const kioskLocationsFromKeyVault = (yield call(
      [this.configService, this.configService.getSetting],
      "KioskBlueDotLocationConfig"
    )) as string;
    const kioskLocationsFromVault: IDeviceConfigKeyVaultResponse = JSON.parse(kioskLocationsFromKeyVault as string);
    const deviceConfigFromKeyVault = kioskLocationsFromVault?.kioskLocations.find(
      (k) => k?.hardwareId === deviceConfig?.hardwareId
    );
    if (deviceConfigFromKeyVault && deviceConfigFromKeyVault.spaceId) {
      const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
      if (destinationSpace.buildingId === deviceConfig?.buildingId) {
        const sourceSpace: IDirectionServiceSpace = {
          dtId: deviceConfigFromKeyVault?.spaceId,
          buildingId: deviceConfig?.buildingId,
          floorName: "",
          floorId: deviceConfig.floorId ?? ""
        };
        const response = (yield call(
          [this.directionsService, this.directionsService.getRoute],
          deviceConfigFromKeyVault?.spaceId,
          destSpaceId,
          sourceSpace,
          destinationSpace,
          traversalType
        )) as IDirectionsServiceRouteData;
        return response;
      }
    }
    if (electronInfo.spaceId && electronInfo.location && buildingRooms.includes(electronInfo.spaceId)) {
      const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
      let sourceSpace = (yield call(this.getSpace, electronInfo.spaceId)) as IDirectionServiceSpace;

      if (destinationSpace.buildingId !== sourceSpace.buildingId) {
        sourceSpace = (yield call(
          this.getBuildingDefaultSpace,
          destinationSpace.buildingId,
          traversalType
        )) as IDirectionServiceSpace;
      }

      response = (yield call(
        [this.directionsService, this.directionsService.getRoute],
        electronInfo.spaceId,
        destSpaceId,
        sourceSpace,
        destinationSpace,
        traversalType,
        { Lat: electronInfo.location.lat, Lng: electronInfo.location.lng }
      )) as IDirectionsServiceRouteData;
    } else if (electronInfo.spaceId && buildingRooms.includes(electronInfo.spaceId)) {
      const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
      let sourceSpace = (yield call(this.getSpace, electronInfo.spaceId)) as IDirectionServiceSpace;

      if (destinationSpace.buildingId !== sourceSpace.buildingId) {
        sourceSpace = (yield call(
          this.getBuildingDefaultSpace,
          destinationSpace.buildingId,
          traversalType
        )) as IDirectionServiceSpace;
      }

      response = (yield call(
        [this.directionsService, this.directionsService.getRoute],
        electronInfo.spaceId,
        destSpaceId,
        sourceSpace,
        destinationSpace,
        traversalType
      )) as IDirectionsServiceRouteData;
    } else {
      const destinationSpace = (yield call(this.getSpace, destSpaceId)) as IDirectionServiceSpace;
      let sourceSpace: IDirectionServiceSpace | null = null;
      if (destinationSpace?.buildingId) {
        sourceSpace = (yield call(
          this.getBuildingDefaultSpace,
          destinationSpace.buildingId,
          traversalType
        )) as IDirectionServiceSpace;
      }
      if (sourceSpace) {
        response = (yield call(
          [this.directionsService, this.directionsService.getRoute],
          sourceSpace.dtId,
          destSpaceId,
          sourceSpace,
          destinationSpace,
          traversalType
        )) as IDirectionsServiceRouteData;
      }
    }

    return response;
  }

  private *convertRouteData(routeData: IDirectionsServiceRouteData): Generator {
    const floors = (yield select(getFloors)) as ISpaceInfo[];
    const mapRouteData: IMapRouteData = { ...routeData, route: [] };

    for (const route of routeData.route) {
      mapRouteData.route.push({
        floor: floors.find((floor) => floor.id === route.floorId)?.name ?? "",
        segments: route.segments.map((segment) => ({
          ...segment,
          continuationFloor: floors.find((floor) => floor.id === segment.continuationFloorId)?.name
        }))
      });
    }
    return mapRouteData;
  }

  private *handleSaveDeepLinkDataAction(data: IMobileHandOffData): Generator {
    const key = (yield select(getSelectedPathMapKey)) as string;
    const map = (yield select(getMyHubUrlMap)) as Record<string, string>;

    if (map[key]) {
      this.logger.logEvent(`${PathFindingSaga.logTag} Hitting Cache: ${map[key]}`);
      return;
    } else {
      yield call(this.retrieveDeepLinkData, data);
    }
  }

  private *retrieveDeepLinkData(data: IMobileHandOffData): Generator {
    const baseURL = (yield call([this.configService, this.configService.getSetting], "MyHubBaseQRCodeURL")) as string;
    const startTime = moment();
    try {
      const dataId = (yield call(
        [this.myhubUserService, this.myhubUserService.postPathFindingDataToMyHub],
        data.sourceSpaceId,
        data.destinationSpaceId,
        data.traversalSpaceSubType,
        data.destinationCoordinate
      )) as string;
      const qrcodeUrl = `${baseURL}&Data=${dataId}`;
      this.logger.logEvent(`${PathFindingSaga.logTag}: Successfully connected to MyHub`, { qrcodeUrl });
      yield put(deepLinkDataIdRetrieved(qrcodeUrl, data));
    } catch (error) {
      this.logger.logError(new Error(`${PathFindingSaga.logTag} Failed to call SaveDeepLink API.`), data);
      yield put(deepLinkDataIdRetrieved(baseURL, data));
    } finally {
      this.logger.trackMetric(
        `${PathFindingSaga.logTag} SaveDeepLink API Latency.`,
        moment().diff(startTime, "milliseconds")
      );
    }
  }

  private *getBuildingDefaultSpace(
    destinationSpaceBuildingId: string,
    traversalSpaceSubType?: TraversalSpaceSubtype
  ): Generator {
    const defaultWayfindingSourceSpace = yield call(this.getWayfindingDefaultSourceSpace, destinationSpaceBuildingId);
    if (defaultWayfindingSourceSpace) return defaultWayfindingSourceSpace;
    const buildingStartSpace = yield call(this.getLobbyOrReception, destinationSpaceBuildingId);
    if (buildingStartSpace) return buildingStartSpace;
    return yield call(this.getLowestFloorStairOrElevator, destinationSpaceBuildingId, traversalSpaceSubType);
  }

  private *getWayfindingDefaultSourceSpace(buildingId: string): Generator {
    const wayfindingDefaultSourceQuery = QueryBuilder.from(Building)
      .where((q) => q.compare(Building, (b) => b.$dtId.equals(buildingId)))
      .join(Building, WayfindingProperty, (b) => b.hasWayfindingProperty)
      .select(WayfindingProperty);
    const queryAction = getTwins(wayfindingDefaultSourceQuery, null, true);
    const wayfindingPropertyResponse = (yield dipSagas.get(queryAction)) as IDipResponse<Building>;
    if (
      wayfindingPropertyResponse &&
      Array.isArray(wayfindingPropertyResponse.data) &&
      wayfindingPropertyResponse.data.length > 0
    ) {
      const defaultSourceId: string = wayfindingPropertyResponse.data[0].defaultPathfindingSource;
      if (defaultSourceId) {
        return (yield call(this.getSpace, defaultSourceId)) as IDirectionServiceSpace;
      }
    }

    return null;
  }

  private *getLobbyOrReception(destinationSpaceBuildingId: string): Generator {
    const lobbyReceptionQuery = QueryBuilder.from(LobbyReception)
      .where((q) => q.compare(LobbyReception, (lr) => lr.buildingId.equals(destinationSpaceBuildingId)))
      .join(LobbyReception, Floor, (lr) => lr.hasParent)
      .addSelect(Floor);
    const lobbyResponse = (yield dipSagas.get(getTwins(lobbyReceptionQuery))) as IDipResponse<LobbyReception[]>;
    if (lobbyResponse.data.length > 0) {
      const lobbySpaces = lobbyResponse.data.map((l) =>
        AdtConverters.formatIntoSmartSpace(l as IAzureDigitalTwinV3SpaceRetrieve)
      );
      return this.getFormattedSpace(lobbySpaces);
    } else {
      const receptionQuery = QueryBuilder.from(Reception)
        .where((q) => q.compare(Reception, (lr) => lr.buildingId.equals(destinationSpaceBuildingId)))
        .join(Reception, Floor, (lr) => lr.hasParent)
        .addSelect(Floor);
      const receptionResponse = (yield dipSagas.get(getTwins(receptionQuery))) as IDipResponse<Reception[]>;
      if (receptionResponse.data.length > 0) {
        const receptionSpaces = receptionResponse.data.map((r) =>
          AdtConverters.formatIntoSmartSpace(r as IAzureDigitalTwinV3SpaceRetrieve)
        );
        return this.getFormattedSpace(receptionSpaces);
      }
    }
    return null;
  }

  private *getLowestFloorStairOrElevator(
    destinationSpaceBuildingId: string,
    traversalSpaceSubType?: TraversalSpaceSubtype
  ): Generator {
    const floorsQuery = QueryBuilder.from(Building)
      .where((q) => q.compare(Building, (b) => b.$dtId.equals(destinationSpaceBuildingId)))
      .join(Building, Floor, (b) => b.hasChildren)
      .addSelect(Floor);
    const buildingWithFloorsResponse = (yield dipSagas.get(getTwins(floorsQuery))) as IDipResponse<Building[]>;
    const floors = AdtConverters.formatIntoSmartSpace(
      buildingWithFloorsResponse.data[0] as IAzureDigitalTwinV3SpaceRetrieve
    ).children;
    const lowestFloorId = floors?.sort((floorA, floorB) => {
      if (floorA.properties?.logicalOrder && floorB.properties?.logicalOrder)
        return floorA.properties?.logicalOrder - floorB.properties?.logicalOrder;
      else return parseInt(floorA.name) < parseInt(floorB.name) ? -1 : 1;
    })[0].dtId;

    if (lowestFloorId) {
      if (!traversalSpaceSubType || traversalSpaceSubType === TraversalSpaceSubtype.StairwellArea) {
        // get stairs on lowest floor, fallback to elevator if no stairs
        const stairs = yield call(this.getStairs, lowestFloorId);
        return stairs ?? (yield call(this.getElevator, lowestFloorId));
      } else if (traversalSpaceSubType && traversalSpaceSubType === TraversalSpaceSubtype.ElevatorSpot) {
        // get elevator on lowest floor, fallback to stairs if no elevator
        const elevator = yield call(this.getElevator, lowestFloorId);
        return elevator ?? (yield call(this.getStairs, lowestFloorId));
      }
    }
    return null;
  }

  private *getStairs(lowestFloorId: string): Generator {
    const stairsQuery = QueryBuilder.from(Stairwell)
      .where((q) => q.compare(Stairwell, (s) => s.floorId.equals(lowestFloorId)))
      .join(Stairwell, Floor, (s) => s.hasParent)
      .addSelect(Floor);
    const stairsResponse = (yield dipSagas.get(getTwins(stairsQuery))) as IDipResponse<Stairwell[]>;
    if (stairsResponse.data.length > 0) {
      const stairSpaces = stairsResponse.data.map((s) =>
        AdtConverters.formatIntoSmartSpace(s as IAzureDigitalTwinV3SpaceRetrieve)
      );
      return this.getFormattedSpace(stairSpaces);
    }
    return null;
  }

  private *getElevator(lowestFloorId: string): Generator {
    const elevatorQuery = QueryBuilder.from(Elevator)
      .where((q) => q.compare(Elevator, (e) => e.floorId.equals(lowestFloorId)))
      .join(Elevator, Floor, (e) => e.hasParent)
      .addSelect(Floor);
    const elevatorResponse = (yield dipSagas.get(getTwins(elevatorQuery))) as IDipResponse<Elevator[]>;
    if (elevatorResponse.data.length > 0) {
      const elevatorSpaces = elevatorResponse.data.map((e) =>
        AdtConverters.formatIntoSmartSpace(e as IAzureDigitalTwinV3SpaceRetrieve)
      );
      return this.getFormattedSpace(elevatorSpaces);
    }
    return null;
  }

  private *getSpace(spaceId: string): Generator {
    const query = QueryBuilder.from(Area)
      .where((q) => q.compare(Area, (s) => s.$dtId.equals(spaceId)))
      .join(Area, Floor, (s) => s.hasParent)
      .addSelect(Floor);
    const res = (yield dipSagas.get(getTwins(query))) as IDipResponse<Area[]>;
    if (res.data.length === 0) return null;
    const smartSpace = AdtConverters.formatIntoSmartSpace(res.data[0] as IAzureDigitalTwinV3SpaceRetrieve);
    return this.getFormattedSpace([smartSpace]);
  }

  private getFormattedSpace(
    spaces: IAzureDigitalTwinSpaceRetrieveIncludesRelationship[]
  ): IDirectionServiceSpace | null {
    for (const space of spaces) {
      const sourceSpace = this.formatSpace(space);
      if (sourceSpace) {
        return sourceSpace;
      }
    }
    return null;
  }

  private formatSpace(space: IAzureDigitalTwinSpaceRetrieveIncludesRelationship): IDirectionServiceSpace | null {
    if (space.floorId && space.buildingId && space.parent) {
      return {
        dtId: space.dtId,
        floorName: space.parent.name,
        buildingId: space.buildingId,
        floorId: space.floorId
      };
    }
    return null;
  }

  private logMetric<T, K>(message: string, action: IAction<T, K>, delayInSec: number): void {
    this.logger.trackMetric(`${PathFindingSaga.logTag} ${message}`, delayInSec, undefined, undefined, undefined, {
      Action: JSON.stringify(action)
    });
  }

  private logEvent<T, K>(message: string, action: IAction<T, K>, properties?: Record<string, string>): void {
    let logProperties: Record<string, string> = {};
    if (properties) logProperties = { ...properties, Action: JSON.stringify(action) };
    else logProperties = { Action: JSON.stringify(action) };
    this.logger.logEvent(`${PathFindingSaga.logTag} ${message}`, logProperties);
  }

  private logError<T, K>(
    action: IAction<T, K> | IBaseAction<T>,
    error: Error,
    properties?: Record<string, string>
  ): void {
    let logProperties: Record<string, string> = {};
    if (properties) logProperties = { ...properties, Action: JSON.stringify(action) };
    else logProperties = { Action: JSON.stringify(action) };
    this.logger.logError(error, logProperties);
  }
}

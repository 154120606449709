import { sensorToRoom } from "./IRoomCard.Type";
import { RoomSubTypes, SensorSubTypes, SpaceHasIncludes, SpaceStatus } from "@smartbuilding/adt-v2-types";
import { getRoomCapabilitiesForSpace, getRoomOccupancyStatus, isHotDeskingEnabled } from "./HelpingFunctions";
var RoomCardService = /** @class */ (function () {
    function RoomCardService(logger, spaceRepo, roomImageService) {
        this.logger = logger;
        this.spaceRepo = spaceRepo;
        this.roomImageService = roomImageService;
        this.defaultImagesCount = 5;
        this.serviceTag = "[RoomCardService]";
        this.imgDir = "Assets/MeetingRooms/";
    }
    //TODO: This uses DIP v2, but was not able to find where this function was called.
    //! DEPRECATE/REMOVE if determined to be unused
    RoomCardService.prototype.getListOfRooms = function (buildingSpaceId, roomSubtype) {
        var _this = this;
        var valuesIncludes = [SpaceHasIncludes.HasValues];
        var calendarIncludes = [SpaceHasIncludes.HasCalendar];
        var filter = "buildingId eq '" + buildingSpaceId + "' and status eq '" + SpaceStatus.Active + "'";
        var meetingRoomList = {};
        return this.spaceRepo
            .getSpaces(filter, valuesIncludes)
            .then(function () { return _this.spaceRepo.getSpaces(filter, calendarIncludes); })
            .then(function (spaces) {
            spaces.forEach(function (space) {
                var _a, _b;
                if (space.type !== roomSubtype) {
                    return;
                }
                meetingRoomList[space.dtId] = {
                    id: space.dtId,
                    spaceName: space.name,
                    floorId: space.floorId !== undefined ? space.floorId : "",
                    conferenceRoomSize: (_a = space.calendar) === null || _a === void 0 ? void 0 : _a.capacity,
                    conferenceRoomAlias: (_b = space.calendar) === null || _b === void 0 ? void 0 : _b.alias,
                    direction: _this.getDirection(),
                    roomCapabilities: getRoomCapabilitiesForSpace(space === null || space === void 0 ? void 0 : space.properties),
                    occupancyStatus: (space === null || space === void 0 ? void 0 : space.values) ? getRoomOccupancyStatus(space.values) : "",
                    temperature: _this.getTemperature(),
                    icon: roomSubtype === RoomSubTypes.ConferenceRoom || roomSubtype === RoomSubTypes.FocusRoom
                        ? "Assets/MeetingRooms/0.jpg"
                        : ""
                };
            });
            return meetingRoomList;
        })
            .then(function (roomAttributes) {
            //Iterate over all rooms and ask for their room image
            if (roomSubtype === RoomSubTypes.ConferenceRoom || roomSubtype === RoomSubTypes.FocusRoom) {
                var roomIds = Object.keys(roomAttributes);
                var roomImageUrls = roomIds.map(function (roomId, index) {
                    //! NOTE: if this gets called, it will not return an image.
                    //! Parameter made optional to not have an error here.
                    return _this.roomImageService.getRoomImageUrl(roomId).then(function (imgUrl) {
                        return {
                            roomId: roomId,
                            roomImage: imgUrl
                        };
                    });
                });
                //Wait till room image urls are fetched and copy them into the meeting room data.
                return Promise.all(roomImageUrls).then(function (roomImageData) {
                    var roomImageIndex = 0;
                    for (var _i = 0, roomImageData_1 = roomImageData; _i < roomImageData_1.length; _i++) {
                        var roomData = roomImageData_1[_i];
                        if (roomData.roomImage) {
                            roomAttributes[roomData.roomId].icon = roomData.roomImage;
                        }
                        else {
                            roomAttributes[roomData.roomId].icon = "".concat(_this.imgDir).concat(roomImageIndex % _this.defaultImagesCount, ".jpg");
                            roomImageIndex += 1;
                        }
                    }
                    return roomAttributes;
                });
            }
            else
                return meetingRoomList;
        })
            .catch(function (error) {
            _this.logger.logError(new Error("".concat(_this.serviceTag, " Can't retrieve List of Room information for the following type :\"  ").concat(roomSubtype)));
            return Promise.reject(error);
        });
    };
    // ! DEPRECATE/REMOVE if not being used.
    RoomCardService.prototype.getListOfRoomsForHotDesking = function (buildingSpaceId) {
        var _this = this;
        var includes = [SpaceHasIncludes.HasValues];
        var filter = "buildingId eq '" + buildingSpaceId + "' and status eq '" + SpaceStatus.Active + "'";
        var hotDeskingAvailableCapacityRoomMap = {};
        return this.spaceRepo
            .getSpaces(filter, includes, sensorToRoom[SensorSubTypes.AvailableCapacity])
            .then(function (spaces) {
            spaces.forEach(function (room) {
                if (isHotDeskingEnabled(room)) {
                    var roomCardAttributes = _this.transformHotDeskData(room);
                    if (roomCardAttributes) {
                        hotDeskingAvailableCapacityRoomMap[room.dtId] = roomCardAttributes;
                    }
                }
            });
            return hotDeskingAvailableCapacityRoomMap;
        })
            .catch(function (error) {
            _this.logger.logError(new Error("".concat(_this.serviceTag, " Can't retrieve Hotdesking available capacity spaces for building id:\"  ").concat(buildingSpaceId)));
            return Promise.reject(error);
        });
    };
    RoomCardService.prototype.transformHotDeskData = function (room) {
        var hotdeskwithAvailableCapacity = undefined;
        if (room.values) {
            room.values.forEach(function (value) {
                var _a;
                if (value.$metadata.$model && value.$metadata.$model.indexOf(SensorSubTypes.AvailableCapacity) > -1) {
                    if (value.count) {
                        hotdeskwithAvailableCapacity = {
                            id: room.dtId,
                            floorId: room.floorId ? room.floorId : "",
                            spaceName: room.name,
                            availableCapacity: value.count.value,
                            totalCapacity: ((_a = room.properties) === null || _a === void 0 ? void 0 : _a.seatCount) || 0,
                            featureId: room.featureId ? room.featureId : ""
                        };
                    }
                }
            });
        }
        return hotdeskwithAvailableCapacity;
    };
    RoomCardService.prototype.getDirection = function () {
        // TODO: once direction services are all integrated, we can show directions
        return "1 min (this floor)";
    };
    RoomCardService.prototype.getTemperature = function () {
        // TODO: Greg has removed it from latest design
        return "34 degrees";
    };
    return RoomCardService;
}());
export { RoomCardService };

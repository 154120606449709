var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/*
    Objects returned in response from DIP ADTv2 APIs can get split into two separate objects with same dtId across pages.
    To overcome this, we merge the split objects into a single object.
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergePaginatedResponseObject(lastObject, firstObject) {
    var _a;
    var combinedSpace = {}; // Use Partial<Type> to build the merged object
    for (var _i = 0, _b = Object.entries(lastObject); _i < _b.length; _i++) {
        var _c = _b[_i], key = _c[0], lastObjectValue = _c[1];
        if (Array.isArray(lastObjectValue)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var combinedValue = [];
            var firstObjectValue = firstObject[key];
            if (Array.isArray(firstObjectValue)) {
                combinedValue = __spreadArray(__spreadArray([], (lastObjectValue || []), true), (firstObjectValue || []), true);
            }
            combinedSpace = __assign(__assign(__assign({}, lastObject), firstObject), (_a = {}, _a[key] = combinedValue, _a));
        }
        else {
            combinedSpace = __assign(__assign({}, lastObject), firstObject);
        }
    }
    return combinedSpace;
}
export function extractTypeFromSpace(model) {
    if (!model || model.length === 0) {
        return "";
    }
    var modelArray = model.split(";");
    var arrayWithMetadata = modelArray[0].split(":").pop();
    return arrayWithMetadata !== null && arrayWithMetadata !== void 0 ? arrayWithMetadata : "";
}

export function areStringsEqual(string1, string2) {
    if (string1 && string2 && string1.trim().toLowerCase() === string2.trim().toLowerCase()) {
        return true;
    }
    return false;
}
export function fillStringTemplate(stringTemplate, templateValues) {
    for (var templateKey in templateValues) {
        if (templateValues[templateKey]) {
            var keyRegex = new RegExp(templateKey, "g");
            stringTemplate = stringTemplate.replace(keyRegex, templateValues[templateKey]);
        }
    }
    return stringTemplate;
}
export function makeFirstCharacterUpperCase(value) {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value;
}
export function seperateUppercase(value, seperator) {
    if (!seperator) {
        seperator = " ";
    }
    value = value.replace(/([a-z])([A-Z])/g, "$1" + seperator + "$2");
    return value.replace(/([A-Z])([A-Z][a-z])/g, "$1" + seperator + "$2");
}

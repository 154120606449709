var DirectionsServiceLogMsgs = /** @class */ (function () {
    function DirectionsServiceLogMsgs() {
    }
    DirectionsServiceLogMsgs.invalidArguments = function (fnName) {
        return "[Directions Service] Invalid arguments received. Function: " + fnName;
    };
    DirectionsServiceLogMsgs.failedToRetrieveSpaceInfo = function (spaceId) {
        return "[Directions Service] Failed to retrieve space information for space: " + spaceId;
    };
    DirectionsServiceLogMsgs.failedToRetrieveDefaultSpace = function (buildingId) {
        return "Failed to retrieve default pathfinding space for building: " + buildingId;
    };
    DirectionsServiceLogMsgs.failedToMapPointrToDIP = function (buildingId, levelIndex) {
        return "[Directions Service] Could not map a pointr route to DIP for building ".concat(buildingId, " and Pointr level ").concat(levelIndex);
    };
    DirectionsServiceLogMsgs.failedToMapDIPToPointr = function (buildingId, floorId) {
        return "[Directions Service] Could not map a DIP floor ".concat(floorId, " in building ").concat(buildingId, " to a Pointr Level");
    };
    DirectionsServiceLogMsgs.failedToMapBuildingExternalIdToInternalId = function (buildingExternalId) {
        return "[Directions Service] Could not map building external id ".concat(buildingExternalId, " to internal id");
    };
    DirectionsServiceLogMsgs.routeRequested = "[Directions Service] Route Requested";
    DirectionsServiceLogMsgs.failedToGenerateRoute = "[Directions Service] Failed to generate route";
    DirectionsServiceLogMsgs.wayfindingNotSupported = "[Directions Service] Wayfinding is not supported for the given route";
    DirectionsServiceLogMsgs.defaultPathfindingSpaceIsNotInDip = "[Directions Service] Default PathFinding space is not in DIP";
    return DirectionsServiceLogMsgs;
}());
export { DirectionsServiceLogMsgs };
export var LogPropertyLabels;
(function (LogPropertyLabels) {
    LogPropertyLabels["SourceSpaceId"] = "SourceSpaceId";
    LogPropertyLabels["DestinationSpaceId"] = "DestinationSpaceId";
    LogPropertyLabels["TraversalSpaceType"] = "TraversalSpaceType";
    LogPropertyLabels["TraversalSpaceSubtype"] = "TraversalSpaceSubtype";
    LogPropertyLabels["SourceFloorId"] = "SourceFloorId";
    LogPropertyLabels["DestinationFloorId"] = "DestinationFloorId";
})(LogPropertyLabels || (LogPropertyLabels = {}));

export var SpaceIncludes;
(function (SpaceIncludes) {
    SpaceIncludes["All"] = "all";
    SpaceIncludes["ChildSpaces"] = "childspaces";
    SpaceIncludes["ChildSpacesDescription"] = "childspacesdescription";
    SpaceIncludes["ChildSpacesProperties"] = "childspacesproperties";
    SpaceIncludes["ChildSpacesTypes"] = "childspacestypes";
    SpaceIncludes["Description"] = "description";
    SpaceIncludes["Devices"] = "devices";
    SpaceIncludes["DevicesDescription"] = "devicesdescription";
    SpaceIncludes["DevicesProperties"] = "devicesproperties";
    SpaceIncludes["DevicesSensors"] = "devicessensors";
    SpaceIncludes["DevicesSensorsFullPath"] = "devicessensorsfullpath";
    SpaceIncludes["DevicesSensorsProperties"] = "devicessensorsproperties";
    SpaceIncludes["DevicesSensorsTypes"] = "devicessensorstypes";
    SpaceIncludes["DevicesSensorsValues"] = "devicessenorsvalues";
    SpaceIncludes["DevicesTypes"] = "devicestypes";
    SpaceIncludes["FullPath"] = "fullpath";
    SpaceIncludes["Location"] = "location";
    SpaceIncludes["None"] = "none";
    SpaceIncludes["Parent"] = "parent";
    SpaceIncludes["Properties"] = "properties";
    SpaceIncludes["Resources"] = "resources";
    SpaceIncludes["ResourcesDependantAncestors"] = "resourcesdependantancestors";
    SpaceIncludes["ResourcesDependantChildren"] = "resourcesdependantchildren";
    SpaceIncludes["Sensors"] = "sensors";
    SpaceIncludes["SensorsProperties"] = "sensorsproperties";
    SpaceIncludes["SensorsTypes"] = "sensorstypes";
    SpaceIncludes["SensorsValues"] = "sensorsvalues";
    SpaceIncludes["TimeZone"] = "timezone";
    SpaceIncludes["Types"] = "types";
    SpaceIncludes["Users"] = "users";
    SpaceIncludes["UsersProperties"] = "usersproperties";
    SpaceIncludes["Values"] = "values";
})(SpaceIncludes || (SpaceIncludes = {}));

import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OutletsOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.7803 3.28033C22.0732 2.98744 22.0732 2.51256 21.7803 2.21967C21.4874 1.92678 21.0126 1.92678 20.7197 2.21967L18.7077 4.23161C17.0483 3.05263 14.7323 3.20693 13.2448 4.6945L12.1767 5.76252C11.4933 6.44594 11.4933 7.55398 12.1767 8.2374L15.7625 11.8232C16.446 12.5066 17.554 12.5066 18.2374 11.8232L19.3054 10.7552C20.793 9.26761 20.9473 6.9517 19.7684 5.29228L21.7803 3.28033ZM18.1945 5.75516L18.2173 5.77798L18.2197 5.78033L18.222 5.78267L18.2448 5.80542C19.3187 6.87936 19.3187 8.62056 18.2448 9.6945L17.1767 10.7625C17.0791 10.8602 16.9208 10.8602 16.8232 10.7625L13.2374 7.17674C13.1398 7.07911 13.1398 6.92082 13.2374 6.82318L14.3054 5.75516C15.3794 4.68122 17.1206 4.68122 18.1945 5.75516ZM10.7803 11.2803C11.0732 10.9874 11.0732 10.5126 10.7803 10.2197C10.4874 9.92678 10.0126 9.92678 9.71967 10.2197L8.00001 11.9393L7.53035 11.4697C7.23746 11.1768 6.76258 11.1768 6.46969 11.4697L4.69456 13.2448C3.20701 14.7324 3.0527 17.0483 4.23163 18.7077L2.21967 20.7197C1.92678 21.0126 1.92678 21.4874 2.21967 21.7803C2.51256 22.0732 2.98744 22.0732 3.28033 21.7803L5.29229 19.7684C6.95171 20.9473 9.26766 20.793 10.7552 19.3055L12.5303 17.5303C12.8232 17.2374 12.8232 16.7626 12.5303 16.4697L12.0607 16L13.7803 14.2803C14.0732 13.9874 14.0732 13.5126 13.7803 13.2197C13.4874 12.9268 13.0126 12.9268 12.7197 13.2197L11 14.9393L9.06067 13L10.7803 11.2803ZM7.46631 13.527L7.46967 13.5303L7.47305 13.5337L10.4664 16.527L10.4697 16.5303L10.473 16.5336L10.9394 17L9.69456 18.2448C8.62062 19.3187 6.87942 19.3187 5.80548 18.2448L5.75522 18.1945C4.68128 17.1206 4.68128 15.3794 5.75522 14.3055L7.00002 13.0607L7.46631 13.527Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "OutletsOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OutletsFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.7071 3.70711C22.0976 3.31658 22.0976 2.68342 21.7071 2.29289C21.3166 1.90237 20.6834 1.90237 20.2929 2.29289L18.4955 4.09027C16.8554 3.06792 14.6699 3.26934 13.2448 4.6945L12.1767 5.76252C11.4933 6.44594 11.4933 7.55398 12.1767 8.2374L15.7625 11.8232C16.446 12.5066 17.554 12.5066 18.2374 11.8232L19.3054 10.7552C20.7306 9.33002 20.932 7.14456 19.9097 5.5045L21.7071 3.70711ZM10.7071 11.7071C11.0976 11.3166 11.0976 10.6834 10.7071 10.2929C10.3166 9.90237 9.68342 9.90237 9.29289 10.2929L7.82323 11.7626L7.53035 11.4697C7.23746 11.1768 6.76258 11.1768 6.46969 11.4697L4.69456 13.2448C3.26941 14.6699 3.06799 16.8554 4.09029 18.4955L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L5.5045 19.9097C7.14458 20.932 9.33007 20.7306 10.7552 19.3055L12.5303 17.5303C12.8232 17.2374 12.8232 16.7626 12.5303 16.4697L12.2374 16.1768L13.7071 14.7071C14.0976 14.3166 14.0976 13.6834 13.7071 13.2929C13.3166 12.9024 12.6834 12.9024 12.2929 13.2929L10.8232 14.7626L9.23745 13.1768L10.7071 11.7071Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "OutletsFilledIcon"
});

/*
    createCleanSpaceQuery is used to take any object which matches the ISpaceQuery interface
    and create a clean copy of the object which contains only the SpaceQuery parameters.
    This is useful for creating the query url, which will traverse the object and turn
    its properties into url query params, and for space repo, which uses a hash of the
    query object itself to determine if a different set of space should be returned or not.
*/
export function createCleanSpaceQuery(query) {
    var cleanQuery = {};
    if (Array.isArray(query.includes)) {
        cleanQuery.includes = query.includes.sort();
    }
    if (query.type) {
        cleanQuery.type = query.type;
    }
    if (query.filter) {
        cleanQuery.filter = query.filter;
    }
    if (query.skiptoken) {
        cleanQuery.skiptoken = query.skiptoken;
    }
    return cleanQuery;
}

import { AssignmentUnits, AudienceFilters } from "./Enums";
import { VariantAssignmentServiceClient } from "@azure/exp-variant-assignment";
var FeatureFlightingService = /** @class */ (function () {
    function FeatureFlightingService(userRingService, logger, configuration, settings) {
        this.userRingService = userRingService;
        this.logger = logger;
        this.configuration = configuration;
        this.response = null;
        this.client = new VariantAssignmentServiceClient(settings);
    }
    FeatureFlightingService.prototype.initialize = function () {
        var _this = this;
        if (this.response) {
            return Promise.resolve();
        }
        return this.userRingService
            .getUserRing(this.configuration.clientDevice)
            .then(function (_a) {
            var ring = _a.ring, userId = _a.userId;
            var parameters = new Map();
            parameters.set(AudienceFilters.ClientDevice, [_this.configuration.clientDevice]);
            parameters.set(AudienceFilters.Environment, [_this.configuration.environment]);
            parameters.set(AudienceFilters.Ring, [ring]);
            parameters.set(AudienceFilters.MobileHandoffTesters, [userId]);
            parameters.set(AudienceFilters.SBSTeam, [userId]);
            parameters.set(AssignmentUnits.UserId, [userId]);
            return _this.client.getVariantAssignments({ parameters: parameters });
        })
            .then(function (res) {
            _this.response = res.featureVariables;
        })
            .catch(function (err) {
            var error = new Error("[Feature Flighting Service] Failed to retrieve variant assignment");
            if (err instanceof Error) {
                error.message += err.message;
                error.stack = err.stack;
            }
            _this.logger.logError(error);
            _this.response = [];
        });
    };
    FeatureFlightingService.prototype.getFeatureValue = function (namespace, featureFlagName) {
        if (!this.response) {
            throw new Error("Feature flighting service is not initialized");
        }
        var featureVariable = this.response.find(function (featureVariable) { return featureVariable.Id === namespace; });
        return featureVariable === null || featureVariable === void 0 ? void 0 : featureVariable.Parameters[featureFlagName];
    };
    return FeatureFlightingService;
}());
export { FeatureFlightingService };

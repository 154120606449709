// eslint-disable-next-line @typescript-eslint/naming-convention
export var SpaceTypeNames = {
    ConferenceRoom: "Conference room",
    FocusRoom: "Focus room",
    ProjectRoom: "Project room",
    Atrium: "Atrium",
    AtriumVoid: "Atrium void",
    AudioVideoStudio: "Audio video studio",
    Auditorium: "Auditorium",
    BathRoom: "Bathroom",
    BreakoutWithRegistration: "Breakout with registration",
    BombShelter: "Bomb shelter",
    BuildingPBX: "BuildingPBX",
    CableRoom: "Cable room",
    Cafe: "Cafe",
    Cafeteria: "Cafeteria",
    CafeteriaKitchen: "Cafeteria kitchen",
    CafeteriaSupport: "Cafeteria support",
    CopyRoom: "Copy room",
    CorporateSupport: "Corporate support",
    ConversationRoom: "Conversation room",
    DiningKitchen: "Dining kitchen",
    DiningStorage: "Dining storage",
    DiningServery: "Dining servery",
    DiningSeating: "Dining seating",
    DiningSupport: "Dining support",
    Elevator: "Elevator",
    ElevatorFreight: "Elevator freight",
    EnvisioningCenter: "Envisioning center",
    EVChargingStation: "EV charging station",
    FacilitiesStorage: "Facilities storage",
    GymFitnessCenter: "Gym fitness center",
    Hallway: "Hallway",
    HardwareLab: "Hardware lab",
    Hub: "Hub",
    InnovationCenter: "Innovation center",
    InteractiveCenter: "Interactive center",
    ITPAC: "ITPAC",
    ITSupport: "IT support",
    JanitorCloset: "Janitor closet",
    Kitchen: "Kitchen",
    KitchenetteVending: "Kitchenette vending",
    Lab: "Lab",
    LactationRoom: "Lactation room",
    Library: "Library",
    LoadingReceiving: "Loading receiving room",
    LobbyReception: "Lobby reception",
    Lounge: "Lounge",
    MailRoom: "Mail room",
    MechanicalElectricalRoom: "Mechanical electrical room",
    MicrosoftStore: "Microsoft store",
    MeditationRoom: "Meditation room",
    MeditationMultiFaithRoom: "Meditation multi faith room",
    MothersRoom: "Mothers room",
    MPRSupportAV: "MPR support AV",
    MPRSupportServery: "MPR support servery",
    MPRSupportCoatCloset: "MPR support coat closet",
    MPRSupportFurnitureStorage: "MPR support furniture storage",
    MPRSupportServeryStorage: "MPR support servery storage",
    MultiPurposeRoom: "Multipurpose room",
    Neighborhood: "Neighborhood",
    OccupantStorage: "Occupant storage",
    Office: "Office",
    OpenMeetingArea: "Open meeting area",
    OtherSpace: "Other space",
    OutdoorSpace: "Outdoor space",
    Desk: "Desk",
    ParkingBay: "Parking bay",
    PatioTerrace: "Patio terrace",
    PersonalStorage: "Personal storage",
    PhoneRoom: "Phone room",
    Reception: "Reception",
    RecreationGaming: "Recreation gaming",
    RetailShop: "Retail shop",
    Sauna: "Sauna",
    SecurityRoom: "Security room",
    ServerDisplay: "Server display",
    ServerRoom: "Server room",
    SharedOffice: "Shared office",
    ShowerLockerRoom: "Shower locker room",
    Specialty: "Specialty",
    SpecialtyProjectRoom: "Specialty project room",
    SprinklerRoom: "Sprinkler room",
    Scrum: "Scrum",
    Stairwell: "Stairwell",
    Storage: "Storage",
    StorageClosetCoatLuggage: "Storage closet coat luggage",
    TrainingRoom: "Training room",
    TechnicalBriefingCenter: "Technical briefing center",
    Techlink: "Techlink",
    HotDeskingRoom: "Hot Desking room",
    TeamOffice: "Team office",
    Touchdown: "Touchdown",
    UnderConstruction: "Under construction",
    UsabilityLab: "Usability Lab",
    VerticalPenetration: "Vertical penetration",
    VisitorArea: "Visitor area",
    WaterCoolerPoint: "Water cooler point",
    WellnessRelaxationRoom: "Wellness relaxation room",
    Zone: "Zone"
};

import { MenuSearchClickCallback, MenuSearchType, SearchFilter } from "./MenuSearch.Types";
import { getBuildingMap, getRoomMap } from "../../../redux/Selectors";
import { useCallback, useEffect, useRef } from "react";
import { Guid } from "@smartbuilding/utilities";
import { ILogger } from "@smartbuilding/log-provider";
import { electronService } from "@smartbuilding/electron-service";
import { useSelector } from "react-redux";

const searchLogMessage = "Search Usage Metric";
const searchLogTypeMessages = {
  Start: "Search started",
  End: "Search ended",
  BackButton: "Back Button Clicked",
  SeeAllButton: "See All Button Clicked",
  SearchTerm: "Search Term Typed"
};

export interface IUseMenuSearchLogger {
  onFocus: VoidFunction;
  onBlur: VoidFunction;
  onSearchCallback: MenuSearchClickCallback;
}

export function useMenuSearchLogger(
  searchTerm: string,
  searchFilter: SearchFilter,
  logger: ILogger
): IUseMenuSearchLogger {
  const isSearchSessionActive = useRef(false);
  const isFocused = useRef({ value: false, temp: false });
  const inputSearchTerm = useRef("");
  const correlationId = useRef("");
  const prevSearchFilter = useRef(searchFilter);
  const searchTimeoutRef = useRef<undefined | NodeJS.Timeout>(undefined);
  const buildings = useSelector(getBuildingMap);
  const rooms = useSelector(getRoomMap);
  const searchTimeoutInMs = useRef(110000);

  const logStartSearch = useCallback((): void => {
    correlationId.current = Guid.newGuid().toString();
    logger.logEvent(searchLogMessage, {
      Type: searchLogTypeMessages.Start,
      CorrelationId: correlationId.current
    });
  }, [logger]);

  const logEndSearch = useCallback((): void => {
    logger.logEvent(searchLogMessage, {
      Type: searchLogTypeMessages.End,
      CorrelationId: correlationId.current
    });
  }, [logger]);

  const stopSearchTimer = useCallback((): void => {
    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = undefined;
  }, []);

  const startSearchTimer = useCallback((): void => {
    if (!electronService.isElectron()) return;

    stopSearchTimer();
    searchTimeoutRef.current = setTimeout(() => {
      isSearchSessionActive.current = false;
      logEndSearch();
    }, searchTimeoutInMs.current);
  }, [logEndSearch, stopSearchTimer]);

  const handleFocusChange = useCallback(
    (focus: boolean): void => {
      // Debouncing focus changes since we can get blur events and then focus events when switching between sub-components of the search
      isFocused.current.temp = focus;
      setTimeout(() => {
        isFocused.current.value = isFocused.current.temp;

        // Start session if we gained focus and have not started a session
        if (isFocused.current.value && !isSearchSessionActive.current) {
          isSearchSessionActive.current = true;
          logStartSearch();
          startSearchTimer();
        }

        // End session if we have lost focus and there is an active session
        if (!isFocused.current.value && isSearchSessionActive.current) {
          isSearchSessionActive.current = false;
          logEndSearch();
          stopSearchTimer();
        }
      }, 1);
    },
    [logEndSearch, logStartSearch, startSearchTimer, stopSearchTimer]
  );

  const onSearchCallback = useCallback(
    (id: string, type: MenuSearchType) => {
      isSearchSessionActive.current = false;
      const resultsLog: Record<string, string> = { ResultId: id, ResultCategory: type };
      if (type === "Building") {
        resultsLog["ResultName"] = buildings[id].name;
      } else if (type !== "Person") {
        resultsLog["ResultName"] = rooms[id].name;
      }

      logger.logEvent(searchLogMessage, {
        Type: searchLogTypeMessages.End,
        CorrelationId: correlationId.current,
        SearchTerm: inputSearchTerm.current,
        ...resultsLog
      });
    },
    [logger, buildings, rooms]
  );

  useEffect(() => {
    if (!isSearchSessionActive.current) return;

    inputSearchTerm.current = searchTerm;
    logger.logEvent(searchLogMessage, {
      Type: searchLogTypeMessages.SearchTerm,
      SearchTerm: inputSearchTerm.current,
      CorrelationId: correlationId.current
    });

    startSearchTimer();
  }, [logger, searchTerm, startSearchTimer]);

  useEffect(() => {
    if (!isSearchSessionActive.current) return;

    let filterToLog = searchFilter;
    let searchLogType = searchLogTypeMessages.SeeAllButton;
    if (searchFilter === "Default") {
      searchLogType = searchLogTypeMessages.BackButton;
      filterToLog = prevSearchFilter.current;
    }

    logger.logEvent(searchLogMessage, {
      Type: searchLogType,
      SearchFilter: filterToLog,
      CorrelationId: correlationId.current
    });

    prevSearchFilter.current = searchFilter;
    startSearchTimer();
  }, [logger, searchFilter, startSearchTimer]);

  const onFocus = useCallback(() => handleFocusChange(true), [handleFocusChange]);
  const onBlur = useCallback(() => handleFocusChange(false), [handleFocusChange]);
  return { onFocus, onBlur, onSearchCallback };
}

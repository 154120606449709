import { CustomProperties } from "./CustomProperties";
var SpaceSubscriptionHttpService = /** @class */ (function () {
    function SpaceSubscriptionHttpService(notificationApiUrl, logger, httpService) {
        this.notificationApiUrl = notificationApiUrl;
        this.logger = logger;
        this.httpService = httpService;
        this.serviceTag = "[Space.Subscription.Http.Service]";
        this.groupSubscribeEndpoint = "api/groupsubscribe";
        this.groupUnsubscribeEndpoint = "api/groupunsubscribe";
    }
    SpaceSubscriptionHttpService.prototype.groupSubscribeToSpaces = function (spaceGroups) {
        var _this = this;
        if (!spaceGroups) {
            return this.logAndReject("".concat(this.serviceTag));
        }
        var requestUrl = "".concat(this.notificationApiUrl).concat(this.groupSubscribeEndpoint);
        return this.httpService
            .post(requestUrl, [spaceGroups])
            .then(function (res) {
            return res.data;
        })
            .catch(function (error) {
            return _this.logAndReject("".concat(CustomProperties.GroupSubscribeToSpaces, ":").concat(error.message), error, requestUrl);
        });
    };
    SpaceSubscriptionHttpService.prototype.groupUnsubscribeToSpaces = function (spaceGroups) {
        var _this = this;
        var requestUrl = "".concat(this.notificationApiUrl).concat(this.groupUnsubscribeEndpoint);
        return this.httpService
            .post(requestUrl, [spaceGroups])
            .then(function (res) {
            return res.data;
        })
            .catch(function (error) {
            return _this.logAndReject("".concat(CustomProperties.GroupUnsubscribeToSpaces, ":").concat(error.message), error, requestUrl);
        });
    };
    SpaceSubscriptionHttpService.prototype.logAndReject = function (message, error, url) {
        this.logger.logError(new Error("".concat(this.serviceTag).concat(message)));
        return Promise.reject(error);
    };
    return SpaceSubscriptionHttpService;
}());
export { SpaceSubscriptionHttpService };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RouteIconLabels, routeMarkerColors, routeMarkerIcons } from "../Assets";
export var SymbolIconTemplates;
(function (SymbolIconTemplates) {
    SymbolIconTemplates["Marker"] = "marker";
    SymbolIconTemplates["MarkerThick"] = "marker-thick";
    SymbolIconTemplates["MarkerCircle"] = "marker-circle";
    SymbolIconTemplates["MarkerFlat"] = "marker-flat";
    SymbolIconTemplates["MarkerSquare"] = "marker-square";
    SymbolIconTemplates["MarkerSquareCluster"] = "marker-square-cluster";
    SymbolIconTemplates["MarkerArrow"] = "marker-arrow";
    SymbolIconTemplates["MarkerBall"] = "marker-ball-pin";
    SymbolIconTemplates["MarkerSquareRounded"] = "marker-square-rounded";
    SymbolIconTemplates["MarkerSquareRoundedCluster"] = "marker-square-rounded-cluster";
    SymbolIconTemplates["Flag"] = "flag";
    SymbolIconTemplates["FlagTriangle"] = "flag-triangle";
    SymbolIconTemplates["Triangle"] = "triangle";
    SymbolIconTemplates["TriangleThick"] = "triangle-thick";
    SymbolIconTemplates["TriangleArrowUp"] = "triangle-arrow-up";
    SymbolIconTemplates["TriangleArrowLeft"] = "triangle-arrow-left";
    SymbolIconTemplates["Hexagon"] = "hexagon";
    SymbolIconTemplates["HexagonThick"] = "hexagon-thick";
    SymbolIconTemplates["HexagonRounded"] = "hexagon-rounded";
    SymbolIconTemplates["HexagonRoundedThick"] = "hexagon-rounded-thick";
    SymbolIconTemplates["Pin"] = "pin";
    SymbolIconTemplates["PinRound"] = "pin-round";
    SymbolIconTemplates["RoundedSquare"] = "rounded-square";
    SymbolIconTemplates["RoundedSquareThick"] = "rounded-square-thick";
    SymbolIconTemplates["ArrowUp"] = "arrow-up";
    SymbolIconTemplates["ArrowUpThin"] = "arrow-up-thin";
    SymbolIconTemplates["Car"] = "car";
})(SymbolIconTemplates || (SymbolIconTemplates = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export var IndoorMapMarkers = __assign({}, RouteIconLabels);
export var indoorMapMarkerColor = __assign({}, routeMarkerColors);
export var indoorMapMarkerIcons = __assign({}, routeMarkerIcons);

import { data } from "azure-maps-control";
import { arrowupTemplatedIconId, directionsIndicatorAnchor, directionsIndicatorArrowSize, directionsIndicatorLineSpacing, directionsIndicatorPlacement, dropoffLocationColor, pickupLocationColor, routePathActiveColor, routePathDelayedColor, routePathInActiveColor, routePathStrokeWidth, stopLabelPixelOffsetX, stopLabelPixelOffsetY, vehicleMarkerColor } from "./Constants";
import { connectorSvg } from "./Assets/Svgs/ConnectorSvg";
import { dropoffLocationSvg } from "./Assets/Svgs/DropoffLocationSvg";
import { fixedRouteShuttleSvg } from "./Assets/Svgs/FixedRouteShuttleSvg";
import { getLabelStyles } from "./Styles";
import { pickupLocationSvg } from "./Assets/Svgs/PickupLocationSvg";
import { shuttleSvg } from "./Assets/Svgs/ShuttleSvg";
import { vehicleInTransitSvg } from "./Assets/Svgs/VehicleInTransitSvg";
export function getLineLayerOptions(routeLeg, tripInfo) {
    var tripStatus = tripInfo.tripStatus;
    var strokeColor = tripStatus === "Delayed"
        ? routePathDelayedColor
        : routeLeg.isActive
            ? routePathActiveColor
            : routePathInActiveColor;
    return {
        strokeColor: strokeColor,
        strokeWidth: routePathStrokeWidth,
        lineJoin: "round",
        lineCap: "round"
    };
}
export function getDirectionsIndicatorSymbolLayerOptions() {
    return {
        lineSpacing: directionsIndicatorLineSpacing,
        placement: directionsIndicatorPlacement,
        iconOptions: {
            image: arrowupTemplatedIconId,
            allowOverlap: true,
            anchor: directionsIndicatorAnchor,
            size: directionsIndicatorArrowSize,
            rotation: 90
        }
    };
}
function getVehicleSvg(vehicleType) {
    switch (vehicleType) {
        case "Shuttle":
            return shuttleSvg;
        case "Connector":
            return connectorSvg;
        case "FixedRouteShuttle":
            return fixedRouteShuttleSvg;
        default:
            return "";
    }
}
export function getVehicleHtmlMarkerOptions(vehicle) {
    var _a, _b;
    var vehicleType = vehicle.vehicleType, vehicleCurrentLocation = vehicle.vehicleCurrentLocation;
    return {
        htmlContent: getVehicleSvg(vehicleType),
        anchor: "center",
        color: vehicleMarkerColor,
        position: new data.Position((_a = vehicleCurrentLocation === null || vehicleCurrentLocation === void 0 ? void 0 : vehicleCurrentLocation.waypoint.longitude) !== null && _a !== void 0 ? _a : 0, (_b = vehicleCurrentLocation === null || vehicleCurrentLocation === void 0 ? void 0 : vehicleCurrentLocation.waypoint.latitude) !== null && _b !== void 0 ? _b : 0)
    };
}
export function getVehicleInTransitHtmlMarkerOptions(routeStartingPosition) {
    return {
        htmlContent: vehicleInTransitSvg,
        anchor: "center",
        color: vehicleMarkerColor,
        position: routeStartingPosition
    };
}
export function getHtmlMarkerOptionsFromStop(location) {
    var _a = location.waypoint, latitude = _a.latitude, longitude = _a.longitude, isDropoff = location.isDropoff, isPickup = location.isPickup;
    var svg = isPickup ? pickupLocationSvg : isDropoff ? dropoffLocationSvg : "";
    return {
        htmlContent: svg,
        color: isPickup ? pickupLocationColor : isDropoff ? dropoffLocationColor : "",
        position: new data.Position(longitude, latitude)
    };
}
export function getHtmlMarkerOptionsForLabel(location) {
    var _a = location.waypoint, latitude = _a.latitude, longitude = _a.longitude, name = location.name;
    var _b = getLabelStyles(location), backgroundColor = _b.backgroundColor, textColor = _b.textColor, padding = _b.padding, borderRadius = _b.borderRadius;
    var styledLabel = "<div style=\"background-color: ".concat(backgroundColor, "; color: ").concat(textColor, "; padding: ").concat(padding, ";border-radius: ").concat(borderRadius, "\"><span>").concat(name, "</span></div>");
    return {
        htmlContent: styledLabel,
        position: new data.Position(longitude, latitude),
        pixelOffset: [stopLabelPixelOffsetX, stopLabelPixelOffsetY]
    };
}

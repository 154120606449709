export var FeatureCategory;
(function (FeatureCategory) {
    FeatureCategory["AreaElement"] = "areaElement";
    FeatureCategory["LineElement"] = "lineElement";
    FeatureCategory["Opening"] = "opening";
    FeatureCategory["PointElement"] = "pointElement";
    FeatureCategory["Unit"] = "unit";
    FeatureCategory["VerticalPenetration"] = "verticalPenetration";
    FeatureCategory["Zone"] = "zone";
})(FeatureCategory || (FeatureCategory = {}));
export var AreaElementFeature;
(function (AreaElementFeature) {
    AreaElementFeature["Equipment"] = "equipment";
    AreaElementFeature["EquipmentAccessPoint"] = "equipment.accessPoint";
    AreaElementFeature["EquipmentCopier"] = "equipment.copier";
    AreaElementFeature["EquipmentDevice"] = "equipment.device";
    AreaElementFeature["EquipmentPrinter"] = "equipment.printer";
    AreaElementFeature["Furniture"] = "furniture";
    AreaElementFeature["FurnitureChair"] = "furniture.chair";
    AreaElementFeature["FurnitureDesk"] = "furniture.desk";
    AreaElementFeature["FurnitureDeskMovable"] = "furniture.desk.movable";
    AreaElementFeature["FurnitureDeskShared"] = "furniture.desk.shared";
    AreaElementFeature["FurnitureDisplay"] = "furniture.display";
    AreaElementFeature["FurnitureKiosk"] = "furniture.kiosk";
    AreaElementFeature["FurniturePlant"] = "furniture.plant";
    AreaElementFeature["FurniturePodium"] = "furniture.podium";
    AreaElementFeature["FurnitureStorage"] = "furniture.storage";
    AreaElementFeature["FurnitureTable"] = "furniture.table";
    AreaElementFeature["FurnitureTableConference"] = "furniture.table.conference";
    AreaElementFeature["Structure"] = "structure";
    AreaElementFeature["StructureWall"] = "structure.wall";
})(AreaElementFeature || (AreaElementFeature = {}));
export var LineElementFeature;
(function (LineElementFeature) {
    LineElementFeature["Equipment"] = "equipment";
    LineElementFeature["EquipmentAccessPoint"] = "equipment.accessPoint";
    LineElementFeature["EquipmentCopier"] = "equipment.copier";
    LineElementFeature["EquipmentDevice"] = "equipment.device";
    LineElementFeature["EquipmentPrinter"] = "equipment.printer";
    LineElementFeature["Furniture"] = "furniture";
    LineElementFeature["FurnitureChair"] = "furniture.chair";
    LineElementFeature["FurnitureDesk"] = "furniture.desk";
    LineElementFeature["FurnitureDeskMovable"] = "furniture.desk.movable";
    LineElementFeature["FurnitureDeskShared"] = "furniture.desk.shared";
    LineElementFeature["FurnitureDisplay"] = "furniture.display";
    LineElementFeature["FurnitureKiosk"] = "furniture.kiosk";
    LineElementFeature["FurniturePlant"] = "furniture.plant";
    LineElementFeature["FurniturePodium"] = "furniture.podium";
    LineElementFeature["FurnitureStorage"] = "furniture.storage";
    LineElementFeature["FurnitureTable"] = "furniture.table";
    LineElementFeature["FurnitureTableConference"] = "furniture.table.conference";
})(LineElementFeature || (LineElementFeature = {}));
export var OpeningFeature;
(function (OpeningFeature) {
    OpeningFeature["Opening"] = "opening";
    OpeningFeature["OpeningDoor"] = "opening.door";
})(OpeningFeature || (OpeningFeature = {}));
export var PointElementFeature;
(function (PointElementFeature) {
    PointElementFeature["Equipment"] = "equipment";
    PointElementFeature["EquipmentCopier"] = "equipment.copier";
    PointElementFeature["EquipmentDevice"] = "equipment.device";
    PointElementFeature["EquipmentDeviceWiFiAP"] = "equipment.device.wiFiAP";
    PointElementFeature["EquipmentFireExtinguisher"] = "equipment.fireextinguisher";
    PointElementFeature["EquipmentIoTDevice"] = "equipment.ioT.device";
    PointElementFeature["EquipmentIoTDeviceEdge"] = "equipment.ioT.device.Edge";
    PointElementFeature["EquipmentPrinter"] = "equipment.printer";
})(PointElementFeature || (PointElementFeature = {}));
export var UnitFeature;
(function (UnitFeature) {
    UnitFeature["Room"] = "room";
    UnitFeature["RoomAtrium"] = "room.atrium";
    UnitFeature["RoomAuditorium"] = "room.auditorium";
    UnitFeature["RoomBreak"] = "room.break";
    UnitFeature["RoomCafeteria"] = "room.cafeteria";
    UnitFeature["RoomCirculation"] = "room.circulation";
    UnitFeature["RoomClassroom"] = "room.classroom";
    UnitFeature["RoomCloset"] = "room.closet";
    UnitFeature["RoomClosetCoat"] = "room.closet.coat";
    UnitFeature["RoomClosetJanitorial"] = "room.closet.janitorial";
    UnitFeature["RoomClosetSupply"] = "room.closet.supply";
    UnitFeature["RoomConference"] = "room.conference";
    UnitFeature["RoomConferenceExtraLarge"] = "room.conference.extraLarge";
    UnitFeature["RoomConferenceLarge"] = "room.conference.large";
    UnitFeature["RoomConferenceMedium"] = "room.conference.medium";
    UnitFeature["RoomConferenceSmall"] = "room.conference.small";
    UnitFeature["RoomCopy"] = "room.copy";
    UnitFeature["RoomDataCenter"] = "room.dataCenter";
    UnitFeature["RoomDemo"] = "room.demo";
    UnitFeature["RoomDocuments"] = "room.documents";
    UnitFeature["RoomFocus"] = "room.focus";
    UnitFeature["RoomFoyer"] = "room.foyer";
    UnitFeature["RoomGaming"] = "room.gaming";
    UnitFeature["RoomGym"] = "room.gym";
    UnitFeature["RoomHallway"] = "room.hallway";
    UnitFeature["RoomInterview"] = "room.interview";
    UnitFeature["RoomKitchen"] = "room.kitchen";
    UnitFeature["RoomKitchenette"] = "room.kitchenette";
    UnitFeature["RoomLab"] = "room.lab";
    UnitFeature["RoomLabStorage"] = "room.lab.storage";
    UnitFeature["RoomLaboratory"] = "room.laboratory";
    UnitFeature["RoomLibrary"] = "room.library";
    UnitFeature["RoomLoading"] = "room.loading";
    UnitFeature["RoomLobby"] = "room.lobby";
    UnitFeature["RoomLocker"] = "room.locker";
    UnitFeature["RoomLounge"] = "room.lounge";
    UnitFeature["RoomMakerSpace"] = "room.makerSpace";
    UnitFeature["RoomMechanical"] = "room.mechanical";
    UnitFeature["RoomMultiPurpose"] = "room.multiPurpose";
    UnitFeature["RoomMultiPurposeLarge"] = "room.multiPurpose.large";
    UnitFeature["RoomMultiPurposeMedium"] = "room.multiPurpose.medium";
    UnitFeature["RoomMultiPurposeSmall"] = "room.multiPurpose.small";
    UnitFeature["RoomNewMother"] = "room.newMother";
    UnitFeature["RoomOffice"] = "room.office";
    UnitFeature["RoomOfficeLarge"] = "room.office.large";
    UnitFeature["RoomOfficeMedium"] = "room.office.medium";
    UnitFeature["RoomOfficeOpen"] = "room.office.open";
    UnitFeature["RoomOfficeShared"] = "room.office.shared";
    UnitFeature["RoomOfficeSmall"] = "room.office.small";
    UnitFeature["RoomOfficeTeam"] = "room.office.team";
    UnitFeature["RoomOpenMeeting"] = "room.openMeeting";
    UnitFeature["RoomOther"] = "room.other";
    UnitFeature["RoomPhone"] = "room.phone";
    UnitFeature["RoomProductionFloor"] = "room.production.floor";
    UnitFeature["RoomProductionShipping"] = "room.production.shipping";
    UnitFeature["RoomProductionWarehouse"] = "room.production.warehouse";
    UnitFeature["RoomProject"] = "room.project";
    UnitFeature["RoomReceiving"] = "room.receiving";
    UnitFeature["RoomReception"] = "room.reception";
    UnitFeature["RoomRecording"] = "room.recording";
    UnitFeature["RoomRecordingBooth"] = "room.recording.booth";
    UnitFeature["RoomRecordingStudio"] = "room.recording.studio";
    UnitFeature["RoomRecreation"] = "room.recreation";
    UnitFeature["RoomRelaxation"] = "room.relaxation";
    UnitFeature["RoomRestroom"] = "room.restroom";
    UnitFeature["RoomRestroomAllGender"] = "room.restroom.allGender";
    UnitFeature["RoomRestroomFemale"] = "room.restroom.female";
    UnitFeature["RoomRestroomMale"] = "room.restroom.male";
    UnitFeature["RoomRestroomPrivate"] = "room.restroom.private";
    UnitFeature["RoomRetail"] = "room.retail";
    UnitFeature["RoomSecurity"] = "room.security";
    UnitFeature["RoomServer"] = "room.server";
    UnitFeature["RoomShared"] = "room.shared";
    UnitFeature["RoomShowcase"] = "room.showcase";
    UnitFeature["RoomShower"] = "room.shower";
    UnitFeature["RoomStock"] = "room.stock";
    UnitFeature["RoomStorage"] = "room.storage";
    UnitFeature["RoomStorageSecure"] = "room.storage.secure";
    UnitFeature["RoomStore"] = "room.store";
    UnitFeature["RoomStudio"] = "room.studio";
    UnitFeature["RoomSupply"] = "room.supply";
    UnitFeature["RoomTraining"] = "room.training";
    UnitFeature["RoomUnderConstruction"] = "room.underConstruction";
    UnitFeature["RoomUnknown"] = "room.unknown";
    UnitFeature["RoomUtility"] = "room.utility";
    UnitFeature["RoomUtilityAudioVisual"] = "room.utility.audioVisual";
    UnitFeature["RoomUtilityCable"] = "room.utility.cable";
    UnitFeature["RoomUtilityCablePrivate"] = "room.utility.cable.private";
    UnitFeature["RoomUtilityElectrical"] = "room.utility.electrical";
    UnitFeature["RoomUtilityMechanical"] = "room.utility.mechanical";
    UnitFeature["RoomUtilitySprinkler"] = "room.utility.sprinkler";
    UnitFeature["RoomVending"] = "room.vending";
    UnitFeature["RoomVisitorArea"] = "room.visitorArea";
    UnitFeature["RoomWarehouse"] = "room.warehouse";
    UnitFeature["RoomWellness"] = "room.wellness";
    UnitFeature["RoomWorkspace"] = "room.workspace";
})(UnitFeature || (UnitFeature = {}));
export var VerticalPenetrationFeature;
(function (VerticalPenetrationFeature) {
    VerticalPenetrationFeature["VerticalPenetration"] = "verticalPenetration";
    VerticalPenetrationFeature["VerticalPenetrationElevator"] = "verticalPenetration.elevator";
    VerticalPenetrationFeature["VerticalPenetrationEscalator"] = "verticalPenetration.escalator";
    VerticalPenetrationFeature["VerticalPenetrationLadder"] = "verticalPenetration.ladder";
    VerticalPenetrationFeature["VerticalPenetrationLift"] = "verticalPenetration.lift";
    VerticalPenetrationFeature["VerticalPenetrationRamp"] = "verticalPenetration.ramp";
    VerticalPenetrationFeature["VerticalPenetrationStairs"] = "verticalPenetration.stairs";
    VerticalPenetrationFeature["VerticalPenetrationStairwell"] = "verticalPenetration.stairwell";
})(VerticalPenetrationFeature || (VerticalPenetrationFeature = {}));

var Types = /** @class */ (function () {
    function Types() {
    }
    Types.boolean = "boolean";
    Types.function = "function";
    Types.number = "number";
    Types.object = "object";
    Types.string = "string";
    Types.undefined = "undefined";
    Types.symbol = "symbol";
    return Types;
}());
export { Types };
export default Types;

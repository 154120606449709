import { indoorMarkerBase, outdoorMarkerBase, spacePinIcons } from "../../Assets";
import { indoorMapMarkerColor, indoorMapMarkerIcons } from "../MapIconTemplates";
import { spacePinAssets } from "../../Assets/SpacePins/SpacePinIcons";
var IconSvgCreator = /** @class */ (function () {
    function IconSvgCreator(logger, smartBuildingApiHttp) {
        this.logger = logger;
        this.smartBuildingApiHttp = smartBuildingApiHttp;
        this.domParser = new DOMParser();
        this.createdSvgs = {};
    }
    IconSvgCreator.prototype.createRouteMarkerSvg = function (iconType) {
        if (!this.createdSvgs[iconType]) {
            var svg = this.createIndoorMarkerSvg(indoorMapMarkerIcons[iconType], indoorMapMarkerColor[iconType]);
            this.createdSvgs[iconType] = svg;
        }
        return this.createdSvgs[iconType];
    };
    IconSvgCreator.prototype.createSpacePinSvg = function (iconType, isFilledIcon) {
        var _this = this;
        if (this.createdSvgs[iconType]) {
            return Promise.resolve(this.createdSvgs[iconType]);
        }
        return this.getSpaceIconSvgFromLocal(iconType).then(function (icon) {
            var svg = undefined;
            if (icon) {
                svg = _this.createSvg(spacePinIcons.PinTop, icon);
            }
            else {
                svg = _this.calculateMarkerOffset(_this.getSvgElementFromString(spacePinIcons.PinTop));
            }
            _this.createdSvgs[iconType] = svg;
            return _this.createdSvgs[iconType];
        });
    };
    IconSvgCreator.prototype.scaleMarker = function (iconType, iconSvg, zoomLevel) {
        // Linear scale factor depending on the maximum zoom level, which is 24.
        // https://docs.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraoptions?view=azure-maps-typescript-latest#maxZoom
        var scaleFactor = 1 / 24;
        var baseMarkerSvg = this.getSvgElementFromString(this.createdSvgs[iconType].svg);
        var markerSvg = this.getSvgElementFromString(iconSvg);
        var width = baseMarkerSvg.getAttribute("width");
        var height = baseMarkerSvg.getAttribute("height");
        if (width && height) {
            width = parseInt(width);
            height = parseInt(height);
            markerSvg.setAttribute("width", "".concat(Math.ceil(width * zoomLevel * scaleFactor)));
            markerSvg.setAttribute("height", "".concat(Math.ceil(height * zoomLevel * scaleFactor)));
        }
        return this.calculateMarkerOffset(markerSvg);
    };
    IconSvgCreator.prototype.createIndoorMarkerSvg = function (icon, color) {
        return this.createSvg(indoorMarkerBase, icon, { color: color, tagName: "path" }, 24);
    };
    IconSvgCreator.prototype.createOutdoorMarkerSvg = function (icon, color) {
        return this.createSvg(outdoorMarkerBase, icon, { color: color, tagName: "path" }, 18);
    };
    IconSvgCreator.prototype.createSvg = function (baseIcon, icon, config, preferredIconSize) {
        var baseIconSvg = this.getSvgElementFromString(baseIcon);
        var iconSvg = this.getSvgElementFromString(icon);
        if (config) {
            this.overrideSvgTagFillColor(baseIconSvg, config);
        }
        if (preferredIconSize) {
            this.setIconSvgSize(iconSvg, preferredIconSize);
        }
        this.appendIconToBaseIcon(baseIconSvg, iconSvg, preferredIconSize);
        return this.calculateMarkerOffset(baseIconSvg);
    };
    IconSvgCreator.prototype.appendIconToBaseIcon = function (baseIconSvg, iconSvg, preferredIconSize) {
        var baseIconSvgWidth = baseIconSvg.viewBox.baseVal.width;
        var iconGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
        var iconSize = preferredIconSize ? preferredIconSize : iconSvg.viewBox.baseVal.width;
        var center = (baseIconSvgWidth - iconSize) / 2;
        iconGroup.setAttribute("transform", "translate(".concat(center, ", ").concat(center, ")"));
        iconGroup.appendChild(iconSvg);
        baseIconSvg.appendChild(iconGroup);
    };
    IconSvgCreator.prototype.setIconSvgSize = function (iconSvg, size) {
        iconSvg.setAttribute("width", "".concat(size));
        iconSvg.setAttribute("height", "".concat(size));
    };
    IconSvgCreator.prototype.getSvgElementFromString = function (svgElement) {
        var document = this.domParser.parseFromString(svgElement, "application/xml");
        return document.getElementsByTagName("svg")[0];
    };
    IconSvgCreator.prototype.overrideSvgTagFillColor = function (svgElement, config) {
        var svgTagNameElements = svgElement.getElementsByTagNameNS("http://www.w3.org/2000/svg", config.tagName);
        for (var _i = 0, _a = Array.from(svgTagNameElements); _i < _a.length; _i++) {
            var tagNameElement = _a[_i];
            tagNameElement.setAttribute("fill", config.color);
        }
    };
    IconSvgCreator.prototype.calculateMarkerOffset = function (markerSvg) {
        document.body.appendChild(markerSvg);
        var viewBox = markerSvg.viewBox.baseVal;
        var bbBox = markerSvg.getBBox();
        var ptPixelConversion = 1.33333;
        document.body.removeChild(markerSvg);
        return {
            svg: markerSvg.outerHTML,
            heightOffset: (viewBox.height - bbBox.height) / ptPixelConversion,
            widthOffset: (viewBox.height - bbBox.height) / ptPixelConversion
        };
    };
    IconSvgCreator.prototype.getSpaceIconSvgFromBlob = function (spaceType, filled) {
        var customProperties = { Type: spaceType, IsFilled: filled };
        this.logger.logEvent("Retrieving space icons for space-pin-markers", customProperties);
        if (this.smartBuildingApiHttp) {
            return this.smartBuildingApiHttp.then(function (smartBuildingApiHttp) {
                return smartBuildingApiHttp.getSpacePinIcon(spaceType, filled);
            });
        }
        return Promise.resolve(undefined);
    };
    IconSvgCreator.prototype.getSpaceIconSvgFromLocal = function (spaceType) {
        return Promise.resolve(spacePinAssets[spaceType]).then(function (icon) { return icon; });
    };
    return IconSvgCreator;
}());
export { IconSvgCreator };

import { IPersonBlobImageMap, ISuggestionBlobImageMap, SpacePersonIdMap } from "../Types";
import { IPersonCardData, IPersonSuggestionData } from "@smartbuilding/smartbuilding-api-service";
import { getBuilding, getRoom } from "./SpaceSelectors";
import { IOrganizerDetails } from "@smartbuilding/ui-components-space-card";
import { IPerson } from "@smartbuilding/people-service";
import { RootState } from "../Reducers/RootReducer";
import { createSelector } from "reselect";

export const getCurrentUser = (store: RootState): IOrganizerDetails | undefined => store.people.currentUser;
export const getPeople = (store: RootState): IPerson[] => store.people.people;
export const getSpacePersonIdMap = (store: RootState): SpacePersonIdMap => store.people.spacePeopleMap;
export const getPersonImageMap = (store: RootState): IPersonBlobImageMap => store.people.peopleImageMap;
export const getSuggestionImageMap = (store: RootState): ISuggestionBlobImageMap => store.people.suggestionImageMap;
export const getSelectedPersonFromPeopleStore = (store: RootState): IPerson | undefined => store.people.selectedPerson;
export const getScannedPersonId = (store: RootState): string => store.people.scannedPersonCardId;
export const getBadgeScanStatus = (store: RootState): string => store.people.badgeScanStatus;
export const getPeopleCardMap = (store: RootState): Record<string, IPersonCardData> => store.people.peopleCardMap;
export const getIsPeopleFeatureDisabled = (store: RootState): boolean => store.people.featureDisabled;
export const getPersonCardData = createSelector(
  [getPeopleCardMap, getScannedPersonId],
  (personCardMap, personCardKey): IPersonCardData | undefined => {
    return personCardMap[personCardKey];
  }
);
export const getSuggestionsSearchingKey = (store: RootState): string => store.people.suggestionsSearchingKey;
export const getPeopleSuggestionMap = (store: RootState): Record<string, IPersonSuggestionData[]> =>
  store.people.peopleSuggestionMap;
export const getSuggestions = createSelector(
  [getSuggestionsSearchingKey, getPeopleSuggestionMap],
  (searchingKey, suggestionMap): IPersonSuggestionData[] | undefined => {
    return suggestionMap[searchingKey];
  }
);
export const getPeopleInBuilding = createSelector([getBuilding, getPeople], (building, people) =>
  building ? people.filter((p) => p.buildingId === building.id) : []
);
export const getListOfPeopleAtSpace = createSelector(
  getPeople,
  (store: RootState, spaceId: string) => spaceId,
  (peopleList, spaceId) => peopleList.filter((p) => p.spaceId === spaceId)
);
export const getPeopleInRoom = createSelector([getPeople, getRoom], (people, room): IPerson[] => {
  if (room) {
    return people.filter((p) => p.spaceId === room.id);
  }
  return [];
});

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CentroEnvType, getCentroURl } from "./GetCentroBaseUrl";
import { bootstrapFeature, initialize, preloadFeature } from "@ms/centro-hvc-loader";
import { InAppFeedbackInit } from "./InAppFeedbackInit";
import { getRuntimeStaticConfig } from "./GetRuntimeStaticConfig";
// declaring as variable to reuse
var hvcName = "ocv-inapp-feedback";
/**
 * This is function you call from outside
 * You can change the signature to include appName and language dynamically
 *
 * Details of the data objects and functions can be found here
 * https://projectcentro.azurewebsites.net/docs/#/consume/adapter
 */
export function initializeCentro(logger, ocvClientName, ocvLanguage) {
    var _this = this;
    (function () { return __awaiter(_this, void 0, void 0, function () {
        var clientName, language, dataSources, runtimeStaticConfig, InitializationParameters, e_1, e_2, bootstrapper, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clientName = ocvClientName !== null && ocvClientName !== void 0 ? ocvClientName : "SBSKiosks";
                    language = ocvLanguage !== null && ocvLanguage !== void 0 ? ocvLanguage : "en";
                    dataSources = function (sd) {
                        try {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            sd("centro.hvc.feedback.initOptions", InAppFeedbackInit);
                        }
                        catch (e) {
                            logger.logError(new Error("[SBS-OCV-Feedback] initializeCentro - failed to load datasources with exception ".concat(e)));
                        }
                    };
                    return [4 /*yield*/, getRuntimeStaticConfig(clientName, language)];
                case 1:
                    runtimeStaticConfig = _a.sent();
                    InitializationParameters = {
                        centroEnvironmentBaseUrl: getCentroURl(CentroEnvType.Prod), // Use CentroEnvType.CI if you want to use latest unreleased version
                        centroAdapters: {
                            runtimeStaticConfig: runtimeStaticConfig,
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            request: (function () { })
                        },
                        centroHvcData: "",
                        forHostVersion: "v2" // This will sent the latest compatible version for Major release 2 with minor upgrades or bug fixes. for eg 2.3.4
                    };
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, initialize(InitializationParameters)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    logger.logError(new Error("[SBS-OCV-Feedback] initializeCentro - failed to initialize with exception ".concat(e_1)));
                    return [3 /*break*/, 5];
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, preloadFeature({ featureName: hvcName })];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 7:
                    e_2 = _a.sent();
                    logger.logError(new Error("[SBS-OCV-Feedback] initializeCentro - failed to preloadFeature with exception ".concat(e_2)));
                    return [3 /*break*/, 8];
                case 8:
                    _a.trys.push([8, 11, , 12]);
                    return [4 /*yield*/, bootstrapFeature({
                            featureName: hvcName,
                            setAdditionalDataSource: dataSources
                        })];
                case 9:
                    bootstrapper = _a.sent();
                    // use the main bootstrapper as only main is provided
                    return [4 /*yield*/, bootstrapper.main(document.getElementById("centroRef"))];
                case 10:
                    // use the main bootstrapper as only main is provided
                    _a.sent();
                    return [3 /*break*/, 12];
                case 11:
                    e_3 = _a.sent();
                    logger.logError(new Error("[SBS-OCV-Feedback] initializeCentro - failed to start bootstrapper with exception ".concat(e_3)));
                    return [3 /*break*/, 12];
                case 12: return [2 /*return*/];
            }
        });
    }); })();
}

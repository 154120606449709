import React from "react";
import { createSvgIcon } from "@fluentui/react-icons-mdl2";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ChairsOutlineIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.25 3C4.45507 3 3 4.45507 3 6.25V18.75C3 19.9926 4.00736 21 5.25 21H18.75C19.9926 21 21 19.9926 21 18.75V6.25C21 4.45507 19.5449 3 17.75 3H6.25ZM19.5 11.6273C18.9331 11.2319 18.2436 11 17.5 11C16.3845 11 15.3909 11.5218 14.75 12.3347C14.1091 11.5218 13.1155 11 12 11C10.8845 11 9.89092 11.5218 9.25 12.3347C8.60909 11.5218 7.61548 11 6.5 11C5.75638 11 5.06692 11.2319 4.5 11.6273V6.25C4.5 5.2835 5.2835 4.5 6.25 4.5H17.75C18.7165 4.5 19.5 5.2835 19.5 6.25V11.6273ZM14 14.5V19.5H10V14.5C10 13.3954 10.8954 12.5 12 12.5C13.1046 12.5 14 13.3954 14 14.5ZM15.5 19.5V14.5C15.5 13.3954 16.3954 12.5 17.5 12.5C18.6046 12.5 19.5 13.3954 19.5 14.5V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H15.5ZM5.25 19.5C4.83579 19.5 4.5 19.1642 4.5 18.75V14.5C4.5 13.3954 5.39543 12.5 6.5 12.5C7.60457 12.5 8.5 13.3954 8.5 14.5V19.5H5.25Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "ChairsOutlineIcon"
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ChairsFilledIcon = createSvgIcon({
  // eslint-disable-next-line react/display-name
  svg: (args) => {
    return (
      <svg viewBox="0 0 24 24" fill="none" className={args.classes.svg} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 6.25C3 4.45507 4.45507 3 6.25 3H17.75C19.5449 3 21 4.45507 21 6.25V11.8773C20.3152 11.3284 19.4459 11 18.5 11C17.1612 11 15.9761 11.6577 15.25 12.6676C14.5239 11.6577 13.3388 11 12 11C10.6612 11 9.47606 11.6577 8.75 12.6676C8.02395 11.6577 6.83878 11 5.5 11C4.55405 11 3.6848 11.3284 3 11.8773V6.25ZM14.5 15C14.5 13.6193 13.3807 12.5 12 12.5C10.6193 12.5 9.5 13.6193 9.5 15V21H14.5V15ZM16 21H18.75C19.9926 21 21 19.9926 21 18.75V15C21 13.6193 19.8807 12.5 18.5 12.5C17.1193 12.5 16 13.6193 16 15V21ZM8 21H5.25C4.00736 21 3 19.9926 3 18.75V15C3 13.6193 4.11929 12.5 5.5 12.5C6.88071 12.5 8 13.6193 8 15V21Z"
          fill="currentColor"
        />
      </svg>
    );
  },
  displayName: "ChairsFilledIcon"
});

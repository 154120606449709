import { makeStyles } from "@fluentui/react-make-styles";

export const useFooterButtonsStyles = makeStyles({
  footerButtonsContainer: () => ({
    position: "fixed",
    bottom: "38px",
    right: "0",
    zIndex: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  })
});

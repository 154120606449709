import { IAction, IBaseAction } from "./IAction";

export enum LayoutActionsTypes {
  TOGGLE_DETAILS_PANEL = "TOGGLE_DETAILS_PANEL",
  TOGGLE_MENU_PANEL = "TOGGLE_MENU_PANEL",
  SET_ZOOM_STATE = "SET_IS_ZOOMED",
  SET_DETAILS_PANEL_STATE = "SET_DETAILS_PANEL_STATE",
  SET_MENU_PANEL_STATE = "SET_MENU_PANEL_STATE"
}

export type ToggleDetailsPanelAction = IBaseAction<LayoutActionsTypes.TOGGLE_DETAILS_PANEL>;
export type ToggleMenuPanelAction = IBaseAction<LayoutActionsTypes.TOGGLE_MENU_PANEL>;
export type SetZoomStateAction = IAction<boolean, LayoutActionsTypes.SET_ZOOM_STATE>;
export type SetDetailsPanelStateAction = IAction<boolean, LayoutActionsTypes.SET_DETAILS_PANEL_STATE>;
export type SetMenuPanelStateAction = IAction<boolean, LayoutActionsTypes.SET_MENU_PANEL_STATE>;

export type LayoutActions =
  | ToggleDetailsPanelAction
  | ToggleMenuPanelAction
  | SetZoomStateAction
  | SetDetailsPanelStateAction
  | SetMenuPanelStateAction;

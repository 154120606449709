import { data } from "azure-maps-control";
var MapPanManager = /** @class */ (function () {
    function MapPanManager() {
        var _this = this;
        this.cameraPositionOffset = 0.0000191;
        this.innerBoundaryPercentage = 0.4;
        this.autoPanTimeIntervalInMillisecond = 50;
        this.autoPanMap = function (getCameraBounds, getCameraCenter, getStartMarkerPosition, updateCamera) {
            _this.autoPan = setInterval(function () {
                var cameraCenter = getCameraCenter();
                var outerBounds = getCameraBounds();
                var startMarkerPosition = getStartMarkerPosition();
                if (startMarkerPosition && cameraCenter && outerBounds) {
                    var newCameraCenter = _this.computeNewCameraCenter(startMarkerPosition, cameraCenter, outerBounds);
                    updateCamera(newCameraCenter);
                }
            }, _this.autoPanTimeIntervalInMillisecond);
        };
        this.computeNewCameraCenter = function (position, cameraCenter, cameraOuterBounds) {
            var cameraInnerBounds = _this.computeCameraInnerBounds(cameraOuterBounds);
            var westBound = data.BoundingBox.getWest(cameraInnerBounds);
            var eastBound = data.BoundingBox.getEast(cameraInnerBounds);
            var southBound = data.BoundingBox.getSouth(cameraInnerBounds);
            var northBound = data.BoundingBox.getNorth(cameraInnerBounds);
            var markerLngPosition = position[0];
            var markerLatPosition = position[1];
            if (markerLngPosition < westBound) {
                var newCameraCenter = new data.Position(cameraCenter[0] - _this.cameraPositionOffset, cameraCenter[1]);
                return newCameraCenter;
            }
            if (markerLngPosition > eastBound) {
                var newCameraCenter = new data.Position(cameraCenter[0] + _this.cameraPositionOffset, cameraCenter[1]);
                return newCameraCenter;
            }
            if (markerLatPosition < southBound) {
                var newCameraCenter = new data.Position(cameraCenter[0], cameraCenter[1] - _this.cameraPositionOffset);
                return newCameraCenter;
            }
            if (markerLatPosition > northBound) {
                var newCameraCenter = new data.Position(cameraCenter[0], cameraCenter[1] + _this.cameraPositionOffset);
                return newCameraCenter;
            }
            return cameraCenter;
        };
        this.computeCameraInnerBounds = function (cameraOuterBounds) {
            var latitudeOffset = Math.abs((cameraOuterBounds[0] - cameraOuterBounds[2]) * _this.innerBoundaryPercentage) / 2;
            var longitudeOffset = Math.abs((cameraOuterBounds[1] - cameraOuterBounds[3]) * _this.innerBoundaryPercentage) / 2;
            var westBound = cameraOuterBounds[0] + latitudeOffset;
            var southBound = cameraOuterBounds[1] + longitudeOffset;
            var eastBound = cameraOuterBounds[2] - latitudeOffset;
            var northBound = cameraOuterBounds[3] - longitudeOffset;
            var cameraInnerBounds = data.BoundingBox.fromEdges(westBound, southBound, eastBound, northBound);
            return cameraInnerBounds;
        };
        this.endAutoPan = function () {
            if (!_this.autoPan)
                return;
            clearTimeout(_this.autoPan);
        };
    }
    return MapPanManager;
}());
export var mapPanManager = new MapPanManager();

import { IRoomStatus, evaluateSpaceStatus } from "@smartbuilding/utilities";
import { useEffect, useState } from "react";
import { SpaceStatusColor } from "@smartbuilding/ui-components-space-status";
import { getSensorData } from "@smartbuilding/signalr-redux";
import { useSelector } from "react-redux";

export function useRoomStatus(spaceId: string): IRoomStatus {
  const sensorRoomMap = useSelector(getSensorData);
  const occupancyStatus = sensorRoomMap[spaceId]?.OccupancyStatus;
  /**
   * Effective Date: 2024-08-21
   * Real-time calendar data in no longer available.
   * Commenting out until we have a solution.
   */
  // const conferenceStatus = sensorRoomMap[spaceId]?.ConferenceStatus;
  const [roomStatus, setRoomStatus] = useState({
    status: {
      primaryStatus: "",
      secondaryStatus: "",
      iconName: "",
      statusColor: SpaceStatusColor.Red,
      mapColor: ""
    }
  });

  useEffect(() => {
    if (!sensorRoomMap || !spaceId) {
      return;
    }
    /**
     * Effective Date: 2024-08-21
     * Real-time calendar data in no longer available.
     * Set ConferenceStatus as undefined until we have a solution.
     */
    // setRoomStatus({ status: evaluateSpaceStatus(conferenceStatus, occupancyStatus) });
    setRoomStatus({ status: evaluateSpaceStatus(undefined, occupancyStatus) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorRoomMap, spaceId]);

  return roomStatus;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { data } from "azure-maps-control";
import axios from "axios";
import { AzureMapServiceCache } from "./AzureMapServiceCache";
import { FacilityResponseLinks } from "./FacilityResponseLinks";
var AzureMapService = /** @class */ (function () {
    function AzureMapService(map) {
        var _this = this;
        this.map = map;
        this.apiVersion = "2.0";
        this.cache = new AzureMapServiceCache();
        this.axiosInstance = axios.create();
        this.axiosInstance.interceptors.request.use(function (config) {
            config = __assign(__assign({}, config), { headers: {
                    Authorization: "Bearer ".concat(_this.map.authentication.getToken()),
                    "x-ms-client-id": _this.map.authentication.getClientId()
                } });
            return config;
        });
    }
    /**
     *
     * @deprecated These API calls are very costly and should not be used outside of local development
     * @param datasetId @type {string} datasetId generated when data is onboarded to Azure Maps
     * @returns @type {IFacility} custom model of a facility
     */
    AzureMapService.prototype.getFacility = function (datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var cachedResult, url;
            var _this = this;
            return __generator(this, function (_a) {
                cachedResult = this.cache.getFacility(datasetId);
                if (cachedResult) {
                    return [2 /*return*/, cachedResult];
                }
                url = "https://us.atlas.microsoft.com/wfs/datasets/".concat(datasetId, "/collections/facility/items?api-version=").concat(this.apiVersion);
                return [2 /*return*/, this.axiosInstance.get(url).then(function (res) {
                        var features = res.data.features;
                        if (features.length <= 0) {
                            return Promise.reject("Failed to retrieve facilities with the provided dataset");
                        }
                        var facility = features[0];
                        if (facility && facility.properties && facility.id) {
                            var result = {
                                originalId: facility.properties.originalId,
                                categoryId: facility.properties.categoryId,
                                id: facility.id,
                                name: facility.properties.name
                            };
                            _this.cache.setFacility(datasetId, result);
                            return Promise.resolve(result);
                        }
                        return Promise.reject("Failed to get facility properties");
                    })];
            });
        });
    };
    /**
     *
     * @deprecated These API calls are very costly and should not be used outside of local development
     * @param datasetId @type {string} datasetId generated when data is onboarded to Azure Maps
     * @returns @type {IFacilityLevel[]} custom model of a facility levels
     */
    AzureMapService.prototype.getFacilityLevels = function (datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var cachedResult, url, facilityLevels, response;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        cachedResult = this.cache.getLevels(datasetId);
                        if (cachedResult) {
                            return [2 /*return*/, cachedResult];
                        }
                        url = "https://us.atlas.microsoft.com/wfs/datasets/".concat(datasetId, "/collections/level/items?api-version=").concat(this.apiVersion);
                        facilityLevels = [];
                        _c.label = 1;
                    case 1: return [4 /*yield*/, this.axiosInstance.get(url)];
                    case 2:
                        response = (_c.sent()).data;
                        response.features.forEach(function (level) {
                            if (level && level.properties && level.id) {
                                facilityLevels.push({
                                    bounds: data.BoundingBox.fromData(level.geometry),
                                    facilityId: level.properties.facilityId,
                                    levelId: level.id,
                                    name: level.properties.name,
                                    ordinal: level.properties.ordinal,
                                    originalId: level.properties.originalId,
                                    boundingCoordinates: level.geometry.coordinates
                                });
                            }
                        });
                        url = (_b = (_a = response.links) === null || _a === void 0 ? void 0 : _a.find(function (link) { return link.rel === FacilityResponseLinks.Next; })) === null || _b === void 0 ? void 0 : _b.href;
                        _c.label = 3;
                    case 3:
                        if (url) return [3 /*break*/, 1];
                        _c.label = 4;
                    case 4:
                        this.cache.setLevels(datasetId, facilityLevels);
                        return [2 /*return*/, facilityLevels];
                }
            });
        });
    };
    /**
     * @deprecated These API calls are very costly and should not be used outside of local development
     * @param spaceId @type {string} originalId of a space
     * @param datasetId @type {string} datasetId generated when data is onboarded to Azure Maps
     * @returns @type { IUnitFeature | null } custom model of feature that has the given spaceId.
     */
    AzureMapService.prototype.getUnitFeature = function (spaceId, datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, _i, _a, feature;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = "https://us.atlas.microsoft.com/wfs/datasets/".concat(datasetId, "/collections/unit/items?api-version=").concat(this.apiVersion, "&originalId=").concat(spaceId);
                        return [4 /*yield*/, this.axiosInstance.get(url)];
                    case 1:
                        response = (_b.sent()).data;
                        if (response.numberReturned > 0 && response.features) {
                            for (_i = 0, _a = response.features; _i < _a.length; _i++) {
                                feature = _a[_i];
                                if (feature && feature.properties && feature.properties.originalId === spaceId) {
                                    return [2 /*return*/, {
                                            id: feature.id,
                                            originalId: feature.properties.originalId,
                                            levelId: feature.properties.levelId,
                                            name: feature.properties.name,
                                            boundingBox: data.BoundingBox.fromData(feature.geometry),
                                            boundingCoordinates: feature.geometry.coordinates
                                        }];
                                }
                            }
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     *
     * @deprecated These API calls are very costly and should not be used outside of local development
     * @param datasetId @type {string} datasetId generated when data is onboarded to Azure Maps
     * @returns @type {IUnitFeature} custom model of a features in a building. Includes unit and verticalPenetration.
     */
    AzureMapService.prototype.getAllUnitFeatures = function (datasetId) {
        return __awaiter(this, void 0, void 0, function () {
            var cachedResult, collectionTypes, unitFeatures, _i, collectionTypes_1, collectionType, url, response;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        cachedResult = this.cache.getFeatures(datasetId);
                        if (cachedResult) {
                            return [2 /*return*/, cachedResult];
                        }
                        collectionTypes = ["unit", "verticalPenetration"];
                        unitFeatures = [];
                        _i = 0, collectionTypes_1 = collectionTypes;
                        _c.label = 1;
                    case 1:
                        if (!(_i < collectionTypes_1.length)) return [3 /*break*/, 6];
                        collectionType = collectionTypes_1[_i];
                        url = "https://us.atlas.microsoft.com/wfs/datasets/".concat(datasetId, "/collections/").concat(collectionType, "/items?api-version=").concat(this.apiVersion, "&limit=500");
                        response = void 0;
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.axiosInstance.get(url)];
                    case 3:
                        response = (_c.sent()).data;
                        response.features.forEach(function (feature) {
                            if (feature && feature.properties && feature.id) {
                                unitFeatures.push({
                                    id: feature.id,
                                    originalId: feature.properties.originalId,
                                    levelId: feature.properties.levelId,
                                    name: feature.properties.name,
                                    boundingBox: data.BoundingBox.fromData(feature.geometry),
                                    boundingCoordinates: feature.geometry.coordinates
                                });
                            }
                        });
                        url = (_b = (_a = response.links) === null || _a === void 0 ? void 0 : _a.find(function (link) { return link.rel === FacilityResponseLinks.Next; })) === null || _b === void 0 ? void 0 : _b.href;
                        _c.label = 4;
                    case 4:
                        if (url) return [3 /*break*/, 2];
                        _c.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6:
                        this.cache.setFeatures(datasetId, unitFeatures);
                        return [2 /*return*/, unitFeatures];
                }
            });
        });
    };
    return AzureMapService;
}());
export { AzureMapService };

import {
  IReserveRoomResponse,
  ISlotsInformation,
  ISmartBuildingApiRoomHttp
} from "@smartbuilding/smartbuilding-api-service";
import { ICheckableListItem } from "@smartbuilding/ui-components-list";
import { ILogger } from "@smartbuilding/log-provider";
import { IRoomInfo } from "../../../redux/Types";
import { PageStatus } from "@smartbuilding/ui-components-space-card";
import { Provider } from "../../../serviceContainer/ServiceIdentifiers";
import React from "react";
import { RoomSubTypes } from "@smartbuilding/adt-v2-types";

export function isRoomBookable(space: IRoomInfo): boolean {
  return !!space.cardAttributes.conferenceRoomAlias && space.type !== RoomSubTypes.MultiPurposeRoom;
}

export function getCurrentFreeSlots(
  spaceHttpProvider: Provider<ISmartBuildingApiRoomHttp>,
  alias: string,
  numberOfSlots: number,
  setTimeSlotMap: React.Dispatch<React.SetStateAction<Record<string, ISlotsInformation>>>,
  setAvailableTimes: React.Dispatch<React.SetStateAction<ICheckableListItem[]>>,
  logger: ILogger,
  loggerMsgTitle: string,
  setIsAvailableTimesLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  return spaceHttpProvider()
    .then((spaceBookingService) => {
      setIsAvailableTimesLoading(true);
      return spaceBookingService.getAvailableTimeSlots(alias, numberOfSlots);
    })
    .then((timeSlotList) => {
      const timeSlotRecord: Record<string, ISlotsInformation> = {};
      const availableTimes = timeSlotList.map((timeSlot, index) => {
        const slotKey = `${timeSlot.slotStartTime} - ${timeSlot.slotEndTime}`;
        timeSlotRecord[slotKey] = timeSlot;
        return {
          key: slotKey,
          text: slotKey,
          secondaryText: `(${timeSlot.slotDuration} mins)`,
          prechecked: index === 0
        };
      });
      setTimeSlotMap(timeSlotRecord);
      setAvailableTimes(availableTimes);
      setIsAvailableTimesLoading(false);
    })
    .catch((error) => {
      const errorMsg = `[${loggerMsgTitle}] Failed to get conference room available slots.`;
      setTimeSlotMap({});
      setAvailableTimes([]);
      setIsAvailableTimesLoading(false);
      if (error instanceof Error) {
        error.message = errorMsg + error.message;
        logger.logError(error, { SpaceAlias: alias });
      } else {
        logger.logError(new Error(errorMsg), { SpaceAlias: alias });
      }
    });
}

export function spaceBookingConfirmation(
  spaceHttpProvider: Provider<ISmartBuildingApiRoomHttp>,
  buildingName: string,
  selectedSpace: IRoomInfo,
  alias: string,
  timeSlotInfo: ISlotsInformation,
  userEmail: string,
  subject: string,
  bodyText: string,
  logger: ILogger,
  loggerMsgTitle: string,
  isElectron: boolean,
  setReservationEventId: (value: React.SetStateAction<string>) => void,
  setPageStatus: React.Dispatch<React.SetStateAction<PageStatus>>,
  setReservationMsg: React.Dispatch<React.SetStateAction<string | undefined>>
): Promise<IReserveRoomResponse | void> {
  return spaceHttpProvider()
    .then(
      async (spaceHttp) =>
        await spaceHttp.reserveRoom(
          buildingName,
          selectedSpace.cardAttributes.spaceName,
          alias,
          timeSlotInfo.startTimeUtc,
          timeSlotInfo.endTimeUtc,
          userEmail.substring(0, userEmail.indexOf("@")),
          subject,
          bodyText,
          []
        )
    )
    .then((success: IReserveRoomResponse) => {
      if (success.isBooked) {
        logger.logEvent(`[${loggerMsgTitle}] Space booked successfully by Smart Building Service.`, {
          roomAlias: alias,
          isElectron: isElectron ? "Yes" : "No",
          spaceId: selectedSpace.cardAttributes.id,
          spaceName: selectedSpace.cardAttributes.spaceName,
          buildingName: buildingName,
          spaceType: selectedSpace.type
        });
        setReservationMsg(
          `This room has been successfully booked for you from ${timeSlotInfo.slotStartTime} to ${timeSlotInfo.slotEndTime}`
        );
        setReservationEventId(success.eventId);
        setPageStatus(PageStatus.BookingCancel);
      } else {
        throw new Error();
      }
      return success;
    })
    .catch((error) => {
      setPageStatus(PageStatus.Default);
      const errorMsg = `[${loggerMsgTitle}] Having issues while booking conference room : ${alias}. `;
      if (error instanceof Error) {
        error.message = errorMsg + error.message;
        logger.logError(error);
      } else {
        logger.logError(new Error(errorMsg));
      }
    });
}

export function cancelBookingConfirmation(
  spaceHttpProvider: Provider<ISmartBuildingApiRoomHttp>,
  eventId: string,
  alias: string,
  logger: ILogger,
  loggerMsgTitle: string,
  isElectron: boolean,
  buildingName: string,
  selectedSpace: IRoomInfo,
  cancelBookingHelper: () => void
): Promise<void> {
  return spaceHttpProvider()
    .then((space: ISmartBuildingApiRoomHttp) =>
      space
        .cancelReservation(eventId, alias)
        .then(() => {
          logger.logEvent(`[${loggerMsgTitle}] Space's booking canceled successfully by Smart Building Service`, {
            roomAlias: alias,
            isElectron: isElectron ? "Yes" : "No",
            spaceId: selectedSpace.cardAttributes.id,
            spaceName: selectedSpace.cardAttributes.spaceName,
            buildingName: buildingName,
            spaceType: selectedSpace.type
          });
        })
        .then(() => {
          setTimeout(() => {
            cancelBookingHelper();
          }, 2000);
        })
    )
    .catch((error) => {
      setTimeout(() => {
        cancelBookingHelper();
      }, 2000);
      const errorMsg = `[${loggerMsgTitle}] Having issues while canceling the booking of conference room: ${alias}. `;
      if (error instanceof Error) {
        error.message = errorMsg + error.message;
        logger.logError(error);
      } else {
        logger.logError(new Error(errorMsg));
      }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "../../Styles/RouteOverlay.css";
import { HtmlMarker, Popup, data, layer, math, source } from "azure-maps-control";
import { StartMarkerEvents } from "../../Types/IMoveStartMarker";
import { getContinuationFloor, getDestinationFloor, getFinalSegmentPosition, getInitialSegmentPosition, getPopupOptions, getRouteContinuationFloor, getRoutePositions } from "./RouteOverlayMapUtilties";
import { RouteIconLabels } from "../../Assets";
import { mapPanManager } from "../../MapUtilities/MapPanManager";
import { startMarkerDragIcon } from "../../Assets/Route/IconStart";
var RouteOverlayMapLayer = /** @class */ (function () {
    function RouteOverlayMapLayer(map, indoorMap, iconSvgCreator, routeData, logger, isStartMarkerDraggable, setFloor, getCurrentFloor, getLevelOrdinal, onStartMarkerDroppedCallback, telemetryCallback, bearing) {
        var _this = this;
        var _a, _b, _c;
        this.map = map;
        this.indoorMap = indoorMap;
        this.iconSvgCreator = iconSvgCreator;
        this.routeData = routeData;
        this.logger = logger;
        this.isStartMarkerDraggable = isStartMarkerDraggable;
        this.setFloor = setFloor;
        this.getCurrentFloor = getCurrentFloor;
        this.getLevelOrdinal = getLevelOrdinal;
        this.onStartMarkerDroppedCallback = onStartMarkerDroppedCallback;
        this.telemetryCallback = telemetryCallback;
        this.bearing = bearing;
        this.routeDataSource = {};
        this.routeHtmlMarkers = {};
        this.routeColor = "#0078D4";
        this.routeWidth = 5;
        this.routeMinZoomVisibility = 14.5;
        this.layerId = "DirectionsLayerId";
        this.baseDataSourceId = "DirectionsDataSource";
        this.routeBBPositions = [];
        this.showDestPath = false;
        this.currentRouteData = undefined;
        this.handleLevelChange = function () {
            _this.removeLayers();
            _this.removeMarkers();
            _this.render(_this.getCurrentFloor());
        };
        this.handleZoomUpdate = function () {
            var _a, _b;
            var zoom = (_a = _this.map.getCamera()) === null || _a === void 0 ? void 0 : _a.zoom;
            if (_this.prevZoomUpdate && zoom && Math.abs(_this.prevZoomUpdate - zoom) > 1) {
                _this.prevZoomUpdate = zoom;
                for (var _i = 0, _c = Object.keys(_this.routeHtmlMarkers); _i < _c.length; _i++) {
                    var label = _c[_i];
                    var entry = _this.routeHtmlMarkers[label];
                    if (!entry) {
                        continue;
                    }
                    var markerOptions = entry.getOptions();
                    var svgMarker = _this.iconSvgCreator.scaleMarker(label, markerOptions.htmlContent, zoom);
                    if (zoom <= _this.routeMinZoomVisibility) {
                        entry.setOptions({ visible: false });
                        if (markerOptions.popup)
                            markerOptions.popup.close();
                    }
                    else {
                        entry.setOptions({
                            htmlContent: svgMarker.svg,
                            pixelOffset: [0, svgMarker.heightOffset],
                            visible: true
                        });
                        var contFloor = getRouteContinuationFloor(_this.routeData, _this.getCurrentFloor(), _this.currentRouteData);
                        if (markerOptions.popup && contFloor) {
                            var position = (_b = markerOptions.popup.getOptions()) === null || _b === void 0 ? void 0 : _b.position;
                            markerOptions.popup.setOptions(getPopupOptions(svgMarker, contFloor, position, zoom));
                            markerOptions.popup.open();
                        }
                    }
                }
            }
        };
        this.handlePopupClick = function () {
            var continuationFloor = getRouteContinuationFloor(_this.routeData, _this.getCurrentFloor(), _this.currentRouteData);
            if (continuationFloor) {
                _this.showDestPath = true;
                _this.setFloor(continuationFloor);
            }
        };
        this.subscribeToClickAndDragEvents = function () {
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker)
                return;
            _this.map.events.add(StartMarkerEvents.Click, startMarker, _this.handleStartMarkerClicked);
            _this.map.events.add(StartMarkerEvents.Dragstart, startMarker, _this.handleStartMarkerDragstart);
            _this.map.events.add(StartMarkerEvents.Dragend, startMarker, _this.handleStartMarkerDragend);
        };
        this.handleStartMarkerClicked = function () {
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker)
                return;
            startMarker.setOptions({
                draggable: true,
                htmlContent: startMarkerDragIcon
            });
            _this.map.setCamera({
                center: startMarker.getOptions().position
            });
        };
        this.handleStartMarkerDragstart = function () {
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker)
                return;
            mapPanManager.autoPanMap(_this.getCameraBounds, _this.getCameraCenter, _this.getStartMarkerPosition, _this.updateCamera);
        };
        this.getCameraBounds = function () {
            return _this.map.getCamera().bounds;
        };
        this.getCameraCenter = function () {
            return _this.map.getCamera().center;
        };
        this.getStartMarkerPosition = function () {
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker) {
                return undefined;
            }
            return startMarker.getOptions().position;
        };
        this.updateCamera = function (center) {
            _this.map.setCamera({
                center: center
            });
        };
        this.handleStartMarkerDragend = function () {
            mapPanManager.endAutoPan();
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker)
                return;
            var marker = _this.iconSvgCreator.createRouteMarkerSvg(RouteIconLabels.Start);
            startMarker.setOptions({
                draggable: false,
                htmlContent: marker.svg
            });
            var markerDropPosition = startMarker.getOptions().position;
            if (!markerDropPosition) {
                return;
            }
            var payload = _this.getFeaturePropertyFromPosition(markerDropPosition);
            if (!payload || !_this.onStartMarkerDroppedCallback) {
                return;
            }
            _this.onStartMarkerDroppedCallback(payload);
        };
        this.getFeaturePropertyFromPosition = function (position) {
            var _a;
            var features = _this.map.layers.getRenderedShapes(position, undefined, ["has", "isRoutable"]);
            if (features.length > 0 && features[0].properties) {
                var payload = {
                    featureSpaceId: (_a = features[0].properties) === null || _a === void 0 ? void 0 : _a.originalId,
                    featureLatLng: {
                        latitude: position[1],
                        longitude: position[0]
                    }
                };
                return payload;
            }
            var closestValidFeature = _this.getClosestValidFeature(position);
            return closestValidFeature;
        };
        this.getClosestValidFeature = function (position) {
            var indooMapFeatures = _this.map.layers.getRenderedShapes(undefined, undefined, [
                "has",
                "isRoutable"
            ]);
            var closestValidFeature;
            var closestValidFeaturePosition;
            var distanceToClosestValidPosition = Number.MAX_VALUE;
            indooMapFeatures.forEach(function (feature) {
                feature.geometry.coordinates.forEach(function (point) {
                    point.forEach(function (coordinate) {
                        var distance = math.getDistanceTo(coordinate, position);
                        if (distance < distanceToClosestValidPosition) {
                            distanceToClosestValidPosition = distance;
                            closestValidFeature = feature.properties;
                            closestValidFeaturePosition = coordinate;
                        }
                    });
                });
            });
            var payload;
            if (closestValidFeature && closestValidFeaturePosition) {
                payload = {
                    featureSpaceId: closestValidFeature.originalId,
                    featureLatLng: {
                        latitude: closestValidFeaturePosition[1],
                        longitude: closestValidFeaturePosition[0]
                    }
                };
            }
            return payload;
        };
        this.removeStartMarkerEventsSubscription = function () {
            var startMarker = _this.routeHtmlMarkers[RouteIconLabels.Start];
            if (!startMarker) {
                return;
            }
            _this.map.events.remove(StartMarkerEvents.Click, startMarker, _this.handleStartMarkerClicked);
            _this.map.events.remove(StartMarkerEvents.Dragstart, startMarker, _this.handleStartMarkerDragstart);
            _this.map.events.remove(StartMarkerEvents.Dragend, startMarker, _this.handleStartMarkerDragend);
        };
        this.logger.logEvent("[RouteOverlayMapLayer] Map layer requested");
        if (!((_b = (_a = this.routeData) === null || _a === void 0 ? void 0 : _a.route) === null || _b === void 0 ? void 0 : _b.length)) {
            var errorMsg = new Error("[RouteOverlayMapLayer] Failed to generate route due to invalid parameters");
            this.logger.logError(errorMsg);
            throw errorMsg;
        }
        var floor = this.routeData.route[0].floor;
        this.prevZoomUpdate = (_c = this.map.getCamera()) === null || _c === void 0 ? void 0 : _c.zoom;
        this.setFloor(floor);
        this.render(floor);
        // Binding callbacks for the map
        this.map.events.add("zoom", this.handleZoomUpdate);
        this.map.events.add("levelchanged", this.indoorMap, this.handleLevelChange);
        // Adding popup callback (we don't get that from map)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.popupCallback = this.handlePopupClick;
    }
    RouteOverlayMapLayer.prototype.dispose = function () {
        this.removeLayers();
        this.removeMarkers();
        this.removeDataSource();
        this.removeSubscription();
    };
    RouteOverlayMapLayer.prototype.resetToRoute = function () {
        if (this.routeBBPositions) {
            this.zoomToRoute(this.routeBBPositions);
        }
    };
    RouteOverlayMapLayer.prototype.render = function (floor) {
        var routeLoadStart = performance.now();
        var route = this.routeData.route.find(function (r) { return r.floor === floor; }); // Get segments for current selected floor.
        if (route) {
            var _a = getRoutePositions(route, this.showDestPath), positions = _a.positions, segments = _a.segments;
            var dataSource = this.getRouteDataSourceFromPositions(floor, positions);
            var isContinousRoute = segments.length === route.segments.length;
            this.renderRoute(dataSource, this.layerId, routeLoadStart);
            this.zoomToRoute(positions);
            this.renderMarkers({ segments: segments, floor: floor }, isContinousRoute);
            this.currentRouteData = { segments: segments, floor: floor };
        }
        else {
            this.renderMarkers(undefined);
        }
    };
    RouteOverlayMapLayer.prototype.renderRoute = function (dataSource, layerId, routeLoadStart) {
        this.addDataSourceToMapIfNotAdded(dataSource);
        this.map.layers.add(new layer.LineLayer(dataSource, layerId, {
            strokeColor: this.routeColor,
            strokeWidth: this.routeWidth,
            minZoom: this.routeMinZoomVisibility
        }));
        var routeLoadEnd = performance.now();
        this.logger.logEvent("[RouteOverlayMapLayer] Pathfinding route layer load time", {
            timeInMs: String(routeLoadEnd - routeLoadStart)
        });
        if (this.telemetryCallback) {
            this.telemetryCallback({
                messageName: "[RouteOverlayMapLayer] Pathfinding route layer created",
                timeInMs: routeLoadEnd - routeLoadStart,
                loadEndtime: performance.now(),
                epochTimeMs: Date.now()
            });
        }
    };
    RouteOverlayMapLayer.prototype.renderMarkers = function (route, isContinousRoute) {
        if (route) {
            this.renderRouteMarker(route, isContinousRoute);
        }
        else {
            var initialRoute = this.routeData.route[0];
            var position = getInitialSegmentPosition(initialRoute);
            this.renderTraversalMarker(position, this.isRouteGoingUp(initialRoute));
        }
    };
    RouteOverlayMapLayer.prototype.renderRouteMarker = function (route, isContinousRoute) {
        var isRouteGoingUp = this.isRouteGoingUp(route);
        var start = route.segments[0];
        var end = route.segments[route.segments.length - 1];
        var startPosition = getInitialSegmentPosition(route);
        var endPosition = getFinalSegmentPosition(route);
        if (route.floor === getDestinationFloor(this.routeData) && (isContinousRoute || this.showDestPath)) {
            this.addRouteMarkerToMap(endPosition, RouteIconLabels.End, false, route);
            if (end.lat !== start.lat || end.lng !== start.lng) {
                this.addRouteMarkerToMap(startPosition, RouteIconLabels.Start, false, route);
            }
        }
        else {
            this.addRouteMarkerToMap(startPosition, RouteIconLabels.Start, false, route);
            this.renderTraversalMarker(endPosition, isRouteGoingUp, route);
        }
        if (this.isStartMarkerDraggable) {
            this.subscribeToClickAndDragEvents();
        }
    };
    RouteOverlayMapLayer.prototype.renderTraversalMarker = function (markerPosition, isRouteGoingUp, route) {
        var traversalType = this.routeData.floorTraversalType;
        if (traversalType === "Elevator") {
            var marker = isRouteGoingUp ? RouteIconLabels.ElevatorUp : RouteIconLabels.ElevatorDown;
            this.addRouteMarkerToMap(markerPosition, marker, true, route);
        }
        else if (traversalType === "Stairwell") {
            var marker = isRouteGoingUp ? RouteIconLabels.StairUp : RouteIconLabels.StairDown;
            this.addRouteMarkerToMap(markerPosition, marker, true, route);
        }
    };
    RouteOverlayMapLayer.prototype.addRouteMarkerToMap = function (position, label, addPopup, route) {
        var _a;
        var routeMarker = this.routeHtmlMarkers[label];
        var marker = this.iconSvgCreator.createRouteMarkerSvg(label);
        var htmlOptions = {
            htmlContent: marker.svg,
            position: position,
            visible: true,
            anchor: "bottom",
            draggable: false,
            pixelOffset: [0, marker.heightOffset]
        };
        if (!routeMarker) {
            routeMarker = new HtmlMarker();
            this.map.markers.add(routeMarker);
        }
        var popup = undefined;
        var contFloor = getRouteContinuationFloor(this.routeData, this.getCurrentFloor(), route);
        if (addPopup && contFloor) {
            popup = new Popup(getPopupOptions(marker, contFloor, position, (_a = this.map.getCamera()) === null || _a === void 0 ? void 0 : _a.zoom));
        }
        routeMarker.setOptions(__assign(__assign({}, htmlOptions), { popup: popup }));
        if (popup)
            routeMarker.togglePopup();
        this.routeHtmlMarkers[label] = routeMarker;
    };
    RouteOverlayMapLayer.prototype.zoomToRoute = function (positions) {
        var _a;
        this.routeBBPositions = positions;
        this.map.setCamera({
            bounds: data.BoundingBox.fromPositions(positions),
            padding: { top: 85, bottom: 85, right: 75, left: 75 }
        });
        var currentZoom = (_a = this.map.getCamera().zoom) === null || _a === void 0 ? void 0 : _a.valueOf();
        var zoomStep = 1.5;
        if (currentZoom) {
            this.map.setCamera({ zoom: currentZoom - zoomStep });
        }
        if (this.bearing) {
            this.map.setCamera({ bearing: this.bearing });
        }
    };
    RouteOverlayMapLayer.prototype.addDataSourceToMapIfNotAdded = function (dataSource) {
        var dataSourceId = dataSource.getId();
        if (!this.map.sources.getById(dataSourceId)) {
            this.map.sources.add(dataSource);
        }
    };
    RouteOverlayMapLayer.prototype.getRouteDataSourceFromPositions = function (key, positions) {
        this.removeDataSource();
        var dataSourceId = this.getRouteDataSourceId(key);
        var dataSource = new source.DataSource(dataSourceId);
        dataSource.add(new data.LineString(positions));
        this.routeDataSource[dataSourceId] = dataSource;
        return this.routeDataSource[dataSourceId];
    };
    RouteOverlayMapLayer.prototype.isRouteGoingUp = function (route) {
        var ordinal = this.getLevelOrdinal(route.floor);
        var continuationFloor = getContinuationFloor(route);
        var continuationOrdinal = continuationFloor ? this.getLevelOrdinal(continuationFloor) : undefined;
        if (ordinal !== undefined && continuationOrdinal !== undefined) {
            return continuationOrdinal > ordinal;
        }
        return false;
    };
    RouteOverlayMapLayer.prototype.removeSubscription = function () {
        this.map.events.remove("levelchanged", this.indoorMap, this.handleLevelChange);
        this.map.events.remove("zoom", this.handleZoomUpdate);
    };
    RouteOverlayMapLayer.prototype.getRouteDataSourceId = function (key) {
        return "".concat(this.baseDataSourceId, "-").concat(key);
    };
    RouteOverlayMapLayer.prototype.getRouteMarkerLayerId = function (routeMarker) {
        return "".concat(this.layerId, "-").concat(routeMarker);
    };
    RouteOverlayMapLayer.prototype.removeMarkers = function () {
        this.removeStartMarkerEventsSubscription();
        for (var _i = 0, _a = Object.values(this.routeHtmlMarkers); _i < _a.length; _i++) {
            var marker = _a[_i];
            if (!marker) {
                continue;
            }
            var popup = marker.getOptions().popup;
            if (popup) {
                this.map.popups.remove(popup);
            }
            this.map.markers.remove(marker);
        }
        this.routeHtmlMarkers = {};
    };
    RouteOverlayMapLayer.prototype.removeDataSource = function () {
        for (var _i = 0, _a = Object.keys(this.routeDataSource); _i < _a.length; _i++) {
            var key = _a[_i];
            var dataSourceId = this.routeDataSource[key].getId();
            if (dataSourceId && this.map.sources.getById(dataSourceId)) {
                this.map.sources.remove(dataSourceId);
            }
        }
        this.routeDataSource = {};
    };
    // Removing all added marker layers
    RouteOverlayMapLayer.prototype.removeLayers = function () {
        this.removeLayer(this.layerId);
        for (var _i = 0, _a = Object.values(RouteIconLabels); _i < _a.length; _i++) {
            var routingMarker = _a[_i];
            this.removeLayer(this.getRouteMarkerLayerId(routingMarker));
        }
    };
    RouteOverlayMapLayer.prototype.removeLayer = function (layerId) {
        if (this.map.layers.getLayerById(layerId)) {
            this.map.layers.remove(layerId);
        }
    };
    return RouteOverlayMapLayer;
}());
export { RouteOverlayMapLayer };

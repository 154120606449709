import "../Styles/BluedotMarker.css";
import { HtmlMarker, data } from "azure-maps-control";
export var LocationMode;
(function (LocationMode) {
    LocationMode["GPSLocation"] = "GPSLocation";
    LocationMode["BuildingLocation"] = "BuildingLocation";
})(LocationMode || (LocationMode = {}));
var BluedotMapMarker = /** @class */ (function () {
    function BluedotMapMarker(map, position, locationMode) {
        this.map = map;
        this.bluedotmarker = {};
        this.createBluedot(position, locationMode);
    }
    BluedotMapMarker.prototype.createBluedot = function (position, locationMode) {
        var bluedotPosition = new data.Position(position.longitude, position.latitude);
        //Create a HTML marker and add it to the map.
        var htmlOptions = {
            color: "DodgerBlue",
            htmlContent: this.htmlContent(locationMode),
            position: bluedotPosition,
            visible: true
        };
        this.bluedotmarker = new HtmlMarker(htmlOptions);
        this.map.markers.add(this.bluedotmarker);
    };
    BluedotMapMarker.prototype.removeBluedot = function () {
        this.map.markers.remove(this.bluedotmarker);
    };
    BluedotMapMarker.prototype.htmlContent = function (locationMode) {
        if (locationMode === LocationMode.BuildingLocation) {
            return '<div id="bluedotIcon"></div>';
        }
        return '<div id="pulseIcon"></div>';
    };
    return BluedotMapMarker;
}());
export { BluedotMapMarker };

import { IAuthClient } from "@smartbuilding/auth-client";
import { init } from "@dip/redux-sagas";
import { store } from "../../redux/Store";

export interface IDIPService {
  initialize(): Promise<void>;
}

export class DIPService implements IDIPService {
  public constructor(
    private authClient: IAuthClient,
    private dipAAD: string,
    private dipURL: string,
    private subKey: string
  ) {}

  public async initialize(): Promise<void> {
    if (store.getState().dip.client) return; // prevent multiple initializations
    store.dispatch(init(this.dipURL, "2022-09-01-preview", this.subKey, () => this.authClient.getToken(this.dipAAD)));
  }
}

import { ResetApplicationStateAction, ResetStateActions } from "../Actions/ResetActions";

interface IResetStateStore {
  shouldReset: boolean;
}

export const initialState: IResetStateStore = {
  shouldReset: false
};

export function resetReducer(state = initialState, action: ResetApplicationStateAction): IResetStateStore {
  switch (action.type) {
    case ResetStateActions.RESET_STATE:
      return {
        ...state,
        shouldReset: action.payload
      };
    default:
      return state;
  }
}

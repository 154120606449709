import { IMapControlStyleProps, IMapControlStyles } from "./MapControl.Types";
import { IStyle } from "@fluentui/react";

export const getMapControlStyles = (props: IMapControlStyleProps): IMapControlStyles => {
  const shouldHideControls = props.isZoomed && !props.isMenuPanelOpen;

  const map: IStyle = {
    width: "100%",
    height: "100%"
  };

  const mapControlPanel: IStyle = {
    marginLeft: props.isZoomed ? "14px" : "35.8px",
    display: shouldHideControls ? "none" : "grid",
    gridRowGap: "14px",
    flexDirection: "column",
    height: "fit-content",
    width: "fit-content",
    pointerEvents: "auto"
  };

  const mapControlPanelButton: IStyle = {
    width: "60px",
    height: "60px",
    color: props.theme.semanticColors.buttonText,
    border: `1px solid ${props.theme.palette.white}`,
    backgroundColor: props.theme.semanticColors.menuBackground,
    position: "relative",
    borderRadius: "5px"
  };

  return {
    map,
    mapControlPanel,
    mapControlPanelButton
  };
};

import { IElectronDeviceInfo } from "@smartbuilding/electron-service";
import { IHealthCheckService } from "./HealthCheckService.Types";
import { ILogger } from "@smartbuilding/log-provider";

export class HealthCheckService implements IHealthCheckService {
  private tenMinutesInMilliseconds = 1000 * 60 * 10;
  private logLabel = "[KioskHeartbeat]";
  private kioskDetails: Partial<IElectronDeviceInfo>;

  public constructor(
    private logger: ILogger,
    private deviceInfo: IElectronDeviceInfo
  ) {
    this.kioskDetails = this.getKioskDetails();
  }

  private getKioskDetails = (): Partial<IElectronDeviceInfo> => {
    const details: Record<string, string> = {};

    if (this.deviceInfo) {
      if (this.deviceInfo.advancedHeartbeat) {
        for (const [key, value] of Object.entries(this.deviceInfo)) {
          if (key === "advancedHeartbeat") continue;

          if (!value) details[key] = "";
          else if (typeof value !== "string") details[key] = JSON.stringify(value);
          else details[key] = value;
        }
      }
      details["id"] = this.deviceInfo.id;
    }

    return details;
  };

  private logHeartbeat(): void {
    setInterval(() => {
      if (this.deviceInfo) {
        this.logger.logTrace(this.logLabel, this.kioskDetails as Record<string, string>);
      }
    }, this.tenMinutesInMilliseconds);
  }

  public startServices(): void {
    this.logHeartbeat();
  }
}

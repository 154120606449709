import { IRoomCapability, getRoomCapabilitiesForSpace } from "@smartbuilding/room-card-service";
import { ISpaceCapabilities } from "@smartbuilding/smartbuilding-api-service";

/**
 * Adapter function to convert space capabilities from smartbuilding-api-service to the ones consumed by the application from room card service.
 * @param spaceCapabilities List of space capabilities
 * @returns Record of room capabilities with their description
 */
export function spaceCapabilityConverter(
  spaceCapabilities?: ISpaceCapabilities
): Record<string, IRoomCapability> | undefined {
  if (!spaceCapabilities) {
    return undefined;
  }

  const transformedCapabilityNames: Record<string, boolean> = {};
  for (const capabilityName of Object.keys(spaceCapabilities)) {
    const newName = `${capabilityName.charAt(0).toUpperCase()}${capabilityName.substring(1)}`;
    transformedCapabilityNames[newName] = (spaceCapabilities as unknown as Record<string, boolean>)[capabilityName];
  }

  return getRoomCapabilitiesForSpace({ capabilities: transformedCapabilityNames });
}

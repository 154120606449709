import { all, spawn } from "redux-saga/effects";
import { AppMetricsSaga } from "./AppMetricsSaga";
import { AppSaga } from "./AppSaga";
import { DeviceConfigSaga } from "./DeviceConfigSaga";
import { MapSaga } from "./MapSaga";
import { PathFindingSaga } from "./PathFindingSaga";
import { PeopleSaga } from "./PeopleSaga";
import { SignalRSaga } from "@smartbuilding/signalr-redux";
import { SpaceSaga } from "./SpaceSaga";
import { dipSagas } from "@dip/redux-sagas";

export class RootSaga {
  public constructor(
    private mapSaga: MapSaga,
    private spaceSaga: SpaceSaga,
    private appSaga: AppSaga,
    private peopleSaga: PeopleSaga,
    private pathFindingSaga: PathFindingSaga,
    private appMetricsSaga: AppMetricsSaga,
    private deviceConfigSaga: DeviceConfigSaga,
    private signalRSaga: SignalRSaga
  ) {
    this.start = this.start.bind(this);
  }

  public *start(): Generator {
    yield all([
      spawn(this.spaceSaga.watcher),
      spawn(this.appSaga.watcher),
      spawn(this.peopleSaga.watcher),
      spawn(this.pathFindingSaga.watcher),
      spawn(this.mapSaga.watcher),
      spawn(this.appMetricsSaga.watcher),
      spawn(this.deviceConfigSaga.watcher),
      spawn(this.signalRSaga.watcher),
      spawn(dipSagas.watcher)
    ]);
  }
}

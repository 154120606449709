import {
  ArtFilledIcon,
  ArtOutlineIcon,
  AuditoriumFilledIcon,
  AuditoriumOutlineIcon,
  BathroomFilledIcon,
  BathroomOutlineIcon,
  CafeAndKitchensFilledIcon,
  CafeAndKitchensOutlineIcon,
  CafeFilledIcon,
  CafeOutlineIcon,
  CafeteriaFilledIcon,
  CafeteriaOutlineIcon,
  CafeteriaServeryFilledIcon,
  CafeteriaServeryOutlineIcon,
  ConferenceRoomFilledIcon,
  ConferenceRoomOutlineIcon,
  ConversationRoomFilledIcon,
  ConversationRoomOutlineIcon,
  CopyRoomFilledIcon,
  CopyRoomOutlineIcon,
  CorporateSupportFilledIcon,
  CorporateSupportOutlineIcon,
  DiningSeatingFilledIcon,
  DiningSeatingOutlineIcon,
  EVChargingStationFilledIcon,
  EVChargingStationOutlineIcon,
  ElevatorFilledIcon,
  ElevatorOutlineIcon,
  EnvisioningCenterFilledIcon,
  EnvisioningCenterOutlineIcon,
  FacilitiesFilledIcon,
  FacilitiesOutlineIcon,
  FocusRoomFilledIcon,
  FocusRoomOutlineIcon,
  GymFitnessCenterFilledIcon,
  GymFitnessCenterOutlineIcon,
  ITSupportFilledIcon,
  ITSupportOutlineIcon,
  InnovationCenterFilledIcon,
  InnovationCenterOutlineIcon,
  InteractiveCenterFilledIcon,
  InteractiveCenterOutlineIcon,
  KitchenFilledIcon,
  KitchenOutlineIcon,
  KitchenetteVendingFilledIcon,
  KitchenetteVendingOutlineIcon,
  LabsFilledIcon,
  LabsOutlineIcon,
  LactationRoomFilledIcon,
  LactationRoomOutlineIcon,
  LibraryFilledIcon,
  LibraryOutlineIcon,
  LobbyReceptionFilledIcon,
  LobbyReceptionOutlineIcon,
  LoungeFilledIcon,
  LoungeOutlineIcon,
  MailRoomFilledIcon,
  MailRoomOutlineIcon,
  MeditationMultiFaithRoomFilledIcon,
  MeditationMultiFaithRoomOutlineIcon,
  MeditationRoomFilledIcon,
  MeditationRoomOutlineIcon,
  MeetingSpacesFilledIcon,
  MeetingSpacesOutlineIcon,
  MicrosoftStoreFilledIcon,
  MicrosoftStoreOutlineIcon,
  MothersRoomFilledIcon,
  MothersRoomOutlineIcon,
  MultiPurposeRoomFilledIcon,
  MultiPurposeRoomOutlineIcon,
  NeighborhoodFilledIcon,
  NeighborhoodOutlineIcon,
  OfficeFilledIcon,
  OfficeOutlineIcon,
  OpenMeetingAreaFilledIcon,
  OpenMeetingAreaOutlineIcon,
  OtherFilledIcon,
  OtherOutlineIcon,
  ParkingBayFilledIcon,
  ParkingBayOutlineIcon,
  PatioTerraceFilledIcon,
  PatioTerraceOutlineIcon,
  PhoneRoomFilledIcon,
  PhoneRoomOutlineIcon,
  ProjectRoomFilledIcon,
  ProjectRoomOutlineIcon,
  ReceptionFilledIcon,
  ReceptionOutlineIcon,
  RecreationGaming1FilledIcon,
  RecreationGaming1OutlineIcon,
  RetailShopFilledIcon,
  RetailShopOutlineIcon,
  SaunaFilledIcon,
  SaunaOutlineIcon,
  SharedOfficeFilledIcon,
  SharedOfficeOutlineIcon,
  ShowerLockerRoomFilledIcon,
  ShowerLockerRoomOutlineIcon,
  SpecialtyProjectRoomFilledIcon,
  SpecialtyProjectRoomOutlineIcon,
  StairwellFilledIcon,
  StairwellOutlineIcon,
  SupportAndStoreFilledIcon,
  SupportAndStoreOutlineIcon,
  SvgIcon,
  TeamOfficeFilledIcon,
  TeamOfficeOutlineIcon,
  TechlinkFilledIcon,
  TechlinkOutlineIcon,
  TechnicalBriefingCenterFilledIcon,
  TechnicalBriefingCenterOutlineIcon,
  TouchdownFilledIcon,
  TouchdownOutlineIcon,
  TrainingRoomFilledIcon,
  TrainingRoomOutlineIcon,
  WaterCoolerPointFilledIcon,
  WaterCoolerPointOutlineIcon,
  WellnessFilledIcon,
  WellnessOutlineIcon,
  WorkSpacesFilledIcon,
  WorkSpacesOutlineIcon
} from "@smartbuilding/ui-components-icons";
import { IStyle, IStyleFunction } from "@fluentui/react";
import { ILogger } from "@smartbuilding/log-provider";
import { ISBClientTheme } from "../../theme-provider/Theme.Types";

export interface IMenuPanelBaseProps {
  logger: ILogger;
  theme: ISBClientTheme;
  styles?: IStyleFunction<IMenuPanelStyleProps, IMenuPanelStyles>;
}

export interface IMenuPanelStyleProps {
  theme: ISBClientTheme;
}

export interface IMenuPanelStyles {
  root: IStyle;
  buildingText: IStyle;
}

export const categoryIconMap: Record<string, { base: SvgIcon; hoverIcon: SvgIcon; activeIcon: SvgIcon }> = {
  MeetingSpaces: {
    base: MeetingSpacesOutlineIcon,
    hoverIcon: MeetingSpacesFilledIcon,
    activeIcon: MeetingSpacesFilledIcon
  },
  ConferenceRoom: {
    base: ConferenceRoomOutlineIcon,
    hoverIcon: ConferenceRoomFilledIcon,
    activeIcon: ConferenceRoomFilledIcon
  },
  FocusRoom: {
    base: FocusRoomOutlineIcon,
    hoverIcon: FocusRoomFilledIcon,
    activeIcon: FocusRoomFilledIcon
  },
  ProjectRoom: {
    base: ProjectRoomOutlineIcon,
    hoverIcon: ProjectRoomFilledIcon,
    activeIcon: ProjectRoomFilledIcon
  },
  OpenMeetingArea: {
    base: OpenMeetingAreaOutlineIcon,
    hoverIcon: OpenMeetingAreaFilledIcon,
    activeIcon: OpenMeetingAreaFilledIcon
  },
  ConversationRoom: {
    base: ConversationRoomOutlineIcon,
    hoverIcon: ConversationRoomFilledIcon,
    activeIcon: ConversationRoomFilledIcon
  },
  Touchdown: {
    base: TouchdownOutlineIcon,
    hoverIcon: TouchdownFilledIcon,
    activeIcon: TouchdownFilledIcon
  },
  WorkSpace: {
    base: WorkSpacesOutlineIcon,
    hoverIcon: WorkSpacesFilledIcon,
    activeIcon: WorkSpacesOutlineIcon
  },
  PhoneRoom: {
    base: PhoneRoomOutlineIcon,
    hoverIcon: PhoneRoomFilledIcon,
    activeIcon: PhoneRoomFilledIcon
  },
  Library: {
    base: LibraryOutlineIcon,
    hoverIcon: LibraryFilledIcon,
    activeIcon: LibraryFilledIcon
  },
  Lounge: {
    base: LoungeOutlineIcon,
    hoverIcon: LoungeFilledIcon,
    activeIcon: LoungeFilledIcon
  },
  CafeArea: {
    base: CafeAndKitchensOutlineIcon,
    hoverIcon: CafeAndKitchensFilledIcon,
    activeIcon: CafeAndKitchensFilledIcon
  },
  Cafe: {
    base: CafeOutlineIcon,
    hoverIcon: CafeFilledIcon,
    activeIcon: CafeFilledIcon
  },
  Cafeteria: {
    base: CafeteriaOutlineIcon,
    hoverIcon: CafeteriaFilledIcon,
    activeIcon: CafeteriaFilledIcon
  },
  Kitchen: {
    base: KitchenOutlineIcon,
    hoverIcon: KitchenFilledIcon,
    activeIcon: KitchenFilledIcon
  },
  KitchenetteVending: {
    base: KitchenetteVendingOutlineIcon,
    hoverIcon: KitchenetteVendingFilledIcon,
    activeIcon: KitchenetteVendingFilledIcon
  },
  WaterCoolerPoint: {
    base: WaterCoolerPointOutlineIcon,
    hoverIcon: WaterCoolerPointFilledIcon,
    activeIcon: WaterCoolerPointFilledIcon
  },
  DiningSeating: {
    base: DiningSeatingOutlineIcon,
    hoverIcon: DiningSeatingFilledIcon,
    activeIcon: DiningSeatingFilledIcon
  },
  Facilities: {
    base: FacilitiesOutlineIcon,
    hoverIcon: FacilitiesFilledIcon,
    activeIcon: FacilitiesFilledIcon
  },
  BathRoom: {
    base: BathroomOutlineIcon,
    hoverIcon: BathroomFilledIcon,
    activeIcon: BathroomFilledIcon
  },
  Reception: {
    base: ReceptionOutlineIcon,
    hoverIcon: ReceptionFilledIcon,
    activeIcon: ReceptionFilledIcon
  },
  LobbyReception: {
    base: LobbyReceptionOutlineIcon,
    hoverIcon: LobbyReceptionFilledIcon,
    activeIcon: LobbyReceptionFilledIcon
  },
  Elevator: {
    base: ElevatorOutlineIcon,
    hoverIcon: ElevatorFilledIcon,
    activeIcon: ElevatorFilledIcon
  },
  Stairwell: {
    base: StairwellOutlineIcon,
    hoverIcon: StairwellFilledIcon,
    activeIcon: StairwellFilledIcon
  },
  CopyRoom: {
    base: CopyRoomOutlineIcon,
    hoverIcon: CopyRoomFilledIcon,
    activeIcon: CopyRoomFilledIcon
  },
  MailRoom: {
    base: MailRoomOutlineIcon,
    hoverIcon: MailRoomFilledIcon,
    activeIcon: MailRoomFilledIcon
  },
  HealthAndWellness: {
    base: WellnessOutlineIcon,
    hoverIcon: WellnessFilledIcon,
    activeIcon: WellnessFilledIcon
  },
  LactationRoom: {
    base: LactationRoomOutlineIcon,
    hoverIcon: LactationRoomFilledIcon,
    activeIcon: LactationRoomFilledIcon
  },
  MothersRoom: {
    base: MothersRoomOutlineIcon,
    hoverIcon: MothersRoomFilledIcon,
    activeIcon: MothersRoomFilledIcon
  },
  MeditationRoom: {
    base: MeditationRoomOutlineIcon,
    hoverIcon: MeditationRoomFilledIcon,
    activeIcon: MeditationRoomFilledIcon
  },
  WellnessRelaxationRoom: {
    base: WellnessOutlineIcon,
    hoverIcon: WellnessFilledIcon,
    activeIcon: WellnessFilledIcon
  },
  MeditationMultiFaithRoom: {
    base: MeditationMultiFaithRoomOutlineIcon,
    hoverIcon: MeditationMultiFaithRoomFilledIcon,
    activeIcon: MeditationMultiFaithRoomFilledIcon
  },
  RecreationGaming: {
    base: RecreationGaming1OutlineIcon,
    hoverIcon: RecreationGaming1FilledIcon,
    activeIcon: RecreationGaming1FilledIcon
  },
  Sauna: {
    base: SaunaOutlineIcon,
    hoverIcon: SaunaFilledIcon,
    activeIcon: SaunaFilledIcon
  },
  ShowerLockerRoom: {
    base: ShowerLockerRoomOutlineIcon,
    hoverIcon: ShowerLockerRoomFilledIcon,
    activeIcon: ShowerLockerRoomFilledIcon
  },
  GymFitnessCenter: {
    base: GymFitnessCenterOutlineIcon,
    hoverIcon: GymFitnessCenterFilledIcon,
    activeIcon: GymFitnessCenterFilledIcon
  },
  PatioTerrace: {
    base: PatioTerraceOutlineIcon,
    hoverIcon: PatioTerraceFilledIcon,
    activeIcon: PatioTerraceFilledIcon
  },
  SupportAndStore: {
    base: SupportAndStoreOutlineIcon,
    hoverIcon: SupportAndStoreFilledIcon,
    activeIcon: SupportAndStoreFilledIcon
  },
  Techlink: {
    base: TechlinkOutlineIcon,
    hoverIcon: TechlinkFilledIcon,
    activeIcon: TechlinkFilledIcon
  },
  ITSupport: {
    base: ITSupportOutlineIcon,
    hoverIcon: ITSupportFilledIcon,
    activeIcon: ITSupportFilledIcon
  },
  MicrosoftStore: {
    base: MicrosoftStoreOutlineIcon,
    hoverIcon: MicrosoftStoreFilledIcon,
    activeIcon: MicrosoftStoreFilledIcon
  },
  RetailShop: {
    base: RetailShopOutlineIcon,
    hoverIcon: RetailShopFilledIcon,
    activeIcon: RetailShopFilledIcon
  },
  Labs: {
    base: LabsOutlineIcon,
    hoverIcon: LabsFilledIcon,
    activeIcon: LabsFilledIcon
  },
  Parking: {
    base: ParkingBayOutlineIcon,
    hoverIcon: ParkingBayFilledIcon,
    activeIcon: ParkingBayFilledIcon
  },
  ParkingBay: {
    base: ParkingBayOutlineIcon,
    hoverIcon: ParkingBayFilledIcon,
    activeIcon: ParkingBayFilledIcon
  },
  EVChargingStation: {
    base: EVChargingStationOutlineIcon,
    hoverIcon: EVChargingStationFilledIcon,
    activeIcon: EVChargingStationFilledIcon
  },
  Other: {
    base: OtherOutlineIcon,
    hoverIcon: OtherFilledIcon,
    activeIcon: OtherFilledIcon
  },
  Atrium: {
    //TODO: update when icon is available
    base: ArtOutlineIcon,
    hoverIcon: ArtFilledIcon,
    activeIcon: ArtFilledIcon
  },
  Auditorium: {
    base: AuditoriumOutlineIcon,
    hoverIcon: AuditoriumFilledIcon,
    activeIcon: AuditoriumFilledIcon
  },
  CafeteriaServery: {
    base: CafeteriaServeryOutlineIcon,
    hoverIcon: CafeteriaServeryFilledIcon,
    activeIcon: CafeteriaServeryFilledIcon
  },
  CorporateSupport: {
    base: CorporateSupportOutlineIcon,
    hoverIcon: CorporateSupportFilledIcon,
    activeIcon: CorporateSupportFilledIcon
  },
  EnvisioningCenter: {
    base: EnvisioningCenterOutlineIcon,
    hoverIcon: EnvisioningCenterFilledIcon,
    activeIcon: EnvisioningCenterFilledIcon
  },
  InnovationCenter: {
    base: InnovationCenterOutlineIcon,
    hoverIcon: InnovationCenterFilledIcon,
    activeIcon: InnovationCenterFilledIcon
  },
  InteractiveCenter: {
    base: InteractiveCenterOutlineIcon,
    hoverIcon: InteractiveCenterFilledIcon,
    activeIcon: InteractiveCenterFilledIcon
  },
  Neighborhood: {
    base: NeighborhoodOutlineIcon,
    hoverIcon: NeighborhoodFilledIcon,
    activeIcon: NeighborhoodFilledIcon
  },
  Desk: {
    base: OfficeOutlineIcon,
    hoverIcon: OfficeFilledIcon,
    activeIcon: OfficeFilledIcon
  },
  Office: {
    base: OfficeOutlineIcon,
    hoverIcon: OfficeFilledIcon,
    activeIcon: OfficeFilledIcon
  },
  SharedOffice: {
    base: SharedOfficeOutlineIcon,
    hoverIcon: SharedOfficeFilledIcon,
    activeIcon: SharedOfficeFilledIcon
  },
  SpecialtyProjectRoom: {
    base: SpecialtyProjectRoomOutlineIcon,
    hoverIcon: SpecialtyProjectRoomFilledIcon,
    activeIcon: SpecialtyProjectRoomFilledIcon
  },
  TeamOffice: {
    base: TeamOfficeOutlineIcon,
    hoverIcon: TeamOfficeFilledIcon,
    activeIcon: TeamOfficeFilledIcon
  },
  TechnicalBriefingCenter: {
    base: TechnicalBriefingCenterOutlineIcon,
    hoverIcon: TechnicalBriefingCenterFilledIcon,
    activeIcon: TechnicalBriefingCenterFilledIcon
  },
  TrainingRoom: {
    base: TrainingRoomOutlineIcon,
    hoverIcon: TrainingRoomFilledIcon,
    activeIcon: TrainingRoomFilledIcon
  },
  UnderConstruction: {
    // TODO: replace when we have
    base: OtherOutlineIcon,
    hoverIcon: OtherFilledIcon,
    activeIcon: OtherFilledIcon
  },
  MultiPurposeRoom: {
    base: MultiPurposeRoomOutlineIcon,
    hoverIcon: MultiPurposeRoomFilledIcon,
    activeIcon: MultiPurposeRoomFilledIcon
  },
  DiningServery: {
    base: CafeAndKitchensOutlineIcon,
    hoverIcon: CafeAndKitchensFilledIcon,
    activeIcon: CafeAndKitchensFilledIcon
  }
};

export var PoiType;
(function (PoiType) {
    PoiType["AEDDevice"] = "AEDDevice";
    PoiType["KioskLocation"] = "KioskLocation";
    PoiType["FireExtinguisher"] = "FireExtinguisher";
    PoiType["EmergencyExit"] = "EmergencyExit";
    PoiType["BombShelter"] = "BombShelter";
})(PoiType || (PoiType = {}));
export var poiTypesLabels = {
    AEDDevice: PoiType.AEDDevice,
    KioskLocation: PoiType.KioskLocation,
    FireExtinguisher: PoiType.FireExtinguisher,
    EmergencyExit: PoiType.EmergencyExit,
    BombShelter: PoiType.BombShelter
};

var BadgeScanHttpProvider = /** @class */ (function () {
    function BadgeScanHttpProvider(httpService, baseApiUrl) {
        this.httpService = httpService;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.logAndReject = function (error, url) {
            var errorMessage = error ? error : "error encountered during http request";
            if (url) {
                errorMessage = "error encountered during http request from following url" + { url: url };
            }
            return Promise.reject(errorMessage);
        };
        this.endpointUrl = "".concat(baseApiUrl, "/api/v1.0/badgescan");
    }
    BadgeScanHttpProvider.prototype.getUserAliasFromCardKey = function (cardKeyId) {
        var _this = this;
        if (!cardKeyId) {
            return this.logAndReject("Card key number cannot be undefined or empty");
        }
        return this.httpService
            .get("".concat(this.endpointUrl, "/").concat(cardKeyId))
            .then(function (res) {
            var _a;
            var response = (_a = {}, _a[res.data.cardKeyNbr] = res.data, _a);
            return response;
        })
            .catch(function (error) { return _this.logAndReject(error, "".concat(_this.endpointUrl, "/").concat(cardKeyId)); });
    };
    return BadgeScanHttpProvider;
}());
export { BadgeScanHttpProvider };

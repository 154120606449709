var _a;
import { ConferenceStatus, SensorDataType } from "@smartbuilding/management-api-types";
var ParsedSensorData = /** @class */ (function () {
    function ParsedSensorData(rawValue, sensorDataType) {
        this.rawValue = rawValue;
        var dataTypeKey = sensorDataType.toLowerCase();
        var finalKey = Object.prototype.hasOwnProperty.call(ParsedSensorData.transformMap, dataTypeKey)
            ? dataTypeKey
            : ParsedSensorData.defaultSensorHandler;
        ParsedSensorData.transformMap[finalKey](this, rawValue);
    }
    ParsedSensorData.defaultSensorHandler = "__default__";
    ParsedSensorData.transformMap = (_a = {},
        _a[ParsedSensorData.defaultSensorHandler] = function (metaData, rawValue) {
            metaData.value = rawValue;
            metaData.displayValue = rawValue;
        },
        _a[SensorDataType.ConferenceStatusWithCalendarEvents.toLowerCase()] = function (metaData, rawValue) {
            var calendarSensorValue;
            try {
                calendarSensorValue = JSON.parse(rawValue);
            }
            catch (_a) {
                calendarSensorValue = {
                    CalendarEvents: [],
                    Status: ConferenceStatus.Error
                };
            }
            metaData.value = calendarSensorValue;
            metaData.displayValue = calendarSensorValue.Status;
        },
        _a);
    return ParsedSensorData;
}());
export { ParsedSensorData };

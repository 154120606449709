import { IPersonCardData, IPersonSuggestionData } from "@smartbuilding/smartbuilding-api-service";
import { IOrganizerDetails } from "@smartbuilding/ui-components-space-card";
import { IPerson } from "@smartbuilding/people-service";

export type SpacePersonMap = Record<string, IPerson[]>; // < space id, list of people >
export type SpacePersonIdMap = Record<string, string[]>; // < space id, list of people ids>
export type PersonBlobImageMap = Record<string, string>; // < person id, blob url>
export type SuggestionBlobImageMap = Record<string, string>; // < upn, blob url>
export type PeopleCardMap = Record<string, IPersonCardData>; // <person card id, personCardData>
export type PeopleSuggestionMap = Record<string, IPersonSuggestionData[]>; // <search key, suggested personCardData>
export type PeopleCardStatus = Record<string, boolean>; // <person card id , status> status here determines whether card is valid or not for eg: if valid <id, true>
export type People = Record<string, boolean>;
export interface IPeopleStore {
  currentUser?: IOrganizerDetails;
  featureDisabled: boolean;
  people: IPerson[];
  spacePeopleMap: SpacePersonIdMap;
  peopleImageMap: IPersonBlobImageMap;
  suggestionImageMap: ISuggestionBlobImageMap;
  selectedPerson?: IPerson;
  peopleCardMap: Record<string, IPersonCardData>;
  badgeScanStatus: string;
  scannedPersonCardId: string;
  suggestionsSearchingKey: string;
  peopleSuggestionMap: Record<string, IPersonSuggestionData[]>;
}

export enum ImageSize {
  Small = "48x48",
  Large = "120x120"
}

export interface IPersonBlobImageMap {
  [ImageSize.Small]: PersonBlobImageMap;
  [ImageSize.Large]: PersonBlobImageMap;
}

export interface ISuggestionBlobImageMap {
  [ImageSize.Small]: SuggestionBlobImageMap;
  [ImageSize.Large]: SuggestionBlobImageMap;
}

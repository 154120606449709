var Multipart = /** @class */ (function () {
    function Multipart() {
        this.boundary = "MultipartBoundary" + this.randomString(10);
        this.body = "";
    }
    Multipart.prototype.appendFormPart = function (name, content, contentType) {
        this.body = "";
        this.body += this.constructFormPart(name, content, contentType);
    };
    Multipart.prototype.toString = function () {
        return "".concat(this.body, "--").concat(this.boundary, "--");
    };
    Multipart.prototype.constructFormPart = function (name, content, contentType) {
        var data = "";
        data += "--" + this.boundary + "\r\n";
        if (contentType && contentType !== undefined) {
            data += "Content-Type: " + contentType + "\r\n";
        }
        data += 'Content-Disposition: form-data; name="' + name + '"\r\n';
        // There's a blank line between the metadata and the data
        data += "\r\n";
        // Append the text data to our body's request
        data += content + "\r\n";
        return data;
    };
    Multipart.prototype.randomString = function (length) {
        var text = "";
        var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return text;
    };
    return Multipart;
}());
export { Multipart };

export var PrimaryStatusValue;
(function (PrimaryStatusValue) {
    PrimaryStatusValue["Available"] = "Available";
    PrimaryStatusValue["Error"] = "Error";
    PrimaryStatusValue["Occupied"] = "Occupied";
    PrimaryStatusValue["Reserved"] = "Reserved";
    PrimaryStatusValue["Unoccupied"] = "Unoccupied";
    PrimaryStatusValue["NonReservable"] = "Non-reservable";
})(PrimaryStatusValue || (PrimaryStatusValue = {}));
export var SecondaryStatusValue;
(function (SecondaryStatusValue) {
    SecondaryStatusValue["Occupied"] = "occupied";
    SecondaryStatusValue["Unoccupied"] = "unoccupied";
    SecondaryStatusValue["NonReservable"] = "non-reservable";
    SecondaryStatusValue["NotReserved"] = "not reserved";
    SecondaryStatusValue["OccupancyUnknown"] = "Occupancy unknown";
    SecondaryStatusValue["NoOccupancySensor"] = "no occupancy sensor";
})(SecondaryStatusValue || (SecondaryStatusValue = {}));
export var SensorLayerColors;
(function (SensorLayerColors) {
    SensorLayerColors["GREEN"] = "#a3c494";
    SensorLayerColors["ORANGE"] = "#f7ce7c";
    SensorLayerColors["RED"] = "#c48282";
    SensorLayerColors["TRANSPARENT"] = "transparent";
})(SensorLayerColors || (SensorLayerColors = {}));
export var SpaceStatusIcon;
(function (SpaceStatusIcon) {
    SpaceStatusIcon["Reserved"] = "Reserved";
    SpaceStatusIcon["Occupied"] = "Occupied";
    SpaceStatusIcon["Available"] = "Available";
})(SpaceStatusIcon || (SpaceStatusIcon = {}));
export var BusynessStatusValue;
(function (BusynessStatusValue) {
    BusynessStatusValue["VeryBusy"] = "Very busy";
    BusynessStatusValue["Busy"] = "Busy";
    BusynessStatusValue["NotBusy"] = "Not busy";
    BusynessStatusValue["OccupancyUnknown"] = "Occupancy unknown";
})(BusynessStatusValue || (BusynessStatusValue = {}));
export var BusynessStatusColors;
(function (BusynessStatusColors) {
    BusynessStatusColors["GREEN"] = "#92C353";
    BusynessStatusColors["ORANGE"] = "#C56653";
    BusynessStatusColors["RED"] = "#D74553";
    BusynessStatusColors["TRANSPARENT"] = "transparent";
    BusynessStatusColors["WHITE"] = "#FFFFFF";
})(BusynessStatusColors || (BusynessStatusColors = {}));
